import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import css from './SavedSearches.module.css';
import PanelHeader from '../PanelHeader/PanelHeader';

import SavedSearchCard from './SavedSearchCard/SavedSearchCard';
import notify from '../../../../lib/notify';
import { modalStyles } from '../../../shared/modal';
import RemoveModal from './RemoveModal/RemoveModal';
import SetHomeFeed from './SetHomeFeed/SetHomeFeed';
import RemoveHomeFeed from './RemoveHomeFeed/RemoveHomeFeed';
import EditSavedSearch from './EditSavedSearch/EditSavedSearch';
import useSavedSearches from '../../../hooks/useSavedSearches';

const typeModal = {
  REMOVE: 'remove',
  SET_FEED: 'setFeed',
  REMOVE_FEED: 'removeFeed',
  EDIT_SEARCH: 'editSearch',
};

function SavedSearches() {
  const [openedModal, setOpenedModal] = useState(null);
  const [activeSearchText, setActiveSearchText] = useState('');

  const {
    data,
    loading,
    refetch,
    updateSearch,
    deleteSavedSearch,
    removeSavedSearchFromCache,
  } = useSavedSearches();

  const savedSearches = data?.listSavedSearchs?.savedSearches || [];
  const count = savedSearches.length;

  const removeSavedSearch = (item) => {
    setOpenedModal({ modal: typeModal.REMOVE, context: item });
  };

  const onRemoveConfirmed = async (savedSearchId) => {
    try {
      await deleteSavedSearch({ variables: { savedSearchId } });
      removeSavedSearchFromCache(savedSearchId);
      notify.success('Saved search deleted!');
    } catch ({ errors }) {
      notify.error(errors[0].message);
    } finally {
      setOpenedModal(null);
    }
  };

  const setAsHomeFeed = (item) => {
    setOpenedModal({ modal: typeModal.SET_FEED, context: item });
  };

  const prepareDataToSave = (item, homeFlag) => {
    const parsed = JSON.parse(item.search_data);

    return {
      filterEntities: parsed.filterEntities.map(({ id }) => id),
      filterIndustries: parsed.filterIndustries.map(({ id }) => id),
      name: parsed.name,
      topics: parsed.topics.map(({ id }) => id),
      filterCategories: parsed.filterCategories,
      searchDates: parsed.searchDates,
      isHomeFeed: homeFlag !== undefined ? homeFlag : parsed.isHomeFeed,
    };
  };

  const onConfirmSetAsFeed = async (item) => {
    try {
      const updatedData = prepareDataToSave(item, true);

      await updateSearch({
        variables: {
          savedSearchId: item.id,
          updatedName: item.name,
          updatedData: JSON.stringify(updatedData),
        },
      });
      refetch();
      notify.success('New Home Search!');
    } catch (error) {
      notify.error(error);
    } finally {
      setOpenedModal(null);
    }
  };

  const removeHomeFeed = (item) => {
    setOpenedModal({ modal: typeModal.REMOVE_FEED, context: item });
  };

  const onConfirmRemoveHomeFeed = async (item) => {
    try {
      const updatedData = prepareDataToSave(item, false);
      await updateSearch({
        variables: {
          savedSearchId: item.id,
          updatedName: item.name,
          updatedData: JSON.stringify(updatedData),
        },
      });

      refetch();
      notify.success('Home search removed!');
    } catch (error) {
      notify.error(error);
    } finally {
      setOpenedModal(null);
    }
  };

  const editSearch = (item) => {
    setOpenedModal({ modal: typeModal.EDIT_SEARCH, context: item });
  };

  const onConfirmEditSearch = async (item) => {
    try {
      const updatedData = prepareDataToSave(item);

      await updateSearch({
        variables: {
          savedSearchId: item.id,
          updatedName: item.name,
          updatedData: JSON.stringify(updatedData),
        },
      });
      refetch();
      notify.success('Saved search updated!');
    } catch (error) {
      notify.error(error);
    } finally {
      setOpenedModal(null);
    }
  };

  const onSearch = (newSearchText) => {
    if (newSearchText === activeSearchText) return;

    setActiveSearchText(newSearchText);
    refetch({ name: newSearchText });
  };

  useEffect(() => {
    // always re-fetch when user open saved search panel
    refetch();
  }, []);

  return (
    <div className={css.main}>
      <PanelHeader title="Saved searches" count={count} onSearch={onSearch} />

      {loading && <LoadingState />}

      {!loading && (
        <div className={css.list}>
          {savedSearches.map((search) => (
            <SavedSearchCard
              key={search.id}
              search={search}
              setAsHomeFeed={setAsHomeFeed}
              onRemove={removeSavedSearch}
              onRemoveHomeFeed={removeHomeFeed}
              onEdit={editSearch}
            />
          ))}
        </div>
      )}

      <Modal
        isOpen={Boolean(openedModal)}
        style={modalStyles}
        contentLabel="Update Saved Search"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpenedModal(null)}
      >
        {openedModal?.modal === typeModal.REMOVE && (
          <RemoveModal
            onClose={() => setOpenedModal(null)}
            onConfirm={() => onRemoveConfirmed(openedModal.context.id)}
            name={openedModal.context.name}
          />
        )}

        {openedModal?.modal === typeModal.SET_FEED && (
          <SetHomeFeed
            onClose={() => setOpenedModal(null)}
            onConfirm={(newName) =>
              onConfirmSetAsFeed({ ...openedModal.context, name: newName })
            }
            name={openedModal.context.name}
          />
        )}

        {openedModal?.modal === typeModal.REMOVE_FEED && (
          <RemoveHomeFeed
            onClose={() => setOpenedModal(null)}
            onConfirm={() => onConfirmRemoveHomeFeed(openedModal.context)}
            name={openedModal.context.name}
          />
        )}

        {openedModal?.modal === typeModal.EDIT_SEARCH && (
          <EditSavedSearch
            onClose={() => setOpenedModal(null)}
            onConfirm={onConfirmEditSearch}
            search={openedModal.context}
          />
        )}
      </Modal>
    </div>
  );
}

export default SavedSearches;

const LoadingState = () => (
  <div className={css.loadingState}>
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
  </div>
);
