import React from 'react';
import FilterType from '../FilterType/FilterType';
import { filterTypes } from '../../index';

function Events({ activeFilter, onClick, count, isLoadingCounts }) {
  return (
    <FilterType
      label="Events"
      count={count}
      isLoadingCounts={isLoadingCounts}
      activeFilter={activeFilter}
      type={filterTypes.Events}
      onClick={onClick}
    >
      {/*  Temporarly removed */}
      {/* {options &&
        options.map((option) => (
          <OptionRow
            key={option.id}
            option={option}
            handleOnInputChange={onOptionChange}
          />
        ))} */}
    </FilterType>
  );
}

export default Events;
