import React from 'react';

import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../Buttom/Buttom';

import css from './RemoveModal.module.css';

function RemoveModal({ onClose, onConfirm, name }) {
  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Remove Saved Search</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      <div className={css.copy}>
        Are you sure you want to remove &quot;{name}&quot; from your saved
        searches ?
      </div>

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="danger" onClick={onConfirm}>
          Remove
        </Button>
      </div>
    </div>
  );
}

export default RemoveModal;
