import React from 'react';

import css from './FilterType.module.css';
import Total from '../../../../../components/Total/Total';

function FilterType({
  activeFilter,
  label,
  count,
  onClick,
  type,
  children,
  isLoadingCounts,
  isActiveFilterCollapsed = false,
}) {
  const selected = activeFilter === type;
  const isOptionsVisible = selected && !isActiveFilterCollapsed;

  return (
    <div
      className={css.main}
      data-selected={selected}
      data-expanded={isOptionsVisible}
    >
      <div
        className={css.header}
        data-selected={selected}
        onClick={() => onClick(type)}
      >
        {label}

        <Total total={count} isLoading={isLoadingCounts} />
      </div>

      {children && (
        <div data-selected={isOptionsVisible} className={css.content}>
          {children}
        </div>
      )}
    </div>
  );
}

export default FilterType;
