import React, { useState, useEffect } from 'react';
import InfoIcon from '../../../../icons/InfoIcon/InfoIcon';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import css from './SecondaryStrengthFilter.module.css';

const SecondaryStengthValue = ({ value, isSelected, onClick }) => (
  <div
    className={css.value}
    data-selected={isSelected ? 'selected' : 'unselected'}
    data-cy-strength={value}
    onClick={onClick}
  >
    <div className={css.indicator}>{value}</div>
  </div>
);

export const SecondaryStrengthFilter = ({
  setGraphFilterSecondaryStrengths,
  currentGraphFilterSecondaryStrengths,
}) => {
  const [significance, setSignificance] = useState(
    currentGraphFilterSecondaryStrengths,
  );

  useEffect(() => {
    if (!significance) return;
    const activeSignificance = JSON.stringify(
      currentGraphFilterSecondaryStrengths,
    );
    const newSignificance = JSON.stringify(significance);
    if (activeSignificance === newSignificance) return;
    setGraphFilterSecondaryStrengths(significance);
  }, [significance]);

  const updateSignificance = (value) => {
    setSignificance((currentSignificance) => {
      if (currentSignificance.includes(value)) {
        return currentSignificance.filter((s) => s !== value);
      }
      return [...currentSignificance, value].sort();
    });
  };

  return (
    <div className={css.section}>
      <div className={css.title}>
        <p className={css.titleDetail}>Secondary strength</p>
        <Tooltip
          className={css.tooltipWrapper}
          text={
            <p className={css.tooltip}>
              Secondary Strength filters the lines between entities on the
              periphery of <br /> your graph to limit connections that don’t go
              towards the core.
            </p>
          }
        >
          <InfoIcon className={css.tip} />
        </Tooltip>
      </div>
      <div className={css.controls}>
        <SecondaryStengthValue
          value="1"
          isSelected={significance.includes(1)}
          onClick={() => updateSignificance(1)}
        />
        <SecondaryStengthValue
          value="2"
          isSelected={significance.includes(2)}
          onClick={() => updateSignificance(2)}
        />
        <SecondaryStengthValue
          value="3"
          isSelected={significance.includes(3)}
          onClick={() => updateSignificance(3)}
        />
        <SecondaryStengthValue
          value="4"
          isSelected={significance.includes(4)}
          onClick={() => updateSignificance(4)}
        />
        <SecondaryStengthValue
          value="5"
          isSelected={significance.includes(5)}
          onClick={() => updateSignificance(5)}
        />
      </div>
    </div>
  );
};
