/* eslint-disable */
import React from 'react';

import ReactMarkdown from 'react-markdown';
import css from './SmartResponse.module.css';
import AiBadge from '../../../../components/AiBadge/AiBadge';

function SmartResponse({ response }) {
  if (!response) return null;

  return (
    <div className={css.main}>
      <div className={css.title} data-cy="semantic-search-response">
        Answer
        <AiBadge />
      </div>
      <ReactMarkdown className={css.response} children={response} />
    </div>
  );
}

export default SmartResponse;
