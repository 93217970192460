import React, { useState } from 'react';
import Email from '../../../../icons/LoginEmail';
import css from './EmailInput.module.css';

const EmailInput = ({
  className = '',
  value = '',
  hasErrors = false,
  onChange = () => {},
}) => {
  const [emailIsFocused, setEmailIsFocused] = useState(false);

  return (
    <div className={className}>
      <p className={css.label}>Email address</p>
      <div className={css.content}>
        <div className={css.icon}>
          <Email color={emailIsFocused === true ? '#8f59ff' : null} />
        </div>
        <input
          name="email"
          onBlur={() => setEmailIsFocused(false)}
          onFocus={() => setEmailIsFocused(true)}
          onChange={onChange}
          value={value}
          className={css.input}
          data-status={hasErrors ? 'error' : 'normal'}
          type="email"
        />
      </div>
    </div>
  );
};

export default EmailInput;
