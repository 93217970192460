import React, { useState } from 'react';
import css from './Checkbox.module.css';

const Checkbox = ({ text }) => {
  const [isChecked, toggleChecked] = useState(false);

  return (
    <div
      onClick={() => toggleChecked((c) => !c)}
      className={css.main}
      data-status={isChecked ? 'checked' : 'unchecked'}
    >
      <div className={css.container}>
        <div className={css.dot} />
      </div>
      <p className={css.text}>{text}</p>
    </div>
  );
};

export default Checkbox;
