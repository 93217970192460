import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import css from './SavedSearchModal.module.css';

import CloseIcon from '../../icons/CloseIcon/CloseIcon';
import BriefPill from '../BriefPill/BriefPill';
import EntityPill from '../EntityPill/EntityPill';
import { useRouteInformation } from '../../contexts/RouteInformationContext';

import { getLabelFromType } from '../../lib/search';

const SavedSearchModal = ({
  openSaveSearchModal,
  handleCloseSaveSearchModal,
  handleSaveSearchNameChange,
  handleSaveSearch,
  searchTypes,
  searchIndustries,
  searchEntities,
  searchCountries,
  searchLandscapes = [],
  activeSearch,
  saveSearchName,
  searchDates,
  isHomeFeed,
  updateType = 'edit',
}) => {
  const [saveSearchNameError, setSaveSearchNameError] = useState(false);
  const [savedSearchRawPayload, setSavedSearchRawPayload] = useState({
    searchEntities,
    searchIndustries,
    activeSearch,
    searchCountries,
    searchLandscapes,
    searchTypes,
    searchDates,
    isHomeFeed,
  });

  useEffect(() => {
    setSavedSearchRawPayload({
      searchEntities,
      searchIndustries,
      activeSearch,
      searchCountries,
      searchLandscapes,
      searchTypes,
      searchDates,
      isHomeFeed,
    });
  }, [openSaveSearchModal]);

  useEffect(() => {
    if (
      savedSearchRawPayload.activeSearch === '' &&
      !savedSearchRawPayload.searchCountries.length &&
      !savedSearchRawPayload.searchEntities.length &&
      !savedSearchRawPayload.searchIndustries.length &&
      !savedSearchRawPayload.searchTypes.length &&
      !savedSearchRawPayload.searchLandscapes.length
    ) {
      handleCloseSaveSearchModal();
    }
  }, [savedSearchRawPayload]);

  const router = useRouteInformation();
  const handleEntityClick = (entityId) => {
    router.openDossier({ dossierEntityId: entityId, dossierType: 'entity' });
  };

  const showType = savedSearchRawPayload.searchTypes.length > 0;
  const showIndustry = savedSearchRawPayload.searchIndustries.length > 0;
  const showCountry = savedSearchRawPayload.searchCountries.length > 0;
  const showEntities = savedSearchRawPayload.searchEntities.length > 0;
  const showLandscapes = savedSearchRawPayload.searchLandscapes.length > 0;

  const handleRemoveType = (type) => {
    const newTypes = savedSearchRawPayload.searchTypes.filter(
      (t) => t !== type,
    );
    setSavedSearchRawPayload({
      ...savedSearchRawPayload,
      searchTypes: newTypes,
    });
  };

  const handleRemoveEntity = (entityId) => {
    const newEntities = savedSearchRawPayload.searchEntities.filter(
      (entity) => entity.id !== entityId,
    );
    setSavedSearchRawPayload({
      ...savedSearchRawPayload,
      searchEntities: newEntities,
    });
  };

  const handleRemoveIndustry = (industryId) => {
    const newIndustries = savedSearchRawPayload.searchIndustries.filter(
      (industry) => industry.id !== industryId,
    );
    setSavedSearchRawPayload({
      ...savedSearchRawPayload,
      searchIndustries: newIndustries,
    });
  };

  const handleRemoveCountry = (countryId) => {
    const newCountries = savedSearchRawPayload.searchCountries.filter(
      (country) => country.id !== countryId,
    );
    setSavedSearchRawPayload({
      ...savedSearchRawPayload,
      searchCountries: newCountries,
    });
  };

  const handleRemoveLandscape = (landscapeId) => {
    const newLandscapes = savedSearchRawPayload.searchLandscapes.filter(
      (landscape) => landscape.id !== landscapeId,
    );
    setSavedSearchRawPayload({
      ...savedSearchRawPayload,
      searchLandscapes: newLandscapes,
    });
  };

  const handleRemoveFreeText = () => {
    setSavedSearchRawPayload({
      ...savedSearchRawPayload,
      activeSearch: '',
    });
  };

  const getHomeFeedCondition = (savedSearchPayload) => {
    if (updateType === 'setHomeFeed') {
      return true;
    }

    if (updateType === 'removeHomeFeed') return false;

    return savedSearchPayload.isHomeFeed || false;
  };

  const handleSave = () => {
    if (!saveSearchName && (updateType === 'edit' || updateType === 'save')) {
      setSaveSearchNameError(true);
      return;
    }
    handleSaveSearch({
      filterEntities: savedSearchRawPayload.searchEntities.map(
        (entity) => entity.id,
      ),
      filterIndustries: savedSearchRawPayload.searchIndustries.map(
        (industry) => industry.id,
      ),
      name: savedSearchRawPayload.activeSearch,
      topics: savedSearchRawPayload.searchCountries.map(
        (country) => country.id,
      ),
      filterCategories: savedSearchRawPayload.searchTypes,
      filterLandscapes: savedSearchRawPayload.searchLandscapes.map(
        (landscape) => landscape.id,
      ),
      searchDates: savedSearchRawPayload.searchDates,
      isHomeFeed: getHomeFeedCondition(savedSearchRawPayload),
    });
  };

  const handleClose = () => {
    setSaveSearchNameError(false);
    handleCloseSaveSearchModal();
  };

  const sortByEntityType = (arr) => {
    const order = ['Actor', 'Plot'];
    arr.sort((a, b) => {
      const aIndex = order.indexOf(a.entityType);
      const bIndex = order.indexOf(b.entityType);
      return aIndex - bIndex;
    });
    return arr;
  };

  const getTitle = () => {
    switch (updateType) {
      case 'edit':
        return 'Edit saved search';
      case 'setHomeFeed':
        return 'Set as your home feed';
      case 'removeHomeFeed':
        return 'Remove as your home feed';
      case 'save':
        return 'Save search';
      default:
        return 'Edit saved search';
    }
  };

  const getButtonTitle = () => {
    switch (updateType) {
      case 'edit':
        return 'Save';
      case 'setHomeFeed':
        return 'Confirm';
      case 'removeHomeFeed':
        return 'Remove';
      default:
        return 'Save';
    }
  };

  return (
    <Modal
      isOpen={openSaveSearchModal}
      contentLabel={getTitle()}
      style={{
        content: {
          backgroundColor: 'white',
          width: `400px`,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 20000,
        },
      }}
    >
      <div className={css.saveSearchModal}>
        <div className={css.saveSearchModalHeader}>
          <h1 data-cy="saved-search-modal-title">{getTitle()}</h1>
          <button
            data-cy="saved-search-modal-close"
            type="button"
            className={css.close}
            onClick={handleClose}
          >
            <CloseIcon className={css.closeIcon} />
          </button>
        </div>
        {(updateType === 'edit' || updateType === 'save') && (
          <div className={css.saveSearchModalBody}>
            <div className={css.searchDetails}>
              <>
                {showType && (
                  <div className={css.paramContainer}>
                    {savedSearchRawPayload.searchTypes.map((type, index) => (
                      <div className={css.typePill} key={index + type}>
                        {getLabelFromType(type)}
                        <span onClick={() => handleRemoveType(type)}>
                          <CloseIcon className={css.closeType} />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className={
                    showEntities ||
                    showIndustry ||
                    showCountry ||
                    savedSearchRawPayload.activeSearch
                      ? css.innerBox
                      : null
                  }
                >
                  {showEntities ? (
                    <>
                      <p
                        data-cy="saved-search-modal-entities"
                        className={css.paramTitle}
                      >
                        Entities
                      </p>
                      <div className={css.paramContainer}>
                        {sortByEntityType(
                          savedSearchRawPayload.searchEntities,
                        ).map((entity, index) => (
                          <>
                            <EntityPill
                              key={index + entity.id}
                              name={entity.name}
                              shortName={entity.shortestName}
                              image={entity.image}
                              type={entity.entityType}
                              inSubscription={entity.inSubscription}
                              size="small"
                              onClick={() => handleEntityClick(entity.id)}
                              onClose={() => {
                                handleRemoveEntity(entity.id);
                              }}
                            />
                          </>
                        ))}
                      </div>
                    </>
                  ) : null}
                  {showIndustry ? (
                    <>
                      <p
                        data-cy="saved-search-modal-industries"
                        className={css.paramTitle}
                      >
                        Industries
                      </p>
                      <div className={css.paramContainer}>
                        {savedSearchRawPayload.searchIndustries.map(
                          (industry, index) => (
                            <BriefPill
                              key={index + industry.id}
                              name={industry.name}
                              onClose={() => {
                                handleRemoveIndustry(industry.id);
                              }}
                            />
                          ),
                        )}
                      </div>
                    </>
                  ) : null}
                  {showCountry ? (
                    <>
                      <p
                        data-cy="saved-search-modal-countries"
                        className={css.paramTitle}
                      >
                        Countries
                      </p>
                      <div className={css.paramContainer}>
                        {savedSearchRawPayload.searchCountries.map(
                          (country, index) => (
                            <BriefPill
                              key={index + country.id}
                              name={country.name}
                              isGeography
                              onClose={() => {
                                handleRemoveCountry(country.id);
                              }}
                            />
                          ),
                        )}
                      </div>
                    </>
                  ) : null}
                  {showLandscapes ? (
                    <>
                      <p
                        data-cy="saved-search-modal-landscapes"
                        className={css.paramTitle}
                      >
                        Landscapes
                      </p>
                      <div className={css.paramContainer}>
                        {savedSearchRawPayload.searchLandscapes.map(
                          (landscape, index) => (
                            <BriefPill
                              key={index + landscape.id}
                              name={landscape.name}
                              onClose={() => {
                                handleRemoveLandscape(landscape.id);
                              }}
                            />
                          ),
                        )}
                      </div>
                    </>
                  ) : null}
                  {savedSearchRawPayload.activeSearch && (
                    <>
                      <p
                        data-cy="saved-search-modal-free-text"
                        className={css.paramTitle}
                      >
                        Free Text
                      </p>
                      <div className={css.paramContainer}>
                        <div className={css.textSearch}>
                          &quot;{savedSearchRawPayload.activeSearch}&quot;
                          <span onClick={handleRemoveFreeText}>
                            <CloseIcon className={css.closeFreeText} />
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            </div>

            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              className={
                saveSearchNameError ? css.errorNameInput : css.nameInput
              }
              type="text"
              placeholder="Name your search"
              onChange={handleSaveSearchNameChange}
              value={saveSearchName}
              data-cy="saved-search-modal-search-name"
            />
            {saveSearchNameError && (
              <p data-cy="saved-search-modal-error" className={css.errorMsg}>
                Please enter a name for your search
              </p>
            )}
          </div>
        )}

        {updateType === 'setHomeFeed' && (
          <div className={css.saveSearchModalBody}>
            <p className={css.message}>
              Are you sure you want to set this as your home feed?
            </p>
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              className={
                saveSearchNameError ? css.errorNameInput : css.nameInput
              }
              type="text"
              placeholder="Name your search"
              onChange={handleSaveSearchNameChange}
              value={saveSearchName}
              data-cy="saved-search-modal-search-name"
            />
          </div>
        )}

        {updateType === 'removeHomeFeed' && (
          <div>
            <p className={css.message}>
              This will reset your home feed to your default subscriptions
            </p>
          </div>
        )}

        <div className={css.saveSearchModalFooter}>
          <button
            className={css.cancelButton}
            type="button"
            onClick={handleClose}
            data-cy="saved-search-modal-cancel"
          >
            Cancel
          </button>
          <button
            data-cy="saved-search-modal-save"
            data-update-type={updateType}
            className={css.saveButton}
            type="button"
            onClick={handleSave}
          >
            {getButtonTitle()}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SavedSearchModal;
