import React from 'react';

const Home = ({ className = '', color = '' }) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 0.999999V2.33333C12 2.88562 11.5523 3.33333 11 3.33333H1C0.447715 3.33333 0 2.88562 0 2.33333V1ZM1.14286 1.11111V2.22222H10.8571V1.11111H1.14286Z"
      fill={color || '#42474D'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.66667C0 9.11438 0.447715 8.66667 1 8.66667H11C11.5523 8.66667 12 9.11438 12 9.66667V11C12 11.5523 11.5523 12 11 12H1C0.447715 12 0 11.5523 0 11V9.66667ZM1.14286 9.77778V10.8889H10.8571V9.77778H1.14286Z"
      fill={color || '#42474D'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.33333C12 4.78105 11.5523 4.33333 11 4.33333H1C0.447715 4.33333 0 4.78105 0 5.33333V6.66667C0 7.21895 0.447715 7.66667 1 7.66667H11C11.5523 7.66667 12 7.21895 12 6.66667V5.33333ZM1.14286 6.55556V5.44444H10.8571V6.55556H1.14286Z"
      fill={color || '#42474D'}
    />
  </svg>
);

export default Home;
