import React from 'react';

const AdvancedSearch = (props) => (
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" {...props}>
    <path
      d="M-0.00195312 13V11H9.99805V13H-0.00195312ZM-0.00195312 8V6H4.99805V8H-0.00195312ZM-0.00195312 3V1H4.99805V3H-0.00195312ZM18.598 13L14.748 9.15C14.348 9.43333 13.9107 9.64567 13.436 9.787C12.9607 9.929 12.4814 10 11.998 10C10.6147 10 9.43571 9.51233 8.46105 8.537C7.48571 7.56233 6.99805 6.38333 6.99805 5C6.99805 3.61667 7.48571 2.43733 8.46105 1.462C9.43571 0.487333 10.6147 0 11.998 0C13.3814 0 14.5607 0.487333 15.536 1.462C16.5107 2.43733 16.998 3.61667 16.998 5C16.998 5.48333 16.9274 5.96267 16.786 6.438C16.644 6.91267 16.4314 7.35 16.148 7.75L19.998 11.6L18.598 13ZM11.998 8C12.8314 8 13.5397 7.70833 14.123 7.125C14.7064 6.54167 14.998 5.83333 14.998 5C14.998 4.16667 14.7064 3.45833 14.123 2.875C13.5397 2.29167 12.8314 2 11.998 2C11.1647 2 10.4564 2.29167 9.87305 2.875C9.28971 3.45833 8.99805 4.16667 8.99805 5C8.99805 5.83333 9.28971 6.54167 9.87305 7.125C10.4564 7.70833 11.1647 8 11.998 8Z"
      fill="currentColor"
    />
  </svg>
);

export default AdvancedSearch;
