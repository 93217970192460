import { gql } from '@apollo/client';

const query = gql`
  query getLandscapes {
    getLandscapes {
      id
      name
    }
  }
`;

export default query;
