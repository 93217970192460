import React, { memo } from 'react';

import { ResponsiveTreeMapHtml } from '@nivo/treemap';

import css from './TreeMap.module.css';
import { nomalizeScore } from './utils';
import TreeNode from '../TreeNode/TreeNode';
import TreeMapEmptyState from '../EmptyState/EmptyState';

function TreeMap({ nodes, onNodeClick }) {
  const getIdentity = (node) => {
    return node.industry?.id + node.country?.id || '';
  };

  const data = {
    name: 'root',
    children: nodes,
  };

  if (nodes.length === 0) return <TreeMapEmptyState />;

  return (
    <div className={css.main}>
      <ResponsiveTreeMapHtml
        data={data}
        identity={getIdentity}
        tile="binary"
        nodeOpacity={1}
        leavesOnly
        value={(node) => nomalizeScore(node.areaPercentage)}
        nodeComponent={(props) => (
          <TreeNode {...props} onNodeClick={onNodeClick} />
        )}
        labelTextColor="#fff"
        borderColor="#fff"
        animate
        motionStiffness={90}
        motionDamping={11}
      />
    </div>
  );
}

export default memo(TreeMap);
