// Dimensions of the graph
export const DOSSIER_WIDTH = 320;
export const HEADER_HEIGHT = 54;
export const CONTROLS_HEIGHT = 70;
export const CONTROLS_WIDTH = 45;

// Colors
export const LABEL_TEXT_COLOR = '#202020';
export const LABEL_TEXT_COLOR_CONNECTED = '#FFFFFF';
export const LABEL_TEXT_COLOR_DISABLED = '#AFAFAF';

export const NODE_COLORS_SOFT = {
  Actor: '#E8DEFA',
  Plot: '#FCCEDC',
  Context: '#CCF1FF',
  Connected: '#8553E6',
  User: '#202020',
};

export const NODE_COLORS_BORDER = {
  Actor: '#D6C5F7',
  Plot: '#F9A3BD',
  Context: '#A5F3FC',
  User: '#202020',
};

// Graph internals
export const MAX_LINK_TEXT_FONT_SIZE = 16;
export const NODE_RELSIZE = 15;

export const LINK_OVERRIDE_RULES = [
  'WORKS_AT',
  'WORKED_AT',
  'PEER',
  'COLLEAGUE',
];
