import React from 'react';

function HelpCenter(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_2813_73973"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2813_73973)">
        <path
          d="M8 12C8.23333 12 8.43056 11.9194 8.59167 11.7583C8.75278 11.5972 8.83333 11.4 8.83333 11.1667C8.83333 10.9333 8.75278 10.7361 8.59167 10.575C8.43056 10.4139 8.23333 10.3333 8 10.3333C7.76667 10.3333 7.56944 10.4139 7.40833 10.575C7.24722 10.7361 7.16667 10.9333 7.16667 11.1667C7.16667 11.4 7.24722 11.5972 7.40833 11.7583C7.56944 11.9194 7.76667 12 8 12ZM7.4 9.43333H8.63333C8.63333 9.03333 8.67778 8.73889 8.76667 8.55C8.85556 8.36111 9.04444 8.12222 9.33333 7.83333C9.72222 7.44444 9.99722 7.11944 10.1583 6.85833C10.3194 6.59722 10.4 6.3 10.4 5.96667C10.4 5.37778 10.2 4.90278 9.8 4.54167C9.4 4.18056 8.86111 4 8.18333 4C7.57222 4 7.05278 4.15 6.625 4.45C6.19722 4.75 5.9 5.16667 5.73333 5.7L6.83333 6.13333C6.91111 5.83333 7.06667 5.59167 7.3 5.40833C7.53333 5.225 7.80556 5.13333 8.11667 5.13333C8.41667 5.13333 8.66667 5.21389 8.86667 5.375C9.06667 5.53611 9.16667 5.75 9.16667 6.01667C9.16667 6.20556 9.10556 6.40556 8.98333 6.61667C8.86111 6.82778 8.65556 7.06111 8.36667 7.31667C8 7.63889 7.74722 7.94722 7.60833 8.24167C7.46944 8.53611 7.4 8.93333 7.4 9.43333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM3.33333 12.6667H12.6667V3.33333H3.33333V12.6667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default HelpCenter;
