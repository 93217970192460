/* eslint-disable no-param-reassign */
import React, { useMemo, useState } from 'react';

import Modal from 'react-modal';
import { uniqueId } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';

import Input from '../../Input/Input';
import Button from '../../Buttom/Buttom';
import notify from '../../../../lib/notify';
import Trash from '../../../../icons/Trash';
import css from './SetupPdbModal.module.css';
import { modalStyles } from '../../../shared/modal';
import { buildTreeOptions } from '../../../../utils';
import UPSERT_PDB from '../../../../queries/upsertUserPdb';
import GENERATE_NEW_PDB from '../../../../queries/generatePdbForUser';
import ModalHeader from '../../Modals/ModalHeader/ModalHeader';
import { useAxisData } from '../../../../contexts/AxisDataContext';
import IndustryFilter from '../../Filters/IndustryFilter/IndustryFilter';
import CountryFilter from '../../Filters/CountryFilter/CountryFilter';

function SetupPdbModal({ config, isOpen, onClose, onSave, isLoading = false }) {
  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      contentLabel="Setup Pdb modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      <div className={css.main}>
        <ModalHeader title="Set up my PDB" onClose={onClose} />
        <div className={css.hint}>
          Try to fill as many details as possible to receive the best PDB.
        </div>
        {isLoading && (
          <div className={css.loadingState}>
            <div
              style={{
                display: 'grid',
                gap: '24px',
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <div style={{ height: 40 }} className="skeleton-v2" />
              <div style={{ height: 40 }} className="skeleton-v2" />
            </div>
            <div style={{ height: 200 }} className="skeleton-v2" />
            <div style={{ height: 40 }} className="skeleton-v2" />
            <div style={{ height: 40 }} className="skeleton-v2" />
            <div style={{ height: 40 }} className="skeleton-v2" />
          </div>
        )}

        {!isLoading && (
          <Form onClose={onClose} config={config} onSave={onSave} />
        )}
      </div>
    </Modal>
  );
}

export default SetupPdbModal;

function Form({ onClose, config, onSave }) {
  const [background, setBackground] = useState(config?.background || '');
  const [error, setError] = useState('');

  const [industries, setIndustries] = useState(() => {
    return config?.industries || [];
  });

  const [countries, setCountries] = useState(() => {
    return config?.countries || [];
  });

  const [inputs, setInputs] = useState(() => {
    if (config?.interests.length > 0) {
      const map = {};
      config.interests.forEach((value) => {
        const id = uniqueId();
        map[id] = { id, value };
      });
      return map;
    }
    return {};
  });

  const { briefs } = useAxisData();
  const [mutate, { loading }] = useMutation(UPSERT_PDB);
  const [generetePDB] = useLazyQuery(GENERATE_NEW_PDB);

  const options = useMemo(() => {
    return buildTreeOptions(briefs);
  }, [briefs]);

  const addNewInput = (value) => {
    const id = uniqueId();
    setInputs((prevMap) => ({
      [id]: {
        id,
        value,
      },
      ...prevMap,
    }));
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      addNewInput(event.target.value);
      event.target.value = '';
      event.target.focus();
    }
  };

  const handleDeleteInterest = (id) => {
    const copy = { ...inputs };
    delete copy[id];
    setInputs(copy);
  };

  const onInputChange = (id, value) => {
    setInputs((prevMap) => ({
      ...prevMap,
      [id]: {
        id,
        value,
      },
    }));
  };

  const notifyError = (err) => {
    notify.error(err || 'An error occurred while saving your pdb setup');
  };

  const interests = Object.values(inputs).reverse();

  const updatePdb = async () => {
    try {
      const { data } = await mutate({
        variables: {
          industries: industries.map((item) => item.id),
          countries: countries.map((item) => item.id),
          background,
          interests: interests.map((item) => item.value),
        },
      });

      if (!data.upsertUserPdb) {
        notifyError();
        return;
      }

      const response = await generetePDB();
      if (response.data.generatePdbForUser?.result === 'OK') {
        notify.success('Your PDB will be available soon...');
      }
      onSave();
    } catch (err) {
      notifyError(err);
    }
  };

  const onSubmit = () => {
    const isFormValid = industries.length > 0 && countries.length > 0;

    if (!isFormValid) {
      setError(
        'At least one industry and country should be selected to save PDB.',
      );
      return;
    }

    setError('');
    updatePdb();
  };

  return (
    <>
      <div className={css.filters}>
        <IndustryFilter
          customClass={css.industryFilter}
          applyFilters={setIndustries}
          activeIndustryFilters={industries}
          options={options}
        />

        <CountryFilter
          activeCountries={countries}
          setActiveCountries={setCountries}
        />
      </div>

      {error && <div className={css.error}>{error}</div>}

      <div>
        <div className={css.label}>Personal Background</div>
        <textarea
          className={css.textarea}
          type="text-area"
          value={background}
          maxLength={256}
          onChange={(e) => setBackground(e.target.value)}
          placeholder="I'm Mishaal, I'm the CEO of Axis, a regulatory data startup. I'm based in New York. I studied law and finance in the UAE and France. Before this I worked in investment banking and geopolitical analysis."
        />
      </div>

      <div>
        <div className={css.label}>My Interests</div>
        <div className={css.interest}>
          <Input
            showIcon={false}
            className={css.input}
            placeholder="Add new interest"
            onKeyDown={handleInputKeyDown}
          />
        </div>
        {interests.map((interest) => (
          <div key={interest.id} className={css.interest}>
            <Input
              showIcon={false}
              value={interest.value}
              onChange={(e) => onInputChange(interest.id, e.target.value)}
              className={css.input}
            />
            <Trash
              className={css.trash}
              onClick={() => handleDeleteInterest(interest.id)}
            />
          </div>
        ))}
      </div>

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Discard Changes
        </Button>
        <Button type="primary" loading={loading} onClick={onSubmit}>
          Save PDB Prompt
        </Button>
      </div>
    </>
  );
}
