/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import css from './SearchResult.module.css';
import { useAxisData } from '../../../../contexts/AxisDataContext';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import { getMostRelevantTopicFromId } from '../../../../lib/topics';

const SearchResult = ({
  id,
  name,
  shortestName,
  image,
  type,
  topics = [],
  onClick,
}) => {
  const { topics: profileTopics } = useAxisData();
  const router = useRouteInformation();
  const { topicId } = router.params;

  const topicFlag = getMostRelevantTopicFromId({
    profileTopics,
    routeTopicId: topicId,
    topicIds: topics,
  });

  return (
    <div
      data-cy="search-header-result-item"
      data-type="mini-search-focus-item"
      tabIndex="0"
      className={css.item}
      onClick={() => onClick({ id, name, topic: topicFlag.id })}
    >
      <EntityPill
        className={css.pill}
        name={name}
        shortName={shortestName}
        image={image}
        type={type}
        size="medium"
      />
    </div>
  );
};

export default SearchResult;
