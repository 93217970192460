import React from 'react';

const Logo = ({ className }) => (
  <svg className={className} viewBox="10 10 135 55" fill="none">
    <g clipPath="url(#clip0)">
      <path
        d="M19.6115 18.538L18.1406 20.0088L55.971 57.8391L57.4418 56.3683L19.6115 18.538Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7609 55.1379L18.875 57.0238C20.906 59.0548 30.8933 52.3597 41.1826 42.0703C51.472 31.781 58.1671 21.7937 56.1361 19.7627L54.2502 21.6486C54.2213 21.7407 54.1873 21.8398 54.1487 21.9474C53.7293 23.1003 52.8664 24.6996 51.5518 26.6615C48.9411 30.5554 44.7908 35.5206 39.7118 40.5995C34.6329 45.6785 29.6677 49.8288 25.7738 52.4395C23.8119 53.7541 22.2126 54.617 21.0597 55.0364L20.8874 55.0966L20.7609 55.1379Z"
        fill="#F23C73"
      />
      <path
        d="M56.1359 19.7625C54.1049 17.7315 44.1177 24.4266 33.8283 34.716C23.5389 45.0054 16.8438 54.9926 18.8748 57.0237L20.7607 55.1378C20.7896 55.0457 20.8236 54.9466 20.8622 54.839C21.2816 53.6861 22.1445 52.0868 23.4591 50.1249C26.0699 46.231 30.2202 41.2658 35.2991 36.1869C40.3781 31.1079 45.3433 26.9576 49.2371 24.3469C51.1991 23.0323 52.7984 22.1693 53.9512 21.75C54.0588 21.7114 54.1579 21.6773 54.25 21.6485L56.1359 19.7625Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M72.566 44.6844C72.4219 45.0502 72.3498 45.2331 72.2284 45.3677C72.1211 45.4866 71.9868 45.578 71.8369 45.6342C71.6672 45.6978 71.4706 45.6978 71.0774 45.6978H70.5936C70.1994 45.6978 70.0022 45.6978 69.8768 45.6149C69.7672 45.5424 69.6905 45.4296 69.6633 45.301C69.6323 45.1539 69.7047 44.9706 69.8496 44.6039L74.4294 33.0093C74.6973 32.3325 75.0709 31.8603 75.5503 31.5924C76.0437 31.3104 76.6711 31.1694 77.4324 31.1694C78.1796 31.1694 78.7929 31.3104 79.2722 31.5924C79.7657 31.8603 80.1393 32.3325 80.393 33.0093L84.8027 44.6136C84.941 44.9777 85.0102 45.1597 84.9781 45.3055C84.9499 45.433 84.8731 45.5444 84.7639 45.616C84.639 45.6978 84.4443 45.6978 84.0549 45.6978H83.5505C83.1517 45.6978 82.9522 45.6978 82.7807 45.6326C82.6291 45.5751 82.4939 45.4816 82.3865 45.3601C82.265 45.2227 82.1945 45.0361 82.0536 44.6629L78.1091 34.2147C77.9822 33.8904 77.7496 33.7283 77.4112 33.7283C77.0729 33.7283 76.8332 33.8904 76.6922 34.2147L72.566 44.6844Z"
        fill="black"
      />
      <path
        d="M93.5295 31.4233C93.8054 31.4233 93.9433 31.4233 94.0711 31.4577C94.1843 31.4882 94.2913 31.5384 94.3872 31.606C94.4953 31.6823 94.5835 31.7883 94.7599 32.0005L97.456 35.2436C97.8696 35.7412 98.0764 35.9899 98.3253 36.083C98.5437 36.1647 98.7837 36.1675 99.0039 36.0909C99.2549 36.0037 99.4674 35.7598 99.8926 35.272L102.769 31.9721C102.945 31.7702 103.033 31.6692 103.139 31.5967C103.233 31.5325 103.338 31.4849 103.448 31.4559C103.573 31.4233 103.707 31.4233 103.975 31.4233H104.577C105.136 31.4233 105.415 31.4233 105.553 31.5364C105.673 31.6348 105.741 31.7833 105.736 31.9384C105.731 32.1169 105.547 32.3274 105.181 32.7486L101.126 37.4072C100.805 37.7752 100.645 37.9592 100.583 38.1664C100.529 38.349 100.527 38.5434 100.58 38.7267C100.639 38.9347 100.797 39.1207 101.112 39.4928L105.259 44.3803C105.615 44.8 105.793 45.0098 105.797 45.1873C105.8 45.3415 105.732 45.4887 105.612 45.586C105.474 45.6979 105.199 45.6979 104.649 45.6979H104.079C103.805 45.6979 103.667 45.6979 103.54 45.6637C103.427 45.6333 103.32 45.5835 103.224 45.5163C103.116 45.4404 103.028 45.335 102.852 45.124L99.8057 41.4798C99.3889 40.9812 99.1805 40.7318 98.9305 40.6395C98.7111 40.5585 98.4703 40.5569 98.2499 40.6351C97.9988 40.7241 97.7871 40.9707 97.3638 41.4639L94.2091 45.1399C94.0329 45.3451 93.9449 45.4478 93.8378 45.5215C93.743 45.5868 93.6376 45.6352 93.5263 45.6647C93.4006 45.6979 93.2654 45.6979 92.9949 45.6979H92.3831C91.8294 45.6979 91.5525 45.6979 91.4145 45.5854C91.2946 45.4877 91.2267 45.34 91.2306 45.1853C91.2351 45.0074 91.4154 44.7973 91.776 44.377L96.1404 39.2899C96.456 38.9221 96.6138 38.7382 96.6744 38.5317C96.7277 38.3498 96.7283 38.1564 96.6759 37.9742C96.6165 37.7674 96.4598 37.5826 96.1462 37.2131L92.3525 32.7408C91.9965 32.3212 91.8185 32.1114 91.8147 31.9339C91.8115 31.7797 91.8796 31.6325 91.9993 31.5352C92.1371 31.4233 92.4122 31.4233 92.9625 31.4233H93.5295Z"
        fill="black"
      />
      <path
        d="M113.975 31.4233C114.205 31.4233 114.32 31.4233 114.416 31.4422C114.814 31.5206 115.125 31.8319 115.204 32.23C115.223 32.3257 115.223 32.4408 115.223 32.671V44.4502C115.223 44.6804 115.223 44.7955 115.204 44.8912C115.125 45.2893 114.814 45.6006 114.416 45.679C114.32 45.6979 114.205 45.6979 113.975 45.6979C113.745 45.6979 113.63 45.6979 113.534 45.679C113.136 45.6006 112.825 45.2893 112.746 44.8912C112.727 44.7955 112.727 44.6804 112.727 44.4502V32.671C112.727 32.4408 112.727 32.3257 112.746 32.23C112.825 31.8319 113.136 31.5206 113.534 31.4422C113.63 31.4233 113.745 31.4233 113.975 31.4233Z"
        fill="black"
      />
      <path
        d="M132.676 31.4233C132.906 31.4233 133.021 31.4233 133.117 31.4422C133.515 31.5206 133.826 31.8319 133.905 32.23C133.923 32.3257 133.923 32.4408 133.923 32.671C133.923 32.9012 133.923 33.0163 133.905 33.112C133.826 33.5102 133.515 33.8214 133.117 33.8998C133.021 33.9187 132.906 33.9187 132.676 33.9187H126.606C125.958 33.9187 125.486 34.0808 125.189 34.4051C124.893 34.7153 124.745 35.1171 124.745 35.6105C124.745 35.822 124.774 36.0335 124.83 36.2449C124.9 36.4423 125.013 36.6256 125.168 36.7948C125.338 36.9499 125.556 37.0767 125.824 37.1754C126.092 37.26 126.437 37.3023 126.86 37.3023H130.201C131.174 37.3023 131.971 37.4292 132.591 37.683C133.211 37.9226 133.698 38.2399 134.05 38.6346C134.417 39.0294 134.664 39.4805 134.79 39.9881C134.931 40.4956 135.002 41.0031 135.002 41.5107C135.002 42.1169 134.917 42.6738 134.748 43.1813C134.579 43.6889 134.311 44.133 133.945 44.5136C133.578 44.8802 133.106 45.1692 132.528 45.3807C131.964 45.5922 131.273 45.6979 130.455 45.6979H123.751C123.521 45.6979 123.406 45.6979 123.31 45.679C122.912 45.6006 122.601 45.2893 122.523 44.8912C122.504 44.7955 122.504 44.6804 122.504 44.4502C122.504 44.22 122.504 44.1049 122.523 44.0092C122.601 43.611 122.912 43.2998 123.31 43.2213C123.406 43.2025 123.521 43.2025 123.751 43.2025H130.54C131.217 43.2025 131.71 43.0404 132.02 42.7161C132.344 42.3777 132.507 41.9477 132.507 41.4261C132.507 41.2005 132.471 40.982 132.401 40.7705C132.33 40.5449 132.21 40.3546 132.041 40.1995C131.872 40.0304 131.639 39.8964 131.343 39.7977C131.061 39.699 130.702 39.6497 130.265 39.6497H126.945C125.986 39.6497 125.204 39.5299 124.597 39.2902C123.991 39.0505 123.512 38.7333 123.159 38.3385C122.821 37.9438 122.581 37.4997 122.44 37.0063C122.313 36.5128 122.25 36.0194 122.25 35.5259C122.25 34.9338 122.328 34.391 122.483 33.8976C122.652 33.39 122.913 32.953 123.265 32.5864C123.632 32.2199 124.097 31.9379 124.661 31.7405C125.225 31.529 125.901 31.4233 126.691 31.4233H132.676Z"
        fill="black"
      />
      <path
        d="M81.7527 39.8188H72.7383V42.2727H81.7527V39.8188Z"
        fill="black"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="18.8761"
        y1="19.2733"
        x2="56.7064"
        y2="57.1037"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F23C73" stopOpacity="0" />
        <stop offset="0.223958" stopColor="#F23C73" />
        <stop offset="0.78125" stopColor="#F23C73" />
        <stop offset="1" stopColor="#F23C73" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="38.1694"
        y1="30.3749"
        x2="29.1254"
        y2="39.4189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F23C73" />
        <stop offset="0.307292" stopColor="#F23C73" stopOpacity="0" />
        <stop offset="0.671875" stopColor="#F23C73" stopOpacity="0" />
        <stop offset="1" stopColor="#F23C73" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="135" height="76" fill="black" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
