import React from 'react';
import { useIdentity } from '../../../contexts/IdentityContext';

const whiteList = 'vilinskyy7@gmail.com'; // - designer

function AxisUserGuard({ children, fallback }) {
  const { isAxisUser, user } = useIdentity();

  if (!isAxisUser && user.email !== whiteList)
    return fallback ? <>{fallback}</> : null;

  return children;
}

export default AxisUserGuard;
