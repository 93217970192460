import React from 'react';
import { useRouteInformation } from '../contexts/RouteInformationContext';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import { useIdentity } from '../contexts/IdentityContext';
import { useAxisData } from '../contexts/AxisDataContext';

const ProtectAuthorized = ({ children }) => {
  const { token, user } = useIdentity();
  const { topics } = useAxisData();
  const router = useRouteInformation();

  if (!token || !user.id) {
    router.navigateToLogin(router.location);
    return null;
  }

  if (!user.id || !topics.length) return <LoadingScreen />;

  return children;
};

export default ProtectAuthorized;
