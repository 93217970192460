import React from 'react';
import { Icon } from '@axis/xyz.assets.icon';
import css from './Header.module.css';
import FilterIcon from '../../../../icons/FilterIcon/FilterIcon';

function Header({ onClose }) {
  return (
    <div className={css.container}>
      <div>
        <FilterIcon className={css.filterIcon} />
        Filters
      </div>
      <Icon
        type="close"
        data-cy="feed-close-filters"
        className={css.closeIcon}
        onClick={onClose}
      />
    </div>
  );
}

export default Header;
