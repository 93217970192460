import { useQuery } from '@apollo/client';
import GET_EVENT_BY_ID from '../../queries/getEventById';

function useEvent(id) {
  const { data, loading } = useQuery(GET_EVENT_BY_ID, {
    variables: { eventId: id },
  });

  return { event: data?.getEventById?.event || null, loading };
}

export default useEvent;
