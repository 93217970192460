import React, { useState } from 'react';

import css from './ConnectButton.module.css';
import CloseIcon from '../../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../../Buttom/Buttom';

function EditConnectionModal({ onClose, onConfirmChanges }) {
  const [know] = useState(true);
  const [textAreaValue, setTextAreaValue] = useState('');

  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Add Connection</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      <textarea
        className={css.textarea}
        type="text-area"
        placeholder="Add a note about this connection"
        onChange={(e) => setTextAreaValue(e.target.value)}
        value={textAreaValue}
      />

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onConfirmChanges({
              flag: know,
              note: textAreaValue
            });
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default EditConnectionModal;
