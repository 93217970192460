import React from 'react';

function HeaderLoadingState() {
  return (
    <>
      <div style={{ padding: '8px 16px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{ height: 54, width: 54, borderRadius: '50%' }}
            className="skeleton-v2"
          />
          <div style={{ display: 'flex', gap: 8 }}>
            <div style={{ height: 36, width: 90 }} className="skeleton-v2" />
            <div style={{ height: 36, width: 90 }} className="skeleton-v2" />
          </div>
        </div>

        <div
          style={{ height: 25, marginTop: 16, width: 200 }}
          className="skeleton-v2"
        />
        <div style={{ height: 25, marginTop: 16 }} className="skeleton-v2" />

        <div style={{ display: 'flex', gap: 8, marginTop: 16 }}>
          <div style={{ height: 20, width: 90 }} className="skeleton-v2" />
          <div style={{ height: 20, width: 90 }} className="skeleton-v2" />
          <div style={{ height: 20, width: 90 }} className="skeleton-v2" />
        </div>

        <div style={{ display: 'flex', gap: 8, marginTop: 16 }}>
          <div style={{ height: 20, width: 120 }} className="skeleton-v2" />
          <div style={{ height: 20, width: 90 }} className="skeleton-v2" />
        </div>

        <div style={{ height: 30, marginTop: 16 }} className="skeleton-v2" />
      </div>
    </>
  );
}

export default HeaderLoadingState;
