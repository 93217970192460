import React, { memo } from 'react';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import Graph from './components/Graph/Graph';
import css from './Explore.module.css';
import ExploreSearch from './components/ExploreSearch/ExploreSearch';

const Explore = memo(({ entityId }) => {
  return (
    <div className={css.wrapper} data-cy="explore">
      <div className={css.container}>
        <ExploreSearch />
        <Graph entityId={entityId} />
      </div>
    </div>
  );
});

const ExploreContainer = () => {
  const router = useRouteInformation();
  const entityId = router.query.get('entityId');
  return <Explore entityId={entityId} />;
};

export default ExploreContainer;
