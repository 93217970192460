import React from 'react';

import css from './Quote.module.css';
import DossierCard from '../../DossierCard/DossierCard';
import useDossierQuotes from '../../../../../../hooks/useDossierQuotes';
import QuoteCard from '../../../../../Cards/QuoteCard/QuoteCard';

function Quote({ entity, size, className }) {
  const {
    quotes,
    isLoading,
    totalQuotes,
    totalPages,
    onNextPage,
    onPreviousPage,
    currentIndex,
  } = useDossierQuotes(entity.id);

  const pagination = {
    totalItems: totalQuotes,
    totalPages,
    currentIndex,
    onNextPage,
    onPreviousPage,
  };

  const quoteMode = size === 'normal' ? 'small-dossier' : 'expanded-dossier';

  if (isLoading)
    return (
      <LoadingState
        size="small"
        pagination={pagination}
        className={className}
      />
    );

  if (!quotes || quotes.length === 0) return null;

  return (
    <DossierCard
      title="Quotes"
      data-cy="quote-facet"
      className={className}
      pagination={pagination}
    >
      <div className={css.list} data-expanded={size === 'small'}>
        {quotes.map((quote) => (
          <QuoteCard
            mode={quoteMode}
            quote={quote}
            key={quote.id}
            showEvent="true"
          />
        ))}
      </div>
    </DossierCard>
  );
}

export default Quote;

function LoadingState({ size, pagination, className }) {
  return (
    <DossierCard title="" pagination={pagination} className={className}>
      <div className={css.container} data-expanded={size === 'expanded'}>
        <div style={{ height: 350 }} className="skeleton-v2" />
      </div>
    </DossierCard>
  );
}
