import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.366 12l-3.8-3.8.95-.95 2.85 2.85 6.117-6.117.95.95L6.366 12z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
