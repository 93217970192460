import React from 'react';
import FilterType from '../FilterType/FilterType';
import { filterTypes } from '../../index';
import OptionRow from '../OptionRow/OptionRow';

function Actors({
  activeFilter,
  onClick,
  onOptionChange,
  options,
  counts,
  isLoadingCounts,
  isActiveFilterCollapsed,
}) {
  return (
    <FilterType
      label="Actors"
      isLoadingCounts={isLoadingCounts}
      count={counts.actor}
      activeFilter={activeFilter}
      type={filterTypes.Actors}
      onClick={onClick}
      isActiveFilterCollapsed={isActiveFilterCollapsed}
    >
      {options &&
        options.map((option) => (
          <OptionRow
            key={option.id}
            option={option}
            counts={counts}
            handleOnInputChange={onOptionChange}
          />
        ))}
    </FilterType>
  );
}

export default Actors;
