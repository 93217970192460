import React, { useState } from 'react';

import Modal from 'react-modal';
import notify from '../../../../lib/notify';
import css from './Connections.module.css';
import { modalStyles } from '../../../shared/modal';
import PanelHeader from '../PanelHeader/PanelHeader';
import useConnections from '../../../hooks/useConnections';
import ConnectionCard from './ConnectionCard/ConnectionCard';
import RemoveConnection from './RemoveConnection/RemoveConnection';

const typeModal = {
  REMMOVE: 'REMOVE',
};

function Connections() {
  const [openedModal, setOpenedModal] = useState(null);
  const { connections, loading, total, refetch, removeConnection } =
    useConnections();

  const onSearch = (query) => {
    refetch({ searchTerm: query });
  };

  const onRemoveConnection = (connection) => {
    setOpenedModal({ modal: typeModal.REMMOVE, context: connection });
  };

  const onRemoveConfirmed = async ({ entity }) => {
    try {
      setOpenedModal(null);
      await removeConnection({
        variables: { entityId: entity.id, flag: false },
      });
      refetch();
      notify.success('Connection removed!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  return (
    <div className={css.main}>
      <PanelHeader
        count={total}
        onSearch={onSearch}
        title="Personal connections"
      />
      {loading && <LoadingState />}

      {!loading && (
        <div className={css.list}>
          {connections.map((connection) => (
            <ConnectionCard
              key={connection.entity.id}
              connection={connection}
              onRemoveConnection={onRemoveConnection}
            />
          ))}
        </div>
      )}

      <Modal
        isOpen={Boolean(openedModal)}
        style={modalStyles}
        contentLabel="Remove personal connection"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpenedModal(null)}
      >
        {openedModal?.modal === typeModal.REMMOVE && (
          <RemoveConnection
            entity={openedModal.context.entity}
            note={openedModal.context.note}
            onClose={() => setOpenedModal(null)}
            onRemove={() => onRemoveConfirmed(openedModal.context)}
          />
        )}
      </Modal>
    </div>
  );
}

export default Connections;

const LoadingState = () => (
  <div className={css.loadingState}>
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
  </div>
);
