import React, { useState, useRef, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Typography } from '@axis/xyz.app.typography';
import { Icon } from '@axis/xyz.assets.icon';
import { useApolloClient } from '../../hooks/useApolloClient';
import { getSearchResultAction } from '../../lib/search';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext';
import notify from '../../lib/notify';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import RequestButton from './components/RequestButton/RequestButton';
import querySearchEntities from '../../queries/searchEntities';
import css from './MiniSearch.module.css';
import SearchResult from './components/SearchResult/SearchResult';
import Skeleton from '../../components/Skeleton/Skeleton';
import Search from '../../icons/Search';

const VISIBLE_RESULTS = 10;

const fetchSearchEntityResults = ({
  client,
  name,
  topics,
  filterIndustries,
  filterEntities,
  filterCategories,
  bookmarked = false,
}) => {
  return client
    .query({
      query: querySearchEntities,
      variables: {
        name,
        topics,
        filterIndustries,
        filterEntities,
        filterCategories,
        bookmarked,
      },
    })
    .then(({ data }) => data.searchEntities.entities);
};

const getFocusableNodes = () =>
  document.querySelectorAll('[data-type="mini-search-focus-item"]');

const MiniSearch = ({ className }) => {
  const client = useApolloClient();

  const [results, setResults] = useState([]);
  const [isLoadingResults, setLoadingResults] = useState(false);

  const [search, setSearch] = useState('');
  const [activeInput, setActiveInput] = useState(false);

  const [focus, setFocus] = useState({ active: false, index: 0 });

  const ref = useRef(null);
  const inputRef = useRef(null);

  const router = useRouteInformation();

  const onClickEntity = ({ id, topic }) => {
    const entity = results.find((e) => e.id === id);
    setActiveInput(false);
    sendEvent('mini_search_result_click', {
      description: 'Click on header search result',
      entityId: id,
      entityName: entity ? entity.name : '',
    });
    getSearchResultAction({ entity, router, topicId: topic });
  };

  const handleSearchClick = () => {
    setActiveInput(true);
    sendEvent('mini_search_open', {
      description: 'Open search header',
    });
  };

  const closeSearch = () => {
    setActiveInput(false);
    if (inputRef.current) inputRef.current.value = '';
    setSearch('');
    setResults([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeSearch();
    }
  };

  const debouncedOnChange = useCallback(
    debounce((event) => {
      const { value } = event.target;
      setSearch(value);
      sendEvent('mini_search_execute', {
        description: 'Search in the header',
        search: value,
      });
      setResults([]);
    }, 300),
    [setSearch],
  );

  useEffect(() => {
    const handler = (event) => {
      const clickInSearch = ref.current && ref.current.contains(event.target);
      if (!clickInSearch) {
        if (inputRef.current) inputRef.current.blur();
        closeSearch();
      }
    };
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, []);

  useEffect(() => {
    const elements = getFocusableNodes().length;
    const opts = elements + 1;
    const { active, index } = focus;
    const upHandler = (event) => {
      if (event.key === 'ArrowDown') {
        setFocus({ active: true, index: index + 1 >= opts ? 0 : index + 1 });
        event.preventDefault();
      }
      if (event.key === 'ArrowUp') {
        setFocus({ active: true, index: index - 1 < 0 ? opts - 1 : index - 1 });
        event.preventDefault();
      }
      if (event.key === 'Enter' && active && index > 0) {
        const element = getFocusableNodes().item(index - 1);
        if (element) element.click();
        event.preventDefault();
      }
      if (event.key === 'Escape' && active && index > 0) {
        setFocus({ active: true, index: 0 });
        event.preventDefault();
      }
      if (event.key === 'Escape' && index === 0) {
        setFocus({ active: false, index: 0 });
        if (inputRef.current) inputRef.current.blur();
        event.preventDefault();
      }
    };
    const downHandler = (event) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        event.stopPropagation();
      }
    };
    ref.current.addEventListener('keyup', upHandler);
    ref.current.addEventListener('keydown', downHandler);
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('keyup', upHandler);
        ref.current.removeEventListener('keydown', downHandler);
      }
    };
  }, [focus, results]);

  useEffect(() => {
    const { index, active } = focus;
    if (!active) return;
    // Index: 0 - focus on the <input />
    if (index === 0) {
      inputRef.current.focus();
    }
    // Focus on a search result
    else {
      const element = getFocusableNodes().item(index - 1);
      if (element) element.focus();
    }
  }, [focus]);

  // Reset focus when results change
  useEffect(() => {
    setFocus({ active: false, index: 0 });
  }, [results]);

  useEffect(() => {
    if (search.length > 1) {
      setLoadingResults(true);
      fetchSearchEntityResults({
        client,
        name: search,
        topics: [],
        filterIndustries: [],
        filterEntities: [],
      })
        .then((data) => {
          /*
           * We only show the top 10 results
           * We need to watch out for duplicates
           * We don't have a "score" yet indicating the relevance of the result
           */
          const newResults = data
            .filter((entity, index, entities) => {
              const firstIndex = entities.findIndex((e) => e.id === entity.id);
              return firstIndex === index;
            })
            .slice(0, VISIBLE_RESULTS);
          setResults(newResults);
        })
        .catch(() => {
          notify.error('There was an error retrieving the search results.');
        })
        .finally(() => {
          setLoadingResults(false);
        });
    }
  }, [search, client]);

  const isRequestButtonVisible =
    !isLoadingResults && search.length > 1 && results.length === 0;

  return (
    <div ref={ref} className={`${className} ${css.main}`}>
      <div
        className={css.content}
        data-status={activeInput ? 'expanded' : 'collapsed'}
      >
        <Search className={css.icon} />
        <input
          data-cy="search-header-input"
          ref={inputRef}
          onClick={handleSearchClick}
          onKeyDown={handleKeyDown}
          placeholder="Search Network"
          className={css.input}
          onChange={debouncedOnChange}
        />

        <Icon
          type="close"
          className={css.close}
          onClick={closeSearch}
          data-type="close-advance-search-icon"
        />
      </div>

      {activeInput && (
        <div className={css.resultsContainer}>
          {isLoadingResults && <LoadingState />}

          {!isLoadingResults && search.length > 1 && results.length && (
            <>
              <div className={css.resultsTitle}>Suggested results</div>

              {results.length > 0 ? (
                results.map((entity) => (
                  <SearchResult
                    key={entity.id}
                    id={entity.id}
                    name={entity.name}
                    image={entity.image}
                    type={entity.type}
                    topics={entity.topics}
                    onClick={({ id, topic }) => onClickEntity({ id, topic })}
                  />
                ))
              ) : (
                <Typography
                  data-cy="search-header-no-results"
                  className={css.empty}
                >
                  No Results
                </Typography>
              )}
            </>
          )}
          {isRequestButtonVisible && <RequestButton name={search} />}
        </div>
      )}
    </div>
  );
};

export default MiniSearch;

const LoadingState = () => (
  <div>
    <Skeleton height="20px" width="180px" />
    <Skeleton height="20px" width="210px" />
    <Skeleton height="20px" width="150px" />
    <Skeleton height="20px" width="190px" />
    <Skeleton height="20px" width="280px" />
  </div>
);
