import React, { useEffect, useState } from 'react';
import { Loading } from '@axis/xyz.app.loading';
import EntityPill from '../../components/EntityPill/EntityPill';
import DossierCard from '../../components/DossierCard/DossierCard';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import css from './SharedLinks.module.css';

const PAGE_SIZE = 10;
const SAFETY_PAGE_BUFFER = 9;

const getLinksPage = (links, page) => {
  const start = (page - 1) * 10;
  const end = start + 10;
  return links.slice(start, end);
};

const SharedLinks = ({ links = [], total, isLoading, onLoadMore }) => {
  const router = useRouteInformation();

  const [page, setPage] = useState(1);

  const availablePages = Math.ceil(links.length / PAGE_SIZE);
  const totalPages = Math.ceil(total / PAGE_SIZE);
  const visibleLinks = getLinksPage(links, page);
  const isAllLinksLoaded = links.length === total;
  const isEmpty = visibleLinks.length === 0;

  const isPageLoading =
    (!isAllLinksLoaded && page * PAGE_SIZE > links.length) ||
    (page === 1 && isLoading);

  useEffect(() => {
    if (isAllLinksLoaded) return;
    if (availablePages - page < SAFETY_PAGE_BUFFER && !isLoading) {
      onLoadMore();
    }
  }, [page]);

  const handleEntityClick = (entityId) => {
    router.openDossier({ dossierEntityId: entityId, dossierType: 'entity' });
  };

  const showCard = (linksData) =>
    linksData.length > 0 ? (
      <DossierCard
        loading={isPageLoading}
        title="Relations"
        count={total}
        currentPage={page}
        totalPages={totalPages}
        showPages
        onPageChange={setPage}
      >
        <div className={css.container}>
          {isEmpty && !isLoading && (
            <div className={css.empty}>
              These entities do not have any shared relationships
            </div>
          )}
          <div className={css.entities}>
            {visibleLinks.map((entity) => (
              <EntityPill
                key={entity.id}
                name={entity.name}
                shortName={entity.shortestName}
                image={entity.image}
                type={entity.type}
                onClick={() => handleEntityClick(entity.id)}
                inSubscription={entity.inSubscription}
              />
            ))}
          </div>
        </div>
      </DossierCard>
    ) : null;

  return (
    <div>
      {isLoading ? (
        <div className={css.loadingArea}>
          <Loading className={css.loading} />
        </div>
      ) : (
        showCard(links)
      )}
    </div>
  );
};

export default SharedLinks;
