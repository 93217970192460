import { useEffect } from 'react';
import { useIdentity } from '../contexts/IdentityContext';

const FullStoryRoute = () => {
  const { user } = useIdentity();

  useEffect(() => {
    if (user && user.email) {
      if (window.FS && window.FS.identify) {
        window.FS.identify(user.id, {
          displayName: user.name,
          email: user.email,
        });
        if (user.email.includes('@google.com')) {
          window.FS.consent(false);
        } else {
          window.FS.consent(true);
        }
        window.FS.restart();
      }
    }
  }, [user]);

  return null;
};

export default FullStoryRoute;
