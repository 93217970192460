import { formatDateMed } from '../date';

export const getLifespanDetails = (entity) => {
  if (!entity || !entity.lifespan) return null;
  return {
    entityType: entity.type,
    entitySubType: entity.subType,
    ...(entity.lifespan || {}),
  };
};

export const getDateLabel = ({ entityType, entitySubType, start, end }) => {
  if (end || !start) return 'Lifespan';

  if (entityType === 'Actor' && entitySubType === 'Person') {
    return 'DOB';
  }
  if (entityType === 'Actor') {
    return 'Founding Date';
  }
  return 'Lifespan';
};

export const getAge = ({ entityType, start, end }) => {
  if (entityType !== 'Actor') return null;
  if (!start) return null;
  const endDate = end ? new Date(end) : new Date();
  const startDate = new Date(start);
  return endDate.getFullYear() - startDate.getFullYear();
};

export const getDateValue = ({ start, end }) => {
  if (!start) return null;
  if (end) {
    return `${formatDateMed(start)} - ${formatDateMed(end)}`;
  }
  return formatDateMed(start);
};
