import { gql } from '@apollo/client';

const query = gql`
  query getRelationshipDossierSharedLocations(
    $sourceId: ID!
    $targetId: ID!
    $page: Int
    $limit: Int
  ) {
    getRelationshipDossierSharedLocations(
      sourceId: $sourceId
      targetId: $targetId
      page: $page
      limit: $limit
    ) {
      totalSharedLocations
      sharedLocations {
        id
        name
      }
    }
  }
`;

export default query;
