import * as React from 'react';

function BookmarkOutline(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M3.333 14V3.333c0-.366.131-.68.392-.942.261-.26.575-.391.942-.391h6.667c.366 0 .68.13.941.391.261.262.392.576.392.942V14L8 12l-4.667 2zm1.334-2.033L8 10.533l3.334 1.434V3.333H4.667v8.634z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default BookmarkOutline;
