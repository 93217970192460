import { useMutation, useQuery } from '@apollo/client';

import LIST_SAVED_SEARCHES from '../../queries/listSavedSearches';
import DELETE_SAVED_SEARCH from '../../queries/deleteSavedSearch';
import UPDATE_SAVED_SEARCH from '../../queries/updateSaveSearch';

function useSavedSearches() {
  const [updateSearch] = useMutation(UPDATE_SAVED_SEARCH);
  const [deleteSavedSearch] = useMutation(DELETE_SAVED_SEARCH);
  const { data, loading, refetch, client } = useQuery(LIST_SAVED_SEARCHES, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const removeSavedSearchFromCache = (removeId) => {
    client.writeQuery({
      query: LIST_SAVED_SEARCHES,
      data: {
        listSavedSearchs: {
          savedSearches: data.listSavedSearchs.savedSearches.filter(
            ({ id }) => id !== removeId,
          ),
        },
      },
    });
  };

  return {
    data,
    loading,
    refetch,
    updateSearch,
    deleteSavedSearch,
    removeSavedSearchFromCache,
  };
}

export default useSavedSearches;
