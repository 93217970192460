const colorRangeMap = {
  '-10': '#D82953',
  '-9': '#D82953',
  '-8': '#D82953',
  '-7': '#D82953',
  '-6': '#D82953',

  '-5': '#B65C6D',
  '-4': '#B65C6D',
  '-3': '#B65C6D',
  '-2': '#B65C6D',
  '-1': '#B65C6D',

  0: '#B9C0D4',

  1: '#428F6F',
  2: '#428F6F',
  3: '#428F6F',
  4: '#428F6F',
  5: '#428F6F',

  6: '#10AE6C',
  7: '#10AE6C',
  8: '#10AE6C',
  9: '#10AE6C',
  10: '#10AE6C',
};

const nomalizeScore = (nodeScore) => {
  return Math.abs(nodeScore);
};

export { colorRangeMap, nomalizeScore };
