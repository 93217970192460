import React from 'react';

const Chevron = ({ direction = 'left' }) => (
  <svg viewBox="0 0 24 24" fill="none">
    <g
      transform={`scale(${direction === 'left' ? -1 : 1} 1)`}
      transform-origin="center"
    >
      <path
        d="M13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289C11.9024 7.68342 11.9024 8.31658 12.2929 8.70711L13.7071 7.29289ZM17 12L17.7071 12.7071C18.0976 12.3166 18.0976 11.6834 17.7071 11.2929L17 12ZM12.2929 15.2929C11.9024 15.6834 11.9024 16.3166 12.2929 16.7071C12.6834 17.0976 13.3166 17.0976 13.7071 16.7071L12.2929 15.2929ZM12.2929 8.70711L16.2929 12.7071L17.7071 11.2929L13.7071 7.29289L12.2929 8.70711ZM16.2929 11.2929L12.2929 15.2929L13.7071 16.7071L17.7071 12.7071L16.2929 11.2929Z"
        fill="#1A1A1A"
      />
      <path
        d="M7.70711 7.29289C7.31658 6.90237 6.68342 6.90237 6.29289 7.29289C5.90237 7.68342 5.90237 8.31658 6.29289 8.70711L7.70711 7.29289ZM11 12L11.7071 12.7071C12.0976 12.3166 12.0976 11.6834 11.7071 11.2929L11 12ZM6.29289 15.2929C5.90237 15.6834 5.90237 16.3166 6.29289 16.7071C6.68342 17.0976 7.31658 17.0976 7.70711 16.7071L6.29289 15.2929ZM6.29289 8.70711L10.2929 12.7071L11.7071 11.2929L7.70711 7.29289L6.29289 8.70711ZM10.2929 11.2929L6.29289 15.2929L7.70711 16.7071L11.7071 12.7071L10.2929 11.2929Z"
        fill="#1A1A1A"
      />
    </g>
  </svg>
);

export default Chevron;
