import React, { useState, useEffect } from 'react';

import notify from '../../../../lib/notify';
import { resetPassword } from '../../../../lib/client';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';

import css from './RestorePassword.module.css';
import Logo from '../../../../icons/Logo';
import PasswordInput from '../PasswordInput/PasswordInput';
import Help from '../Help/Help';

const PasswordReset = () => {
  const router = useRouteInformation();
  const resetToken = router.query.get('resetToken');

  const [hasFormErrors, setFormErrors] = useState(false);
  const [isValid, setValid] = useState(true);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useState(false);

  useEffect(() => {
    const validPasswords = newPassword && confirmPassword;
    const matchPasswords = newPassword === confirmPassword;
    setValid(validPasswords && matchPasswords);
    setFormErrors(false);
  }, [newPassword, confirmPassword]);

  const handleConfirm = async () => {
    if (!resetToken) return;
    try {
      await resetPassword(newPassword, resetToken);
      notify.success('Your password has been reset');
      router.navigateToLogin({});
    } catch (err) {
      setFormErrors(true);
    }
  };

  return (
    <div className={css.formContainer}>
      <div className={css.header}>
        <div className={css.logoContainer}>
          <Logo />
        </div>
        <Help className={css.headerHelp} />
      </div>
      <div className={css.form}>
        <PasswordInput
          className={css.inputContent}
          showVisibleIcon={false}
          showForgotPassword={false}
          label="New password"
          value={newPassword}
          hasErrors={hasFormErrors}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <PasswordInput
          className={css.inputContent}
          showVisibleIcon={false}
          showForgotPassword={false}
          label="Confirm password"
          value={confirmPassword}
          hasErrors={hasFormErrors}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <div className={css.footer}>
          <button
            type="button"
            className={css.button}
            onClick={handleConfirm}
            disabled={!isValid}
          >
            Confirm
          </button>
          <Help className={css.footerHelp} />
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
