import { useEffect, useState } from 'react';
import { sendEvent } from '../contexts/AnalyticsTrackingContext';

const RAGE_BOX_SIZE = 100; // Size of the "rage box"
const RAGE_SEPARATION = 10 * 1000; // 10s between rage clicks

const getRangeInfo = ({ clicks }) => {
  if (!clicks.length) return false;
  const lastClick = clicks[clicks.length - 1];
  if (Date.now() - lastClick.time > 5000) return false;
  if (lastClick.detail >= 4) return true;
  if (clicks.length < 5) return false;
  const coordinates = clicks.slice(-4).reduce(
    (acc, { pageX, pageY }) => ({
      x: [...acc.x, pageX],
      y: [...acc.y, pageY],
    }),
    { x: [], y: [] },
  );
  const box = {
    minX: Math.min(...coordinates.x),
    maxX: Math.max(...coordinates.x),
    minY: Math.min(...coordinates.y),
    maxY: Math.max(...coordinates.y),
  };
  const inRange =
    box.maxX - box.minX < RAGE_BOX_SIZE && box.maxY - box.minY < RAGE_BOX_SIZE;
  if (!inRange) return false;
  return true;
};

const useRageClickTracker = () => {
  const [clicks, setClicks] = useState([]);
  const [lastRage, setLastRage] = useState(0);

  useEffect(() => {
    const isRage = getRangeInfo({ clicks });
    const timeRange = Date.now() - lastRage;
    if (isRage && timeRange > RAGE_SEPARATION) {
      setClicks([]);
      sendEvent('rage_click', {
        description: 'Rage clicking!',
      });
      setLastRage(Date.now());
    }
  }, [clicks]);

  useEffect(() => {
    const listener = ({ detail, pageX, pageY, target }) => {
      setClicks((currentClicks) => [
        ...currentClicks.slice(-9),
        { pageX, pageY, detail, target, time: Date.now() },
      ]);
    };
    document.addEventListener('click', listener);
    return () => document.removeEventListener('click', listener);
  }, []);
};

export default useRageClickTracker;
