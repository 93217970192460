import { gql } from '@apollo/client';

const query = gql`
  query getBookmarkedEntities($searchTerm: String, $limit: Int, $skip: Int) {
    getBookmarkedEntities(searchTerm: $searchTerm, limit: $limit, skip: $skip) {
      total
      entities {
        id
        name
        shortestName
        type
        inSubscription
        isConnected
        image
        primaryRoleTitle
      }
    }
  }
`;

export default query;
