import React from 'react';

import css from './Buttom.module.css';
import Spinner from '../../../icons/spinner';

/**
 * type = 'secundary' | 'danger' | 'primary' | 'ghost'
 */

function Button({ children, type, className, loading, ...props }) {
  return (
    <button
      type="button"
      className={`${css.main} ${className}`}
      data-type={type}
      {...props}
    >
      {loading && <Spinner />}
      {children}
    </button>
  );
}

export default Button;
