import React from 'react';

import Source from '../../../../../Source/Source';
import { formatDateMed } from '../../../../../../../lib/date';
import useCardPagination from '../../DossierCard/useCardPagination';
import DossierCard, { DossierCardItem } from '../../DossierCard/DossierCard';
import EntityPill from '../../../../../../../components/EntityPill/EntityPill';

function Education({ entity, className }) {
  const { state, pagination } = useCardPagination(entity.educations);

  if (!state.data) return null;

  const { degree, institution, institutionEntity, sources, date } = state.data;

  const content = institutionEntity ? (
    <div style={{ display: 'flex' }}>
      <EntityPill
        size="small"
        autoOpenDossier
        id={institutionEntity.id}
        type={institutionEntity.type}
        name={institutionEntity.name}
        image={institutionEntity.image}
        shortName={institutionEntity.shortestName}
        isConnected={institutionEntity.isConnected}
        inSubscription={institutionEntity.inSubscription}
      />
    </div>
  ) : (
    institution
  );

  return (
    <DossierCard
      title="Education"
      data-cy="education-facet"
      className={className}
      pagination={pagination}
    >
      <DossierCardItem
        title="Degree"
        data-cy="education-degree"
        content={degree}
      />
      <DossierCardItem title="Org" data-cy="education-org" content={content} />
      {date && (
        <DossierCardItem
          title="Date"
          data-cy="education-date"
          content={formatDateMed(date)}
        />
      )}
      {sources && (
        <DossierCardItem
          title="Source"
          data-cy="education-sources"
          content={sources.map((item) => (
            <Source src={item} key={item} />
          ))}
        />
      )}
    </DossierCard>
  );
}

export default Education;
