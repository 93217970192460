import React, { useState } from 'react';

import { Loading } from '@axis/xyz.app.loading';

import css from './NotesDossier.module.css';
import notify from '../../../../../lib/notify';
import client from '../../../../../apollo/apollo';
import SAVE_NOTE from '../../../../../queries/createNote';
import arrowBack from '../../../../../icons/arrow_back.svg';
import DefaultButton from '../../../../../components/DefaultButton/DefaultButton';
import EntityDossierNotes from '../../../../../components/EntityDossierNotes/EntityDossierNotes';

function NotesDossier({ entity, onCancel, size, onNoteAdded }) {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [notesRefetchToggls, setNotesRefetchToggls] = useState(false);

  const handleToggleNoteUpdate = () => {
    setTimeout(() => {
      setNotesRefetchToggls(true);
    }, 1000);
  };

  const handleSaveNote = () => {
    if (!text || text.trim() === '') {
      notify.error('Note cannot be empty', { position: 'bottom-right' });
      return;
    }

    setLoading(true);

    client
      .mutate({
        mutation: SAVE_NOTE,
        variables: {
          targetId: entity.id,
          noteType: 'entity',
          note: text,
        },
      })
      .then(({ errors, data }) => {
        if (errors || !data || !data.createNote) {
          return notify.error(errors[0].message, { position: 'bottom-right' });
        }
        handleToggleNoteUpdate();
        setText('');
        onNoteAdded();
        setNotesRefetchToggls(true);
        return notify.success('Note added successfully', {
          position: 'bottom-right',
        });
      })
      .catch(() => {
        notify.error('Error trying to create the note', {
          position: 'bottom-right',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTextAreaChanged = (e) => {
    setText(e.target.value);
  };

  return (
    <div className={css.bodyItems}>
      <DefaultButton
        label={`Back to ${entity.name}`}
        onClick={onCancel}
        icon={<img src={arrowBack} alt="back" />}
        className={css.addNoteBackButton}
      />
      <h1 className={css.noteAreaTitle}>Notes</h1>
      <div className={css.noteArea}>
        {loading ? (
          <div className={css.note} data-status="loading">
            <Loading className={css.spinner} />
          </div>
        ) : (
          <textarea
            className={css.note}
            data-cy="notes-textbox"
            type="text-area"
            placeholder="Add your note..."
            onChange={onTextAreaChanged}
            value={text}
          />
        )}
        <div className={css.addNoteButtons}>
          <button
            data-cy="notes-cancel-button"
            className={css.cancelButton}
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            data-cy="notes-save-button"
            data-status={loading ? 'loading' : 'idle'}
            disabled={loading}
            className={css.saveButton}
            type="button"
            onClick={handleSaveNote}
          >
            Save
          </button>
        </div>
      </div>
      <EntityDossierNotes
        entity={entity}
        notesRefetchToggls={notesRefetchToggls}
        size={size}
        handleToggleNoteUpdate={handleToggleNoteUpdate}
      />
    </div>
  );
}

export default NotesDossier;
