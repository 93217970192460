import React, { useEffect } from 'react';
import { Typography } from '@axis/xyz.app.typography';
import { sendEvent } from '../../../../contexts/AnalyticsTrackingContext';
import RequestEntityButton from '../../../../components/RequestEntityButton/RequestEntityButton';
import styles from './RequestButton.module.css';

const RequestButton = ({ name }) => {
  useEffect(() => {
    sendEvent('mini_search_no_results', {
      description: 'Header search returned no results',
      search: name,
    });
  }, []);

  return (
    <div className={styles.request}>
      <Typography className={styles.text}>No Results</Typography>
      <RequestEntityButton name={name} source="mini-search" />
    </div>
  );
};

export default RequestButton;
