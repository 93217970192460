import { useTooltip } from '@nivo/tooltip';
import { createElement, useCallback } from 'react';
import TreeMapTooltip from './TreeMapTooltip';

function useAnimatedTooltip() {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  const showTooltip = useCallback(
    (node, event) => {
      showTooltipFromEvent(
        createElement(TreeMapTooltip, { node }),
        event,
        'left',
      );
    },
    [showTooltipFromEvent],
  );

  const handleMouseEnter = useCallback(
    (node, event) => {
      showTooltip(node, event);
    },
    [showTooltip],
  );

  const handleMouseMove = useCallback(
    (node, event) => {
      showTooltip(node, event);
    },
    [showTooltip],
  );

  const handleMouseLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  return { handleMouseEnter, handleMouseMove, handleMouseLeave };
}

export default useAnimatedTooltip;
