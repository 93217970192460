import React from 'react';
import css from './DefaultButton.module.css';

const DefaultButton = ({
  className,
  label,
  icon,
  onClick,
  iconPosition = 'leading',
  disabled,
  ...rest
}) => {
  const isIconButton = !label && icon;

  const hasLeadingIcon = icon && iconPosition === 'leading';
  const hasTrailingIcon = icon && iconPosition === 'trailing';

  const handleClick = (e) => {
    if (disabled) return;
    onClick && onClick(e);
  };

  return (
    <button
      className={`${css.button} ${className}`}
      onClick={handleClick}
      disabled={disabled}
      type="button"
      data-type={isIconButton ? 'icon-button' : 'text-button'}
      {...rest}
    >
      {hasLeadingIcon && (
        <span
          data-cy="button-leading-icon"
          className={css.icon}
          data-position="leading"
        >
          {icon}
        </span>
      )}
      {label && <span className={css.label}>{label}</span>}
      {hasTrailingIcon && (
        <span
          data-cy="button-trailing-icon"
          className={css.icon}
          data-position="trailing"
        >
          {icon}
        </span>
      )}
    </button>
  );
};

export default DefaultButton;
