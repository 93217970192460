import React, { useMemo } from 'react';

import css from './DetailsSection.module.css';

import Quotes from '../Quotes/Quote';
import Appointments from '../Appointments/Appointments';

function DetailsSection({ industryId, countryId, endDate, startDate, counts }) {
  const [quotesCount, appointmentsCount] = useMemo(() => {
    if (!counts) return ['', ''];

    return [
      counts.find(({ type }) => type === 'quote')?.total || '',
      counts.find(({ type }) => type === 'appointment')?.total || '',
    ];
  }, [counts]);

  return (
    <div className={css.main}>
      <Quotes
        countryId={countryId}
        industryId={industryId}
        endDate={endDate}
        count={quotesCount}
        startDate={startDate}
      />
      <Appointments
        countryId={countryId}
        industryId={industryId}
        endDate={endDate}
        count={appointmentsCount}
        startDate={startDate}
      />
    </div>
  );
}

export default DetailsSection;
