import React, { useState } from 'react';
import { Typography } from '@axis/xyz.app.typography';
import { useIdentity } from '../../contexts/IdentityContext';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext';
import css from './RequestEntityButton.module.css';

const RequestEntityButton = ({
  className,
  name,
  source = '',
  description = '',
}) => {
  const { user } = useIdentity();
  const { email } = user;
  const [isRequesting, setIsRequesting] = useState(false);
  const [requestedEntities, setRequestedEntities] = useState([]);

  const handleEntityRequest = () => {
    setIsRequesting(true);
    sendEvent('request_entity', {
      name,
      email,
      source,
      description,
    });
    setRequestedEntities((c) => [...c, name]);
    setIsRequesting(false);
  };

  if (requestedEntities.find((n) => n.toLowerCase() === name.toLowerCase())) {
    return (
      <Typography
        data-cy="request-entity-message"
        className={css.text}
      >{`"${name}" requested!`}</Typography>
    );
  }
  return (
    <button
      onClick={handleEntityRequest}
      type="button"
      className={`${css.button} ${className}`}
      disabled={isRequesting}
      data-cy="request-entity-button"
    >
      Request &quot;{name}&quot;
    </button>
  );
};

export default RequestEntityButton;
