import React, { useRef, useState } from 'react';

import css from './EntityDossier.module.css';
import useNavigation from '../../../hooks/useNavigation';
import { DossierType, UrlParam } from '../../../shared/dossier';
import useEntity from '../../../hooks/useEntity';
import DossierHeader from '../../../../components/DossierHeader/DossierHeader';
import Header from './Header/Header';
import Body from './Body/Body';
import NotesDossier from './NotesDossier/NotesDossier';
import AbstractErrorBundary from '../../AbstractErrorBundary/AbstractErrorBundary';
import EntityDossierError from './EntityDossierError';

function EntityDossier({ entityId, onSizeChange, size }) {
  const [showNotesDossier, setShowNotesDossier] = useState(false);

  const dossierRef = useRef();
  const {
    entity,
    isLoading,
    toggleBookmark,
    toggleUserConnection,
    onNoteAdded,
  } = useEntity(entityId);

  return (
    <div className={css.main} ref={dossierRef}>
      <DossierHeader size={size} onSizeChange={onSizeChange} border sticky>
        <div
          className={css.content}
          data-expanded={size === 'expanded'}
          data-hidden={showNotesDossier}
        >
          <Header
            size={size}
            dossierRef={dossierRef}
            entity={entity}
            isLoading={isLoading}
            toggleBookmark={toggleBookmark}
            toggleUserConnection={toggleUserConnection}
            showNotesDossier={() => setShowNotesDossier(true)}
          />

          <Body entity={entity} size={size} isEntityLoading={isLoading} />
        </div>

        {showNotesDossier && (
          <NotesDossier
            entity={entity}
            onCancel={() => setShowNotesDossier(false)}
            size={size}
            onNoteAdded={onNoteAdded}
          />
        )}
      </DossierHeader>
    </div>
  );
}

function EntityDossierWrapper({ onSizeChange, size }) {
  const { params } = useNavigation();

  const entityId = params.get(UrlParam.entityId);
  const dossierType = params.get(DossierType.DossierType);

  if (!entityId || !dossierType || dossierType !== DossierType.Entity)
    return null;

  return (
    <AbstractErrorBundary fallback={<EntityDossierError />}>
      <EntityDossier
        size={size}
        entityId={entityId}
        onSizeChange={onSizeChange}
      />
    </AbstractErrorBundary>
  );
}

export default EntityDossierWrapper;
