/* eslint-disable no-shadow */
import { useQuery } from '@apollo/client';

import GET_TREEMAP from '../../../queries/getTreeMap';

function useTreeMap({ industries = [], countries = [], dates = [] }) {
  const { data, loading, refetch } = useQuery(GET_TREEMAP, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      industryIds: industries.map((ind) => ind.id),
      countryIds: countries.map((country) => country.id),
      startDate: dates[0],
      endDate: dates[1],
    },
  });

  const applyFilters = (industries = [], countries = [], dates = []) => {
    refetch({
      industryIds: industries.map((ind) => ind.id),
      countryIds: countries.map((country) => country.id),
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  return { loading, nodes: data?.getTreeMap.nodes, applyFilters };
}

export default useTreeMap;
