import React, { useState } from 'react';

import css from './SetHomeFeed.module.css';
import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../Buttom/Buttom';

function SetHomeFeed({ onClose, onConfirm, name }) {
  const [value, setValue] = useState(name);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onConfirm(value);
    }
  };

  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Set as your home feed</h2>
        <button className={css.closeBtn} onClick={onClose} type="button">
          <CloseIcon />
        </button>
      </div>

      <div className={css.copy}>
        Are you sure you want to set this as your home feed?
      </div>

      <input
        type="text"
        value={value}
        onKeyDown={handleKeyDown}
        className={css.input}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Name your search"
      />

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => onConfirm(value)}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

export default SetHomeFeed;
