import React from 'react';
import BriefPillCloseIcon from '../../icons/BriefPillCloseIcon/BriefPillCloseIcon';
import GeographyIcon from '../../icons/GeographyIcon/GeographyIcon';
import css from './BriefPill.module.css';
import Tooltip from '../Tooltip/Tooltip';

const BriefPill = ({
  className = '',
  name,
  isGeography = false,
  onClose,
  tooltip,
}) => {
  const isCloseable = typeof onClose === 'function';

  return (
    <Tooltip text={tooltip}>
      <div
        data-cy="brief-pill"
        className={`${css.pill} ${className}`}
        data-type="Brief"
        data-action={isCloseable ? 'close' : 'none'}
      >
        {isGeography && <GeographyIcon className={css.geography} />}
        <p data-cy="brief-pill-name" className={css.name}>
          {name}
        </p>
        {isCloseable && (
          <button
            data-cy="brief-pill-close"
            type="button"
            className={css.close}
            onClick={onClose}
          >
            <BriefPillCloseIcon className={css.icon} />
          </button>
        )}
      </div>
    </Tooltip>
  );
};

export default BriefPill;
