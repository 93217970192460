import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import listCountries from '../queries/listCountries';
import briefTree from '../queries/briefTree';
import getLandscapes from '../queries/getLandscapes';
import getBookmarks from '../queries/getUserBookmarks';
import getConnections from '../queries/getUserConnections';
import { useIdentity } from './IdentityContext';
import { useApolloClient } from '../hooks/useApolloClient';
import getUserTotalNotes from '../queries/getUserTotalNotes';
import { includeCountryAltNames } from '../utils/countries';

const Context = createContext();

const fetchCountries = async ({ client }) => {
  return client
    .query({ query: listCountries, variables: { limit: 300, skip: 0 } })
    .then(({ data }) => data.listCountries.countries);
};

const fetchBriefs = async ({ client }) => {
  return client
    .query({ query: briefTree, variables: { limit: 500, skip: 0 } })
    .then(({ data }) => data.briefTree);
};

const fetchLandscapes = async ({ client }) => {
  return client
    .query({ query: getLandscapes })
    .then(({ data }) => data.getLandscapes);
};

const fetchBookmarks = async ({ client }) => {
  return client
    .query({ query: getBookmarks })
    .then(({ data }) => data.getBookmarkedEntities);
};

const fetchConnections = async ({ client }) => {
  return client
    .query({ query: getConnections })
    .then(({ data }) => data.getUserConnections);
};

const fetchUserTotalNotes = async ({ client }) => {
  return client
    .query({ query: getUserTotalNotes })
    .then(({ data }) => data.getUserTotalNotes);
};

export const AxisDataContext = ({ children }) => {
  const { user } = useIdentity();
  const client = useApolloClient();

  const [topics, setTopics] = useState([]);
  const [subscribedCountries, setSubscribedCountries] = useState([]);
  const [briefs, setBriefs] = useState([]);
  const [landscapes, setLandscapes] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [connections, setConnections] = useState([]);
  const [totalBookmarks, setTotalBookmarks] = useState(0);
  const [totalConnections, setTotalConnections] = useState(0);
  const [totalNotes, setTotalNotes] = useState(0);

  // togglers
  const [toggleFetchUserTotalNotes, setToggleFetchUserTotalNotes] =
    useState(false);

  useEffect(() => {
    if (!user || !user.id) return;
    fetchCountries({ client }).then((countries) => {
      const ordered = orderBy(
        countries,
        [(country) => get(country, 'name', '')],
        ['desc', 'asc'],
      );
      setTopics(ordered);
      setSubscribedCountries(
        includeCountryAltNames(ordered.filter((country) => country.subscribed)),
      );
    });
  }, [user, client]);

  useEffect(() => {
    if (!user || !user.id) return;
    fetchBriefs({ client }).then((briefList) => {
      const ordered = orderBy(
        briefList,
        [(brief) => get(brief, 'type', ''), (brief) => get(brief, 'name', '')],
        ['asc', 'asc'],
      );
      setBriefs(ordered);
    });
  }, [user, client]);

  useEffect(() => {
    if (!user || !user.id) return;
    fetchLandscapes({ client }).then((landscapeList) => {
      setLandscapes(landscapeList);
    });

    fetchBookmarks({ client }).then((bookmarkData) => {
      setBookmarks(bookmarkData.entities);
      setTotalBookmarks(bookmarkData.total);
    });
  }, [user, client]);

  useEffect(() => {
    if (!user || !user.id) return;
    fetchConnections({ client }).then((connectionsData) => {
      setConnections(connectionsData.connections);
      setTotalConnections(connectionsData.total);
    });
  }, [user, client]);

  useEffect(() => {
    if (!user || !user.id) return;
    fetchConnections({ client }).then((connectionsData) => {
      setConnections(connectionsData.connections);
      setTotalConnections(connectionsData.total);
    });
  }, [user, client]);

  useEffect(() => {
    if (!user || !user.id) return;
    fetchUserTotalNotes({ client }).then((notesCount) => {
      setTotalNotes(notesCount);
    });
  }, [user, client, toggleFetchUserTotalNotes]);

  const refetchUserTotalNotes = () => {
    setToggleFetchUserTotalNotes(!toggleFetchUserTotalNotes);
  };

  const flatIndustries = useMemo(() => {
    if (!briefs || briefs.length === 0) return [];

    const thirdLevel = [];

    briefs.forEach((sector) => {
      sector.children.forEach((subSector) => {
        subSector.children.forEach((industry) => {
          thirdLevel.push(cloneDeep(industry));
        });
      });
    });

    return thirdLevel;
  }, [briefs]);

  return (
    <Context.Provider
      value={{
        briefs,
        topics,
        subscribedCountries,
        landscapes,
        bookmarks,
        connections,
        totalBookmarks,
        totalConnections,
        totalNotes,
        flatIndustries,

        setConnections,
        setTotalConnections,
        setBookmarks,
        setTotalBookmarks,
        setTotalNotes,

        refetchUserTotalNotes,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAxisData = () => useContext(Context);
