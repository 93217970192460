import queryFeed from '../../queries/getFeed';

export const UPDATED_DAYS = 1;

const resultTypeNormalize = (type) => {
  switch (type) {
    case 'events':
      return 'event';
    case 'regulations':
      return 'regulation';
    default:
      return type;
  }
};

export const fetchFeed = ({
  client,
  topics = [],
  entityIds = [],
  industryIds = [],
  updatedInLastDays = UPDATED_DAYS,
  filter,
  regulationTypes = [],
  includeBookmarks = false,
  includeConnections = false,
  includeNotes = false,
  includeCompliance = false,
  limit = 10,
  offset = 0,
}) => {
  return client
    .query({
      query: queryFeed,
      fetchPolicy: 'no-cache',
      variables: {
        topics,
        entityIds,
        industryIds,
        updatedInLastDays,
        filter: filter.map(resultTypeNormalize), // TODO: Fix when moving to v2 resolvers
        regulationTypes,
        limit,
        offset,
        includeBookmarks,
        includeConnections,
        includeNotes,
        includeCompliance,
      },
    })
    .then(({ data }) => data.getFeed);
};
