import React from 'react';
import css from './ToggleSidenav.module.css';
import FilterIcon from '../../../../icons/FilterIcon/FilterIcon';

function ToggleSideNav({ filterStatus, onClick }) {
  return (
    <div data-status={filterStatus} className={css.toggleContainer}>
      <button
        type="button"
        onClick={onClick}
        data-cy="feed-open-filters"
        className={css.toggleButton}
      >
        <FilterIcon />
      </button>
    </div>
  );
}

export default ToggleSideNav;
