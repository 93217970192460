import React, { useMemo } from 'react';

import css from './SmallEventCard.module.css';
import { BaseCard } from '../BaseCard/BaseCard';
import { calcImportanceScore } from '../../../shared/events';
import Score from '../../Score/Score';
import useNavigation from '../../../hooks/useNavigation';

function SmallEventCard({ event }) {
  const { toggleEventDossier } = useNavigation();

  const score = useMemo(
    () => calcImportanceScore(event.magnitude, event.sentiment),
    [event],
  );

  return (
    <div className={css.main}>
      <BaseCard.DateRow date={event.date} before={<Score score={score} />} />
      <BaseCard.Title
        onClick={() => toggleEventDossier(event.id)}
        className={css.title}
      >
        {event.title}
      </BaseCard.Title>
    </div>
  );
}

export default SmallEventCard;
