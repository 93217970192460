import React, { useEffect, useState } from 'react';
import { Loading } from '@axis/xyz.app.loading';
import { formatDate } from '../../lib/date';
import DossierCard from '../../components/DossierCard/DossierCard';
import EventCard from '../../components/EventCard/EventCard';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import css from './RelationshipEvents.module.css';

const PAGE_SIZE = 5;
const SAFETY_PAGE_BUFFER = 4;

const getEventsPage = (events, page) => {
  const start = (page - 1) * 5;
  const end = start + 5;
  return events.slice(start, end);
};

const RelationshipEvents = ({
  events = [],
  totalEvents,
  isEventsLoading,
  onLoadMore,
}) => {
  const router = useRouteInformation();

  const [page, setPage] = useState(1);

  const availablePages = Math.ceil(events.length / PAGE_SIZE);
  const totalPages = Math.ceil(totalEvents / PAGE_SIZE);
  const visibleEvents = getEventsPage(events, page);
  const isAllEventsLoaded = events.length === totalEvents;
  const isEmpty = visibleEvents.length === 0;

  const isPageLoading =
    (!isAllEventsLoaded && page * PAGE_SIZE > events.length) ||
    (page === 1 && isEventsLoading);

  useEffect(() => {
    if (isAllEventsLoaded) return;
    if (availablePages - page < SAFETY_PAGE_BUFFER && !isEventsLoading) {
      onLoadMore();
    }
  }, [page]);

  const handleEntityClick = (entityId) => {
    router.openDossier({ dossierEntityId: entityId, dossierType: 'entity' });
  };

  const showCard = (eventsData) =>
    eventsData.length > 0 ? (
      <DossierCard
        loading={isPageLoading}
        title="Events"
        count={totalEvents}
        currentPage={page}
        totalPages={totalPages}
        showPages
        onPageChange={setPage}
      >
        <div className={css.container}>
          {isEmpty && !isEventsLoading && (
            <div className={css.empty}>
              These entities do not have a shared timeline
            </div>
          )}
          {visibleEvents.map(
            (
              {
                id,
                title,
                date,
                entities,
                briefs,
                countries,
                landscapes,
                sources,
              },
              i,
            ) => (
              <EventCard
                key={i}
                id={id}
                pillSize="small"
                title={title}
                date={formatDate(date)}
                sources={sources}
                entities={entities}
                briefs={briefs}
                countries={countries}
                landscapes={landscapes}
                onEntityClick={handleEntityClick}
              />
            ),
          )}
        </div>
      </DossierCard>
    ) : null;

  return (
    <div>
      {isEventsLoading ? (
        <div className={css.loadingArea}>
          <Loading className={css.loading} />
        </div>
      ) : (
        showCard(events)
      )}
    </div>
  );
};

export default RelationshipEvents;
