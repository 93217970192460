import React from 'react';
import Chevron from '../../../../icons/Chevron';
import css from './ExpandDossierButton.module.css';

const ExpandDossierButton = ({ size, onClick }) => (
  <div className={css.button} onClick={onClick} data-cy="dossier-expand">
    {size === 'normal' && <Chevron direction="left" />}
    {size === 'expanded' && <Chevron direction="right" />}
  </div>
);

export default ExpandDossierButton;
