import { gql } from '@apollo/client';

const query = gql`
  query getDossierQuotes($id: ID!, $page: Int, $limit: Int) {
    getDossierQuotes(id: $id, page: $page, limit: $limit) {
      totalQuotes
      quotes {
        id
        quote
        context
        date
        speaker {
          id
          name
          shortestName
          image
          type
          topics
          inSubscription
          isConnected
        }
        industries {
          id
          name
        }
        countries {
          id
          name
        }
        event {
          id
          title
          sources
        }
      }
    }
  }
`;

export default query;
