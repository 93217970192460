import React from 'react';

import { Loading } from '@axis/xyz.app.loading';
import { useHistory } from 'react-router-dom';
import ArrowLeft from '../../icons/ArrowLeft';
import css from './DossierHeader.module.css';
import CloseDossierButton from './components/CloseDossierButton/CloseDossierButton';
import ExpandDossierButton from './components/ExpandDossierButton/ExpandDossierButton';

const DossierHeader = ({
  isLoading,
  size,
  onSizeChange,
  children,
  border,
  sticky,
}) => {
  const history = useHistory();
  const { state } = history.location;

  if (isLoading) {
    return (
      <div data-cy="loading" className={css.loading}>
        <Loading className={css.spinner} />
      </div>
    );
  }

  return (
    <div data-cy="dossier-header" className={css.header} data-sticky={sticky}>
      <div
        className={css.controls}
        data-border={border}
        data-sticky={sticky}
        data-cy="dossier-controls"
      >
        <ExpandDossierButton
          size={size}
          onClick={() =>
            onSizeChange(size === 'expanded' ? 'normal' : 'expanded')
          }
        />

        {state?.back && (
          <button type="button" className={css.goBack} onClick={history.goBack}>
            <ArrowLeft />
            {state.back}
          </button>
        )}

        <CloseDossierButton />
      </div>
      {children}
    </div>
  );
};

export default DossierHeader;
