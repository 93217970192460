import React from 'react';

import css from './TreeNode.module.css';
import { colorRangeMap } from '../TreeMap/utils';
import useAnimatedTooltip from '../TreeMapTooltip/useAnimatedTooltip';

function TreeNode({ node, onNodeClick }) {
  const { data } = node;
  const { handleMouseEnter, handleMouseLeave, handleMouseMove } =
    useAnimatedTooltip();

  const getColor = ({ score }) => {
    const roundedScore = Math.round(score);
    return colorRangeMap[roundedScore.toString()] || 'gray'; // Default to gray if score is out of range
  };

  const getEventCount = (eventCount) => {
    const maxHeight = 90;
    const maxWidth = 100;

    if (node.height < maxHeight) return '';
    return eventCount + (node.width < maxWidth ? '' : ' Events');
  };

  const hideText = node.width < 100 && node.height < 100;

  return (
    <div
      onMouseEnter={(e) => handleMouseEnter(node, e)}
      onMouseMove={(e) => handleMouseMove(node, e)}
      onMouseLeave={handleMouseLeave}
      onClick={() => onNodeClick(data)}
      style={{
        top: node.y,
        left: node.x,
        width: node.width,
        height: node.height,
        background: getColor(data),
      }}
      className={css.main}
    >
      {!hideText && (
        <>
          <div className={css.info}>
            <div className={css.industryLabel}>{data.industry.name}</div>
            <div className={css.countryLabel}>{data.country.name}</div>
          </div>

          <div className={css.stats}>
            <div>{getEventCount(data.occurrences)}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default TreeNode;
