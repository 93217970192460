import React from 'react';
import css from './MultiSelectionTree.module.css';
import ChevronDown from '../../icons/ChevronDown';
import { ACTIONS } from '../Filters/SearchIndustries/reducer';

/**
 * @param expandAll  expand all options in case a query is applied
 * @param selectedOptions list of selected options ids
 * @param expandedOptions list of expanded options ids
 * @param dispatch dipacther instance used to emit actions
 * @param options list of options that may contain .children attribute
 */

function MultiSelectionTree({
  options,
  expandAll,
  dispatch,
  selectedOptions,
  expandedOptions,
  singleSelection = false,
}) {
  return (
    <div className={css.container} data-cy="multi-selection-tree">
      {options.map((option) => (
        <Option
          key={option.id}
          option={option}
          dispatch={dispatch}
          expandAll={expandAll}
          singleSelection={singleSelection}
          selectedOptions={selectedOptions}
          expandedOptions={expandedOptions}
        />
      ))}
    </div>
  );
}

export default MultiSelectionTree;

const Option = ({
  option,
  selectedOptions,
  expandedOptions,
  dispatch,
  level = 0,
  expandAll,
  singleSelection = false,
}) => {
  const childSpacing = 25;
  const hasChildren = option.children?.length > 0;
  const isSelected = selectedOptions.includes(option.id);
  const isExpanded = expandAll || expandedOptions.includes(option.id);
  const showChildrenOptions = hasChildren && isExpanded;

  const handleOnInputChange = (item) => {
    const type = isSelected ? ACTIONS.UNSELECT_OPTION : ACTIONS.SELECT_OPTION;
    dispatch({ type, payload: item });
  };

  const handleToggleExpanssion = (optionId) => {
    const type = isExpanded ? ACTIONS.COLLAPSE_OPTION : ACTIONS.EXPAND_OPTION;
    dispatch({ type, payload: optionId });
  };

  if (!option.visible) return null;

  return (
    <>
      <div
        className={css.optionRow}
        style={{ marginLeft: level * childSpacing }}
      >
        <ChevronDown
          data-hide-svg={singleSelection}
          data-visible={hasChildren}
          data-expanded={isExpanded}
          className={css.chevron}
          data-cy={`tree-expand-option-${option.id}`}
          id="industry-filter-expand"
          onClick={() => handleToggleExpanssion(option.id)}
        />
        <div
          className={css.option}
          data-cy={`tree-select-option-${option.id}`}
          id="industry-filter-name"
          onClick={() => handleOnInputChange(option)}
        >
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => handleOnInputChange(option, isSelected)}
          />
          <div data-cy="industry-name">{option.name}</div>
        </div>
      </div>

      {showChildrenOptions &&
        option.children.map((item) => (
          <Option
            key={item.id}
            option={item}
            level={level + 1}
            expandAll={expandAll}
            selectedOptions={selectedOptions}
            expandedOptions={expandedOptions}
            dispatch={dispatch}
          />
        ))}
    </>
  );
};
