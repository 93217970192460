import React from 'react';
import FilterType from '../FilterType/FilterType';
import { filterTypes } from '../../index';
import OptionRow from '../OptionRow/OptionRow';

function Regulations({
  activeFilter,
  onClick,
  options,
  onOptionChange,
  counts,
  isLoadingCounts,
  isActiveFilterCollapsed,
}) {
  return (
    <FilterType
      label="Regulations"
      count={counts.regulation}
      isLoadingCounts={isLoadingCounts}
      activeFilter={activeFilter}
      isActiveFilterCollapsed={isActiveFilterCollapsed}
      type={filterTypes.Regulations}
      onClick={onClick}
    >
      {options &&
        options.map((option) => (
          <OptionRow
            key={option.id}
            option={option}
            counts={counts}
            handleOnInputChange={onOptionChange}
          />
        ))}
    </FilterType>
  );
}

export default Regulations;
