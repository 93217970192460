/* eslint-disable */

import React, { useState } from 'react';

import Button from '../../../Buttom/Buttom';
import css from './EditCompliantRegulation.module.css';
import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';

function EditCompliantRegulation({
  regulationId,
  isCompliant,
  note,
  onClose,
  onConfirmChanges,
}) {
  const [comply, setComply] = useState(isCompliant);
  const [textAreaValue, setTextAreaValue] = useState(note);

  return (
    <div className={css.main} onClick={(e) => e.stopPropagation()}>
      <div className={css.header}>
        <h2>Regulation Compliance</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      <div className={css.checkbox}>
        <input
          type="checkbox"
          name="flag"
          id="flag"
          checked={comply}
          onChange={(e) => setComply(e.target.checked)}
        />
        <label htmlFor="flag">My organization is compliant</label>
      </div>

      {isCompliant && (
        <p className={css.info}>
          Unchecking this box will remove this compliance
        </p>
      )}

      <textarea
        className={css.textarea}
        type="text-area"
        placeholder="Add a note about this regulation"
        onChange={(e) => setTextAreaValue(e.target.value)}
        value={textAreaValue}
      />

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onConfirmChanges({
              regulationId,
              note: textAreaValue,
              flag: comply,
            });
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default EditCompliantRegulation;
