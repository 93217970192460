import React from 'react';

const Explore = ({ className = '' }) => (
  <svg className={className} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 5C2.5 3.61929 3.61929 2.5 5 2.5C6.38071 2.5 7.5 3.61929 7.5 5C7.5 5.50954 7.34756 5.98348 7.0858 6.3787L10.6213 9.9142C11.0165 9.65244 11.4905 9.5 12 9.5C12.5095 9.5 12.9835 9.65244 13.3787 9.9142L16.9142 6.37869C16.6524 5.98348 16.5 5.50954 16.5 5C16.5 3.61929 17.6193 2.5 19 2.5C20.3807 2.5 21.5 3.61929 21.5 5C21.5 6.38071 20.3807 7.5 19 7.5C18.4905 7.5 18.0165 7.34756 17.6213 7.0858L14.0858 10.6213C14.3476 11.0165 14.5 11.4905 14.5 12C14.5 12.5095 14.3476 12.9835 14.0858 13.3787L17.6213 16.9142C18.0165 16.6524 18.4905 16.5 19 16.5C20.3807 16.5 21.5 17.6193 21.5 19C21.5 20.3807 20.3807 21.5 19 21.5C17.6193 21.5 16.5 20.3807 16.5 19C16.5 18.4905 16.6524 18.0165 16.9142 17.6213L13.3787 14.0858C12.9835 14.3476 12.5095 14.5 12 14.5C11.4905 14.5 11.0165 14.3476 10.6213 14.0858L7.0858 17.6213C7.34756 18.0165 7.5 18.4905 7.5 19C7.5 20.3807 6.38071 21.5 5 21.5C3.61929 21.5 2.5 20.3807 2.5 19C2.5 17.6193 3.61929 16.5 5 16.5C5.50954 16.5 5.98348 16.6524 6.37869 16.9142L9.9142 13.3787C9.65244 12.9835 9.5 12.5095 9.5 12C9.5 11.4905 9.65244 11.0165 9.9142 10.6213L6.3787 7.0858C5.98348 7.34756 5.50954 7.5 5 7.5C3.61929 7.5 2.5 6.38071 2.5 5ZM12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13ZM6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5ZM19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20ZM6 19C6 19.5523 5.55228 20 5 20C4.44772 20 4 19.5523 4 19C4 18.4477 4.44772 18 5 18C5.55228 18 6 18.4477 6 19ZM19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4C18.4477 4 18 4.44772 18 5C18 5.55228 18.4477 6 19 6Z"
      fill="#F2F4F7"
    />
  </svg>
);

export default Explore;
