import React, { memo, useRef } from 'react';

import css from './Question.module.css';
import useSearch from '../../../../hooks/useSearch';
import Process from '../Process/Process';
import SmartResponse from '../SmartResponse/SmartResponse';
import Sources from '../Sources/Sources';
import Button from '../../../../components/Buttom/Buttom';
import useNavigation from '../../../../hooks/useNavigation';
import { ROUTES } from '../../../../shared/routes';

function Question({ question }) {
  const containerRef = useRef(null);
  const { isLoading, data } = useSearch(question);
  const { navigate, params } = useNavigation();

  const switchToKeywordSearch = () => {
    params.delete('question');
    params.set('search', question);
    navigate(ROUTES.ADV_SEARCH, params.toString());
  };

  return (
    <div ref={containerRef} className={css.main}>
      <h1 className={css.question} data-cy="semantic-search-question">
        {question}
      </h1>
      <div className={css.switcher}>
        Results don’t look right?
        <Button type="secondary" onClick={switchToKeywordSearch}>
          Switch to Keyword search
        </Button>
      </div>
      <Process question={question} isQuestionLoading={isLoading} />
      <SmartResponse response={data.answer} />

      {data.answerCompleted && (
        <Sources sources={data.source} isLoading={!data.source} />
      )}
    </div>
  );
}

export default memo(Question);
