import React from 'react';

import css from './Header.module.css';
import SingleIndustryFilter from '../SingleIndustryFilter/SingleIndustryFilter';
import SingleCountryFilter from '../SingleCountryFilter/SingleCountryFilter';
import DateFilter from '../../../components/Filters/DateFilter/DateFilter';

function Header({
  startDate,
  endDate,
  industryId,
  setIndustryId,
  countryId,
  setCountryId,
  setDateInterval,
}) {
  return (
    <div className={css.main}>
      <div className={css.filters}>
        <SingleIndustryFilter
          industryId={industryId}
          setIndustryID={setIndustryId}
        />

        <div className={css.divider} />

        <SingleCountryFilter
          countryId={countryId}
          setCountryId={setCountryId}
        />

        <div className={css.divider} />

        <DateFilter
          singleSelection
          dateInterval={[new Date(startDate), new Date(endDate)]}
          setDateInterval={setDateInterval}
        />
      </div>
    </div>
  );
}

export default Header;
