import React, { useCallback, useState } from 'react';
import CountryFlag from '@axis/xyz.flags.flags';
import PaginationButton from '../../../components/PaginationButton/PaginationButton';
import Checkbox from './Checkbox';
import css from './CountrySubscriptionRow.module.css';

const CountrySubscriptionRow = ({
  topic,
  briefs,
  subscribedBriefs,
  onBriefClick,
  onSelectAll,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = useCallback(() => setExpanded(!expanded), [expanded]);

  const handleBriefClick = (brief) => {
    onBriefClick(topic.id, brief.id);
  };

  const handleSelectAll = () => {
    onSelectAll(
      topic.id,
      subscribedBriefs.length === briefs.length ? [] : briefs.map((b) => b.id),
    );
  };

  const handleSave = () => {
    setExpanded(false);
  };

  return (
    <div className={css.card} data-status={expanded ? 'expanded' : 'collapsed'}>
      <div className={css.titleCard} onClick={toggle}>
        <div className={css.cardContent}>
          <CountryFlag country={topic.name} />
          <h1 className={css.title}>{topic.name}</h1>
        </div>
        <PaginationButton
          onClick={toggle}
          direction={expanded ? 'up' : 'down'}
        />
      </div>

      {expanded && (
        <div>
          <div className={css.briefs}>
            {briefs.map((b, idx) => (
              <div className={css.briefItem} key={idx}>
                <Checkbox
                  id={`${topic.name}-${b.name}`}
                  isChecked={subscribedBriefs.find((sb) => sb === b.id)}
                  onChange={() => handleBriefClick(b)}
                  label={b.name}
                />
              </div>
            ))}
          </div>
          <div className={css.buttons}>
            <button
              type="button"
              className={css.selectButton}
              onClick={handleSelectAll}
            >
              {subscribedBriefs.length === briefs.length
                ? 'Unselect all'
                : 'Select all'}
            </button>
            <button
              type="button"
              className={css.saveButton}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountrySubscriptionRow;
