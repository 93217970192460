import React from 'react';
import css from './FieldRow.module.css';

export const FieldRow = ({ label, text, children }) => (
  <div className={css.row} data-cy="field-row" data-cy-content={label}>
    <p data-cy="field-row-label" className={css.rowLabel}>
      {label}
    </p>
    <div data-cy="field-row-value" className={css.rowValue}>
      {text && <p className={css.textValue}>{text}</p>}
      {children}
    </div>
  </div>
);
