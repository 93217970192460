import React from 'react';

import css from './DossierAvatar.module.css';

function DossierAvatar({ children, small }) {
  return (
    <div className={css.main} data-small={small}>
      {children}
    </div>
  );
}

export default DossierAvatar;
