import React from 'react';
import DossierCard, { DossierCardItem } from '../../DossierCard/DossierCard';
import {
  getDateLabel,
  getDateValue,
  getLifespanDetails,
} from '../../../../../../../lib/facets/lifespan';

import css from './PersonalInformation.module.css';

function PersonalInformation({ entity, className }) {
  const lifespan = getLifespanDetails(entity);

  if (!lifespan) return null;

  const { entityType, entitySubType, start, end, age } = lifespan;
  const dateLabel = getDateLabel({ entityType, entitySubType, start, end });
  const dateValue = getDateValue({ start, end });

  return (
    <DossierCard
      className={className}
      title="Personal Information"
      data-cy="lifespan-facet"
    >
      <div className={css.container}>
        {age && (
          <DossierCardItem
            title="Age"
            content={age}
            data-cy="lifespan-age"
          />
        )}

        {dateLabel && dateValue && (
          <DossierCardItem
            title={dateLabel}
            content={dateValue}
            data-cy="lifespan-date"
          />
        )}
      </div>
    </DossierCard>
  );
}

export default PersonalInformation;
