import * as React from 'react';

function Pdb(props) {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.333 12.5h5.333v-1.333H5.333V12.5zm0-2.667h5.333V8.5H5.333v1.333zm-1.334 5.334c-.366 0-.68-.131-.941-.392a1.284 1.284 0 01-.392-.942V3.167c0-.367.13-.681.392-.942.26-.261.575-.392.941-.392h5.334l4 4v8c0 .367-.13.68-.392.942a1.284 1.284 0 01-.942.392H4zM8.666 6.5h3.333L8.666 3.167V6.5z"
        fill="#929AA3"
      />
    </svg>
  );
}

export default Pdb;
