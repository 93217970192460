import * as React from 'react';

function NotesOutline(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M4 14.667c-.367 0-.68-.13-.942-.392a1.284 1.284 0 01-.391-.942V2.667c0-.367.13-.681.392-.942.26-.261.574-.392.94-.392h5.334l4 4v8c0 .367-.13.681-.391.942-.261.261-.575.392-.942.392H4zM8.666 6V2.667H4v10.666h8V6H8.666z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default NotesOutline;
