import React, { useMemo } from 'react';

import css from './Tags.module.css';
import BriefPill from '../../../../../../components/BriefPill/BriefPill';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
// this is the count of how many characters fit on small dossier view
const MAX_COUTN = 32;
const MAX_COUTN_COUNTRIES = 27;

const getFitOptionsCount = (options, max) => {
  let count = 0;
  let charCount = 0;

  for (let index = 0; index < options.length; index++) {
    const element = options[index];

    if (element.name.length + charCount > max) {
      break;
    }

    count = index + 1;
    charCount = element.name.length + charCount;
  }

  return count;
};

const putPrimaryTopicOnTop = (countries, primaryTopicId) => {
  if (!primaryTopicId) return countries;

  const index = countries.findIndex((item) => item.id === primaryTopicId);
  if (index === -1) return countries; // Item not found, return original array

  const arrayCopy = [...countries];
  // Remove the item at the found index and store it in 'item'
  const [item] = arrayCopy.splice(index, 1);

  // Add the item to the beginning of the array
  arrayCopy.unshift(item);

  return arrayCopy;
};

export function Industries({ industries, size }) {
  const [tags, remaining] = useMemo(() => {
    if (size === 'expanded') return [industries, 0];
    if (!industries) return [[], 0];

    const count = getFitOptionsCount(industries, MAX_COUTN);

    const tg = industries.slice(0, count);
    const rem = industries.slice(count, industries.length);

    return [tg, rem];
  }, [size, industries]);

  if (!industries) return [];
  return (
    <div className={css.tags}>
      {tags.map((tag) => (
        <BriefPill key={tag.id} name={tag.name} />
      ))}

      {remaining.length > 0 && (
        <Tooltip
          text={
            <div className={css.tooltip}>
              {remaining.map(({ name }) => (
                <React.Fragment key={name}>
                  {name} <br />
                </React.Fragment>
              ))}
            </div>
          }
        >
          <BriefPill name={`+${remaining.length}`} />
        </Tooltip>
      )}
    </div>
  );
}

export function Countries({ countries, size, primaryTopicId }) {
  const [tags, remaining] = useMemo(() => {
    if (size === 'expanded')
      return [putPrimaryTopicOnTop(countries, primaryTopicId), 0];
    if (!countries) return [[], 0];

    const options = putPrimaryTopicOnTop(countries, primaryTopicId);
    const fitCount = getFitOptionsCount(options, MAX_COUTN_COUNTRIES);
    const tg = options.slice(0, fitCount);
    const rem = options.slice(fitCount, options.length);
    return [tg, rem];
  }, [size, countries]);

  if (!countries) return [];
  return (
    <div className={css.tags}>
      {tags.map((tag) => (
        <BriefPill key={tag.id} name={tag.name} isGeography />
      ))}

      {remaining.length > 0 && (
        <Tooltip
          text={
            <div className={css.tooltip}>
              {remaining.map(({ name }) => (
                <React.Fragment key={name}>
                  {name} <br />
                </React.Fragment>
              ))}
            </div>
          }
        >
          <BriefPill isGeography name={`+${remaining.length}`} />
        </Tooltip>
      )}
    </div>
  );
}
