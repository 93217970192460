import React from 'react';

import css from './Body.module.css';
import Roles from './Facets/Roles/Roles';
import Timeline from './Facets/TimeLine/Timeline';
import Education from './Facets/Education/Education';
import AlterName from './Facets/AlterName/AlterName';
import NetworkTabs from './Facets/NetworkTabs/NetworkTabs';
import PersonalInformation from './Facets/PersonalInformation/PersonalInformation';
import useEntityDossierRelationships from '../../../../hooks/useEntityDossierRelationships';
import Regulations from './Facets/Regulations/Regulations';
import Quote from './Facets/Quote/Quote';
import Bio from './Facets/Bio/Bio';
import AxisUserGuard from '../../../AxisUserGuard/AxisUserGuard';

function Body({ entity, size, isEntityLoading }) {
  const { isLoadingRelationships, relationships } =
    useEntityDossierRelationships(entity?.id);

  if (!entity || isLoadingRelationships || isEntityLoading)
    return <LoadingState />;
  if (!entity.inSubscription) return <OutOfSubscription />;

  const showRoleEducationLine = Boolean(
    entity && (entity.roles?.length || entity.educations?.length),
  );

  const showInfo = Boolean(
    entity && (entity.lifespan || entity.alternateNames?.length),
  );

  return (
    <>
      <div className={css.content} data-size={size}>
        <div
          data-cy="dossier-overview"
          data-type="dossier-section-v2"
          data-group="overview"
        />

        <AxisUserGuard>
          {entity.bio && (
            <div className={css.lineOne}>
              <Bio bio={entity.bio} />
            </div>
          )}
        </AxisUserGuard>

        {showInfo && (
          <div className={css.line}>
            <PersonalInformation entity={entity} />
            <AlterName entity={entity} />
          </div>
        )}

        {showRoleEducationLine && (
          <div className={css.line}>
            <Roles entity={entity} />
            <Education entity={entity} />
          </div>
        )}

        <Regulations entity={entity} size={size} className={css.lineOne} />

        <div
          data-cy="dossier-timeline"
          data-type="dossier-section-v2"
          data-group="timeline"
        />

        <Timeline entity={entity} size={size} className={css.lineOne} />

        <Quote entity={entity} size={size} className={css.lineOne} />

        <div
          data-cy="dossier-network"
          data-type="dossier-section-v2"
          data-group="network"
        />
        <NetworkTabs
          relationships={relationships}
          size={size}
          className={css.line}
        />
      </div>
    </>
  );
}

export default Body;

function OutOfSubscription() {
  return (
    <p className={css.outsideSubscription}>
      This content is outside your subscription. Please
      <a href="mailto:contact@axis.xyz"> contact Sales</a> to gain access.
    </p>
  );
}

function LoadingState() {
  return (
    <div className={css.loadingContainer}>
      <div style={{ height: 200 }} className="skeleton-v2" />
      <div style={{ height: 200 }} className="skeleton-v2" />
      <div style={{ height: 200 }} className="skeleton-v2" />
      <div style={{ height: 200 }} className="skeleton-v2" />
    </div>
  );
}
