import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { Typography } from '@axis/xyz.app.typography';
import css from './SavedSearchCard.module.css';
import SearchIcon from '../../icons/Search';
import TrashIcon from '../../icons/Trash';
import EditIcon from '../../icons/Edit';
import MoreIcon from '../../icons/More';
import HomeIcon from '../../icons/Home';
import { getLabelFromType } from '../../lib/search';
import BriefPill from '../BriefPill/BriefPill';
import EntityPill from '../EntityPill/EntityPill';

import updateSavedSearch from '../../queries/updateSaveSearch';
import SavedSearchModal from '../SavedSearchModal/SavedSearchModal';
import { useApolloClient } from '../../hooks/useApolloClient';
import notify from '../../lib/notify';
import ExploreIcon from '../../icons/Explore';

const updateSavedSearchMutation = async ({
  client,
  savedSearchId,
  updatedName,
  updatedData,
}) => {
  return client
    .mutate({
      mutation: updateSavedSearch,
      variables: { savedSearchId, updatedName, updatedData },
    })
    .then(({ data }) => data.updateSavedSearch);
};

const SavedSearchCard = ({
  savedSearch,
  onClick,
  onDelete,
  // closeSavedSearchTab,
  refreshSavedSearches,
  source,
}) => {
  const savedSearchData = JSON.parse(savedSearch.search_data);
  const {
    name,
    topics,
    filterEntities,
    filterCategories,
    filterIndustries,
    filterLandscapes,
    searchDates,
    isHomeFeed,
  } = savedSearchData;
  const [openUpdateSavedSearchModal, setOpenUpdateSavedSearchModal] =
    useState(false);
  const client = useApolloClient();
  const [modalSavedSearchId, setModalSavedSearchId] = useState(null);
  const [savedSearchName, setSavedSearchName] = useState(savedSearch.name);
  const [cardOptionsOpen, setCardOptionsOpen] = useState(false);
  const [updateType, setUpdateType] = useState(null);

  const handleOnUpdate = (e, savedSearchId, type) => {
    e.stopPropagation();
    setUpdateType(type);
    setOpenUpdateSavedSearchModal(true);
    setModalSavedSearchId(savedSearchId);
    setCardOptionsOpen(!cardOptionsOpen);
  };

  const handleCloseUpdateSavedSearchModal = () => {
    setModalSavedSearchId(null);
    setOpenUpdateSavedSearchModal(false);
  };

  const handleSavedSearchNameChange = (e) => {
    setSavedSearchName(e.target.value);
  };

  const handleDelete = (e, savedSearchId) => {
    e.stopPropagation();
    onDelete(savedSearchId);
  };

  const handleOnUpdateSave = async (payload) => {
    const result = await updateSavedSearchMutation({
      client,
      savedSearchId: modalSavedSearchId,
      updatedName: savedSearchName,
      updatedData: JSON.stringify(payload),
    });
    if (result) {
      notify.success('Search has been saved!', { position: 'bottom-right' });
    } else {
      notify.error('Something went wrong!', { position: 'bottom-right' });
    }
    handleCloseUpdateSavedSearchModal();
    refreshSavedSearches();
  };

  const handleOptionClick = (e) => {
    e.stopPropagation();
    setCardOptionsOpen(!cardOptionsOpen);
  };

  return (
    <div data-cy="saved-searches-card" className={css.savedSearchCard}>
      <div
        onClick={() =>
          onClick(savedSearchData, savedSearchName, savedSearch.id)
        }
      >
        {isHomeFeed && (
          <Typography className={css.homeFeed}>Home Feed</Typography>
        )}
        <div className={css.savedSearchCardHeader}>
          <div className={css.savedSearchCardHeaderLeft}>
            <span>
              {source === 'explore' ? (
                <ExploreIcon className={css.exploreIcon} />
              ) : (
                <SearchIcon className={css.searchIcon} />
              )}
            </span>
            <div
              data-cy="saved-searches-card-title"
              className={css.savedSearchCardHeaderTitle}
            >
              {savedSearch.name}
            </div>
          </div>
          <div>
            <Popover
              isOpen={cardOptionsOpen}
              positions={['bottom', 'right']}
              containerClassName={css.menu}
              onClickOutside={() => setCardOptionsOpen(!cardOptionsOpen)}
              content={
                <div>
                  <div className={css.optionsItem}>
                    <span
                      data-cy="saved-searches-card-edit"
                      onClick={(e) => handleOnUpdate(e, savedSearch.id, 'edit')}
                    >
                      <EditIcon className={css.editIcon} /> Edit saved search
                    </span>
                  </div>
                  {isHomeFeed ? (
                    <div className={css.optionsItemRemoval}>
                      <span
                        data-cy="saved-searches-card-edit"
                        onClick={(e) =>
                          handleOnUpdate(e, savedSearch.id, 'removeHomeFeed')
                        }
                      >
                        <HomeIcon className={css.homeIcon} color="#cc0909" />{' '}
                        Remove as home feed
                      </span>
                    </div>
                  ) : (
                    <div className={css.optionsItem}>
                      <span
                        data-cy="saved-searches-card-edit"
                        onClick={(e) =>
                          handleOnUpdate(e, savedSearch.id, 'setHomeFeed')
                        }
                      >
                        <HomeIcon className={css.homeIcon} /> Set as home feed
                      </span>
                    </div>
                  )}
                  {!isHomeFeed && (
                    <div className={css.optionsItemRemoval}>
                      <span
                        data-cy="saved-searches-card-delete"
                        onClick={(e) => handleDelete(e, savedSearch.id)}
                      >
                        <TrashIcon className={css.trashIcon} color="#cc0909" />{' '}
                        Delete saved search
                      </span>
                    </div>
                  )}
                </div>
              }
            >
              <div onClick={handleOptionClick}>
                <MoreIcon className={css.moreIcon} />
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <div
        className={css.savedSearchCardBody}
        data-cy="saved-searches-card-apply"
        onClick={() =>
          onClick(savedSearchData, savedSearchName, savedSearch.id)
        }
      >
        {filterCategories.length
          ? savedSearchData.filterCategories.map((type) => (
              <BriefPill key={type} name={getLabelFromType(type)} />
            ))
          : null}

        {topics.length
          ? topics.map((country) => (
              <BriefPill key={country.id} name={country.name} isGeography />
            ))
          : null}

        {filterEntities.length
          ? filterEntities.map((entity) => (
              <EntityPill
                key={entity.id}
                name={entity.name}
                shortName={entity.shortestName}
                image={entity.image}
                type={entity.type}
                size="small"
              />
            ))
          : null}
        {filterIndustries.length
          ? filterIndustries.map((industry) => (
              <BriefPill key={industry.id} name={industry.name} />
            ))
          : null}

        {filterLandscapes.length
          ? filterLandscapes.map((landscape) => (
              <BriefPill key={landscape.id} name={landscape.name} />
            ))
          : null}
        {name ? <div className={css.textSearch}>{name}</div> : null}
      </div>
      <SavedSearchModal
        activeSearch={name}
        openSaveSearchModal={openUpdateSavedSearchModal}
        saveSearchName={savedSearchName}
        handleSaveSearchNameChange={(e) => handleSavedSearchNameChange(e)}
        handleSaveSearch={(payload) => handleOnUpdateSave(payload)}
        handleCloseSaveSearchModal={() => handleCloseUpdateSavedSearchModal()}
        searchCountries={topics}
        searchTypes={filterCategories}
        searchIndustries={filterIndustries}
        searchEntities={filterEntities}
        searchDates={searchDates}
        searchLandscapes={filterLandscapes}
        updateType={updateType}
        isHomeFeed={isHomeFeed}
      />
    </div>
  );
};

export default SavedSearchCard;
