import React from 'react';
import Modal from 'react-modal';
import { modalStyles } from '../../../shared/modal';
import RemoveBookmark from '../../LeftPanel/Bookmarks/RemoveBookmark/RemoveBookmark';

function RemoveBookmarkModal({
  isOpen,
  entity,
  onBookmarkRemoveConfirm,
  onClose,
}) {
  const onRemoveBookmark = async () => {
    onBookmarkRemoveConfirm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      contentLabel="Remove bookmark"
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      <RemoveBookmark
        entity={entity}
        onClose={onClose}
        onRemove={onRemoveBookmark}
      />
    </Modal>
  );
}

export default RemoveBookmarkModal;
