import React from 'react';

import css from './List.module.css';

export const List = ({
  title,
  loading,
  children,
  empty,
  onClickSeeAll,
  count,
}) => {
  return (
    <div className={css.list}>
      <div className={css.title}>
        {title}
        <span>{count}</span>
        {!empty && (
          <button type="button" className={css.seeAll} onClick={onClickSeeAll}>
            See All
          </button>
        )}
      </div>

      <div className={css.list}>
        {empty && !loading && <EmptyState title={title} />}

        {loading && <LoadingState />}

        {!loading && children}
      </div>
    </div>
  );
};

function LoadingState() {
  return (
    <div className={css.loadingContainer}>
      <div style={{ height: 100 }} className="skeleton-v2" />
      <div style={{ height: 100 }} className="skeleton-v2" />
      <div style={{ height: 100 }} className="skeleton-v2" />
    </div>
  );
}

function EmptyState({ title }) {
  return <div className={css.empty}>No {title} for this period.</div>;
}
