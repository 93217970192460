import React, { useEffect, useRef, useState } from 'react';

import Modal from 'react-modal';
import css from './DossierNotes.module.css';
import Button from '../Buttom/Buttom';
import useOnKeyDown from '../../hooks/useOnKeyDown';
import NoteCard from './NoteCard/NoteCard';
import { modalStyles } from '../../shared/modal';
import EditNoteModal from '../LeftPanel/Notes/EditNoteModal/EditNoteModal';
import DeleteNoteModal from '../LeftPanel/Notes/DeleteNoteModal/DeleteNoteModal';
import notify from '../../../lib/notify';

const typeModal = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

function DossierNotes({
  saveNote,
  isSavingNote,
  isDeletingNote,
  isUpdatingNote,
  notes,
  onEditNote,
  onDeleteNote,
}) {
  const [openedModal, setOpenedModal] = useState(null);
  const textareaRef = useRef();

  const onSubmitNote = () => {
    const note = textareaRef.current.value;

    if (note.length === 0) return;

    saveNote(textareaRef.current.value);
  };

  useOnKeyDown(textareaRef, 'Enter', onSubmitNote);

  useEffect(() => {
    if (!isSavingNote) {
      textareaRef.current.value = ''; // reset input
    }
  }, [isSavingNote]);

  const onEdit = (note) => {
    setOpenedModal({ modal: typeModal.EDIT, context: note });
  };

  const onDelete = (note) => {
    setOpenedModal({
      modal: typeModal.DELETE,
      context: note,
    });
  };

  const onDeleteNoteConfirm = async (note) => {
    try {
      await onDeleteNote(note);
      setOpenedModal(null);
    } catch (error) {
      notify.error(error);
    }
  };

  const onEditNoteConfirm = async (note) => {
    try {
      await onEditNote(note);
      setOpenedModal(null);
    } catch (error) {
      notify.error(error);
    }
  };

  return (
    <div className={css.main}>
      <span className={css.label}>Notes</span>
      <div className={css.noteInput}>
        <textarea
          type="text"
          ref={textareaRef}
          autoComplete="off"
          placeholder="Type your note here"
        />
        <Button type="primary" onClick={onSubmitNote} loading={isSavingNote}>
          Save
        </Button>
      </div>

      <div className={css.notes}>
        {notes.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </div>

      <Modal
        isOpen={Boolean(openedModal)}
        style={modalStyles}
        contentLabel="Edit Note"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpenedModal(null)}
      >
        {openedModal?.modal === typeModal.EDIT && (
          <EditNoteModal
            note={openedModal.context.note}
            isUpdating={isUpdatingNote}
            onClose={() => setOpenedModal(null)}
            onConfirmChanges={(note) =>
              onEditNoteConfirm({ ...openedModal.context, note })
            }
          />
        )}
        {openedModal?.modal === typeModal.DELETE && (
          <DeleteNoteModal
            note={openedModal.context}
            isComplianceNote={false}
            isConnectionNote={false}
            isDeletingNote={isDeletingNote}
            onClose={() => setOpenedModal(null)}
            onDelete={(note) =>
              onDeleteNoteConfirm({ ...openedModal.context, note })
            }
          />
        )}
      </Modal>
    </div>
  );
}

export default DossierNotes;
