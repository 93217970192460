import React from 'react';
import { Link } from 'react-router-dom';

const InternalLink = ({ onClick, to = '', ...rest }) => (
  <Link
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    to={to}
    {...rest}
  />
);

export default InternalLink;
