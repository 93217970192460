import React from 'react';

const SourceIcon = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.66665 3.33337H3.99998C3.63179 3.33337 3.33331 3.63185 3.33331 4.00004V12C3.33331 12.3682 3.63179 12.6667 3.99998 12.6667H12C12.3682 12.6667 12.6666 12.3682 12.6666 12V9.33337"
      stroke="#AFAFAF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5833 5.99996C12.5833 6.41417 12.9191 6.74996 13.3333 6.74996C13.7475 6.74996 14.0833 6.41417 14.0833 5.99996L12.5833 5.99996ZM13.3333 2.66663L14.0833 2.66663C14.0833 2.46771 14.0043 2.27695 13.8637 2.1363C13.723 1.99564 13.5322 1.91663 13.3333 1.91663L13.3333 2.66663ZM10 1.91663C9.58579 1.91663 9.25 2.25241 9.25 2.66663C9.25 3.08084 9.58579 3.41663 10 3.41663V1.91663ZM14.0833 5.99996V2.66663L12.5833 2.66663L12.5833 5.99996L14.0833 5.99996ZM13.3333 1.91663H10V3.41663H13.3333V1.91663Z"
      fill="#AFAFAF"
    />
    <path
      d="M8.13636 6.80296C7.84346 7.09586 7.84346 7.57073 8.13636 7.86362C8.42925 8.15652 8.90412 8.15652 9.19702 7.86362L8.13636 6.80296ZM13.8637 3.19696C14.1566 2.90406 14.1566 2.42919 13.8637 2.1363C13.5708 1.8434 13.0959 1.8434 12.803 2.1363L13.8637 3.19696ZM9.19702 7.86362L13.8637 3.19696L12.803 2.1363L8.13636 6.80296L9.19702 7.86362Z"
      fill="#AFAFAF"
    />
  </svg>
);

export default SourceIcon;
