import React, { useEffect, useState } from 'react';
import { intersection } from 'lodash';
import { useApolloClient } from '../../hooks/useApolloClient';
import queryNewsletterSubscription from '../../queries/getNewsletterSubscriptions';
import mutateNewsletterSubscription from '../../queries/updateNewsletterSubscription';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext';
import notify from '../../lib/notify';
import CountrySubscriptionRow from './components/CountrySubscriptionRow';
import css from './Account.module.css';

const fetchNewsletterSubscription = ({ client }) => {
  return client
    .query({
      query: queryNewsletterSubscription,
    })
    .then(({ data }) => data.getTopicBriefSubscriptions);
};

const updateNewsletterSubscription = ({ client, subscriptions }) => {
  return client
    .mutate({
      mutation: mutateNewsletterSubscription,
      variables: {
        subscriptions,
      },
    })
    .then(({ data }) => data.updateNewsletterSubscription);
};

const Account = () => {
  const client = useApolloClient();

  const [industrySubscriptions, setIndustrySubscriptions] = useState({});
  const [axisBriefs, setAxisBriefs] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const handleClickedSubscription = (topicId, briefId) => {
    if (topicId === 'allCountries') {
      const subs = Object.keys(industrySubscriptions).reduce((acc, topic) => {
        acc[topic] = industrySubscriptions[topic].includes(briefId)
          ? industrySubscriptions[topic].filter((b) => b !== briefId)
          : [...new Set([...industrySubscriptions[topic], briefId])];
        return acc;
      }, {});
      setIndustrySubscriptions(subs);
      return;
    }

    if (industrySubscriptions[topicId].includes(briefId)) {
      setIndustrySubscriptions({
        ...industrySubscriptions,
        [topicId]: industrySubscriptions[topicId].filter((b) => b !== briefId),
      });
    } else {
      setIndustrySubscriptions({
        ...industrySubscriptions,
        [topicId]: [...new Set([...industrySubscriptions[topicId], briefId])],
      });
    }
  };

  const handleAllBriefsClick = (topicId, briefIds) => {
    if (topicId === 'allCountries') {
      const subs = Object.keys(industrySubscriptions).reduce((acc, topic) => {
        acc[topic] = briefIds;
        return acc;
      }, {});
      setIndustrySubscriptions(subs);
      return;
    }
    setIndustrySubscriptions({
      ...industrySubscriptions,
      [topicId]: briefIds,
    });
  };

  useEffect(() => {
    const subs = subscriptions.reduce((acc, topic) => {
      acc[topic.id] = topic.subscribedBriefs || [];
      return acc;
    }, {});
    setIndustrySubscriptions(subs);
  }, [subscriptions]);

  useEffect(() => {
    fetchNewsletterSubscription({ client })
      .then((data) => {
        const { briefs, subscribedTopics } = data;
        setAxisBriefs(briefs);
        const allCountries = [
          {
            name: 'All Countries',
            id: 'allCountries',
            flag: 'https://s3.amazonaws.com/axis.assets/2167e9b239ba786c1c4538ad0c37b71a.png',
            subscribedBriefs: intersection(
              ...subscribedTopics.map((t) => t.subscribedBriefs),
            ),
          },
        ];
        setSubscriptions([...allCountries, ...subscribedTopics]);
      })
      .catch(console.error);
  }, []);

  const handleSubmit = async () => {
    try {
      const { allCountries, ...subs } = industrySubscriptions;

      const subscriptionList = Object.keys(subs).map((topicId) => ({
        topicId,
        briefIds: industrySubscriptions[topicId],
      }));
      updateNewsletterSubscription({
        client,
        subscriptions: subscriptionList,
      }).then((result) => {
        if (result) {
          sendEvent('topic_subscription', {
            description: 'Update newsletter subscriptions',
            subscriptions: subscriptionList,
          });
          notify.success('Your changes have been saved.');
        } else {
          notify.error('Your subscriptions failed to update. Please try again');
        }
      });
    } catch (e) {
      notify.error('There was an error saving your changes.');
    }
  };

  return (
    <div className={css.main}>
      <h1 className={css.title}>Newsletter Subscriptions</h1>
      <p className={css.subtitle}>
        Newsletters are sent once a day and include the most relevant updates
        for each country. You can update your preferences below by selecting the
        topics and industries you are interested in. Select industries under
        &quot;All Countries&quot; to receive one industry newsletter from all of
        your subscriptions, or pick and choose industries from specific
        countries to receive multiple.
      </p>
      {subscriptions.map((topic) => (
        <CountrySubscriptionRow
          key={topic.name}
          topic={topic}
          briefs={axisBriefs}
          subscribedBriefs={industrySubscriptions[topic.id] || []}
          onBriefClick={handleClickedSubscription}
          onSelectAll={handleAllBriefsClick}
        />
      ))}
      <div className={css.submit}>
        <button type="button" className={css.button} onClick={handleSubmit}>
          Save subscriptions
        </button>
      </div>
    </div>
  );
};

export default Account;
