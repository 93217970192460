import React from 'react';

function Apartment(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_2813_74009"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2813_74009)">
        <path
          d="M2 14V4.66667H4.66667V2H11.3333V7.33333H14V14H8.66667V11.3333H7.33333V14H2ZM3.33333 12.6667H4.66667V11.3333H3.33333V12.6667ZM3.33333 10H4.66667V8.66667H3.33333V10ZM3.33333 7.33333H4.66667V6H3.33333V7.33333ZM6 10H7.33333V8.66667H6V10ZM6 7.33333H7.33333V6H6V7.33333ZM6 4.66667H7.33333V3.33333H6V4.66667ZM8.66667 10H10V8.66667H8.66667V10ZM8.66667 7.33333H10V6H8.66667V7.33333ZM8.66667 4.66667H10V3.33333H8.66667V4.66667ZM11.3333 12.6667H12.6667V11.3333H11.3333V12.6667ZM11.3333 10H12.6667V8.66667H11.3333V10Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Apartment;
