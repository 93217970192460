import { gql } from '@apollo/client';

const query = gql`
  query getRelationshipDossierHeader($targetId: ID!, $sourceId: ID!) {
    getRelationshipDossierHeader(targetId: $targetId, sourceId: $sourceId) {
      sourceEntity {
        id
        name
        shortestName
        topicsId
        inSubscription
        type
        image
        isConnected
      }
      targetEntity {
        id
        name
        shortestName
        topicsId
        inSubscription
        type
        image
        isConnected
      }
      earliestDate
      summary
    }
  }
`;

export default query;
