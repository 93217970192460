/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Typography, PRESET } from '@axis/xyz.app.typography';
import css from './NoteCard.module.css';
import EntityPill from '../EntityPill/EntityPill';
import ConnectionButton from '../Connection/ConnectionButton';
import BriefPill from '../BriefPill/BriefPill';
import { formatDateMed } from '../../lib/date';
import eventIcon from '../../icons/event.svg';
import regulationIcon from '../../icons/regulation.svg';
import { HamburgerMenuIcon } from '../../icons/HamburgerMenuIcon/HamburgerMenuIcon';
import InternalLink from '../InternalLink/InternalLink';
import { useModal } from '../../contexts/ModalContext';
import ComplianceButton from '../ComplianceButton/ComplianceButton';

const TYPE_MAPPING = {
  eventNote: 'event',
  regulationNote: 'regulation',
  connectionNote: 'connection',
  entityNote: 'entity',
  complianceNote: 'compliance',
};

const NoteCard = ({
  id,
  date,
  note,
  entity,
  userName,
  noteType,
  title,
  source,
  isConnected = false,
  handleToggleNoteUpdate,
}) => {
  const [visible, setVisible] = useState(false);
  const { setModalData, resetModalData } = useModal();
  const handleEdit = () => {
    setModalData({
      modalType: 'EDIT_NOTE',
      noteType: TYPE_MAPPING[noteType],
      openModal: true,
      targetId: entity && entity.id,
      noteData: note,
      noteId: id,
      handleModalClose: () => {
        resetModalData();
        handleToggleNoteUpdate();
      },
    });
  };

  const handleDelete = () => {
    setModalData({
      modalType: 'DELETE_NOTE',
      noteType: TYPE_MAPPING[noteType],
      openModal: true,
      targetId: entity && entity.id,
      noteData: note,
      noteId: id,
      handleModalClose: () => {
        resetModalData();
        handleToggleNoteUpdate();
      },
    });
  };

  if (!note) return null;
  return (
    <div>
      <div className={css.card}>
        <div className={css.topSection}>
          <div className={css.entitySection}>
            {entity && (
              <EntityPill
                name={entity.name}
                shortName={entity.shortestName}
                image={entity.image}
                type={entity.type}
                isConnected={isConnected}
                size="medium"
              />
            )}
            {title && (
              <a
                className={css.link}
                href={source}
                rel="noopener noreferrer"
                target="_blank"
                data-cy="link-source"
              >
                <img
                  data-cy="saved-searches-back"
                  src={noteType === 'eventNote' ? eventIcon : regulationIcon}
                  alt="back"
                />
                {title}
              </a>
            )}
          </div>
          <div className={css.noteSection} data-cy="notes-content">
            {note}
          </div>
          {noteType === 'connectionNote' && (
            <div className={css.connectedSection}>
              <ConnectionButton data-status="active" isConnected disabled />
            </div>
          )}
          {noteType === 'complianceNote' && (
            <div className={css.connectedSection}>
              <ComplianceButton data-status="active" isCompliant disabled />
            </div>
          )}
        </div>
        <div className={css.bottomSection}>
          <BriefPill name={userName} className={css.userPill} />
          {formatDateMed(date)}
          {!['complianceNote', 'connectionNote'].includes(noteType) && (
            <div
              data-cy="notes-hamburger-icon"
              className={css.hamburger}
              data-status={visible ? 'active' : 'inactive'}
              onClick={() => setVisible((v) => !v)}
            >
              <div className={css.icon}>
                <HamburgerMenuIcon />
              </div>
              <div className={css.dropdown}>
                {visible && (
                  <div className={css.menu}>
                    <div className={css.item}>
                      <InternalLink
                        className={css.hamburguerLink}
                        onClick={handleEdit}
                      >
                        <Typography preset={PRESET.NAVBAR_TITLE}>
                          Edit
                        </Typography>
                      </InternalLink>
                    </div>
                    <div className={css.item}>
                      <InternalLink
                        className={css.hamburguerLink}
                        onClick={handleDelete}
                      >
                        <Typography preset={PRESET.NAVBAR_TITLE}>
                          Delete
                        </Typography>
                      </InternalLink>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoteCard;
