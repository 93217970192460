import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useReducer,
} from 'react';
import { useRouteInformation } from '../../contexts/RouteInformationContext';

import { getResultType } from '../../lib/search';

import SearchBar from './components/SearchBar/SearchBar';
import SearchResults from './components/SearchResults/SearchResults';

import css from './Search.module.css';
import SearchHeader from './components/SearchHeader/SearchHeader';
import useNavigation from '../../v2/hooks/useNavigation';
import ToggleSideNav from '../Feed/components/ToggleSidenav/ToggleSidenav';
import { useSharedState } from '../../contexts/SharedStateContext';
import Filter from '../../v2/components/Filter/Filter';
import {
  ACTIONS,
  filterDefaultState,
  filterReducer,
} from '../../v2/components/Filter/filterReducer';
import {
  getEventSubFilters,
  getIdList,
  getSelectedValues,
  getSelectedValuesOnTree,
  getValueList,
  normalizeKey,
  normalizeSearchActiveFilter,
  searchCountToFilterAdapter,
} from '../../v2/components/Filter';
import { getSelectedValuesInTree, isFilterEqual } from './helper';
import useSearchCounts from './hooks/useSearchCounts';
import useSearchFacets from './hooks/useSearchFacets';
import useSearchEntities from './hooks/useSearchEntities';

const SearchContext = createContext();

const getFiltersSavedOnSessionStorageByQueryId = (queryId, type) => {
  if (!queryId) return [];
  const savedFilters = sessionStorage.getItem(queryId);
  try {
    const parsed = JSON.parse(savedFilters);
    return parsed[type] ?? [];
  } catch (_) {
    return [];
  }
};

const Search = () => {
  const router = useRouteInformation();
  const { params, replaceParams } = useNavigation();
  const { counts, fetchCounts, isCountLoading } = useSearchCounts();
  const {
    facetResults,
    isFacetResultsLoading,
    hasMoreEventsToLoad,
    hasMoreRegulationsToLoad,
    fetchFacets,
    setFacetResults,
  } = useSearchFacets();

  const {
    entityResults,
    hasMoreToLoad,
    aiEntitySuggestions,
    isEntityResultsLoading,
    fetchSearchEntities,
    setAiEntitySuggestions,
    setEntityResults,
  } = useSearchEntities();

  const [filterState, dispatch] = useReducer(filterReducer, filterDefaultState);

  const [previousSearch, setPreviousSearch] = useState();
  const [activeSearchText, setActiveSearchText] = useState('');
  const [pagination, setPagination] = useState({ skip: 0, limit: 10 });

  const [savedSearches, setSavedSearches] = useState([]);
  const [savedSearchId, setSavedSearchId] = useState(null);
  const [homeFeedSavedSearch, setHomeFeedSavedSearch] = useState(null);
  const [savedSearchName, setSavedSearchName] = useState(null);
  const { selectedSavedSearch, setSelectedSavedSearch } = useSharedState();

  const dossierType = router.query.get('dossierType');
  const searchQueryId = router.query.get('queryId');
  const isFilterOpen = params.get('isFilterOpen');
  const searchQuery = router.query.get('search');

  const searchTypes = filterState.activeFilter
    ? [normalizeSearchActiveFilter(filterState.activeFilter)]
    : [];

  const visibleResultType = getResultType(
    normalizeSearchActiveFilter(filterState.activeFilter),
  );

  const resetPagination = () => {
    setPagination({ skip: 0, limit: 10 });
    setEntityResults([]);
    setAiEntitySuggestions([]);
    setFacetResults([]);
  };

  const applyFilters = (filters, search, resetPageInfo = true) => {
    setPreviousSearch({ ...filters, search });

    const pageInfo = resetPageInfo ? { skip: 0, limit: 10 } : pagination;
    if (resetPageInfo) {
      resetPagination();
    }

    const isSameFilter = isFilterEqual(previousSearch, {
      ...filters,
      search,
    });

    if (!isSameFilter) {
      fetchCounts({
        search,
        industries: getIdList(filters.industries),
        countries: getIdList(filters.countries),
        entities: getIdList(filters.entities),
        dates: filters.dates,
        filterActorTypes: getSelectedValuesInTree(filters.actorTypes),
      });
    }

    fetchFacets({
      search,
      type: normalizeSearchActiveFilter(filters.activeFilter),
      pageInfo,
      industries: getIdList(filters.industries),
      countries: getIdList(filters.countries),
      entities: getIdList(filters.entities),
      dates: filters.dates,
      regulationTypes: getValueList(getSelectedValues(filters.regulationTypes)),
      eventTypes: getValueList(getSelectedValues(filters.eventTypes)),
    });

    fetchSearchEntities({
      countries: getIdList(filters.countries),
      entities: getIdList(filters.entities),
      industries: getIdList(filters.industries),
      pageInfo,
      search,
      type: normalizeSearchActiveFilter(filters.activeFilter),
      actorTypes: getSelectedValuesOnTree(filters.actorTypes),
    });
  };

  useEffect(() => {
    if (pagination.skip > 0) {
      applyFilters(filterState, activeSearchText, false);
    }
  }, [pagination]);

  const updateSearchType = (type) => {
    const newActiveFilter = normalizeKey(type);
    const filters = { ...filterState, activeFilter: newActiveFilter };
    dispatch({ type: ACTIONS.UPDATE_ACTIVE_FILTER, payload: newActiveFilter });
    applyFilters(filters, activeSearchText);
  };

  const onSearch = (text) => {
    setActiveSearchText(text);
    applyFilters(filterState, text);
  };

  const onToggleFilterClicked = () => {
    params.delete('leftPanel');
    params.set('isFilterOpen', true);
    replaceParams(params);
  };

  const filterCounts = useMemo(() => {
    return searchCountToFilterAdapter(counts);
  }, [counts]);

  const getActiveFilterFromSearchId = () => {
    const activeFilter = getFiltersSavedOnSessionStorageByQueryId(
      searchQueryId,
      'searchTypes',
    );

    if (activeFilter instanceof Array) {
      return 'all';
    }

    return activeFilter;
  };

  const getEventTypesFilterFromSearchId = () => {
    const activeFilter = getFiltersSavedOnSessionStorageByQueryId(
      searchQueryId,
      'eventTypes',
    );

    const eventTypes = getEventSubFilters();

    if (!activeFilter || activeFilter.length === 0) return eventTypes;

    return eventTypes.map((item) => ({
      ...item,
      selected: activeFilter.includes(item.value),
    }));
  };

  const getInitialFilters = (queryId) => {
    if (!queryId) return filterState;

    return {
      ...filterState,
      dates: getFiltersSavedOnSessionStorageByQueryId(searchQueryId, 'dates'),
      countries: getFiltersSavedOnSessionStorageByQueryId(
        searchQueryId,
        'countries',
      ),
      industries: getFiltersSavedOnSessionStorageByQueryId(
        searchQueryId,
        'industries',
      ),
      entities: getFiltersSavedOnSessionStorageByQueryId(
        searchQueryId,
        'entities',
      ),
      activeFilter: getActiveFilterFromSearchId(),
      eventTypes: getEventTypesFilterFromSearchId(),
    };
  };

  const applySavedSearchFilter = (savedSearch) => {
    setSavedSearchId(savedSearch.id);
    setSavedSearchName(savedSearch.name);

    const searchData = JSON.parse(savedSearch.search_data);

    setHomeFeedSavedSearch(searchData.isHomeFeed ? savedSearch : null);
    setActiveSearchText(searchData.name);

    const newFilters = {
      ...filterState,
      dates: searchData.searchDates,
      industries: searchData.filterIndustries,
      countries: searchData.topics,
      entities: searchData.filterEntities,
      activeFilter: normalizeKey(searchData.filterCategories[0] ?? 'all'),
    };

    dispatch({ type: ACTIONS.UPDATE_ANY, payload: newFilters });
    applyFilters(newFilters, searchData.name);
  };

  const clearAll = () => {
    setActiveSearchText('');
    applyFilters(filterDefaultState, '', true);
  };

  useEffect(() => {
    applyFilters(filterState, activeSearchText, true);
  }, [filterState]);

  // Apply saved search when user clicks on leftPanel
  useEffect(() => {
    if (!selectedSavedSearch) return;

    applySavedSearchFilter(selectedSavedSearch);
  }, [selectedSavedSearch]);

  // Remove saved search when unMounting component
  useEffect(() => () => setSelectedSavedSearch(null), []);

  useEffect(() => {
    if (searchQuery) return;
    const filters = getInitialFilters(searchQueryId);

    if (searchQueryId) {
      dispatch({
        type: ACTIONS.UPDATE_ANY,
        payload: filters,
      });

      applyFilters(filters, activeSearchText);
    }

    fetchCounts({
      search: activeSearchText,
      industries: getIdList(filters.industries),
      countries: getIdList(filters.countries),
      entities: getIdList(filters.entities),
      dates: filters.dates,
      filterActorTypes: getSelectedValuesInTree(filters.actorTypes),
    });
  }, [searchQueryId]);

  return (
    <SearchContext.Provider
      value={{
        visibleResultType,
        searchIndustries: filterState.industries,
        searchEntities: filterState.entities,
        searchCountries: filterState.countries,
        searchDates: filterState.dates,
        entityResults,
        facetResults,
        searchTypes,
        homeFeedSavedSearch,
        previousState: filterState,
        isFacetResultsLoading,
        isEntityResultsLoading,
        aiEntitySuggestions,
        savedSearchId,
        savedSearchName,
        counts,
        activeSearchText,
        savedSearches,
        pagination,
        isCountLoading,
        hasMoreToLoad,
        hasMoreEventsToLoad,
        hasMoreRegulationsToLoad,

        clearAll,
        updateSearchType,
        dispatch,
        onSearch,
        setSavedSearches,
        setHomeFeedSavedSearch,
        setPagination,
      }}
    >
      <div data-cy="search" className={css.main}>
        <Filter
          dispatch={dispatch}
          dataCy="search-filters"
          showActorsOption
          showDateFilter
          filterState={filterState}
          isFilterOpen={isFilterOpen}
          counts={filterCounts}
          isLoadingCounts={isCountLoading}
        />
        <aside className={css.toggleFilter}>
          <ToggleSideNav
            filterStatus={isFilterOpen ? 'expanded' : 'closed'}
            onClick={onToggleFilterClicked}
          />
        </aside>
        <div
          data-dossier={dossierType ? 'open' : 'closed'}
          className={css.content}
        >
          <div className={css.header}>
            <SearchBar />
            <SearchHeader />
          </div>
          <div className={css.results}>
            <SearchResults />
          </div>
        </div>
      </div>
    </SearchContext.Provider>
  );
};

export default Search;

export const useSearch = () => useContext(SearchContext);
