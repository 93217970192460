import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={14}
      style={{ padding: 1 }}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 13.659v-2.39c0-.47.113-.911.34-1.324.227-.412.54-.725.94-.939.68-.37 1.447-.683 2.3-.939.853-.256 1.793-.384 2.82-.384.4 0 .79.021 1.17.064.38.043.75.107 1.11.192l-1.4 1.494a2.255 2.255 0 00-.43-.043H6.4c-.947 0-1.797.121-2.55.363-.753.242-1.37.505-1.85.79a.843.843 0 00-.4.726v.682h5l1.6 1.707H0zM10.84 14l-2.76-2.945L9.2 9.86l1.64 1.75 4.04-4.311L16 8.494 10.84 14zM6.4 6.83c-.88 0-1.633-.335-2.26-1.004-.627-.668-.94-1.472-.94-2.411 0-.94.313-1.743.94-2.412C4.767.334 5.52 0 6.4 0c.88 0 1.633.334 2.26 1.003.627.669.94 1.473.94 2.412 0 .939-.313 1.743-.94 2.411-.627.669-1.38 1.003-2.26 1.003zm0-1.708c.44 0 .817-.167 1.13-.502A1.7 1.7 0 008 3.415a1.7 1.7 0 00-.47-1.206 1.494 1.494 0 00-1.13-.502c-.44 0-.817.167-1.13.502a1.7 1.7 0 00-.47 1.206c0 .47.157.871.47 1.205.313.335.69.502 1.13.502z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
