import React from 'react';
import DossierCard from '../../DossierCard/DossierCard';

import css from './Regulations';
import useDossierRegulations from '../../../../../../hooks/useDossierRegulations';
import RegulationCard from '../RegulationCard/RegulationCard';

function Regulations({ entity, size, className }) {
  const {
    regulations,
    isLoading,
    totalRegulations,
    totalPages,
    currentIndex,
    onNextPage,
    onPreviousPage,
    onComplianceChange,
  } = useDossierRegulations(entity.id);

  const pagination = {
    totalItems: totalRegulations,
    totalPages,
    currentIndex,
    onNextPage,
    onPreviousPage,
  };

  if (isLoading) return <LoadingState size={size} pagination={pagination} />;
  if (!regulations || regulations.length === 0) return null;

  return (
    <DossierCard
      title="Regulations"
      data-cy="regulations-facet"
      className={className}
      pagination={pagination}
    >
      <div className={css.list} data-expanded={size === 'expanded'}>
        {regulations.map((regulation) => (
          <RegulationCard
            onComplianceChange={onComplianceChange}
            entityName={entity.name}
            regulation={regulation}
            key={regulation.id}
            size={size}
          />
        ))}
      </div>
    </DossierCard>
  );
}

export default Regulations;

function LoadingState({ size, pagination }) {
  return (
    <DossierCard title="Regulations" pagination={pagination}>
      <div className={css.container} data-expanded={size === 'expanded'}>
        <div style={{ height: 400 }} className="skeleton-v2" />
      </div>
    </DossierCard>
  );
}
