import React, { useEffect, useState } from 'react';

import css from './Summary.module.css';
import Pagination from '../../../icons/Pagination';
import { trimText } from '../../../lib/text';

function Summary({ summary, bullets, expanded = false }) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  if (!summary) return null;

  const visibleSummary = isExpanded ? summary : trimText(summary, 100);

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  return (
    <div className={css.main}>
      <p className={css.textValue}>{visibleSummary}</p>
      {isExpanded && bullets?.length > 0 && (
        <ul className={css.bullets}>
          {bullets.map((bullet) => (
            <li className={css.textValue} key={bullet}>
              {bullet.replaceAll("'", '')}
            </li>
          ))}
        </ul>
      )}

      <button
        type="button"
        className={css.showToggle}
        onClick={() => setIsExpanded((e) => !e)}
      >
        See {isExpanded ? 'less' : 'more'}
        <Pagination
          className={css.showToggleIcon}
          direction={isExpanded ? 'up' : 'down'}
        />
      </button>
    </div>
  );
}

export default Summary;
