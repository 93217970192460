import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

/**
 * Hook sync state with url query params
 */
function useUrlState(paramName, initialValue) {
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlValue = searchParams.get(paramName);

    if (urlValue === null) return initialValue;

    /**
     * Values stored in url will be always string
     * when using boolean we want to convert 'true' or 'false' to boolean type
     */
    const isBoolean = typeof initialValue === 'boolean';
    return isBoolean ? urlValue === 'true' : urlValue;
  });

  const updateState = (value) => {
    const searchParams = new URLSearchParams(location.search);

    if (!value) {
      searchParams.delete(paramName);
    } else {
      searchParams.set(paramName, value);
    }

    history.push({
      search: searchParams.toString(),
    });
    setState(value);
  };

  const setLatestStateFromUrl = () => {
    setState(() => {
      const searchParams = new URLSearchParams(location.search);
      const urlValue = searchParams.get(paramName);

      if (urlValue === null) return initialValue;

      const isBoolean = typeof initialValue === 'boolean';
      return isBoolean ? urlValue === 'true' : urlValue;
    });
  };

  useEffect(() => {
    setLatestStateFromUrl();
  }, [location]);

  return [state, updateState];
}

export default useUrlState;
