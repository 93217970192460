import React from 'react';
import { LogoAxis } from '@axis/xyz.logos.axis';
import css from './EntityDossier.module.css';
import DossierHeader from '../../../../components/DossierHeader/DossierHeader';

function EntityDossierError() {
  return (
    <DossierHeader>
      <div className={css.errorMain}>
        <LogoAxis className={css.errorLogo} />
        <h1 className={css.errorTitle}>Something went wrong</h1>
        <h2 className={css.errorDescription}>
          There was an unexpected error in the dossier.
          <br />
          If the error persists report it to the Axis team.
        </h2>
      </div>
    </DossierHeader>
  );
}

export default EntityDossierError;
