export const trimText = (text, maxLength = 300) => {
  if (!text) return '';
  if (text.length <= maxLength) {
    return text;
  }
  let trimmedText = text.substring(0, maxLength);
  const lastSpaceIndex = trimmedText.lastIndexOf(' ');

  if (lastSpaceIndex !== -1) {
    trimmedText = trimmedText.substring(0, lastSpaceIndex);
  }
  return `${trimmedText}…`;
};
