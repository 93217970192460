import React from 'react';
import Tooltip from '../../../components/Tooltip/Tooltip';
import SocialMediaIcon from '../../../icons/SocialMediaIcon/SocialMediaIcon';
import css from './Online.module.css';

const getSrc = (url) => {
  if (url.startsWith('www')) {
    return 'https://' + url;
  }

  return url;
};

const Online = ({ socials = [] }) => {
  if (!socials || socials.length === 0) return null;

  return (
    <div className={css.online} data-cy="dossier-online-presence">
      {socials.map(({ url, platform }) => (
        <div
          key={url}
          className={css.onlineLink}
          data-cy="dossier-online-link"
          data-cy-social={platform}
        >
          <Tooltip text={getSrc(url)}>
            <a href={getSrc(url)} target="_blank" rel="noopener noreferrer">
              <SocialMediaIcon icon={platform} />
            </a>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default Online;
