import React, { useState } from 'react';
import css from './GraphExtraFilter.module.css';
import SavedSearchFilter from './SavedSearchFilter/SavedSearchFilter';
import { getTypeChildren } from '../../../../lib/search';
import { useIdentity } from '../../../../contexts/IdentityContext';
import FilterType from '../../../../v2/components/Filter/Types/FilterType/FilterType';

const connectionFilters = [
  {
    type: 'connections',
    children: [{ type: 'connectionsOnGraph' }, { type: 'allConnections' }],
  },
];

const getFilterLabel = (type) => {
  if (type === 'connectionsOnGraph') return 'My Connections on Graph';
  if (type === 'allConnections') return 'All my Connections';
  return '';
};

const FilterGroup = ({
  type,
  level,
  extraFilter,
  setExtraFilter,
  setCenterId,
  centerId,
}) => {
  const hasChildren = getTypeChildren(type).length > 0;
  const { user } = useIdentity();
  const [oldCenterId, setOldCenterId] = useState(null);

  if (hasChildren) {
    return (
      <FilterCollapse
        type={type}
        level={level}
        extraFilter={extraFilter}
        setExtraFilter={setExtraFilter}
      />
    );
  }

  const handleClick = () => {
    if (type === 'allConnections') {
      setOldCenterId(centerId);
      setCenterId(user.id);
    }
    if (extraFilter === type) {
      setExtraFilter('');
      setCenterId(oldCenterId);
    } else {
      setExtraFilter(type);
    }
  };

  return (
    <FilterType
      onClick={handleClick}
      label={getFilterLabel(type)}
      activeFilter={extraFilter}
      type={type}
    />
  );
};

const FilterCollapse = ({
  type,
  level,
  extraFilter,
  setExtraFilter,
  resetGraphFilter,
  setCenterId,
  centerId,
}) => {
  const isActive = extraFilter === type;
  const subtypes = ['connectionsOnGraph', 'allConnections'];

  return (
    <div
      className={css.collapse}
      data-status={isActive ? 'selected' : 'unselected'}
    >
      <div className={css.collapseBody} data-cy="graph-filter-collapse-body">
        {subtypes.map((subtype) => (
          <FilterGroup
            key={subtype}
            type={subtype}
            level={level + 1}
            extraFilter={extraFilter}
            setExtraFilter={setExtraFilter}
            resetGraphFilter={resetGraphFilter}
            setCenterId={setCenterId}
            centerId={centerId}
          />
        ))}
      </div>
    </div>
  );
};

const GraphExtraFilter = ({
  setGraphFilterIndustries,
  setCenterId,
  extraFilter,
  setExtraFilter,
  resetGraphFilter,
  centerId,
}) => {
  return (
    <div data-cy="search-extra-filter" className={css.extraFilter}>
      {connectionFilters.map(({ type }) => (
        <FilterCollapse
          key={type}
          type={type}
          level={0}
          extraFilter={extraFilter}
          setExtraFilter={setExtraFilter}
          resetGraphFilter={resetGraphFilter}
          setCenterId={setCenterId}
          centerId={centerId}
        />
      ))}

      <div className={css.divider} />

      <SavedSearchFilter
        setGraphFilterIndustries={setGraphFilterIndustries}
        setCenterId={setCenterId}
      />
    </div>
  );
};

export default GraphExtraFilter;
