import React from 'react';

const GeographyIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 21 22" data-cy="geography-icon">
    <path
      d="M10.5 16.2764C11.9211 15.0628 12.9779 13.9414 13.6706 12.9122C14.3639 11.8836 14.7106 10.9296 14.7106 10.0503C14.7106 8.73116 14.2853 7.68045 13.4348 6.89814C12.5836 6.11512 11.6053 5.72362 10.5 5.72362C9.39478 5.72362 8.41688 6.11512 7.56636 6.89814C6.71513 7.68045 6.28951 8.73116 6.28951 10.0503C6.28951 10.9296 6.63618 11.8836 7.32951 12.9122C8.02215 13.9414 9.07899 15.0628 10.5 16.2764ZM10.5 11C10.1843 11 9.91688 10.8903 9.69794 10.6708C9.47829 10.4506 9.36846 10.1822 9.36846 9.86558C9.36846 9.56658 9.47829 9.30276 9.69794 9.07412C9.91688 8.84548 10.1843 8.73116 10.5 8.73116C10.8158 8.73116 11.0836 8.84548 11.3032 9.07412C11.5221 9.30276 11.6316 9.56658 11.6316 9.86558C11.6316 10.1822 11.5221 10.4506 11.3032 10.6708C11.0836 10.8903 10.8158 11 10.5 11Z"
      fill="#323232"
    />
    <circle
      cx="10.5"
      cy="11"
      r="9.5"
      stroke="#323232"
      strokeWidth="2"
      fill="none"
    />
  </svg>
);

export default GeographyIcon;
