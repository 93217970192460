import React, { useState } from 'react';
import avatarPlaceholder from '../../assets/avatar-placeholder.png';

const FallbackImage = ({
  src,
  alt = '',
  fallbackSrc = avatarPlaceholder,
  ...rest
}) => {
  const [isError, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  return (
    <img
      data-image-type={isError ? 'fallback' : 'source'}
      src={isError || !src ? fallbackSrc : src}
      alt={alt}
      onError={handleError}
      {...rest}
    />
  );
};

export default FallbackImage;
