import { gql } from '@apollo/client';

const query = gql`
  query getFeedGraph($filters: FeedGraphFilters) {
    getFeedGraph(filters: $filters) {
      nodes {
        id
        name
        image
        labels
        inSubscription
        isConnected
      }
      links {
        source
        target
        type
        strength
      }
    }
  }
`;

export default query;
