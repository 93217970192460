import React, { useEffect, useState } from 'react';

import css from './AppointmentDossier.module.css';
import { UrlParam } from '../../../shared/dossier';
import useNavigation from '../../../hooks/useNavigation';
import DossierHeader from '../../../../components/DossierHeader/DossierHeader';
import useAppointment from '../../../hooks/useAppointment';
import DossierTitle from '../DossierTitle/DossierHeader';
import { BaseCard } from '../../Cards/BaseCard/BaseCard';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import { formatDateMed } from '../../../../lib/date';
import NewLoadingState from '../NewLoadingState/NewLoadingState';
import DossierNotes from '../../DossierNotes/DossierNotes';
import Source from '../../Source/Source';

function AppointmentDossier({ size, appointmentId, onSizeChange }) {
  const {
    appointment,
    loading,
    notes,
    createNote,
    isSavingNote,
    deleteNote,
    updateNote,
    isDeletingNote,
    isUpdatingNote,
  } = useAppointment(appointmentId);

  if (loading) return <NewLoadingState />;
  if (!appointment) return null;

  return (
    <div className={css.main}>
      <div className={css.header}>
        <DossierHeader size={size} onSizeChange={onSizeChange} />
        <div
          style={{ marginTop: -5, zIndex: 5, position: 'relative' }}
          className={css.divider}
        />
      </div>
      <div className={css.content}>
        <DossierTitle>Appointment</DossierTitle>
        <h2 className={css.headline}>{appointment.headline}</h2>
        <BaseCard.InfoColumn title="Person">
          <EntityPill
            id={appointment.entity.id}
            image={appointment.entity.image}
            name={appointment.entity.name}
            shortName={appointment.entity.shortestName}
            tooltip={appointment.entity.tooltip}
            type={appointment.entity.type}
            isConnected={appointment.entity.isConnected}
            inSubscription={appointment.entity.inSubscription}
            autoOpenDossier
            size="small"
          />
        </BaseCard.InfoColumn>

        <BaseCard.InfoColumn title="Title">
          {appointment.title}
        </BaseCard.InfoColumn>

        {appointment.organization && !appointment.organizationEntity && (
          <BaseCard.InfoColumn title="Organization">
            {appointment.organization}
          </BaseCard.InfoColumn>
        )}

        {appointment.organizationEntity && (
          <BaseCard.InfoColumn title="Organization">
            <EntityPill
              id={appointment.organizationEntity.id}
              image={appointment.organizationEntity.image}
              name={appointment.organizationEntity.name}
              shortName={appointment.organizationEntity.shortestName}
              tooltip={appointment.organizationEntity.tooltip}
              type={appointment.organizationEntity.type}
              isConnected={appointment.organizationEntity.isConnected}
              inSubscription={appointment.organizationEntity.inSubscription}
              autoOpenDossier
              size="small"
            />
          </BaseCard.InfoColumn>
        )}

        <BaseCard.InfoColumn title="Start Date">
          {formatDateMed(appointment.startDate)}
        </BaseCard.InfoColumn>

        <BaseCard.InfoColumn title="Source">
          <Source source={appointment.source} />
        </BaseCard.InfoColumn>
      </div>
      <div className={css.divider} />

      <DossierNotes
        notes={notes}
        saveNote={(note) => createNote(appointmentId, note)}
        onDeleteNote={deleteNote}
        onEditNote={updateNote}
        isSavingNote={isSavingNote}
        isUpdatingNote={isUpdatingNote}
        isDeletingNote={isDeletingNote}
      />
    </div>
  );
}

export default function AppointmentDossierWrapper({ size, onSizeChange }) {
  const [isDossierOpen, setIsDossierOpen] = useState(false);
  const { params } = useNavigation();

  useEffect(() => {
    const dossierAppointmentId = params.get(UrlParam.appointmentId);
    const dossierType = params.get('dossierType');

    if (dossierAppointmentId && dossierType) {
      setIsDossierOpen(true);
    } else {
      setIsDossierOpen(false);
    }
  }, [params]);

  if (!isDossierOpen) return null;

  return (
    <AppointmentDossier
      size={size}
      appointmentId={params.get(UrlParam.appointmentId)}
      onSizeChange={onSizeChange}
    />
  );
}
