import React, { useEffect, useState } from 'react';

import css from './NetworkTabs.module.css';
import DossierCard from '../../DossierCard/DossierCard';
import useCardPagination from '../../DossierCard/useCardPagination';
import EntityPill from '../../../../../../../components/EntityPill/EntityPill';

const ITEMS_PER_PAGE = 15;
const ITEMS_PER_PAGE_EXPANDED = 45;

const getActorPages = (list, size) => {
  if (!list) return [];

  const subarrays = [];
  for (let i = 0; i < list.length; i += size) {
    subarrays.push(list.slice(i, i + size));
  }

  return subarrays;
};

function NetworkTabs({ relationships, size, className }) {
  const qtd = size === 'normal' ? ITEMS_PER_PAGE : ITEMS_PER_PAGE_EXPANDED;
  const [actorPages, setActorPages] = useState(() =>
    getActorPages(relationships?.actors, qtd),
  );

  const { state, pagination, resetState } = useCardPagination(actorPages);

  useEffect(() => {
    const newPages = getActorPages(relationships?.actors, qtd);
    setActorPages(newPages);
    resetState(newPages);
  }, [size]);

  const customPagination = {
    ...pagination,
    totalItems: relationships?.actors?.length,
  };

  const renderPills = state.data;

  if (!renderPills) return null;

  return (
    <div className={`${css.main} ${className}`} data-cy="network-facet">
      <div className={css.tabContainer}>
        <div className={css.tab} data-cy="network-actors-tab" data-active>
          Actors
        </div>
        <div className={css.tab} data-cy="network-plot-tab" data-disabled>
          Plots
        </div>
      </div>

      <DossierCard pagination={customPagination}>
        <div
          className={css.pillList}
          data-fullpage={size === 'normal'}
          data-no-pagination={size === 'expanded'}
          data-cy="network-actor-list"
        >
          {renderPills.map((actor) => (
            <EntityPill
              key={actor.id}
              id={actor.id}
              size="small"
              autoOpenDossier
              name={actor.name}
              type={actor.type}
              image={actor.image}
              isConnected={actor.isConnected}
              inSubscription={actor.inSubscription}
            />
          ))}
        </div>
      </DossierCard>
    </div>
  );
}

export default NetworkTabs;
