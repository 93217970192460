import { gql } from '@apollo/client';

const query = gql`
  query getRelationshipDossierSharedEvents(
    $targetId: ID!
    $sourceId: ID!
    $page: Int
    $limit: Int
  ) {
    getRelationshipDossierSharedEvents(
      targetId: $targetId
      sourceId: $sourceId
      page: $page
      limit: $limit
    ) {
      sharedEvents {
        id
        title
        sources
        date
        entities {
          id
          name
          shortestName
          topicsId
          inSubscription
          type
          image
          isConnected
        }
        briefs {
          id
          name
          type
        }
        countries {
          id
          name
        }
        landscapes {
          id
          name
        }
      }
      totalSharedEvents
    }
  }
`;

export default query;
