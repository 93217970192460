import React from 'react';

import css from './SetupState.module.css';
import Button from '../../Buttom/Buttom';

function SetupState({ openModal }) {
  return (
    <div className={css.main}>
      <h2 className={css.setupTitle}>
        Get a daily personalized updates on the topics that matter most to you.
      </h2>
      <Button type="primary" onClick={openModal}>
        Set Up Daily Brief
      </Button>
    </div>
  );
}

export default SetupState;
