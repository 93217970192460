import React from 'react';

function Cycle(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_2813_73992"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2813_73992)">
        <path
          d="M5.23203 14.0833C4.07648 13.5499 3.13759 12.7444 2.41536 11.6666C1.69314 10.5888 1.33203 9.37214 1.33203 8.01659C1.33203 7.7277 1.34592 7.44436 1.3737 7.16659C1.40148 6.88881 1.4487 6.61659 1.51536 6.34992L0.748698 6.79992L0.0820312 5.64992L3.26536 3.81659L5.0987 6.98325L3.93203 7.64992L3.03203 6.08325C2.90981 6.38325 2.81814 6.69436 2.75703 7.01659C2.69592 7.33881 2.66536 7.67214 2.66536 8.01659C2.66536 9.09436 2.95981 10.0749 3.5487 10.9583C4.13759 11.8416 4.92092 12.4944 5.8987 12.9166L5.23203 14.0833ZM10.332 5.99992V4.66659H12.1487C11.6376 4.03325 11.0209 3.54159 10.2987 3.19159C9.57648 2.84159 8.80981 2.66659 7.9987 2.66659C7.38759 2.66659 6.80981 2.76103 6.26536 2.94992C5.72092 3.13881 5.22092 3.40547 4.76536 3.74992L4.0987 2.58325C4.65425 2.19436 5.25981 1.88881 5.91536 1.66659C6.57092 1.44436 7.26536 1.33325 7.9987 1.33325C8.87648 1.33325 9.71536 1.49714 10.5154 1.82492C11.3154 2.1527 12.032 2.6277 12.6654 3.24992V2.33325H13.9987V5.99992H10.332ZM9.8987 15.9999L6.71536 14.1666L8.5487 10.9999L9.6987 11.6666L8.7487 13.2999C10.0598 13.111 11.1515 12.5166 12.0237 11.5166C12.8959 10.5166 13.332 9.34436 13.332 7.99992C13.332 7.8777 13.3293 7.76381 13.3237 7.65825C13.3181 7.5527 13.3043 7.44436 13.282 7.33325H14.632C14.6431 7.44436 14.6515 7.5527 14.657 7.65825C14.6626 7.76381 14.6654 7.8777 14.6654 7.99992C14.6654 9.49992 14.2181 10.8416 13.3237 12.0249C12.4293 13.2083 11.2654 14.0055 9.83203 14.4166L10.5654 14.8499L9.8987 15.9999Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Cycle;
