import React from 'react';
import css from './HamburgerMenuIcon.module.css';

export const HamburgerMenuIcon = () => (
  <div className={css.dots} data-cy="menu-hamburger-icon">
    <div className={css.dot} />
    <div className={css.dot} />
    <div className={css.dot} />
  </div>
);
