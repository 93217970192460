import React from 'react';

const Edit = ({ className = '' }) => (
  <svg
    className={className}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33333 11.6667H2.26667L8.01667 5.91673L7.08333 4.9834L1.33333 10.7334V11.6667ZM10.8667 4.95006L8.03333 2.15006L8.96667 1.21673C9.22222 0.961176 9.53611 0.833398 9.90833 0.833398C10.2806 0.833398 10.5944 0.961176 10.85 1.21673L11.7833 2.15006C12.0389 2.40562 12.1722 2.71395 12.1833 3.07506C12.1944 3.43618 12.0722 3.74451 11.8167 4.00006L10.8667 4.95006ZM9.9 5.9334L2.83333 13.0001H0V10.1667L7.06667 3.10006L9.9 5.9334Z"
      fill="#939393"
    />
  </svg>
);

export default Edit;
