import { useQuery } from '@apollo/client';

import GET_REGULATION_BY_ID from '../../queries/getRegulationById';

function useRegulation(id) {
  const { data, loading } = useQuery(GET_REGULATION_BY_ID, {
    variables: { id },
  });

  return { regulation: data?.getRegulation?.regulation || null, loading };
}

export default useRegulation;
