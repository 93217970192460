import React, { memo, useEffect, useState } from 'react';

import Regulation from '../../../../../icons/Regulation';
import HelpCenter from '../../../../../icons/HelpCenter';
import Chevron from '../../../../../icons/ChevronDown';
import Apartment from '../../../../../icons/Apartment';
import Location from '../../../../../icons/Location';
import Language from '../../../../../icons/Language';
import Pill from '../../../../components/Pill/Pill';
import Tag from '../../../../components/Tag/Tag';
import Cycle from '../../../../../icons/Cycle';
import Event from '../../../../../icons/Event';
import logo from '../../../../assets/axis.png';

import css from './Process.module.css';

const DELAY_STEPS = 1800;

function Process({ question, isQuestionLoading }) {
  const [isOpen, setIsOpen] = useState(false);
  const [stepsDone, setStepsDone] = useState(0);

  /* This useEffect trigger the open after ms to animate first render */
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 500);
  }, []);

  /* This useEffect create a interval to complete all steps an block the last one until streaming starts */
  useEffect(() => {
    if (!isQuestionLoading && stepsDone < 5) {
      setStepsDone(5);
      return undefined;
    }
    if (stepsDone >= 5) return undefined;
    if (stepsDone === 4 && isQuestionLoading) {
      return undefined;
    }
    const timer = setTimeout(() => {
      setStepsDone((prev) => prev + 1);
    }, DELAY_STEPS);

    return () => {
      clearTimeout(timer);
    };
  }, [stepsDone, isQuestionLoading]);

  return (
    <div className={css.main} data-is-open={isOpen}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={css.header}
        data-cy="toggle-process-card"
      >
        Process <Chevron className={css.chevron} />
      </div>
      <div className={css.steps}>
        <Step isComplete={stepsDone >= 1}>
          <HelpCenter />
          Analysing query
        </Step>

        <Step isComplete={stepsDone >= 2}>
          <div className={css.questiontitle}>
            <Language /> Searching Axis db
          </div>
          <Tag className={css.question}>{question}</Tag>
        </Step>

        <Step isComplete={stepsDone >= 3}>
          <Cycle className={stepsDone >= 3 ? null : css.spin} /> Finding Actors
          <Pill text="Actors" img={logo} />
        </Step>

        <Step isComplete={stepsDone >= 4}>
          <Cycle className={stepsDone >= 4 ? null : css.spin} />
          Finding
          <Tag className={css.tagExtraSpace}>
            <Apartment /> Industries
          </Tag>
          <Tag className={css.tagExtraSpace}>
            <Location />
            Locations
          </Tag>
        </Step>

        <Step isComplete={stepsDone >= 5}>
          <Cycle className={stepsDone >= 5 ? null : css.spin} />
          Finding
          <Tag className={css.tagExtraSpace}>
            <Event />
            Events
          </Tag>
          <Tag className={css.tagExtraSpace}>
            <Regulation />
            Regulations
          </Tag>
        </Step>
      </div>
    </div>
  );
}

export default memo(Process);

const Step = ({ children, isComplete }) => {
  return (
    <div className={css.step}>
      {children}

      {isComplete && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_2813_74587"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <rect width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2813_74587)">
            <path
              d="M7.06536 11.0666L11.7654 6.36659L10.832 5.43325L7.06536 9.19992L5.16536 7.29992L4.23203 8.23325L7.06536 11.0666ZM7.9987 14.6666C7.07648 14.6666 6.20981 14.4916 5.3987 14.1416C4.58759 13.7916 3.88203 13.3166 3.28203 12.7166C2.68203 12.1166 2.20703 11.411 1.85703 10.5999C1.50703 9.78881 1.33203 8.92214 1.33203 7.99992C1.33203 7.0777 1.50703 6.21103 1.85703 5.39992C2.20703 4.58881 2.68203 3.88325 3.28203 3.28325C3.88203 2.68325 4.58759 2.20825 5.3987 1.85825C6.20981 1.50825 7.07648 1.33325 7.9987 1.33325C8.92092 1.33325 9.78759 1.50825 10.5987 1.85825C11.4098 2.20825 12.1154 2.68325 12.7154 3.28325C13.3154 3.88325 13.7904 4.58881 14.1404 5.39992C14.4904 6.21103 14.6654 7.0777 14.6654 7.99992C14.6654 8.92214 14.4904 9.78881 14.1404 10.5999C13.7904 11.411 13.3154 12.1166 12.7154 12.7166C12.1154 13.3166 11.4098 13.7916 10.5987 14.1416C9.78759 14.4916 8.92092 14.6666 7.9987 14.6666Z"
              fill="#027A48"
            />
          </g>
        </svg>
      )}
    </div>
  );
};
