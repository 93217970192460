import { Loading } from '@axis/xyz.app.loading';
import React from 'react';
import PaginationButton from '../PaginationButton/PaginationButton';
import css from './DossierCard.module.css';

const DossierCard = ({
  className,
  title,
  count,
  currentPage = 1,
  totalPages,
  showCount = true,
  showPages = false,
  loading = false,
  onPageChange = () => {},
  children,
  badge,
}) => {
  return (
    <div
      className={`${css.card} ${className}`}
      data-cy="dossier-card"
      data-cy-title={title}
    >
      <div className={css.title}>
        <div className={css.titleInfo}>
          <p className={css.cardTitle} data-cy="dossier-card-title">
            {title} {badge}
          </p>
          {showCount && count > 0 && (
            <p data-cy="dossier-card-total" className={css.cardTotal}>
              {`${count} total`}
            </p>
          )}
        </div>
        {totalPages > 1 && (
          <div className={css.pageInfo} data-cy="dossier-card-pagination">
            <PaginationButton
              disabled={currentPage === 1}
              direction="left"
              onClick={() => onPageChange(currentPage - 1)}
            />
            {showPages && (
              <p
                className={css.pageCount}
                data-cy="dossier-card-pagination-info"
              >
                {currentPage} of {totalPages}
              </p>
            )}
            <PaginationButton
              disabled={currentPage === totalPages}
              direction="right"
              onClick={() => onPageChange(currentPage + 1)}
            />
          </div>
        )}
      </div>
      {loading ? (
        <div className={css.loadingArea}>
          <Loading className={css.loading} />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default DossierCard;
