import React, { createContext, useContext, useState } from 'react';

const Context = createContext();

export const SharedStateContext = ({ children }) => {
  const [selectedSavedSearch, setSelectedSavedSearch] = useState(null);

  return (
    <Context.Provider value={{ selectedSavedSearch, setSelectedSavedSearch }}>
      {children}
    </Context.Provider>
  );
};

export const useSharedState = () => useContext(Context);
