import React from 'react';

import Sparkle from '../../../icons/Smart';
import css from './AiBadge.module.css';

function AiBadge() {
  return (
    <div className={css.main}>
      <Sparkle />
      AI
    </div>
  );
}

export default AiBadge;
