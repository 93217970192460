import React, { useMemo } from 'react';

import css from './RegulationCard.module.css';
import EntityPill from '../../../../../../../components/EntityPill/EntityPill';
import BriefPill from '../../../../../../../components/BriefPill/BriefPill';
import Summary from '../../../../../Summary/Summary';
import Source from '../../../../../Source/Source';
import { useRouteInformation } from '../../../../../../../contexts/RouteInformationContext';
import Button from '../../../../../Buttom/Buttom';
import NotesOutline from '../../../../../../../icons/NotesOutline';
import RegulationComplianceButton from '../../../../../RegulationComplianceButton/RegulationComplianceButton';
import { filterIndustries } from '../../../../../../../utils';

function RegulationCard({ regulation, size, entityName, onComplianceChange }) {
  const {
    summarizedName,
    issuer,
    authorizedIssuer,
    industries,
    jurisdiction,
    legalName,
    summary,
    bullets,
    source,
    attachment,
    isCompliant,
  } = regulation;

  const router = useRouteInformation();

  const filteredIndustries = useMemo(
    () => filterIndustries(industries),
    [industries],
  );

  const onAddNoteClick = () => {
    const reg = {
      ...regulation,
      dossierEntityName: entityName,
      dataLocation: 'dossier-regulation-card',
      dossierEntityId: router.query.get('dossierEntityId'),
    };
    router.openDossier({ regulation: reg, dossierType: 'regulation' });
  };

  return (
    <div>
      {summarizedName && <FieldRow label="Name" text={summarizedName} />}
      {issuer && issuer.id && (
        <FieldRow label="Issued By">
          <EntityPill
            size="small"
            type={issuer.type}
            image={issuer.image}
            name={issuer.name}
            id={issuer.id}
            shortName={issuer.shortestName}
            inSubscription={issuer.inSubscription}
            isConnected={issuer.isConnected}
            autoOpenDossier
            tooltip={issuer.tooltip}
          />
        </FieldRow>
      )}

      {authorizedIssuer && authorizedIssuer.id && (
        <FieldRow
          labelClass={css.relevantIndividual}
          label="Relevant Individual"
        >
          <EntityPill
            size="small"
            type={authorizedIssuer.type}
            image={authorizedIssuer.image}
            name={authorizedIssuer.name}
            id={authorizedIssuer.id}
            shortName={authorizedIssuer.shortestName}
            inSubscription={authorizedIssuer.inSubscription}
            isConnected={authorizedIssuer.isConnected}
            autoOpenDossier
            tooltip={authorizedIssuer.tooltip}
          />
        </FieldRow>
      )}

      {filteredIndustries && filteredIndustries.length > 0 && (
        <FieldRow
          label={filteredIndustries.length === 1 ? 'Industry' : 'Industries'}
        >
          <div className={css.tags}>
            {filteredIndustries.map((i) => (
              <BriefPill key={i.id} name={i.name} tooltip={i.tooltip} />
            ))}
          </div>
        </FieldRow>
      )}

      {jurisdiction && (
        <FieldRow label="Jurisdiction">
          <BriefPill name={jurisdiction} isGeography />
        </FieldRow>
      )}

      {legalName && <FieldRow label="Legal Name" text={legalName} />}

      {summary && (
        <FieldRow label="Summary">
          <Summary
            summary={summary}
            bullets={bullets}
            expanded={size !== 'normal'}
          />
        </FieldRow>
      )}

      <div data-cy="source-list" className={css.sources}>
        {source && (
          <div>
            <Source key={source} src={source} />
          </div>
        )}
        {attachment && (
          <div>
            <Source key={attachment} src={attachment} />
          </div>
        )}
      </div>

      <div className={css.actionButtons}>
        <Button
          type="secondary"
          className={css.linkButton}
          data-cy="regulation-add-note"
          onClick={onAddNoteClick}
        >
          <NotesOutline />
          Add Note
        </Button>

        <RegulationComplianceButton
          onComplianceChange={onComplianceChange}
          regulationId={regulation.id}
          isCompliant={isCompliant}
        />
      </div>
    </div>
  );
}

export default RegulationCard;

const FieldRow = ({ label, text, children, labelClass }) => (
  <div className={css.row} data-cy="field-row" data-cy-content={label}>
    <p data-cy="field-row-label" className={`${css.rowLabel} ${labelClass}`}>
      {label}
    </p>
    <div data-cy="field-row-value" className={css.rowValue}>
      {text && <p className={css.textValue}>{text}</p>}
      {children}
    </div>
  </div>
);
