import React, { memo } from 'react';

import css from './LinkMenu.module.css';

function LinkMenu({ handleLinkClick, link }) {
  return (
    <div className={css.menu}>
      <ul>
        <li>
          <span
            onClick={() => {
              handleLinkClick(link);
            }}
          >
            Show connection between these two entities
          </span>
        </li>
      </ul>
    </div>
  );
}

export default memo(LinkMenu);
