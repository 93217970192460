const { format, isToday, isYesterday } = require('date-fns');

// Function to format the date using date-fns
function formatDate(date) {
  const parsedDate = new Date(date);

  if (isToday(parsedDate)) {
    return 'Today';
  }

  if (isYesterday(parsedDate)) {
    return 'Yesterday';
  }
  return format(parsedDate, 'MM-dd');
}

export function getTimeline(notifications) {
  const groupedNotifications = notifications.reduce((acc, notification) => {
    const date = formatDate(notification.timestamp);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(notification);
    return acc;
  }, {});

  return Object.keys(groupedNotifications).map((date) => ({
    date,
    notifications: groupedNotifications[date],
  }));
}

export const NOTIFICATION_ACTIONS = {
  MARK_AS_READ: 'MARK_AS_READ',
  REMOVE_BOOKMARK: 'REMOVE_BOOKMARK',
  CUSTOMIZE_PDB: 'CUSTOMIZE_PDB',
  REMOVE_NOTE: 'REMOVE_NOTE',
  REMOVE_CONNECTION: 'REMOVE_CONNECTION',
  REMOVE_SEARCH: 'REMOVE_SEARCH',
};
