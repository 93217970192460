import { gql } from '@apollo/client';

const query = gql`
  query getNotesForAppointment($appointmentId: ID!) {
    getNotesForAppointment(appointmentId: $appointmentId) {
      id
      note
      date
      title
      source
      userName
    }
  }
`;

export default query;
