import { useMutation, useQuery } from '@apollo/client';
import GET_ENTITY_BY_ID from '../../queries/dossierEntity';
import TOGGLE_BOOKMARK from '../../queries/toggleBookmark';
import GET_BOOKMARLS from '../../queries/getUserBookmarks';
import notify from '../../lib/notify';

function useEntity(id) {
  const [mutateBookmark] = useMutation(TOGGLE_BOOKMARK, {
    refetchQueries: [GET_BOOKMARLS],
  });

  const { data, loading, client } = useQuery(GET_ENTITY_BY_ID, {
    notifyOnNetworkStatusChange: true,
    variables: { id },
  });

  const toggleBookmark = async () => {
    const entity = data?.getDossierEntity;
    if (!entity) return;

    try {
      const result = await mutateBookmark({
        variables: { id: entity.id, type: 'ENTITY' },
      });

      client.writeQuery({
        query: GET_ENTITY_BY_ID,
        data: {
          getDossierEntity: {
            ...data?.getDossierEntity,
            isBookmarked: result.data.toggleBookmark,
          },
        },
      });
    } catch (error) {
      notify.error('Error');
    }
  };

  const toggleUserConnection = (isConnected) => {
    client.writeQuery({
      query: GET_ENTITY_BY_ID,
      data: {
        getDossierEntity: {
          ...data?.getDossierEntity,
          isConnected,
        },
      },
    });
  };

  const onNoteAdded = () => {
    client.writeQuery({
      query: GET_ENTITY_BY_ID,
      data: {
        getDossierEntity: {
          ...data?.getDossierEntity,
          notesCount: data.getDossierEntity.notesCount + 1,
        },
      },
    });
  };

  return {
    entity: data?.getDossierEntity,
    isLoading: loading,
    toggleBookmark,
    toggleUserConnection,
    onNoteAdded,
  };
}

export default useEntity;
