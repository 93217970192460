import React from 'react';

import css from './ListDivider.module.css';

function ListDivider({ title, buttonLabel, onClick }) {
  return (
    <div className={css.subheader}>
      {title}
      <button type="button" className={css.button} onClick={onClick}>
        {buttonLabel}
      </button>
    </div>
  );
}

export default ListDivider;
