import { gql } from '@apollo/client';

const query = gql`
  query getDossierEvents($id: ID!, $page: Int, $limit: Int) {
    getDossierEvents(id: $id, page: $page, limit: $limit) {
      totalEvents
      events {
        id
        title
        date
        sources
        sentiment
        magnitude
        summary
        bullets
        entities {
          id
          name
          shortestName
          image
          type
          topicsId
          inSubscription
          isConnected
          tooltip
        }
        briefs {
          id
          name
          type
          tooltip
        }
        countries {
          id
          name
        }
        landscapes {
          id
          name
        }
        notesCount
        quotes {
          id
          quote
          context
          speaker {
            id
            name
            shortestName
            image
            type
            topicsId
            inSubscription
            isConnected
            tooltip
          }
        }
      }
    }
  }
`;

export default query;
