import React from 'react';

import css from './Pill.module.css';

import FallbackImage from '../../../components/FallbackImage/FallbackImage';

function Pill({ text, img, type, onClick }) {
  const isClickable = typeof onClick === 'function';

  return (
    <div
      className={css.pill}
      data-type={type}
      onClick={onClick}
      style={{ cursor: isClickable ? 'pointer' : 'default' }}
    >
      <FallbackImage src={img} alt="Entity" />
      {text}
    </div>
  );
}

export default Pill;
