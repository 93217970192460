import React from 'react';
import css from './Skeleton.module.css';

function Skeleton({ height = '2rem', width = '', margin = '1rem' }) {
  return (
    <div
      className={css.main}
      style={{
        height,
        width,
        margin,
      }}
    />
  );
}

export default Skeleton;
