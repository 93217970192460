import {
  filterTypes,
  getSelectedValuesOnTree,
} from '../../../../v2/components/Filter';

export const SEARCH_TYPES = [
  {
    type: 'actors',
    children: [
      {
        type: 'people',
        children: [{ type: 'peopleRegulator' }],
      },
      {
        type: 'government',
        children: [{ type: 'governmentRegulator' }],
      },
      { type: 'organizations' },
      { type: 'companies' },
    ],
  },
];

export const getQueryValue = (type) => {
  if (type === 'actors') return 'Actor';
  if (type === 'people') return 'Person';
  if (type === 'peopleRegulator') return 'Person_Regulator';
  if (type === 'government') return 'Government_Body';
  if (type === 'governmentRegulator') return 'Government_Body_Regulator';
  if (type === 'organizations') return 'Organization';
  if (type === 'companies') return 'Company';
  return '';
};

export const getGraphNodeTypes = (activeFilterType, actorTypes) => {
  if (activeFilterType === filterTypes.All) return [];

  return getSelectedValuesOnTree(actorTypes);
};
