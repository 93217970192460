import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import { modalStylesCommandKey } from '../../shared/modal';
import UnifiedSearch from '../UnifiedSearch/UnifiedSearch';

export const triggerCommandKey = () => {
  document.dispatchEvent(
    new KeyboardEvent('keydown', {
      key: 'k',
      keyCode: 75,
      code: 'KeyK',
      which: 75,
      shiftKey: false,
      ctrlKey: true,
      metaKey: true,
    }),
  );
};

const isMacOS = navigator.userAgent.indexOf('Mac OS') > -1;

function CommandKey() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const preventOpenBrowserCommandKey = (event) => {
    if (!isModalOpen) {
      event.preventDefault();
    }
  };

  const handleMac = (event) => {
    if (event.code === 'KeyK' && event.metaKey && !isModalOpen) {
      preventOpenBrowserCommandKey(event);
      setIsModalOpen(true);
    }
  };

  const handleOthers = (event) => {
    if (event.code === 'KeyK' && event.ctrlKey && !isModalOpen) {
      preventOpenBrowserCommandKey(event);
      setIsModalOpen(true);
    }
  };

  const handler = (event) => {
    const handleByDevice = isMacOS ? handleMac : handleOthers;
    handleByDevice(event);
  };

  const onSearchDispatch = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, []);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isModalOpen}
      shouldCloseOnOverlayClick
      contentLabel="Search modal"
      style={modalStylesCommandKey}
      onRequestClose={() => setIsModalOpen(false)}
    >
      {isModalOpen && <UnifiedSearch autoFocus onSearch={onSearchDispatch} />}
    </Modal>
  );
}

export default CommandKey;
