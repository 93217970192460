import React from 'react';

const CloseIcon = ({ className, ...props }) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16 16L8 8"
      stroke="#1A1A1A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 8L8 16"
      stroke="#1A1A1A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseIcon;
