import { useMutation, useQuery } from '@apollo/client';
import REMOVE_CONNECTION from '../../queries/toggleUserConnection';
import GET_CONNECTIONS from '../../queries/getUserConnections';

function useConnections() {
  const [removeConnection] = useMutation(REMOVE_CONNECTION, {
    refetchQueries: [GET_CONNECTIONS],
  });
  const { data, loading, refetch } = useQuery(GET_CONNECTIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 50,
      skip: 0,
    },
  });

  return {
    connections: data?.getUserConnections?.connections || [],
    total: data?.getUserConnections?.total || 0,
    loading,
    refetch,
    removeConnection,
  };
}

export default useConnections;
