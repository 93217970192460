import { gql } from '@apollo/client';

const query = gql`
  query getRelationshipDossierSharedLinks(
    $sourceId: ID!
    $targetId: ID!
    $page: Int
    $limit: Int
  ) {
    getRelationshipDossierSharedLinks(
      sourceId: $sourceId
      targetId: $targetId
      page: $page
      limit: $limit
    ) {
      totalSharedLinks
      sharedLinks {
        id
        name
        image
        inSubscription
        type
        shortestName
      }
    }
  }
`;

export default query;
