import { gql } from '@apollo/client';

const query = gql`
  query generatePdbForUser {
    generatePdbForUser(waitForResponse: false) {
      result
    }
  }
`;

export default query;
