import React, { memo } from 'react';

import css from './CountryFilterWrapper.module.css';
import CountryFilter from '../../../../v2/components/Filters/CountryFilter/CountryFilter';

function CountryFilterWrapper({
  setGraphFilterCountries,
  graphFilterCountries,
}) {
  return (
    <CountryFilter
      customClass={css.countryFilter}
      activeCountries={graphFilterCountries}
      setActiveCountries={setGraphFilterCountries}
    />
  );
}

export default memo(CountryFilterWrapper);
