import React from 'react';

import Search from '../../../icons/Search';
import css from './Input.module.css';

function Input({
  className,
  inputRef,
  children,
  leftSlot,
  showIcon = true,
  ...props
}) {
  return (
    <main className={`${css.main} ${className}`}>
      <div className={css.inputContainer}>
        {showIcon && <Search className={css.icon} />}

        {leftSlot}

        <input
          ref={inputRef}
          autoComplete="off"
          className={css.input}
          {...props}
        />
      </div>
      {children}
    </main>
  );
}

export default Input;
