import React from 'react';

const Password = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 11H3V23H21V11Z"
      stroke={color || '#808584'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12 19C13.1046 19 14 18.1046 14 17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17C10 18.1046 10.8954 19 12 19Z"
      stroke={color || '#808584'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M17 11V6C17.0131 4.68724 16.5042 3.42303 15.5853 2.48539C14.6664 1.54776 13.4128 1.01346 12.1 1H12C10.6872 0.986939 9.42303 1.4958 8.48539 2.41469C7.54776 3.33357 7.01346 4.58724 7 5.9V11"
      stroke={color || '#808584'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default Password;
