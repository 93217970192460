import { gql } from '@apollo/client';

const query = gql`
  query getEventById($eventIds: [ID]) {
    getEventById(eventIds: $eventIds) {
      events {
        id
        title
        date
        sentiment
        magnitude
      }
    }
  }
`;

export default query;
