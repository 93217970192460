import React from 'react';
import css from './GraphMenu.module.css';
import NodeMenu from './NodeMenu/NodeMenu';
import LinkMenu from './LinkMenu/LinkMenu';
import BGMenu from './BGMenu/BGMenu';

const MenuType = ({
  type,
  target,
  resetMenu,
  handleLinkClick,
  handleSetNodeFocus,
  hideEntity,
  hideRelationships,
  expandNode,
}) => {
  if (type === 'NODE') {
    return (
      <NodeMenu
        resetMenu={resetMenu}
        node={target}
        handleSetNodeFocus={handleSetNodeFocus}
        hideEntity={hideEntity}
        hideRelationships={hideRelationships}
        expandNode={expandNode}
      />
    );
  }
  if (type === 'LINK') {
    return (
      <LinkMenu
        resetMenu={resetMenu}
        link={target}
        handleLinkClick={handleLinkClick}
      />
    );
  }
  if (type === 'BG') {
    return <BGMenu resetMenu={resetMenu} />;
  }

  return null;
};

const GraphMenu = ({
  isOpen = false,
  type,
  target,
  resetMenu,
  handleLinkClick,
  handleSetNodeFocus,
  hideEntity,
  hideRelationships,
  expandNode,
}) =>
  isOpen && (
    <div
      id="GraphMenu"
      data-cy="graph-menu"
      className={css.menu}
      data-area="explore"
    >
      <div className={css.zoomSection}>
        {MenuType({
          type,
          target,
          resetMenu,
          handleLinkClick,
          handleSetNodeFocus,
          hideEntity,
          hideRelationships,
          expandNode,
        })}
      </div>
    </div>
  );

export default GraphMenu;
