/* eslint-disable */
export const getActorSubFilters = () => [
  {
    id: 1,
    level: 0,
    value: 'organizations',
    name: 'Organizations',
    selected: true,
    parentId: null,
    children: [
      {
        id: 2,
        level: 1,
        parentId: 1,
        value: 'companies',
        name: 'Companies',
        selected: true,
        children: [],
      },
      {
        id: 3,
        level: 1,
        parentId: 1,
        value: 'governments',
        name: 'Government Bodies',
        selected: true,
        children: [],
      },
      {
        id: 4,
        level: 1,
        parentId: 1,
        value: 'organizations',
        name: 'Organizations',
        selected: true,
        children: [],
      },
    ],
  },
  {
    id: 6,
    level: 0,
    parentId: null,
    value: 'people',
    name: 'People',
    selected: true,
    children: [
      {
        id: 7,
        level: 1,
        parentId: 6,
        value: 'peopleAtCompany',
        name: 'at Companies',
        selected: true,
        children: [],
      },
      {
        id: 8,
        level: 1,
        parentId: 7,
        value: 'peopleAtGovernment',
        name: 'at Governments',
        selected: true,
        children: [],
      },
      {
        id: 9,
        level: 1,
        parentId: 7,
        value: 'peopleAtOrganization',
        name: 'at Organizations',
        selected: true,
        children: [],
      },
      {
        id: 10,
        level: 1,
        parentId: 1,
        value: 'others',
        name: 'at Other',
        selected: true,
        children: [],
      },
    ],
  },
];

export const getActorSubFiltersForGraph = () => [
  {
    id: 1,
    level: 0,
    value: 'organizations',
    name: 'Organizations',
    selected: true,
    parentId: null,
    children: [
      {
        id: 2,
        level: 1,
        parentId: 1,
        value: 'companies',
        name: 'Companies',
        selected: true,
        children: [],
      },
      {
        id: 3,
        level: 1,
        parentId: 1,
        value: 'organizations',
        name: 'Organizations',
        selected: true,
        children: [],
      },
      {
        id: 4,
        level: 1,
        parentId: 1,
        value: 'governmentRegulators',
        name: 'Regulators',
        selected: true,
        children: [],
      },
    ],
  },
  {
    id: 5,
    level: 0,
    parentId: null,
    value: 'people',
    name: 'People',
    selected: true,
    children: [
      {
        id: 6,
        level: 1,
        parentId: 6,
        value: 'peopleRegulators',
        name: 'Regulators',
        selected: true,
        children: [],
      },
    ],
  },
];

export const getEventSubFilters = () => [
  {
    id: 1,
    name: 'News',
    selected: true,
    value: 'event',
    level: 0,
    children: [],
  },
];

export const getRegulationSubfilters = () => [
  {
    id: 1,
    name: 'Legislative Acts',
    value: 'legislativeActs',
    selected: true,
    level: 0,
    children: [],
  },
  {
    id: 2,
    name: 'Regulatory Instruments',
    value: 'regulatoryInstruments',
    selected: true,
    level: 0,
    children: [],
  },
  {
    id: 3,
    name: 'Policy Documents and Advisory Opinions',
    value: 'policyDocuments',
    selected: true,
    level: 0,
    children: [],
  },
  {
    id: 4,
    name: 'Decisions and Orders',
    value: 'decisions',
    selected: true,
    level: 0,
    children: [],
  },
  {
    id: 5,
    name: 'International and Interinstitutional Agreements',
    value: 'internationalAgreements',
    selected: true,
    level: 0,
    children: [],
  },
  {
    id: 6,
    name: 'Other',
    value: 'otherDocuments',
    selected: true,
    level: 0,
    children: [],
  },
];

export const filterTypes = {
  All: 'all',
  Actors: 'actors',
  Regulations: 'regulation',
  Events: 'event',
  Quotes: 'quote',
  Appointments: 'appointment',
};

export function toggleSelection(tree, nodeId) {
  function findAndToggle(node, nodeId) {
    if (node.id === nodeId) {
      node.selected = !node.selected;
      const newValue = node.selected;
      node.children.forEach((child) => {
        child.selected = newValue;
      });
      return true;
    }
    for (let child of node.children) {
      if (findAndToggle(child, nodeId)) {
        return true;
      }
    }
    return false;
  }

  tree.forEach((rootNode) => {
    findAndToggle(rootNode, nodeId);
  });

  return tree;
}

export const getIdList = (list) => {
  if (!list) return [];

  return list.map((object) => object.id);
};

export const getValueList = (list) => {
  if (!list) return [];

  return list.map((object) => object.value);
};

export const getSelectedValues = (list) => {
  if (!list) return [];

  return list.filter((object) => object.selected);
};

export function getSelectedValuesOnTree(nodes) {
  let selectedValues = [];

  function traverse(node) {
    if (node.selected) {
      selectedValues.push(node.value);
    }
    if (node.children) {
      node.children.forEach((child) => traverse(child));
    }
  }

  nodes.forEach((node) => traverse(node));
  return selectedValues;
}

export const getFilterTypes = (filters) => {
  if (filters.activeFilter === 'event') {
    const values = getValueList(getSelectedValues(filters.eventTypes));
    const set = new Set(['event', ...values]);
    return Array.from(set);
  }
  return filters.activeFilter;
};

export const categoriesToActiveFilter = (filterCategories) => {
  if (!filterCategories) return 'all';
  if (filterCategories[0] === 'events') return 'event';

  return filterCategories[0];
};

export const feedCountToFilterAdapter = (totals) => {
  if (!totals) return {};

  const map = {};
  totals.forEach((item) => {
    map[item.type] = item.count;
  });

  map.total = totals.reduce((total, item) => total + item.count, 0);
  return map;
};

export function normalizeKey(key) {
  switch (key) {
    case 'Person':
      return 'person';
    case 'Government Body':
      return 'governmentBody';
    case 'Organization':
      return 'organization';
    case 'Company':
      return 'company';
    case 'Company':
      return 'company';
    case 'regulations':
      return 'regulation';
    case 'events':
      return 'event';
    case 'quotes':
      return 'quote';
    case 'actors':
      return 'actor';
    case 'actor':
      return 'actors';
    case 'appointments':
      return 'appointment';
    default:
      return key; // Return the original key if no match is found
  }
}

export const searchCountToFilterAdapter = (counts) => {
  if (!counts) return {};

  const map = {};
  Object.entries(counts).forEach(([key, value]) => {
    map[normalizeKey(key)] = value;
  });

  const total =
    counts.actors +
    counts.events +
    counts.quotes +
    counts.regulations +
    counts.appointments;

  map.total = total;
  return map;
};

export const normalizeSearchActiveFilter = (activeFilter) => {
  switch (activeFilter) {
    case 'event':
      return 'events';
    case 'quote':
      return 'quotes';
    case 'regulation':
      return 'regulations';
    case 'appointment':
      return 'appointments';
    default:
      return activeFilter;
  }
};


export function getRegulationKey(typeName) {
  switch (typeName) {
    case 'Legislative Acts':
      return 'legislativeActs';
    case 'Regulatory Instruments':
      return 'regulatoryInstruments';
    case 'Policy Documents and Advisory Opinions':
      return 'policyDocuments';
    case 'Decisions and Orders':
      return 'decisions';
    case 'International and Interinstitutional Agreements':
      return 'internationalAgreements';
    case 'Other Documents':
      return 'otherDocuments';
    default:
      return typeName;
  }
}
