import * as React from 'react';

function Notes(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.667 13.333v-7.35c0-.366.133-.677.4-.933.266-.256.583-.383.95-.383h7.316c.367 0 .68.13.942.391.261.261.392.575.392.942v5.333l-3.334 3.334H6c-.367 0-.68-.13-.942-.392a1.284 1.284 0 01-.391-.942zM1.35 4.167a1.251 1.251 0 01.217-.992c.21-.294.5-.475.866-.542L9.667 1.35c.366-.067.697.006.991.217.295.21.475.5.542.866l.167.9H6c-.733 0-1.361.261-1.883.784A2.568 2.568 0 003.333 6v6.367a1.5 1.5 0 01-.458-.4 1.232 1.232 0 01-.242-.567L1.35 4.167zm11.983 6.5h-2.666v2.666l2.666-2.666z"
        fill="#929AA3"
      />
    </svg>
  );
}

export default Notes;
