import React, { useEffect, useRef, useState } from 'react';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import RelationshipDossier from '../../dossierRelationship/RelationshipDossier';
import EventsAndRegulationsDossier from '../../dossierEventsAndRegulations/EventsAndRegulationsDossier';
import css from './DossierLayout.module.css';
import RegulationDossierWrapper from '../../v2/components/Dossier/RegulationDossier/RegulationDossier';
import EventDossierWrapper from '../../v2/components/Dossier/EventDossier/EventDossier';
import EntityDossierWrapper from '../../v2/components/Dossier/EntityDossier/EntityDossier';
import { DossierType } from '../../v2/shared/dossier';
import AppointmentDossier from '../../v2/components/Dossier/AppointmentDossier/AppointmentDossier';
import QuoteDossierWrapper from '../../v2/components/Dossier/QuoteDossier/QuoteDossier';

const DossierLayoutContainer = ({ children }) => {
  const router = useRouteInformation();
  const dossierType = router.query.get('dossierType');
  const dossierEntityId = router.query.get('dossierEntityId');
  const target = router.query.get('target');
  const source = router.query.get('source');
  const isFilterOpen = router.query.get('isFilterOpen');
  const isLeftPanelOpen = router.query.get('leftPanel');
  const dossierRef = useRef(null);

  const [dossierSize, setDossierSize] = useState('normal');

  useEffect(() => {
    if (!dossierType) setDossierSize('normal');
  }, [dossierType]);

  useEffect(() => {
    const handler = (event) => {
      const dossierDOM = dossierRef.current;
      const isAttached = document.body.contains(event.target);
      const isContained = dossierDOM && dossierDOM.contains(event.target);
      if (isAttached && !isContained) {
        setDossierSize('normal');
      }
    };
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, [dossierRef]);

  // isFilterOpen value can be: null | 'true' | 'false'
  const pageHasFilterVisible = isFilterOpen === 'true';
  const pageHasFilterOrLeftPanelVisible =
    Boolean(isLeftPanelOpen) || pageHasFilterVisible;

  return (
    <>
      <div
        className={css.content}
        data-cy="dossier-layout"
        data-status={dossierType ? 'open' : 'closed'}
      >
        {children}
      </div>
      <div className={css.dossierContainer} ref={dossierRef}>
        <div
          data-cy="dossier"
          className={css.dossier}
          data-status={dossierType === 'entity' ? 'open' : 'closed'}
          data-size={dossierSize}
          data-left-panel-open={pageHasFilterOrLeftPanelVisible}
        >
          <EntityDossierWrapper
            size={dossierSize}
            entityId={dossierEntityId}
            onSizeChange={setDossierSize}
          />
        </div>

        <div
          data-cy="dossier-relationship"
          className={css.dossier}
          data-status={
            ['link', 'allConnections'].includes(dossierType) ? 'open' : 'closed'
          }
          data-size={dossierSize}
        >
          <RelationshipDossier
            source={source}
            target={target}
            size={dossierSize}
            dossierType={dossierType}
            onSizeChange={setDossierSize}
          />
        </div>

        <div
          data-cy="dossier-events-and-regulations"
          className={css.dossier}
          data-status={
            ['event', 'regulation'].includes(dossierType) ? 'open' : 'closed'
          }
          data-size={dossierSize}
        >
          <EventsAndRegulationsDossier
            source={source}
            target={target}
            size={dossierSize}
            dossierType={dossierType}
            onSizeChange={setDossierSize}
          />
        </div>

        <div
          data-cy="dossier-regulations"
          className={css.dossier}
          data-status={
            ['regulationDetails'].includes(dossierType) ? 'open' : 'closed'
          }
          data-size={dossierSize}
          data-left-panel-open={pageHasFilterOrLeftPanelVisible}
        >
          <RegulationDossierWrapper
            size={dossierSize}
            onSizeChange={setDossierSize}
          />
        </div>

        <div
          data-cy="dossier-event"
          className={css.dossier}
          data-status={
            ['eventDetails'].includes(dossierType) ? 'open' : 'closed'
          }
          data-size={dossierSize}
          data-left-panel-open={pageHasFilterOrLeftPanelVisible}
        >
          <EventDossierWrapper
            size={dossierSize}
            onSizeChange={setDossierSize}
          />
        </div>

        <div
          data-cy="dossier-appointment"
          className={css.dossier}
          data-status={
            [DossierType.Appointment].includes(dossierType) ? 'open' : 'closed'
          }
          data-size={dossierSize}
          data-left-panel-open={pageHasFilterOrLeftPanelVisible}
        >
          <AppointmentDossier
            size={dossierSize}
            onSizeChange={setDossierSize}
          />
        </div>

        <div
          data-cy="dossier-quote"
          className={css.dossier}
          data-status={
            [DossierType.Quote].includes(dossierType) ? 'open' : 'closed'
          }
          data-size={dossierSize}
          data-left-panel-open={pageHasFilterOrLeftPanelVisible}
        >
          <QuoteDossierWrapper
            size={dossierSize}
            onSizeChange={setDossierSize}
          />
        </div>
      </div>
    </>
  );
};

export default DossierLayoutContainer;
