/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Loading } from '@axis/xyz.app.loading';
import css from './ComplianceModal.module.css';
import CloseIcon from '../../icons/CloseIcon/CloseIcon';
import { useApolloClient } from '../../hooks/useApolloClient';
import toggleUserCompliance from '../../queries/toggleUserCompliance';
import notify from '../../lib/notify';
import { useAxisData } from '../../contexts/AxisDataContext';

const ComplianceModal = ({
  openModal,
  handleModalClose,
  isCompliant = false,
  regulationId,
}) => {
  Modal.setAppElement('#root');
  const client = useApolloClient();
  const [dataToSave, setDataToSave] = useState({
    regulationId,
    flag: isCompliant,
    note: '',
  });
  const [loading, setLoading] = useState(false);
  const { refetchUserTotalNotes } = useAxisData();

  const handleFlagChange = (event) => {
    event.stopPropagation();
    setDataToSave({
      ...dataToSave,
      flag: event.target.checked,
    });
  };

  const handleNoteChange = (event) => {
    setDataToSave({
      ...dataToSave,
      note: event.target.value,
    });
  };

  const handleClose = (cancelled, status, message) => {
    setLoading(false);
    handleModalClose({ cancelled, status, message, ...dataToSave });
  };

  const handleSaveConnection = () => {
    setLoading(true);
    return client
      .mutate({
        mutation: toggleUserCompliance,
        variables: dataToSave,
      })
      .then(({ errors, data }) => {
        if (errors || !data) {
          notify.error(errors[0].message, { position: 'top-right' });
          return handleClose(false, 'error', errors[0].message);
        }
        notify.success(
          `Compliance successfully ${dataToSave.flag ? 'added' : 'removed'}`,
          {
            position: 'top-right',
          },
        );
        refetchUserTotalNotes();
        return handleClose(false, 'success');
      })
      .catch((err) => {
        notify.error(err.message, { position: 'top-right' });
        return handleClose(false, 'error');
      });
  };

  return (
    <Modal
      isOpen={openModal}
      contentLabel="Regulation Compliance"
      style={{
        content: {
          backgroundColor: 'white',
          width: '400px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0px',
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 20000,
        },
      }}
      on
      shouldCloseOnOverlayClick
      onRequestClose={() => handleClose(true)}
    >
      <div className={css.modal} onClick={(event) => event.stopPropagation()}>
        <div className={css.header}>
          <h1 className={css.title}>Regulation Compliance</h1>
          <button
            type="button"
            className={css.close}
            onClick={() => handleClose(true)}
          >
            <CloseIcon className={css.closeIcon} />
          </button>
        </div>
        <div className={css.modalBody}>
          {loading ? (
            <div className={css.spinner}>
              <Loading />
            </div>
          ) : (
            <div className={css.bodyItems}>
              <div
                className={css.checkbox}
                data-status={isCompliant ? 'compliant' : 'not-compliant'}
              >
                <input
                  type="checkbox"
                  name="flag"
                  id="flag"
                  checked={dataToSave.flag}
                  onChange={handleFlagChange}
                />
                <label htmlFor="flag">My organization is compliant</label>
              </div>
              {isCompliant && (
                <p className={css.info}>
                  Unchecking this box will remove this compliance
                </p>
              )}
              <textarea
                className={css.note}
                type="text-area"
                placeholder="Add a note about this regulation"
                onChange={handleNoteChange}
                value={dataToSave.note || ''}
              />
            </div>
          )}
        </div>

        <div className={css.modalFooter}>
          <button
            className={css.cancelButton}
            type="button"
            onClick={() => handleClose(true)}
          >
            Cancel
          </button>
          <button
            data-status={loading ? 'loading' : 'idle'}
            disabled={loading}
            className={css.saveButton}
            type="button"
            onClick={handleSaveConnection}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ComplianceModal;
