import { useQuery } from '@apollo/client';
import GET_QUOTE_BY_ID from '../../queries/getQuoteById';

function useQuote(id) {
  const { data, loading } = useQuery(GET_QUOTE_BY_ID, {
    variables: { quoteId: id },
  });

  return { quote: data?.getQuoteById || null, loading };
}

export default useQuote;
