import React, { useEffect, useState } from 'react';
import { Loading } from '@axis/xyz.app.loading';
import BriefPill from '../../components/BriefPill/BriefPill';
import DossierCard from '../../components/DossierCard/DossierCard';
import css from './SharedLocations.module.css';

const PAGE_SIZE = 25;
const SAFETY_PAGE_BUFFER = 24;

const getLocationPage = (locations, page) => {
  const start = (page - 1) * 25;
  const end = start + 25;
  return locations.slice(start, end);
};

const SharedLocations = ({ locations = [], total, isLoading, onLoadMore }) => {
  const [page, setPage] = useState(1);

  const availablePages = Math.ceil(locations.length / PAGE_SIZE);
  const totalPages = Math.ceil(total / PAGE_SIZE);
  const visibleLocations = getLocationPage(locations, page);
  const isAllLocationsLoaded = locations.length === total;
  const isEmpty = visibleLocations.length === 0;

  const isPageLoading =
    (!isAllLocationsLoaded && page * PAGE_SIZE > locations.length) ||
    (page === 1 && isLoading);

  useEffect(() => {
    if (isAllLocationsLoaded) return;
    if (availablePages - page < SAFETY_PAGE_BUFFER && !isLoading) {
      onLoadMore();
    }
  }, [page]);

  const showCard = (locationsData) =>
    locationsData.length > 0 ? (
      <DossierCard
        loading={isPageLoading}
        title="Locations"
        count={total}
        currentPage={page}
        totalPages={totalPages}
        showPages
        onPageChange={setPage}
      >
        <div className={css.container}>
          {isEmpty && !isLoading && (
            <div className={css.empty}>
              These entities do not have any shared locations
            </div>
          )}
          <div className={css.briefs}>
            {visibleLocations.map(({ id, name }) => (
              <BriefPill key={id} name={name} isGeography />
            ))}
          </div>
        </div>
      </DossierCard>
    ) : null;

  return (
    <div>
      {isLoading ? (
        <div className={css.loadingArea}>
          <Loading className={css.loading} />
        </div>
      ) : (
        showCard(locations)
      )}
    </div>
  );
};

export default SharedLocations;
