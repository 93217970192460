const UUID_PATTERN =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const isValidUUID = (value) => {
  if (!value) return false;
  if (typeof value !== 'string') return false;
  if (!UUID_PATTERN.test(value)) return false;
  return true;
};

export default isValidUUID;
