import React, { useState } from 'react';

import Modal from 'react-modal';
import css from './Notes.module.css';
import NoteCard from './NoteCard/NoteCard';
import useNotes from '../../../hooks/useNotes';
import { modalStyles } from '../../../shared/modal';
import PanelHeader from '../PanelHeader/PanelHeader';
import EditNoteModal from './EditNoteModal/EditNoteModal';
import DeleteNoteModal from './DeleteNoteModal/DeleteNoteModal';
import notify from '../../../../lib/notify';

const typeModal = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

function Notes() {
  const [selectedNoteId, setSelectedNoteId] = useState();
  const [openedModal, setOpenedModal] = useState(null);
  const { loading, notes, updateNote, refetch, deleteNote } = useNotes();

  const onSearch = (query) => {
    refetch({ searchTerm: query });
  };

  const onEdit = (note) => {
    setOpenedModal({ modal: typeModal.EDIT, context: note });
  };

  const onDelete = (note, isComplianceNote, isConnectionNote) => {
    setOpenedModal({
      modal: typeModal.DELETE,
      context: { ...note, isComplianceNote, isConnectionNote },
    });
  };

  const onConfirmEditRegulation = async (note) => {
    try {
      setOpenedModal(null);
      updateNote(note);
      refetch();
      notify.success('Note updated!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  const onConfirmDeleteNote = async (note) => {
    try {
      setOpenedModal(null);
      deleteNote(note);
      refetch();
      notify.success('Note deleted!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  return (
    <div className={css.main}>
      <PanelHeader count={notes.length} onSearch={onSearch} title="Notes" />
      {loading && <LoadingState />}

      {!loading && (
        <div className={css.list}>
          {notes.map((note) => (
            <NoteCard
              key={note.id}
              note={note}
              onEdit={onEdit}
              onDelete={onDelete}
              onNoteSelected={setSelectedNoteId}
              selectedNoteId={selectedNoteId}
            />
          ))}
        </div>
      )}

      <Modal
        isOpen={Boolean(openedModal)}
        style={modalStyles}
        contentLabel="Edit Note"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpenedModal(null)}
      >
        {openedModal?.modal === typeModal.EDIT && (
          <EditNoteModal
            note={openedModal.context.note}
            onClose={() => setOpenedModal(null)}
            onConfirmChanges={(note) =>
              onConfirmEditRegulation({ ...openedModal.context, note })
            }
          />
        )}
        {openedModal?.modal === typeModal.DELETE && (
          <DeleteNoteModal
            note={openedModal.context}
            isComplianceNote={openedModal.context.isComplianceNote}
            isConnectionNote={openedModal.context.isConnectionNote}
            onClose={() => setOpenedModal(null)}
            onDelete={(note) =>
              onConfirmDeleteNote({ ...openedModal.context, note })
            }
          />
        )}
      </Modal>
    </div>
  );
}

export default Notes;

const LoadingState = () => (
  <div className={css.loadingState}>
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
  </div>
);
