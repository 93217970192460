import { useQuery } from '@apollo/client';
import GET_ENTITY_RELATIONSHIPS from '../../queries/dossierRelationships';

function useEntityDossierRelationships(id) {
  const { data, loading } = useQuery(GET_ENTITY_RELATIONSHIPS, {
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: { id },
    fetchPolicy: 'cache-first',
  });

  return {
    relationships: data?.getDossierRelationship,
    isLoadingRelationships: loading,
  };
}

export default useEntityDossierRelationships;
