import * as React from 'react';

function NotificationBell(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M3.333 15.833v-1.666H5V8.333A4.88 4.88 0 016.042 5.26 4.806 4.806 0 018.75 3.5v-.583c0-.348.122-.643.365-.886s.538-.364.885-.364.642.121.885.364c.244.243.365.538.365.886V3.5a4.806 4.806 0 012.708 1.76A4.88 4.88 0 0115 8.333v5.834h1.667v1.666H3.333zm6.667 2.5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.176h3.334c0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default NotificationBell;
