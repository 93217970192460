import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import css from './ScatterPlotEvents.module.css';
import useUrlState from '../../../hooks/useUrlState';
import Header from './Header/Header';
import ScatterGraph from './ScatterGraph/ScatterGraph';
import News from './News/News';
import DetailsSection from './DetailsSection/DetailsSection';
import GET_FILTERED_EVENTS from '../../../queries/getFilteredEventsForTreeMap';
import GET_COUNTS from '../../../queries/searchCounts';
import useDatesUrlState from '../../../hooks/useDatesUrlState';

function ScatterPlotEvents() {
  const [industryId, setIndustryId] = useUrlState('industryId');
  const [countryId, setCountryId] = useUrlState('countryId');
  const [[startDate, endDate], setDates] = useDatesUrlState(); // miliseconds
  const [selectedNode, setSelectedNode] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(null);

  const { loading } = useQuery(GET_FILTERED_EVENTS, {
    fetchPolicy: 'cache-first',
    variables: { eventIds: selectedNode?.eventIds },
    skip: !selectedNode,
    onCompleted: (data) => {
      setFilteredEvents(data.getEventById.events);
    },
  });

  const { data } = useQuery(GET_COUNTS, {
    variables: {
      filterIndustries: [industryId],
      topics: [countryId],
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
    },
  });

  const cleanNodeData = () => {
    setSelectedNode(null);
    setFilteredEvents(null);
  };

  const setDateInterval = (dates) => {
    setDates({
      startDate: dates[0].getTime(),
      endDate: dates[1].getTime(),
    });
  };

  const counts = data?.searchEventsAndRegulationsCount.eventTypesCount;

  useEffect(() => {
    cleanNodeData();
  }, [industryId, countryId, startDate, endDate]);

  return (
    <div className={css.main}>
      <Header
        industryId={industryId}
        setIndustryId={setIndustryId}
        endDate={endDate}
        startDate={startDate}
        countryId={countryId}
        setCountryId={setCountryId}
        setDateInterval={setDateInterval}
      />
      <div className={css.body}>
        <div className={css.content}>
          <ScatterGraph
            countryId={countryId}
            industryId={industryId}
            endDate={endDate}
            startDate={startDate}
            setSelectedNode={setSelectedNode}
            onClickOutside={cleanNodeData}
            selectedNodeId={selectedNode?.id}
          />
          <DetailsSection
            countryId={countryId}
            industryId={industryId}
            endDate={endDate}
            counts={counts}
            startDate={startDate}
          />
        </div>
        <News
          countryId={countryId}
          industryId={industryId}
          counts={counts}
          endDate={endDate}
          startDate={startDate}
          selectedNode={selectedNode}
          filteredEvents={filteredEvents}
          isLoadingFilteredEvents={loading}
        />
      </div>
    </div>
  );
}

export default ScatterPlotEvents;
