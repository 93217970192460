import { gql } from '@apollo/client';

const createNote = gql`
  mutation createNote(
    $note: String!
    $targetId: ID!
    $noteType: NoteTypeEnum!
  ) {
    createNote(note: $note, targetId: $targetId, noteType: $noteType)
  }
`;

export default createNote;
