import React from 'react';

const Eye = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.6665 16.0001C2.6665 16.0001 7.99984 6.66675 15.9998 6.66675C23.9998 6.66675 29.3332 16.0001 29.3332 16.0001C29.3332 16.0001 23.9998 25.3334 15.9998 25.3334C7.99984 25.3334 2.6665 16.0001 2.6665 16.0001Z"
      stroke={color || '#C4C4C4'}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
      stroke={color || '#C4C4C4'}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default Eye;
