import React, { createContext, useContext, useState } from 'react';

// This very unusual import is for the Dossier, which is a React.PureComponent
export const IdentityContextConfiguration = createContext({});

export const IdentityContext = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}'),
  );

  const saveToken = (value) => {
    setToken(value);
    localStorage.setItem('token', value);
  };

  const saveUser = (value) => {
    setUser(value);
    localStorage.setItem('user', JSON.stringify(value));
  };

  const removeToken = () => {
    setToken('');
    localStorage.removeItem('token');
  };

  const removeUser = () => {
    setUser({});
    localStorage.removeItem('user');
  };

  const isAxisUser = user.email?.endsWith('@axis.xyz');

  const value = {
    token,
    user,
    isAxisUser,

    saveToken,
    removeToken,
    saveUser,
    removeUser,
  };

  return (
    <IdentityContextConfiguration.Provider value={value}>
      {children}
    </IdentityContextConfiguration.Provider>
  );
};

export const useIdentity = () => useContext(IdentityContextConfiguration);
