import { gql } from '@apollo/client';

const query = gql`
  query getEntityById($id: ID!) {
    getDossierEntity(id: $id) {
      id
      name
      type
      image
      subType
      shortestName
      inSubscription
      isConnected
      connectionNote
      notesCount
    }
  }
`;

export default query;
