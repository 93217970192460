/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Modal from 'react-modal';
import css from './DeleteNoteModal.module.css';
import CloseIcon from '../../icons/CloseIcon/CloseIcon';
import notify from '../../lib/notify';
import { useApolloClient } from '../../hooks/useApolloClient';
import deleteNote from '../../queries/deleteNote';
import { useAxisData } from '../../contexts/AxisDataContext';

const DeleteNoteModal = ({ openModal, handleModalClose, noteType, noteId }) => {
  Modal.setAppElement('#root');
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const { refetchUserTotalNotes } = useAxisData();

  const handleClose = (cancelled, status, message) => {
    setLoading(false);
    if (!cancelled) {
      if (status === 'error') {
        notify.error(message, { position: 'top-right' });
      } else {
        notify.success('Note successfully deleted', { position: 'top-right' });
      }
    }
    handleModalClose(status);
  };

  const handleDeleteNote = () => {
    return client
      .mutate({
        mutation: deleteNote,
        variables: { noteType, noteId },
      })
      .then(({ errors, data }) => {
        refetchUserTotalNotes();
        if (errors || !data || !data.updateNote) {
          return handleClose(false, 'error', errors[0].message);
        }
        return handleClose(false, 'success');
      })
      .catch(() => {
        return handleClose(false, 'error');
      });
  };

  return (
    <Modal
      isOpen={openModal}
      contentLabel="Edit Note"
      style={{
        content: {
          backgroundColor: 'white',
          width: '400px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0px',
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 20000,
        },
      }}
      on
      shouldCloseOnOverlayClick
      onRequestClose={() => handleClose(true)}
    >
      <div className={css.modal} onClick={(event) => event.stopPropagation()}>
        <div className={css.header}>
          <h1 className={css.title}>Confirm to delete!</h1>
          <button
            type="button"
            className={css.close}
            onClick={() => handleClose(true)}
          >
            <CloseIcon className={css.closeIcon} />
          </button>
        </div>

        <div className={css.modalFooter}>
          <button
            className={css.cancelButton}
            type="button"
            onClick={() => handleClose(true)}
          >
            Cancel
          </button>
          <button
            data-status={loading ? 'loading' : 'idle'}
            disabled={loading}
            className={css.saveButton}
            type="button"
            onClick={handleDeleteNote}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteNoteModal;
