import React from 'react';
import FilterType from '../FilterType/FilterType';
import { filterTypes } from '../../index';
import OptionRow from '../OptionRow/OptionRow';

function Events({
  activeFilter,
  onClick,
  counts,
  isLoadingCounts,
  options,
  onOptionChange,
}) {
  return (
    <FilterType
      label="Events"
      count={counts.event}
      isLoadingCounts={isLoadingCounts}
      activeFilter={activeFilter}
      type={filterTypes.Events}
      onClick={onClick}
    >
      {options &&
        options.map((option) => (
          <OptionRow
            key={option.id}
            option={option}
            counts={counts}
            handleOnInputChange={onOptionChange}
          />
        ))}
    </FilterType>
  );
}

export default Events;
