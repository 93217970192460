import React, { createContext, useContext, useState } from 'react';
import AddNoteModal from '../components/AddNoteModal/AddNoteModal';
import EditNoteModal from '../components/EditNoteModal/EditNoteModal';
import DeleteNoteModal from '../components/DeleteNoteModal/DeleteNoteModal';
import ComplianceModal from '../components/ComplianceModal/ComplianceModal';

// This very unusual import is for the Dossier, which is a React.PureComponent
export const ModalContextConfiguration = createContext({});

const ModalType = {
  ADD_NOTE: ({ noteType, openModal, handleModalClose, targetId }) => (
    <AddNoteModal
      noteType={noteType}
      openModal={openModal}
      handleModalClose={handleModalClose}
      targetId={targetId}
    />
  ),
  EDIT_NOTE: ({
    noteType,
    openModal,
    handleModalClose,
    targetId,
    noteData,
    noteId,
  }) => (
    <EditNoteModal
      noteType={noteType}
      openModal={openModal}
      handleModalClose={handleModalClose}
      targetId={targetId}
      noteData={noteData}
      noteId={noteId}
    />
  ),
  DELETE_NOTE: ({
    noteType,
    openModal,
    handleModalClose,
    targetId,
    noteData,
    noteId,
  }) => (
    <DeleteNoteModal
      noteType={noteType}
      openModal={openModal}
      handleModalClose={handleModalClose}
      targetId={targetId}
      noteData={noteData}
      noteId={noteId}
    />
  ),
  COMPLIANCE: ({ openModal, handleModalClose, regulationId, isCompliant }) => (
    <ComplianceModal
      openModal={openModal}
      handleModalClose={handleModalClose}
      regulationId={regulationId}
      isCompliant={isCompliant}
    />
  ),
};

export const ModalContext = ({ children }) => {
  const [modalData, setModalData] = useState({
    modalType: '',
    openModal: false,
  });

  const resetModalData = () => {
    setModalData({
      modalType: '',
      openModal: false,
    });
  };

  const value = {
    modalData,
    setModalData,
    resetModalData,
  };

  return (
    <ModalContextConfiguration.Provider value={value}>
      {children}
      {modalData.openModal &&
        ModalType[modalData.modalType] &&
        ModalType[modalData.modalType](modalData)}
    </ModalContextConfiguration.Provider>
  );
};

export const useModal = () => useContext(ModalContextConfiguration);
