/* eslint-disable no-underscore-dangle */
import { useMutation, useQuery } from '@apollo/client';
import GET_APPOINTMENT_BY_ID from '../../queries/getAppointmentById';
import GET_NOTES_FOR_APPOINTMENTS from '../../queries/getNotesForAppointment';
import CREATE_NOTE from '../../queries/createNote';
import DELETE_NOTE from '../../queries/deleteNote';
import UPDATE_NOTE from '../../queries/updateNote';
import GET_ALL_NOTES from '../../queries/getAllNotes';

import notify from '../../lib/notify';
import { NoteType } from './useNotes';

function useAppointment(id, skipNotes = false) {
  const notes = useQuery(GET_NOTES_FOR_APPOINTMENTS, {
    skip: skipNotes,
    variables: { appointmentId: id },
  });

  const { data, loading } = useQuery(GET_APPOINTMENT_BY_ID, {
    variables: { appointmentId: id },
  });

  const [insertNote, createNoteDetails] = useMutation(CREATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_APPOINTMENTS],
    onError: (error) => notify.error(error),
  });

  const [updateNoteMutation, updateNoteDetails] = useMutation(UPDATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_APPOINTMENTS],
    onError: (error) => notify.error(error),
  });

  const [deleteNoteMutation, deleteNoteDetails] = useMutation(DELETE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_APPOINTMENTS],
    onError: (error) => notify.error(error),
  });

  const createNote = (appointmentId, note) => {
    insertNote({
      variables: {
        targetId: appointmentId,
        note,
        noteType: NoteType.appointmentNoteType,
      },
    });
  };

  const updateNote = (note) => {
    return updateNoteMutation({
      variables: {
        noteId: note.id,
        note: note.note,
        noteType: NoteType[note.__typename],
      },
    });
  };

  const deleteNote = (note) => {
    return deleteNoteMutation({
      variables: {
        noteId: note.id,
        noteType: NoteType[note.__typename],
      },
    });
  };

  return {
    appointment: data?.getAppointmentById || null,
    loading,
    notes: notes.data?.getNotesForAppointment || [],
    isLoadingNotes: notes.loading,
    isSavingNote: createNoteDetails.loading,
    isDeletingNote: deleteNoteDetails.loading,
    isUpdatingNote: updateNoteDetails.loading,
    createNote,
    updateNote,
    deleteNote,
  };
}

export default useAppointment;
