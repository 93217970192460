import React from 'react';

const InfoIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 17 16" fill="none">
    <path
      d="M7.83337 11.3333H9.16671V7.33325H7.83337V11.3333ZM8.50004 5.99992C8.68893 5.99992 8.84737 5.93592 8.97537 5.80792C9.10293 5.68036 9.16671 5.52214 9.16671 5.33325C9.16671 5.14436 9.10293 4.98592 8.97537 4.85792C8.84737 4.73036 8.68893 4.66658 8.50004 4.66658C8.31115 4.66658 8.15293 4.73036 8.02537 4.85792C7.89737 4.98592 7.83337 5.14436 7.83337 5.33325C7.83337 5.52214 7.89737 5.68036 8.02537 5.80792C8.15293 5.93592 8.31115 5.99992 8.50004 5.99992ZM8.50004 14.6666C7.57782 14.6666 6.71115 14.4915 5.90004 14.1413C5.08893 13.7915 4.38337 13.3166 3.78337 12.7166C3.18337 12.1166 2.70849 11.411 2.35871 10.5999C2.00849 9.78881 1.83337 8.92214 1.83337 7.99992C1.83337 7.0777 2.00849 6.21103 2.35871 5.39992C2.70849 4.58881 3.18337 3.88325 3.78337 3.28325C4.38337 2.68325 5.08893 2.20814 5.90004 1.85792C6.71115 1.50814 7.57782 1.33325 8.50004 1.33325C9.42226 1.33325 10.2889 1.50814 11.1 1.85792C11.9112 2.20814 12.6167 2.68325 13.2167 3.28325C13.8167 3.88325 14.2916 4.58881 14.6414 5.39992C14.9916 6.21103 15.1667 7.0777 15.1667 7.99992C15.1667 8.92214 14.9916 9.78881 14.6414 10.5999C14.2916 11.411 13.8167 12.1166 13.2167 12.7166C12.6167 13.3166 11.9112 13.7915 11.1 14.1413C10.2889 14.4915 9.42226 14.6666 8.50004 14.6666ZM8.50004 13.3333C9.98893 13.3333 11.25 12.8166 12.2834 11.7833C13.3167 10.7499 13.8334 9.48881 13.8334 7.99992C13.8334 6.51103 13.3167 5.24992 12.2834 4.21658C11.25 3.18325 9.98893 2.66659 8.50004 2.66659C7.01115 2.66659 5.75004 3.18325 4.71671 4.21658C3.68337 5.24992 3.16671 6.51103 3.16671 7.99992C3.16671 9.48881 3.68337 10.7499 4.71671 11.7833C5.75004 12.8166 7.01115 13.3333 8.50004 13.3333Z"
      fill="#606060"
    />
  </svg>
);

export default InfoIcon;
