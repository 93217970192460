import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import css from './TrendingEntities.module.css';
import EntityPill from '../../../components/EntityPill/EntityPill';
import GET_TRENDING_ENTITIES from '../../../queries/getTrendingEntities';

function TrendingEntities() {
  const { data, loading } = useQuery(GET_TRENDING_ENTITIES, {
    fetchPolicy: 'cache-first',
    variables: {
      limit: 7,
    },
  });

  const entities = useMemo(() => {
    if (data && data.getTrendingEntities) {
      return data.getTrendingEntities?.map(({ entity }) => entity);
    }

    return [];
  }, [data]);

  if (!loading && entities.length === 0) return null;

  return (
    <div className={css.main}>
      <h3 className={css.title}>Trending actors</h3>
      {loading && <LoadingState />}

      {!loading && (
        <div className={css.entities}>
          {entities.map((entity) => (
            <EntityPill
              key={entity.id}
              id={entity.id}
              name={entity.name}
              image={entity.image}
              type={entity.type}
              shortName={entity.shortestName}
              autoOpenDossier
              isConnected={entity.isConnected}
              inSubscription={entity.inSubscription}
              className={css.entity}
              size="small"
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default TrendingEntities;

function LoadingState() {
  return (
    <div className={css.loadingContainer}>
      <div style={{ height: 20, width: 300 }} className="skeleton-v2" />
      <div style={{ height: 20, width: 100 }} className="skeleton-v2" />
      <div style={{ height: 20, width: 550 }} className="skeleton-v2" />
      <div style={{ height: 20, width: 100 }} className="skeleton-v2" />
      <div style={{ height: 20, width: 650 }} className="skeleton-v2" />
      <div style={{ height: 20, width: 400 }} className="skeleton-v2" />
      <div style={{ height: 20, width: 500 }} className="skeleton-v2" />
      <div style={{ height: 20, width: 50 }} className="skeleton-v2" />
    </div>
  );
}
