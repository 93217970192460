import React, { useState } from 'react';

import Button from '../../../../Buttom/Buttom';
import Bookmark from '../../../../../../icons/Bookmark';
import BookmarkOutline from '../../../../../../icons/BookmarkOutline';
import RemoveBookmarkModal from '../../../../Modals/RemoveBookmarkModal/RemoveBookmarkModal';

function BookmarkButton({ toggleBookmark, entity }) {
  const [removeBookmarkModalOpen, setRemoveBookmarkModalOpen] = useState(false);

  const { isBookmarked } = entity;
  const Icon = isBookmarked ? Bookmark : BookmarkOutline;
  const type = isBookmarked ? 'success' : 'secondary';
  const identifier = isBookmarked
    ? 'dossier-remove-bookmark'
    : 'dossier-add-bookmark';

  const onToggle = () => {
    if (!isBookmarked) {
      toggleBookmark();
    } else {
      setRemoveBookmarkModalOpen(true);
    }
  };

  return (
    <>
      <Button type={type} onClick={onToggle} data-cy={identifier}>
        <Icon style={{ width: 16, height: 16 }} />
      </Button>

      <RemoveBookmarkModal
        entity={entity}
        isOpen={removeBookmarkModalOpen}
        onBookmarkRemoveConfirm={toggleBookmark}
        onClose={() => setRemoveBookmarkModalOpen(false)}
      />
    </>
  );
}

export default BookmarkButton;
