import React from 'react';
import { getHost } from '../../lib/facets/attachment';
import SourceIcon from '../../icons/SourceIcon/SourceIcon';
import css from './Source.module.css';

const Source = ({ source }) => (
  <a
    className={css.link}
    href={source}
    rel="noopener noreferrer"
    target="_blank"
    data-cy="link-source"
  >
    <SourceIcon className={css.icon} />
    {getHost(source)}
  </a>
);

export default Source;
