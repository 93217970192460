export const getMostRelevantTopicFromId = ({
  profileTopics = [],
  routeTopicId,
  topicIds = [],
}) => {
  // Check the topics
  if (!topicIds || topicIds.length === 0) return null;
  // Get the topics that are in the profile
  const topicsInProfile = profileTopics.filter((profileTopic) =>
    topicIds.some((topic) => profileTopic.id === topic),
  );

  if (topicsInProfile.length === 0) return null;

  // Check if the route topic is in the topics (best match)
  const routeTopic = topicsInProfile.find((topic) => topic.id === routeTopicId);
  if (routeTopic) return routeTopic;

  // Return the first topic contained in the profileTopics
  return topicsInProfile[0];
};
