import React from 'react';
import css from './CountIndicator.module.css';

export const CountIndicator = ({ count, type = 'Actor' }) => {
  return (
    <div data-type={type} className={css.pill}>
      <span>{count}</span>
    </div>
  );
};
