import React, { useState } from 'react';
import Button from '../Buttom/Buttom';
import compliantIcon from '../../../icons/compliant_icon.svg';
import notCompliantIcon from '../../../icons/not_compliant.svg';

import css from './RegulationComplianceButton';
import ComplianceRegulationModal from '../Modals/ComplianceRegulationModal/ComplianceRegulationModal';

function RegulationComplianceButton({
  regulationId,
  isCompliant,
  onComplianceChange,
}) {
  const [isComplianceModalOpen, setIsComplianceModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsComplianceModalOpen(true);
  };

  const text = isCompliant ? 'Compliant' : 'Comply';
  const type = isCompliant ? 'success' : 'secondary';

  return (
    <>
      <Button
        type={type}
        className={css.linkButton}
        data-cy="regulation-comply"
        onClick={handleButtonClick}
      >
        {isCompliant ? (
          <img src={compliantIcon} alt="compliant" />
        ) : (
          <img src={notCompliantIcon} alt="not-compliant" />
        )}
        {text}
      </Button>

      <ComplianceRegulationModal
        isOpen={isComplianceModalOpen}
        regulationId={regulationId}
        isCompliant={isCompliant}
        onConfirmChanges={onComplianceChange}
        onClose={() => {
          setIsComplianceModalOpen(false);
        }}
      />
    </>
  );
}

export default RegulationComplianceButton;
