/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import css from './Checkbox.module.css';

const Checkbox = ({ id = 'checkbox', label, isChecked = false, onChange }) => {
  return (
    <div className={css.main}>
      <input
        id={id}
        className={css.checkbox}
        type="checkbox"
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <label htmlFor={id} className={css.label}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
