import React, { useMemo } from 'react';

import { Popover } from 'react-tiny-popover';
import css from './FilterPopUp.module.css';

const getPopupTop = (parentRef, popUpDimmensions) => {
  if (!parentRef.current) return {};

  const [popupWidth, popupHeight] = popUpDimmensions;
  const windownHeight = window.innerHeight;
  const windownWidth = window.innerWidth;

  // parentRef position in screen
  const { right, top, left } = parentRef.current.getBoundingClientRect();

  // move popup half of available area on top of parent ref
  const paddingTop = top / 2;
  const isBottomOverflowing = paddingTop + popupHeight > windownHeight;
  const shouldOpenOnLeftSide = right + popupWidth > windownWidth * 0.9;

  let popUpTop = paddingTop;

  if (isBottomOverflowing) {
    const diff = windownHeight - (popUpTop + popupHeight);
    popUpTop = Math.max(0, popUpTop - Math.abs(diff));
  }

  let leftPosition = right;

  if (shouldOpenOnLeftSide) {
    leftPosition = Math.max(left - popupWidth - 10, 0);
  }

  const styles = {
    zIndex: 9999,
    left: `${leftPosition}px`,
    top: `${popUpTop}px`,
    marginLeft: `10px`,
    marginRight: `10px`,
  };

  return styles;
};

function FilterPopUp({
  children,
  content,
  onClickOutside,
  isPopoverOpen,
  parentRef,
  popupDimenssions,
}) {
  const containerStyle = useMemo(() => {
    if (isPopoverOpen) {
      return getPopupTop(parentRef, popupDimenssions);
    }
    return {};
  }, [isPopoverOpen]);

  return (
    <Popover
      padding={10}
      reposition={false}
      positions={['right']}
      isOpen={isPopoverOpen}
      containerStyle={containerStyle}
      onClickOutside={onClickOutside}
      content={
        <div
          className={css.card}
          style={{ width: popupDimenssions[0], height: popupDimenssions[1] }}
        >
          {content}
        </div>
      }
    >
      {children}
    </Popover>
  );
}

export default FilterPopUp;
