import React, { useEffect, useState } from 'react';

import { uniqueId } from 'lodash';
import { useQuery } from '@apollo/client';
import { List } from '../DetailsSection/List/List';
import SmallAppointmentCard from '../../../components/Cards/SmallAppointmentCard/SmallAppointmentCard';
import GET_APPOINTMENTS from '../../../../queries/getAppointments';
import useNavigation from '../../../hooks/useNavigation';
import { useAxisData } from '../../../../contexts/AxisDataContext';
import { ROUTES } from '../../../shared/routes';

function Appointments({ countryId, industryId, startDate, endDate, count }) {
  const { navigate } = useNavigation();
  const { flatIndustries, topics } = useAxisData();

  const [appointments, setAppointments] = useState([]);
  const [variables, setVariables] = useState({
    filterCategories: {
      events: {
        eventFilters: ['appointment'],
        limit: 20,
        nextToken: null,
      },
    },
    topics: [countryId],
    filterIndustries: [industryId],
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  });

  const { loading } = useQuery(GET_APPOINTMENTS, {
    variables,
    onCompleted: (data) => {
      const { results } = data.searchEventsAndRegulations.events;
      setAppointments(results);
    },
  });

  useEffect(() => {
    setVariables((current) => {
      return {
        ...current,
        topics: [countryId],
        filterIndustries: [industryId],
        endDate: new Date(endDate),
        startDate: new Date(startDate),
      };
    });
  }, [industryId, countryId, startDate, endDate]);

  const handleSeeAll = () => {
    const industry = flatIndustries.find(({ id }) => id === industryId);
    const country = topics.find(({ id }) => id === countryId);

    const dates = [
      new Date(startDate).toISOString(),
      new Date(endDate).toISOString(),
    ];

    const params = new URLSearchParams();
    const uuid = uniqueId('search-');
    params.set('queryId', uuid);
    params.set('isFilterOpen', true);
    sessionStorage.setItem(
      uuid,
      JSON.stringify({
        industries: [industry],
        countries: [country],
        dates,
        searchTypes: 'event',
        eventTypes: ['appointment'],
      }),
    );

    navigate(ROUTES.ADV_SEARCH, params);
  };

  return (
    <List
      loading={loading}
      title="Appointments"
      count={count}
      empty={appointments.length === 0}
      onClickSeeAll={handleSeeAll}
    >
      {appointments.map((appointment) => (
        <SmallAppointmentCard key={appointment.id} appointment={appointment} />
      ))}
    </List>
  );
}

export default Appointments;
