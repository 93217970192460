import * as React from 'react';

function SavedSearch(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_7089_3741)">
        <mask
          id="a"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={16}
          height={16}
        >
          <path fill="#D9D9D9" d="M0 0H16V16H0z" />
        </mask>
        <g mask="url(#a)">
          <path
            d="M10.3 10.033l.733-.7-1.4-1.4c.122-.189.214-.389.275-.6.06-.21.091-.433.091-.666 0-.656-.23-1.209-.691-1.659a2.27 2.27 0 00-1.642-.675 2.27 2.27 0 00-1.642.675 2.227 2.227 0 00-.691 1.659c0 .655.23 1.208.691 1.658A2.27 2.27 0 007.666 9a2.3 2.3 0 00.658-.092c.206-.06.409-.152.609-.275l1.366 1.4zM7.665 8c-.367 0-.68-.13-.942-.392a1.284 1.284 0 01-.391-.941c0-.367.13-.68.391-.942.261-.261.575-.392.942-.392.356 0 .667.13.933.392.267.261.4.575.4.942 0 .366-.13.68-.391.941A1.284 1.284 0 017.666 8zm-5 4c-.367 0-.68-.13-.942-.392a1.284 1.284 0 01-.391-.941V3.333c0-.366.13-.68.391-.941C1.985 2.13 2.3 2 2.666 2h10.667c.366 0 .68.13.941.392.262.26.392.575.392.941v7.334c0 .366-.13.68-.392.941a1.284 1.284 0 01-.941.392H2.666zm-2 2v-1.333h14.667V14H.666z"
            fill="#929AA3"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7089_3741">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SavedSearch;
