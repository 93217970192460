import React from 'react';

import css from './ConnectionCard.module.css';
import Card from '../../Card/Card';
import EntityPill from '../../../../../components/EntityPill/EntityPill';
import IconButton from '../../../IconButton/IconButton';
import RemoveConnection from '../../../../../icons/RemoveConnection';
import useNavigation from '../../../../hooks/useNavigation';

function ConnectionCard({ connection, onRemoveConnection }) {
  const { params, toggleEntityDossier } = useNavigation();
  const { primaryRoleTitle } = connection.entity;

  return (
    <Card
      selected={params.get('dossierEntityId') === connection.entity.id}
      onClick={() => toggleEntityDossier(connection.entity.id)}
    >
      <div className={css.header}>
        <EntityPill
          autoOpenDossier
          id={connection.entity.id}
          type={connection.entity.type}
          name={connection.entity.name}
          image={connection.entity.image}
          shortName={connection.entity.shortestName}
          inSubscription={connection.entity.inSubscription}
          isConnected
          size="small"
        />
        <IconButton
          size="medium"
          onClick={(e) => {
            e.stopPropagation();
            onRemoveConnection(connection);
          }}
        >
          <RemoveConnection />
        </IconButton>
      </div>

      <div className={css.content}>
        {primaryRoleTitle && (
          <div className={css.roleTitle}>{primaryRoleTitle}</div>
        )}
      </div>
    </Card>
  );
}

export default ConnectionCard;
