import React from 'react';

import css from './Summary.module.css';
import AiBadge from '../../v2/components/AiBadge/AiBadge';
import DossierCard from '../../components/DossierCard/DossierCard';

function Summary({ summary }) {
  if (!summary) return null;
  return (
    <DossierCard title="Summary" badge={<AiBadge />}>
      <div className={css.container}>{summary}</div>
    </DossierCard>
  );
}

export default Summary;
