import React from 'react';

import Modal from 'react-modal';
import { modalStyles } from '../../../shared/modal';
import useToogleRegulationCompliance from '../../../hooks/useToogleRegulationCompliant';
import EditCompliantRegulation from '../../LeftPanel/CompliantRegulations/EditCompliantRegulation/EditCompliantRegulation';

function ComplianceRegulationModal({
  isOpen,
  onClose,
  regulationId,
  isCompliant = false,
  note = '',
  onConfirmChanges,
}) {
  const { tooggleCompliance, loading } = useToogleRegulationCompliance();

  const onSave = async ({ flag, note }) => {
    if (loading) return;
    
    try {
      await tooggleCompliance(regulationId, note, flag);
      onConfirmChanges({
        regulationId,
        note,
        isCompliant: flag,
      });
      onClose();
    } catch (error) {
      console.log('error:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      contentLabel="Regulation Compliance"
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      <EditCompliantRegulation
        isCompliant={isCompliant}
        note={note}
        onClose={onClose}
        regulationId={regulationId}
        onConfirmChanges={onSave}
      />
    </Modal>
  );
}

export default ComplianceRegulationModal;
