import { gql } from '@apollo/client';

const query = gql`
  query briefTree($limit: Int, $skip: Int) {
    briefTree(limit: $limit, skip: $skip) {
      id
      name
      type
      children {
        id
        name
        type
        children {
          id
          name
          type
        }
      }
    }
  }
`;

export default query;
