import { useMutation, useQuery } from '@apollo/client';

import GET_BOOKMARKS from '../../queries/getUserBookmarks';
import REMOVE_BOOKMARK from '../../queries/toggleBookmark';

function useBookmarks() {
  const { data, loading, refetch } = useQuery(GET_BOOKMARKS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 50,
      skip: 0,
    },
  });
  const [removeBookmark] = useMutation(REMOVE_BOOKMARK);

  return {
    bookmarks: data?.getBookmarkedEntities.entities || [],
    total: data?.getBookmarkedEntities.total || 0,
    loading,
    refetch,
    removeBookmark,
  };
}

export default useBookmarks;
