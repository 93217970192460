import { gql } from '@apollo/client';

const mutation = gql`
  mutation upsertUserPdb(
    $id: ID
    $background: String
    $industries: [ID]
    $countries: [ID]
    $interests: [String]
  ) {
    upsertUserPdb(
      id: $id
      background: $background
      industries: $industries
      countries: $countries
      interests: $interests
    )
  }
`;

export default mutation;
