import { forceCollide, forceLink, forceCenter, forceManyBody } from 'd3-force';

import { LINK_OVERRIDE_RULES } from './graph.constants';
import {
  getLinkColor,
  getLinkLength,
  getLinkMidPoint,
  getLinkName,
  getLinkTextFontSize,
  getTextWidth,
  isSameLink,
  paintLinkArrow,
  paintLinkText,
} from './link.utils';
import {
  drawImage,
  drawNodeText,
  drawRoundedRect,
  getNodeColor,
  isValidCoordinate,
  isSameNode,
} from './node.utils';

export const clamp = (number, min, max) => {
  return Math.min(Math.max(number, min), max);
};

export const drawNodes = ({ node, context, scale, highlightNodes }) => {
  if (!isValidCoordinate(node.x) || !isValidCoordinate(node.y)) return;
  let nodeOpacity;
  const isHighlightedNode = highlightNodes.some((n) => isSameNode(n, node));
  if (
    !isHighlightedNode &&
    highlightNodes.length > 0 &&
    !node.labels.includes('User')
  ) {
    nodeOpacity = 0.1;
  }

  const hasImage = node.image && node.labels.includes('Actor');
  const { name } = node;

  const fontSize = 6.5;
  context.font = `${fontSize}px Sans-Serif`;
  const textWidth = context.measureText(name).width;

  const pillDetails = {
    x: textWidth + fontSize * 1.5,
    y: fontSize * 2.5,
  };

  drawRoundedRect({
    context,
    x: node.x - pillDetails.x / 2 - (hasImage ? fontSize * 2 : 0),
    y: node.y - pillDetails.y / 2,
    width: pillDetails.x + (hasImage ? fontSize * 2 : 0),
    height: pillDetails.y,
    radius: 50,
    color: getNodeColor({ node, highlightNodes, nodeOpacity }),
    fill: node.inSubscription,
    expanded: node.expanded,
  });

  drawNodeText({ context, node, textOpacity: nodeOpacity });

  const areaWidth = context.measureText(name).width;
  // eslint-disable-next-line no-param-reassign
  node.width = pillDetails.x + (hasImage ? fontSize * 2 : 0);

  const imageDetails = {
    x: areaWidth + fontSize / 2,
    y: fontSize * 1.5,
  };

  if (hasImage) {
    drawImage({
      context,
      node,
      image: node.image,
      imageDetails,
      fontSize,
      nodeOpacity,
    });
  }
};

export const drawLinks = ({
  centerNodeId,
  context,
  link,
  scale,
  highlightLinks,
  withText = false,
}) => {
  const isHighlighted = highlightLinks.some((hLink) => isSameLink(hLink, link));
  const hasHighlightedElements = highlightLinks.length > 0;

  const LABEL_NODE_MARGIN = 10;

  const { source, target } = link;

  if (typeof source !== 'object' || typeof target !== 'object') return;

  const linkColor = getLinkColor({
    centerNodeId,
    link,
    isHighlighted,
    hasHighlightedElements,
  });
  const textPosition = getLinkMidPoint(link);
  const maxTextLength = LABEL_NODE_MARGIN * 5;
  const textLabel = getLinkName(link);

  const textFontSize = getLinkTextFontSize({
    context,
    text: textLabel,
    maxTextLength,
  });

  const textWidth = getTextWidth({
    context,
    text: textLabel,
    fontSize: textFontSize,
  });

  const textBackgroundDimensions = {
    width: textWidth + textFontSize * 0.2,
    height: textFontSize * 1.2,
  };

  context.strokeStyle = linkColor;
  context.lineWidth = 0.2;
  context.save();
  context.beginPath();
  context.moveTo(source.x, source.y);
  context.lineTo(target.x, target.y);
  context.stroke();
  context.restore();

  if (
    (withText || isHighlighted) &&
    (LINK_OVERRIDE_RULES.includes(link.type) || link.strength > 3)
  ) {
    // Draw link label
    paintLinkText({
      context,
      fontSize: textFontSize,
      position: textPosition,
      dimensions: textBackgroundDimensions,
      color: linkColor,
      label: textLabel,
    });
  }

  if (isHighlighted) {
    paintLinkArrow({
      context,
      link,
      scale,
      color: linkColor,
    });
  }
};

export const zoomToFit = (graph) => {
  if (!graph) return;
  graph.zoomToFit(1000, 100, () => true);
};

const DEFAULT_LINK_FORCE = {
  distance: 0,
};

const DEFAULT_CENTER_FORCE = {
  strength: 1,
};

const DEFAULT_CHANGE_FORCE = {
  strength: -15,
  distanceMin: 20,
  theta: 0.9,
  distanceMax: 100,
};

const DEFAULT_COLLIDE_FORCE = {
  nodeSize: 10,
};
const buildLinkForce = (linkForce) => {
  const { distance } = linkForce;
  const link = forceLink();

  if (distance) {
    link.distance(distance);
  }

  return link;
};

const buildCenterForce = (centerForce) => {
  const { strength } = centerForce;
  const center = forceCenter();
  if (strength != null) {
    center.strength(strength);
  }
  return center;
};

const buildChargeForce = (graph, chargeForce) => {
  const { strength, minDistance } = chargeForce;
  let charge = graph.d3Force('charge');
  if (!charge) {
    graph.d3Force('charge', forceManyBody());
    charge = graph.d3Force('charge');
  }
  if (minDistance != null) {
    charge.distanceMin(minDistance);
  }
  if (strength != null) {
    charge.strength(strength);
  }

  return charge;
};

export const forcesConfigure = (
  graph,
  linkForce = DEFAULT_LINK_FORCE,
  centerForce = DEFAULT_CENTER_FORCE,
  chargeForce = DEFAULT_CHANGE_FORCE,
  collideForce = DEFAULT_COLLIDE_FORCE,
) => {
  graph.d3Force('link', buildLinkForce(linkForce));
  graph.d3Force('center', buildCenterForce(centerForce));
  graph.d3Force(
    'collide',
    forceCollide((node) => node.width * 0.6 || collideForce.nodeSize),
  );
  buildChargeForce(graph, chargeForce);
};

export const stopForces = (graph) => {
  graph.d3Force('link', null);
  graph.d3Force('charge', null);
};
