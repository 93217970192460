import { formatDateMed, sortElementsByRecentDate } from '../date';

export const getEducationDetails = (entity) => {
  if (!entity || !entity.educations || !entity.educations.length) return null;
  return {
    educations: [...entity.educations].sort(sortElementsByRecentDate),
  };
};

export const getDateValue = ({ start, end }) => {
  if (!start && !end) return null;
  if (start && end) {
    return `${formatDateMed(start)} - ${formatDateMed(end)}`;
  }
  if (start) return formatDateMed(start);
  return formatDateMed(end);
};
