import React, { useMemo, memo } from 'react';

import { buildTreeOptions } from '../../../../utils';
import { useAxisData } from '../../../../contexts/AxisDataContext';
import IndustryFilter from '../../../../v2/components/Filters/IndustryFilter/IndustryFilter';

function IndustryFilterWrapper({
  graphFilterIndustries,
  setGraphFilterIndustries,
}) {
  const { briefs } = useAxisData();

  const options = useMemo(() => buildTreeOptions(briefs), [briefs]);

  return (
    <IndustryFilter
      activeIndustryFilters={graphFilterIndustries}
      applyFilters={setGraphFilterIndustries}
      options={options}
    />
  );
}

export default memo(IndustryFilterWrapper);
