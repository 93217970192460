import React, { useState, useEffect } from 'react';
import { login } from '../../../../lib/client';
import Logo from '../../../../icons/Logo';
import { useIdentity } from '../../../../contexts/IdentityContext';
import notify from '../../../../lib/notify';
import { validateEmail } from '../utils';
import Help from '../Help/Help';
import EmailInput from '../EmailInput/EmailInput';
import PasswordInput from '../PasswordInput/PasswordInput';
import Checkbox from '../Checkbox/Checkbox';

import css from './LoginForm.module.css';

const LoginForm = () => {
  const { saveToken, saveUser } = useIdentity();

  const [hasFormErrors, setFormErrors] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isValid, setValid] = useState(true);

  useEffect(() => {
    const validEmail = validateEmail(email);
    const validPassword = password && password.length > 0;
    const validForm = validEmail && validPassword;
    setValid(validForm);
    setFormErrors(false);
  }, [email, password]);

  const onClick = async () => {
    if (!validateEmail(email)) {
      setFormErrors(true);
      notify.error('Email is incorrect');
      return;
    }
    try {
      const response = await login(email, password);
      const { user, token, trialRemainingTime } = response;
      saveToken(token);
      saveUser(user);
      if (trialRemainingTime)
        notify.info(trialRemainingTime, {
          autoClose: false,
          position: 'top-right',
        });
    } catch (err) {
      setFormErrors(true);
      notify.error(err.message);
    }
  };

  return (
    <div className={css.formContainer}>
      <div className={css.header}>
        <div className={css.logoContainer}>
          <Logo />
        </div>
        <Help className={css.headerHelp} />
      </div>
      <div className={css.form}>
        <p className={css.title}>Log In</p>
        <p className={css.subtitle}>Sign in to your Axis workspace</p>
        <EmailInput
          className={css.inputContent}
          value={email}
          hasErrors={hasFormErrors}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordInput
          className={css.inputContent}
          value={password}
          hasErrors={hasFormErrors}
          onChange={(e) => setPassword(e.target.value)}
          onSubmit={onClick}
        />
        <div className={css.inputContent}>
          <Checkbox text="Stay logged in" />
        </div>
        <div className={css.footer}>
          <button
            type="button"
            className={css.button}
            onClick={onClick}
            disabled={!isValid}
            data-cy="login-button"
          >
            Sign in to your account
          </button>
          <Help className={css.footerHelp} />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
