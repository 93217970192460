import React from 'react';

import css from './EventPill.module.css';
import useNavigation from '../../hooks/useNavigation';

function EventPill({ id, title }) {
  const { toggleEventDossier } = useNavigation();

  return (
    <div
      onClick={() => {
        toggleEventDossier(id);
      }}
      className={css.pill}
    >
      {title}
    </div>
  );
}

export default EventPill;
