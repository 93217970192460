import { gql } from '@apollo/client';

const query = gql`
  query listSavedSearchs($searchData: String!) {
    listSavedSearchs(searchData: $searchData) {
      savedSearches {
        id
        search_data
        name
      }
    }
  }
`;

export default query;
