import { gql } from '@apollo/client';

const mutation = gql`
  mutation updateSavedSearch(
    $savedSearchId: ID!
    $updatedName: String!
    $updatedData: String!
  ) {
    updateSavedSearch(
      savedSearchId: $savedSearchId
      updatedName: $updatedName
      updatedData: $updatedData
    )
  }
`;

export default mutation;
