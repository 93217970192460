import {
  formatDateMed,
  formatDateShort,
  sortElementsByRecentDate,
} from '../date';

export const getRoleDetails = (entity) => {
  if (!entity || !entity.roles || !entity.roles.length) return null;
  return {
    roles: [...entity.roles].sort(sortElementsByRecentDate),
  };
};

export const getDateValue = ({ start, end }) => {
  if (!start && !end) return 'Date unknown';
  if (!end) return 'Current';
  if (!start) return `Until ${formatDateMed(end)}`;
  return `${formatDateMed(start)} - ${formatDateMed(end)}`;
};

export const getDateValueForRoles = ({ start, end }) => {
  if (!start && !end) return null;
  if (!end) return 'Current';
  if (!start) return `Until ${formatDateShort(end)}`;
  return `${formatDateShort(start)} - ${formatDateShort(end)}`;
};
