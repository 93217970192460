import React from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import compliantIcon from '../../icons/compliant_icon.svg';
import notCompliantIcon from '../../icons/not_compliant.svg';

const getLabel = ({ showLabel, isCompliant }) => {
  if (!showLabel) return '';
  return isCompliant ? 'Compliant' : 'Comply';
};

const ComplianceButton = ({
  showLabel = true,
  isCompliant,
  onClick,
  className,
  disabled = false,
}) => {
  const label = getLabel({ showLabel, isCompliant });

  return (
    <PrimaryButton
      data-cy="compliant-button"
      data-cy-status={isCompliant ? 'compliant' : 'not-compliant'}
      onClick={onClick}
      label={label}
      icon={
        isCompliant ? (
          <img src={compliantIcon} alt="compliant" />
        ) : (
          <img src={notCompliantIcon} alt="not-compliant" />
        )
      }
      isActive={isCompliant}
      className={className}
      disabled={disabled}
    />
  );
};

export default ComplianceButton;
