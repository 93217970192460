import { useState, useEffect } from 'react';

const MOBILE_BREAKPOINT = 950;

const isIOS = () =>
  (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

const useResponsive = () => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = screenWidth < MOBILE_BREAKPOINT;

  return {
    screenWidth,
    height,
    width,
    isMobile,
    isIOS,
  };
};

export default useResponsive;

export const MobileView = ({ breakpoint = MOBILE_BREAKPOINT, children }) => {
  const { screenWidth } = useResponsive();
  return screenWidth < breakpoint ? children : null;
};

export const DesktopView = ({ breakpoint = MOBILE_BREAKPOINT, children }) => {
  const { screenWidth } = useResponsive();
  return screenWidth >= breakpoint ? children : null;
};
