import { useEffect, useState } from 'react';
import { useApolloClient } from '../../hooks/useApolloClient';
import GET_DOSSIER_REGULATIONS from '../../queries/dossierRegulations';

const PAGE_SIZE = 1;

function useDossierRegulations(id) {
  const client = useApolloClient();
  const [state, setState] = useState({
    pages: {},
    isLoading: true,
    totalRegulations: 0,
    totalPages: 0,
    currentIndex: 0,
    currentPage: 0,
    regulations: [],
  });

  const updatePage = (page, regulations, totalRegulations) => {
    setState((prev) => ({
      ...prev,
      currentPage: page,
      currentIndex: page - 1,
      totalRegulations,
      totalPages: Math.ceil(totalRegulations / PAGE_SIZE),
      isLoading: false,
      pages: { ...prev.pages, [page]: regulations },
      regulations,
    }));
  };

  const fetchPage = async (page, limit) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    try {
      const { data } = await client.query({
        query: GET_DOSSIER_REGULATIONS,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
          id,
          page,
          limit,
        },
      });

      const { regulations, totalRegulations } = data.getDossierRegulations;

      updatePage(page, regulations, totalRegulations);
    } catch (error) {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const onNextPage = () => {
    const nextPage = state.currentPage + 1;
    const cachedPage = state.pages[nextPage];

    if (cachedPage) {
      updatePage(nextPage, cachedPage, state.totalPages);
      return;
    }

    fetchPage(state.currentPage + 1, PAGE_SIZE);
  };

  const onPreviousPage = () => {
    setState((prev) => {
      const newPage = prev.currentPage - 1;

      return {
        ...prev,
        currentPage: newPage,
        currentIndex: newPage - 1,
        regulations: prev.pages[newPage],
      };
    });
  };

  const onComplianceChange = ({ regulationId, isCompliant }) => {
    setState((data) => {
      const updatedPage = data.pages[data.currentPage].map((item) => {
        if (item.id === regulationId) {
          return { ...item, isCompliant };
        }

        return item;
      });

      const newPages = { ...data.pages, [data.currentPage]: updatedPage };
      const newRegulations = newPages[data.currentPage];

      return {
        ...data,
        pages: newPages,
        regulations: newRegulations,
      };
    });
  };

  useEffect(() => {
    fetchPage(state.currentPage + 1, PAGE_SIZE);
  }, []);

  return { ...state, onNextPage, onPreviousPage, onComplianceChange };
}

export default useDossierRegulations;
