import React from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const NoteIcon = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99935 13.6673C1.63268 13.6673 1.3189 13.5369 1.05802 13.276C0.796682 13.0147 0.666016 12.7007 0.666016 12.334V1.66732C0.666016 1.30065 0.796682 0.986651 1.05802 0.725318C1.3189 0.464429 1.63268 0.333984 1.99935 0.333984H7.33268L11.3327 4.33398V12.334C11.3327 12.7007 11.2022 13.0147 10.9413 13.276C10.68 13.5369 10.366 13.6673 9.99935 13.6673H1.99935ZM6.66602 5.00065V1.66732H1.99935V12.334H9.99935V5.00065H6.66602Z"
      fill="#404040"
    />
  </svg>
);

const getLabel = ({ showLabel, notesAvailable }) => {
  if (!showLabel) return '';
  return notesAvailable ? 'Notes' : 'Add Note';
};

const NoteButton = ({
  showLabel = true,
  notesAvailable,
  onClick,
  trailing,
  className,
  dataLocation,
  dataSubtype,
}) => {
  const label = getLabel({ showLabel, notesAvailable });

  return (
    <PrimaryButton
      data-cy="follow-button"
      data-cy-status={notesAvailable ? 'note-available' : 'note-not-available'}
      data-location={dataLocation}
      data-subtype={dataSubtype === 'Person' ? 'person' : 'non-person'}
      onClick={onClick}
      label={label}
      icon={<NoteIcon />}
      trailingIcon={trailing}
      className={className}
    />
  );
};

export default NoteButton;
