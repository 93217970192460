import * as React from 'react';

function SavedSearchs(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.833 14.25a.857.857 0 01-.646-.27.857.857 0 01-.27-.647V4.167c0-.258.083-.46.27-.647a.857.857 0 01.646-.27h13.334c.257 0 .46.083.646.27.187.187.27.389.27.647v9.166c0 .258-.083.46-.27.647a.857.857 0 01-.646.27H3.833zm-1.75 2.5v-.167h16.834v.167H2.083z"
        fill="transparent"
        stroke="currentColor"
        strokeWidth={1.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.375 12.542l.917-.875-1.75-1.75c.152-.236.267-.486.343-.75.077-.264.115-.542.115-.834 0-.82-.288-1.51-.865-2.073-.576-.562-1.26-.843-2.052-.843-.791 0-1.475.28-2.052.843-.576.563-.864 1.254-.864 2.073 0 .82.288 1.51.864 2.073.577.563 1.26.844 2.052.844.292 0 .566-.038.823-.115.257-.076.51-.19.76-.343l1.709 1.75zM8.906 9.51c.327.327.719.49 1.177.49.459 0 .851-.163 1.177-.49.327-.326.49-.718.49-1.177 0-.458-.167-.85-.5-1.177a1.616 1.616 0 00-1.167-.49c-.458 0-.85.164-1.177.49-.326.327-.49.719-.49 1.177 0 .459.164.851.49 1.177z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SavedSearchs;
