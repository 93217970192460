import React, { useState, useRef } from 'react';
import delay from 'lodash/delay';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import useResponsive from '../../hooks/useResponsive';
import css from './Tooltip.module.css';

/**
 * theme: 'dark' | 'ligth'
 */

const Tooltip = ({
  className,
  text,
  children,
  containerClassName,
  theme = 'dark',
}) => {
  const ref = useRef(false);
  const [open, setOpen] = useState(false);

  const { isMobile } = useResponsive();

  const onMouseEnter = () => {
    ref.current = true;
    delay(() => {
      if (ref.current) {
        setOpen(true);
      }
    }, 200);
  };

  const onMouseDown = () => {
    setOpen(false);
    ref.current = false;
  };

  if (isMobile || !text) {
    return children;
  }

  return (
    <Popover
      isOpen={open}
      positions={['bottom', 'top', 'left', 'right']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowSize={10}
          arrowColor={theme === 'dark' ? '#13191f' : '#F9FAFB'}
          arrowClassName={css.arrow}
        >
          <div className={css.text} data-theme={theme} data-cy="tooltidiv">
            {text}
          </div>
        </ArrowContainer>
      )}
      onClickOutside={() => setOpen(false)}
      containerClassName={`${css.tooltip} ${containerClassName}`}
    >
      <div
        data-cy="tooltip-content"
        className={`${css.wrapper} ${className}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseDown}
      >
        {children}
      </div>
    </Popover>
  );
};

export default Tooltip;
