import { gql } from '@apollo/client';

const query = gql`
  query getSuggestions($term: String) {
    getSuggestions(term: $term) {
      entities {
        id
        name
        image
        type
        inSubscription
        isConnected
        topics
        primaryRoleTitle
        primaryRoleEmployerName
      }
    }
  }
`;

export default query;
