import React from 'react';

const More = ({ className = '' }) => (
  <svg
    className={className}
    width="4"
    height="14"
    viewBox="0 0 4 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99992 13.6666C1.54159 13.6666 1.14922 13.5034 0.822835 13.177C0.496446 12.8506 0.333252 12.4583 0.333252 11.9999C0.333252 11.5416 0.496446 11.1492 0.822835 10.8228C1.14922 10.4964 1.54159 10.3333 1.99992 10.3333C2.45825 10.3333 2.85061 10.4964 3.177 10.8228C3.50339 11.1492 3.66659 11.5416 3.66659 11.9999C3.66659 12.4583 3.50339 12.8506 3.177 13.177C2.85061 13.5034 2.45825 13.6666 1.99992 13.6666ZM1.99992 8.66658C1.54159 8.66658 1.14922 8.50339 0.822835 8.177C0.496446 7.85061 0.333252 7.45825 0.333252 6.99992C0.333252 6.54158 0.496446 6.14922 0.822835 5.82284C1.14922 5.49645 1.54159 5.33325 1.99992 5.33325C2.45825 5.33325 2.85061 5.49645 3.177 5.82284C3.50339 6.14922 3.66659 6.54158 3.66659 6.99992C3.66659 7.45825 3.50339 7.85061 3.177 8.177C2.85061 8.50339 2.45825 8.66658 1.99992 8.66658ZM1.99992 3.66659C1.54159 3.66659 1.14922 3.50339 0.822835 3.177C0.496446 2.85061 0.333252 2.45825 0.333252 1.99992C0.333252 1.54159 0.496446 1.14922 0.822835 0.822835C1.14922 0.496446 1.54159 0.333252 1.99992 0.333252C2.45825 0.333252 2.85061 0.496446 3.177 0.822835C3.50339 1.14922 3.66659 1.54159 3.66659 1.99992C3.66659 2.45825 3.50339 2.85061 3.177 3.177C2.85061 3.50339 2.45825 3.66659 1.99992 3.66659Z"
      fill="#656A70"
    />
  </svg>
);

export default More;
