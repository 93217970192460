import { cloneDeep } from 'lodash';
import {
  LABEL_TEXT_COLOR,
  LABEL_TEXT_COLOR_CONNECTED,
  LABEL_TEXT_COLOR_DISABLED,
  NODE_COLORS_BORDER,
  NODE_COLORS_SOFT,
} from './graph.constants';
import connected from './connected.png';
import avatarPlaceholder from '../../../../../assets/avatar-placeholder.png';

export const isValidCoordinate = (coord) => {
  return (coord || coord === 0) && !Number.isNaN(coord);
};

export const isSameNode = (node1, node2) => {
  return node1.id === node2.id;
};

export const hexaOpacity = (percentage) =>
  percentage ? Math.ceil(255 * percentage).toString(16) : 'ff';

export const getNodeColor = ({ node, highlightNodes, nodeOpacity = 1 }) => {
  if (node.labels.includes('User')) {
    return NODE_COLORS_BORDER.User;
  }
  const label = node.isConnected ? 'Connected' : node.labels[0];
  const isNodeHighlighted = highlightNodes.some((n) => isSameNode(n, node));
  const { inSubscription } = node;
  if (!inSubscription) return NODE_COLORS_BORDER[label];
  if (!isNodeHighlighted) {
    return NODE_COLORS_SOFT[label] + hexaOpacity(nodeOpacity);
  }
  return NODE_COLORS_SOFT[label];
};

export const addNodeDOMImage = (data) => {
  const cloneData = cloneDeep(data);
  if (!cloneData) return { nodes: [], links: [] };
  cloneData.nodes = data.nodes.map((node) => {
    const cloneNode = cloneDeep(node);
    let image = cloneNode.isConnected ? connected : `${cloneNode.image}?axis`;

    if (!node.image || node.image === 'null') {
      image = avatarPlaceholder;
    }

    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = image;
    img.onerror = () => {
      img.src = avatarPlaceholder;
    };
    cloneNode.image = img;
    return cloneNode;
  });
  return cloneData;
};

export const addNodeNeighbors = (data) => {
  const cloneData = cloneDeep(data);
  if (!cloneData) return { nodes: [], links: [] };
  cloneData.links.forEach((link) => {
    const a = cloneData.nodes.find((node) => node.id === link.source);
    const b = cloneData.nodes.find((node) => node.id === link.target);
    if (!a && !b) return;
    !a.neighbors && (a.neighbors = []);
    !b.neighbors && (b.neighbors = []);
    a.neighbors.push(b);
    b.neighbors.push(a);
    !a.links && (a.links = []);
    !b.links && (b.links = []);
    a.links.push(link);
    b.links.push(link);
  });
  return cloneData;
};
export const drawRoundedRect = ({
  context,
  x,
  y,
  width,
  height,
  radius,
  color,
  fill,
  expanded,
}) => {
  let minRadius = radius;
  if (width < 2 * radius) minRadius = width / 2;
  if (height < 2 * radius) minRadius = height / 2;
  context.save();
  context.beginPath();
  context.moveTo(x + minRadius, y);
  context.arcTo(x + width, y, x + width, y + height, minRadius);
  context.arcTo(x + width, y + height, x, y + height, minRadius);
  context.arcTo(x, y + height, x, y, minRadius);
  context.arcTo(x, y, x + width, y, minRadius);
  context.closePath();
  if (fill) {
    if (expanded) {
      context.strokeStyle = '#6750A4';
      context.lineWidth = 5;
      context.stroke();
    }
    context.fillStyle = color;
    context.fill();
  } else {
    context.strokeStyle = color;
    context.lineWidth = 3;
    context.stroke();
    context.fillStyle = '#ffffff';
    context.fill();
  }
  context.restore();
};

export const drawImage = ({
  context,
  node,
  nodeOpacity,
  image,
  imageDetails,
  fontSize,
}) => {
  const imageRadius = imageDetails.y / 2;
  context.save();
  context.beginPath();
  context.globalAlpha = nodeOpacity || 1;
  context.arc(
    node.x + imageRadius - imageDetails.x / 2 - fontSize * 2,
    node.y,
    imageRadius,
    0,
    2 * Math.PI,
  );
  context.fill();
  context.closePath();
  context.clip();
  context.drawImage(
    image,
    node.x - imageDetails.x / 2 - fontSize * 2,
    node.y - imageRadius,
    imageDetails.y,
    imageDetails.y,
  );
  context.restore();
};

export const drawNodeText = ({ context, node, textOpacity = 1 }) => {
  context.save();
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  if (!node.inSubscription) {
    context.fillStyle = LABEL_TEXT_COLOR_DISABLED;
  } else {
    context.fillStyle =
      (node.isConnected || node.labels.includes('User')
        ? LABEL_TEXT_COLOR_CONNECTED
        : LABEL_TEXT_COLOR) + hexaOpacity(textOpacity);
  }
  context.fillText(node.name, node.x, node.y);
  context.restore();
};

export const setDataDepth = (data, expandedFrom, depth = 0) => {
  const cloneData = cloneDeep(data);
  if (!cloneData) return { nodes: [], links: [] };
  cloneData.nodes.forEach((node) => {
    if (node.depth === undefined || node.depth === null) {
      node.depth = depth;
      node.expandedFrom = expandedFrom;
    }
    return node;
  });
  cloneData.links.forEach((link) => {
    if (link.depth === undefined || link.depth === null) {
      link.depth = depth;
      link.expandedFrom = expandedFrom;
    }
    return link;
  });
  return cloneData;
};
