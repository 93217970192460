/* eslint-disable */
/**
 * @param {{id, name, children: []}} options
 * Create a tree structure options
 */
export const buildTreeOptions = (options) => {
  if (!options) return [];

  return options.map((item) => {
    return {
      id: item.id,
      name: item.name,
      children: buildTreeOptions(item.children),
    };
  });
};

/**
 * @param {[{id, name, children: []}]} options
 * @param {string} query
 * Filter optios that match query and keep hierachy
 */
export const filterInTree = (options, query) => {
  if (options.length === 0) return [];
  if (query === '') {
    // Reset visibility flag for all levels if query is empty.
    const resetVisibility = (node) => {
      node.visible = true;
      if (node.children) {
        node.children.forEach((child) => resetVisibility(child));
      }
    };
    options.forEach((option) => resetVisibility(option));
    return options;
  }

  // Define a recursive function to update visibility based on the query.
  function updateVisibility(tree) {
    if (!tree.children || tree.children.length === 0) {
      // If the node has no children, check if its name includes the query text.
      return {
        ...tree,
        visible: tree.name.toLowerCase().includes(query.toLowerCase()),
      };
    }

    // Recursively update visibility for children.
    const updatedChildren = tree.children.map((child) =>
      updateVisibility(child),
    );

    // Check if any child is visible, then mark parent as visible.
    const anyChildVisible =
      tree.name.toLowerCase().includes(query.toLowerCase()) ||
      updatedChildren.some((child) => child.visible);

    return {
      ...tree,
      visible: anyChildVisible,
      children: updatedChildren,
    };
  }

  // Start updating visibility from the root of the options tree.
  const updatedOptions = options.map((option) => updateVisibility(option));

  return updatedOptions;
};

/**
 * @param {{ type }} industries
 * Filter industries tags
 */
export const filterIndustries = (industries) => {
  if (!industries || industries.length === 0) return [];

  const industryList = industries.filter(({ type }) => type === 'industry');
  if (industryList.length) return industryList;
  return industries;
};

export function flattenTree(tree) {
  const flattened = [];

  function traverse(node) {
    flattened.push(node);

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        traverse(node.children[i]);
      }
    }
  }

  for (let i = 0; i < tree.length; i++) {
    traverse(tree[i]);
  }

  return flattened;
}
