import React, { useMemo } from 'react';
import Source from '../Source/Source';
import EntityPill from '../EntityPill/EntityPill';
import BriefPill from '../BriefPill/BriefPill';
import css from './EventCard.module.css';
import NoteButton from '../Note/NoteButton';
import { CountIndicator } from '../CountIndicator/CountIndicator';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import { filterIndustries } from '../../utils';
import useNavigation from '../../v2/hooks/useNavigation';
import Summary from '../../v2/components/Summary/Summary';
import Score from '../../v2/components/Score/Score';
import AxisUserGuard from '../../v2/components/AxisUserGuard/AxisUserGuard';
import { calcImportanceScore } from '../../v2/shared/events';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const EventCard = ({
  id,
  title = '',
  pillSize = 'medium',
  date,
  sources,
  notesCount = 0,
  entities = [],
  briefs = [],
  landscapes = [],
  countries = [],
  onEntityClick,
  dataLocation,
  dossierEntityName,
  summary,
  bullets,
  sentiment,
  magnitude,
  language,
  quotes = [],
}) => {
  const router = useRouteInformation();
  const { toggleEventDossier } = useNavigation();

  const onAddNoteClick = () => {
    const event = {
      id,
      title,
      sources,
      entities,
      briefs,
      landscapes,
      countries,
      dataLocation,
      dossierEntityId: router.query.get('entityId'),
      dossierEntityName,
    };
    router.openDossier({ event, dossierType: 'event' });
  };

  const onTitleClick = () => {
    toggleEventDossier(id);
  };

  const onQuoteClick = () => {
    toggleEventDossier(id, true);
  };

  const filteredBriefs = filterIndustries(briefs);

  const score = useMemo(
    () => calcImportanceScore(magnitude, sentiment),
    [sentiment, magnitude],
  );

  return (
    <div className={css.card} data-cy="event-card" data-cy-facet="event">
      <div className={css.content}>
        <div data-cy="event-title" className={css.title} onClick={onTitleClick}>
          {title}
        </div>

        <div className={css.secondary}>
          {date && (
            <p data-cy="event-date" className={css.date}>
              {date}
            </p>
          )}

          <Score score={score} data-cy="event-score" />
        </div>
        <div className={css.entities}>
          {entities.map((entity) => (
            <EntityPill
              size={pillSize}
              key={entity.entityId || entity.id}
              name={entity.name}
              shortName={entity.shortestName}
              image={entity.image}
              type={entity.type}
              onClick={() => onEntityClick(entity.entityId || entity.id)}
              inSubscription={entity.inSubscription}
              isConnected={entity.isConnected}
              tooltip={entity.tooltip}
            />
          ))}
        </div>
        <div className={css.briefs}>
          {filteredBriefs.map((entity) => (
            <BriefPill
              key={entity.id}
              name={entity.name}
              tooltip={entity.tooltip}
            />
          ))}
          {countries.map((entity) => (
            <BriefPill key={entity.id} name={entity.name} isGeography />
          ))}
          {landscapes.map((entity) => (
            <BriefPill key={entity.id} name={entity.name} />
          ))}
        </div>

        <Summary summary={summary} bullets={bullets} />

        {sources && sources.length > 0 && (
          <p data-cy="source-list" className={css.source}>
            {sources.map((source) => (
              <Source key={source} source={source} />
            ))}
          </p>
        )}

        <AxisUserGuard>
          {!!language && language !== 'en' && (
            <div className={css.neSource}>Language - {language}</div>
          )}
        </AxisUserGuard>
      </div>
      <div className={css.action}>
        <NoteButton
          className={css.note}
          dataLocation={dataLocation}
          notesAvailable={notesCount}
          onClick={onAddNoteClick}
          trailing={notesCount ? <CountIndicator count={notesCount} /> : null}
        />
        {quotes.length > 0 && (
          <PrimaryButton
            data-cy="quote-button"
            data-cy-status={
              quotes.length > 0 ? 'quote-available' : 'quote-not-available'
            }
            data-location={dataLocation}
            onClick={onQuoteClick}
            label="Quotes"
            trailingIcon={<CountIndicator count={quotes.length} />}
            className={css.quote}
          />
        )}
      </div>
    </div>
  );
};

export default EventCard;
