import React from 'react';
import css from './Help.module.css';

const Help = ({ className = '' }) => (
  <p className={`${css.help} ${className}`}>
    Questions or problems?{' '}
    <a className={css.link} href="mailto:tech@axis.xyz">
      Get in touch
    </a>
  </p>
);

export default Help;
