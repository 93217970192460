import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApolloClient } from '../hooks/useApolloClient';
import GET_NOTIFICATIONS from '../queries/getNotificationFeed';
import notify from '../lib/notify';
import MARK_NOTIFICATION_AS_READ from '../queries/markNotificationAsRead';

const POOL_INTERVAL = 5 * 60 * 1000; // 5 minutes

const Context = createContext();

function sortNotificationsByDate(notifications) {
  return notifications.sort((a, b) => {
    const dateA = new Date(a.timestamp);
    const dateB = new Date(b.timestamp);

    // Sort by most recent date first (descending)
    return dateB - dateA;
  });
}

export const NotificationContext = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(true);
  const { query, mutate } = useApolloClient();

  const fetchNotifications = useCallback(async () => {
    try {
      const { data } = await query({ query: GET_NOTIFICATIONS });
      const updates = data.getNotificationFeed.updates.map((notification) => ({
        ...notification,
        read: false,
      }));
      setNotifications(sortNotificationsByDate(updates));
    } catch (error) {
      notify.error(error);
    } finally {
      setIsLoadingNotifications(false);
    }
  }, []);

  const markNotificationAsRead = useCallback(async (id) => {
    try {
      await mutate({
        mutation: MARK_NOTIFICATION_AS_READ,
        variables: {
          notificationFeedId: id,
        },
      });

      setNotifications((current) =>
        current.map((notification) => {
          if (notification.id === id) {
            return { ...notification, read: true };
          }
          return notification;
        }),
      );
    } catch (error) {
      notify.error(error);
    } finally {
      setIsLoadingNotifications(false);
    }
  }, []);

  const markAllNotificationsAsRead = useCallback(async (ids) => {
    try {
      await Promise.all(
        ids.map((id) =>
          mutate({
            mutation: MARK_NOTIFICATION_AS_READ,
            variables: {
              notificationFeedId: id,
            },
          }),
        ),
      );

      setNotifications((current) =>
        current.map((notification) => {
          if (ids.includes(notification.id)) {
            return { ...notification, read: true };
          }
          return notification;
        }),
      );
    } catch (error) {
      notify.error(error);
    } finally {
      setIsLoadingNotifications(false);
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, POOL_INTERVAL);
    return () => clearInterval(intervalId);
  }, [fetchNotifications]);

  const unreadNotificationsCount = useMemo(() => {
    return notifications.filter(({ read }) => !read).length;
  });

  return (
    <Context.Provider
      value={{
        notifications,
        setNotifications,
        markNotificationAsRead,
        markAllNotificationsAsRead,
        unreadNotificationsCount,
        isLoadingNotifications,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useNotificationContext = () => useContext(Context);
