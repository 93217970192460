import { gql } from '@apollo/client';

const query = gql`
  query listSavedSearchs(
    $name: String
    $graphEnabled: Boolean
    $feedEnabled: Boolean
    $limit: Int
    $page: Int
  ) {
    listSavedSearchs(
      name: $name
      graphEnabled: $graphEnabled
      feedEnabled: $feedEnabled
      limit: $limit
      page: $page
    ) {
      savedSearches {
        id
        name
        search_data
        user_id
      }
    }
  }
`;

export default query;
