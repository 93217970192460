import { gql } from '@apollo/client';

const query = gql`
  query getUserPdb {
    getUserPdb {
      pdb {
        id
        background
        industries {
          id
          name
        }
        countries {
          id
          name
        }
        interests
        bulletin {
          summary
          shortSummary
          events {
            id
            sources
            title
            date
            entities {
              id
              name
              image
              type
              isConnected
              inSubscription
              shortestName
              tooltip
            }
            industries {
              id
              name
              tooltip
              type
            }
            countries {
              id
              name
            }
            summary
            notesCount
            sentiment
            magnitude
            language
            quotes {
              id
              quote
              context
              date
              industries {
                id
                name
              }
              countries {
                id
                name
              }
              speaker {
                id
                name
                image
                type
                inSubscription
                isConnected
                shortestName
                tooltip
              }
            }
            bullets
          }
        }
      }
    }
  }
`;

export default query;
