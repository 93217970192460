/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Loading } from '@axis/xyz.app.loading';
import css from './AddNoteModal.module.css';
import CloseIcon from '../../icons/CloseIcon/CloseIcon';
import notify from '../../lib/notify';
import { useApolloClient } from '../../hooks/useApolloClient';
import createNote from '../../queries/createNote';
import { useAxisData } from '../../contexts/AxisDataContext';

const AddNoteModal = ({ openModal, targetId, handleModalClose, noteType }) => {
  Modal.setAppElement('#root');
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState({
    targetId,
    noteType,
  });

  const { refetchUserTotalNotes } = useAxisData();

  const handleNoteChange = (event) => {
    event.stopPropagation();
    setNote({
      ...note,
      note: event.target.value,
    });
  };

  const handleClose = (cancelled, status, message) => {
    setLoading(false);
    if (!cancelled) {
      if (status === 'error') {
        notify.error(message, { position: 'top-right' });
      } else {
        notify.success('Note added successfully', { position: 'top-right' });
      }
    }
    handleModalClose(status);
  };

  const handleSaveNote = () => {
    setLoading(true);
    if (!note.note || note.note.trim() === '') {
      return handleClose(false, 'error', 'Note cannot be empty');
    }
    return client
      .mutate({
        mutation: createNote,
        variables: note,
      })
      .then(({ errors, data }) => {
        if (errors || !data || !data.createNote) {
          return handleClose(false, 'error', errors[0].message);
        }
        refetchUserTotalNotes();
        return handleClose(false, 'success');
      })
      .catch(() => {
        return handleClose(false, 'error');
      });
  };

  return (
    <Modal
      isOpen={openModal}
      contentLabel="Add Connection"
      style={{
        content: {
          backgroundColor: 'white',
          width: '400px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0px',
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 20000,
        },
      }}
      on
      shouldCloseOnOverlayClick
      onRequestClose={() => handleClose(true)}
    >
      <div className={css.modal} onClick={(event) => event.stopPropagation()}>
        <div className={css.header}>
          <h1 className={css.title}>Add Note</h1>
          <button
            type="button"
            className={css.close}
            onClick={() => handleClose(true)}
          >
            <CloseIcon className={css.closeIcon} />
          </button>
        </div>
        <div className={css.modalBody}>
          {loading ? (
            <div className={css.spinner}>
              <Loading />
            </div>
          ) : (
            <div className={css.bodyItems}>
              <textarea
                className={css.note}
                type="text-area"
                placeholder={`Add a note about this ${noteType}`}
                onChange={handleNoteChange}
                value={note.note || ''}
              />
            </div>
          )}
        </div>

        <div className={css.modalFooter}>
          <button
            className={css.cancelButton}
            type="button"
            onClick={() => handleClose(true)}
          >
            Cancel
          </button>
          <button
            data-status={loading ? 'loading' : 'idle'}
            disabled={loading}
            className={css.saveButton}
            type="button"
            onClick={handleSaveNote}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNoteModal;
