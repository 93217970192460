import React from 'react';

const FilterIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 18 18" fill="none">
    <path
      d="M8 18V12H10V14H18V16H10V18H8ZM0 16V14H6V16H0ZM4 12V10H0V8H4V6H6V12H4ZM8 10V8H18V10H8ZM12 6V0H14V2H18V4H14V6H12ZM0 4V2H10V4H0Z"
      fill="#404040"
    />
  </svg>
);

export default FilterIcon;
