import { gql } from '@apollo/client';

const query = gql`
  query autoCompleteQuery(
    $term: String
    $symbol: String
    $topics: [ID]
    $entities: [ID]
    $limit: Int
  ) {
    autoCompleteQuery(
      term: $term
      symbol: $symbol
      topics: $topics
      entities: $entities
      limit: $limit
    ) {
      entities {
        id
        name
        shortestName
        entityType
        topicId
        image
      }
      landscapes {
        id
        name
      }
    }
  }
`;

export default query;
