import React from 'react';

function Badge() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2908_18528"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2908_18528)">
        <path
          d="M2.66536 14.6666C2.2987 14.6666 1.98481 14.536 1.7237 14.2749C1.46259 14.0138 1.33203 13.6999 1.33203 13.3333V5.99992C1.33203 5.63325 1.46259 5.31936 1.7237 5.05825C1.98481 4.79714 2.2987 4.66658 2.66536 4.66658H5.9987V2.66659C5.9987 2.29992 6.12925 1.98603 6.39036 1.72492C6.65148 1.46381 6.96536 1.33325 7.33203 1.33325H8.66536C9.03203 1.33325 9.34592 1.46381 9.60703 1.72492C9.86814 1.98603 9.9987 2.29992 9.9987 2.66659V4.66658H13.332C13.6987 4.66658 14.0126 4.79714 14.2737 5.05825C14.5348 5.31936 14.6654 5.63325 14.6654 5.99992V13.3333C14.6654 13.6999 14.5348 14.0138 14.2737 14.2749C14.0126 14.536 13.6987 14.6666 13.332 14.6666H2.66536ZM2.66536 13.3333H13.332V5.99992H9.9987C9.9987 6.36658 9.86814 6.68047 9.60703 6.94159C9.34592 7.2027 9.03203 7.33325 8.66536 7.33325H7.33203C6.96536 7.33325 6.65148 7.2027 6.39036 6.94159C6.12925 6.68047 5.9987 6.36658 5.9987 5.99992H2.66536V13.3333ZM3.9987 11.9999H7.9987V11.6999C7.9987 11.511 7.94592 11.336 7.84036 11.1749C7.73481 11.0138 7.58759 10.8888 7.3987 10.7999C7.17648 10.6999 6.95148 10.6249 6.7237 10.5749C6.49592 10.5249 6.25425 10.4999 5.9987 10.4999C5.74314 10.4999 5.50148 10.5249 5.2737 10.5749C5.04592 10.6249 4.82092 10.6999 4.5987 10.7999C4.40981 10.8888 4.26259 11.0138 4.15703 11.1749C4.05148 11.336 3.9987 11.511 3.9987 11.6999V11.9999ZM9.33203 10.9999H11.9987V9.99992H9.33203V10.9999ZM5.9987 9.99992C6.27648 9.99992 6.51259 9.9027 6.70703 9.70825C6.90148 9.51381 6.9987 9.2777 6.9987 8.99992C6.9987 8.72214 6.90148 8.48603 6.70703 8.29158C6.51259 8.09714 6.27648 7.99992 5.9987 7.99992C5.72092 7.99992 5.48481 8.09714 5.29036 8.29158C5.09592 8.48603 4.9987 8.72214 4.9987 8.99992C4.9987 9.2777 5.09592 9.51381 5.29036 9.70825C5.48481 9.9027 5.72092 9.99992 5.9987 9.99992ZM9.33203 8.99992H11.9987V7.99992H9.33203V8.99992ZM7.33203 5.99992H8.66536V2.66659H7.33203V5.99992Z"
          fill="#42474D"
        />
      </g>
    </svg>
  );
}

export default Badge;
