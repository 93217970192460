import { useEffect, useState } from 'react';
import GET_DOSSIER_QUOTES from '../../queries/dossierQuotes';
import { useApolloClient } from '../../hooks/useApolloClient';

const PAGE_SIZE = 1;

function useDossierQuotes(id) {
  const client = useApolloClient();
  const [state, setState] = useState({
    pages: {},
    isLoading: true,
    totalQuotes: 0,
    totalPages: 0,
    currentIndex: 0,
    currentPage: 0,
    quotes: [],
  });

  const fetchPage = async (page, limit) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    try {
      const { data } = await client.query({
        query: GET_DOSSIER_QUOTES,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        variables: {
          id,
          page,
          limit,
        },
      });

      const { quotes, totalQuotes } = data.getDossierQuotes;

      setState((prev) => ({
        ...prev,
        currentPage: page,
        currentIndex: page - 1,
        totalQuotes,
        totalPages: Math.ceil(totalQuotes / PAGE_SIZE),
        isLoading: false,
        pages: { ...prev.pages, [page]: quotes },
        quotes,
      }));
    } catch (error) {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const onNextPage = () => {
    fetchPage(state.currentPage + 1, PAGE_SIZE);
  };

  const onPreviousPage = () => {
    setState((prev) => {
      const newPage = prev.currentPage - 1;

      return {
        ...prev,
        currentPage: newPage,
        currentIndex: newPage - 1,
        quotes: prev.pages[newPage],
      };
    });
  };

  useEffect(() => {
    fetchPage(state.currentPage + 1, PAGE_SIZE);
  }, []);

  return { ...state, onNextPage, onPreviousPage };
}

export default useDossierQuotes;
