import { gql } from '@apollo/client';

const query = gql`
  query getNotificationFeed {
    getNotificationFeed {
      updates {
        id
        title
        description
        type
        timestamp
        timestamp
        query {
          entities {
            id
            name
            image
            type
            isConnected
            inSubscription
          }
          startDate
          endDate
          target
          searchId
          searchTerm
          note {
            id
            note
          }
          industryIds
          topicIds
        }
      }
    }
  }
`;

export default query;
