import React from 'react';
import Card from '../../Card/Card';

import css from './BookmarkCard.module.css';
import EntityPill from '../../../../../components/EntityPill/EntityPill';
import IconButton from '../../../IconButton/IconButton';
import RemoveBooknark from '../../../../../icons/RemoveBookmark';
import useNavigation from '../../../../hooks/useNavigation';

function BookmarkCard({ entity, onRemoveBookmark }) {
  const { params, toggleEntityDossier } = useNavigation();

  return (
    <Card
      selected={params.get('dossierEntityId') === entity.id}
      onClick={() => toggleEntityDossier(entity.id)}
    >
      <div className={css.header}>
        <EntityPill
          id={entity.id}
          type={entity.type}
          name={entity.name}
          image={entity.image}
          autoOpenDossier
          size="small"
          shortName={entity.shortestName}
          isConnected={entity.isConnected}
          inSubscription={entity.inSubscription}
        />
        <IconButton
          size="medium"
          onClick={(e) => {
            e.stopPropagation();
            onRemoveBookmark(entity);
          }}
        >
          <RemoveBooknark />
        </IconButton>
      </div>

      {entity.primaryRoleTitle && (
        <div className={css.roleTitle}>{entity.primaryRoleTitle}</div>
      )}
    </Card>
  );
}

export default BookmarkCard;
