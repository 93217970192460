import React from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const ConnectedIcon = () => (
  <svg
    height={18}
    width={18}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_210_5291"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_210_5291)">
      <path
        d="M2.00003 12.7563V11.049C2.00003 10.7136 2.08503 10.3986 2.25503 10.1038C2.42503 9.80913 2.66003 9.58555 2.96003 9.43311C3.47003 9.16888 4.04503 8.94531 4.68503 8.76238C5.32503 8.57945 6.03003 8.48799 6.80003 8.48799C7.10003 8.48799 7.39253 8.50323 7.67753 8.53372C7.96253 8.56421 8.24003 8.60994 8.51003 8.67091L7.46003 9.73799C7.35003 9.71766 7.24253 9.7075 7.13753 9.7075H6.80003C6.09003 9.7075 5.45253 9.79388 4.88753 9.96665C4.32253 10.1394 3.86003 10.3274 3.50003 10.5307C3.41003 10.5815 3.33753 10.6526 3.28253 10.7441C3.22753 10.8355 3.20003 10.9372 3.20003 11.049V11.5368H6.95003L8.15003 12.7563H2.00003ZM10.13 13.0002L8.06003 10.8965L8.90003 10.0429L10.13 11.2929L13.16 8.2136L14 9.06726L10.13 13.0002ZM6.80003 7.87823C6.14003 7.87823 5.57503 7.63941 5.10503 7.16177C4.63503 6.68413 4.40003 6.10994 4.40003 5.43921C4.40003 4.76848 4.63503 4.19429 5.10503 3.71665C5.57503 3.239 6.14003 3.00018 6.80003 3.00018C7.46003 3.00018 8.02503 3.239 8.49503 3.71665C8.96503 4.19429 9.20003 4.76848 9.20003 5.43921C9.20003 6.10994 8.96503 6.68413 8.49503 7.16177C8.02503 7.63941 7.46003 7.87823 6.80003 7.87823ZM6.80003 6.65872C7.13003 6.65872 7.41253 6.53931 7.64753 6.30049C7.88253 6.06167 8.00003 5.77457 8.00003 5.43921C8.00003 5.10384 7.88253 4.81675 7.64753 4.57793C7.41253 4.33911 7.13003 4.2197 6.80003 4.2197C6.47003 4.2197 6.18753 4.33911 5.95253 4.57793C5.71753 4.81675 5.60003 5.10384 5.60003 5.43921C5.60003 5.77457 5.71753 6.06167 5.95253 6.30049C6.18753 6.53931 6.47003 6.65872 6.80003 6.65872Z"
        fill="#027A48"
      />
    </g>
  </svg>
);

const NotConnectedIcon = () => (
  <svg
    height={18}
    width={18}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_210_5291"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_210_5291)">
      <path
        d="M2.00003 12.7563V11.049C2.00003 10.7136 2.08503 10.3986 2.25503 10.1038C2.42503 9.80913 2.66003 9.58555 2.96003 9.43311C3.47003 9.16888 4.04503 8.94531 4.68503 8.76238C5.32503 8.57945 6.03003 8.48799 6.80003 8.48799C7.10003 8.48799 7.39253 8.50323 7.67753 8.53372C7.96253 8.56421 8.24003 8.60994 8.51003 8.67091L7.46003 9.73799C7.35003 9.71766 7.24253 9.7075 7.13753 9.7075H6.80003C6.09003 9.7075 5.45253 9.79388 4.88753 9.96665C4.32253 10.1394 3.86003 10.3274 3.50003 10.5307C3.41003 10.5815 3.33753 10.6526 3.28253 10.7441C3.22753 10.8355 3.20003 10.9372 3.20003 11.049V11.5368H6.95003L8.15003 12.7563H2.00003ZM10.13 13.0002L8.06003 10.8965L8.90003 10.0429L10.13 11.2929L13.16 8.2136L14 9.06726L10.13 13.0002ZM6.80003 7.87823C6.14003 7.87823 5.57503 7.63941 5.10503 7.16177C4.63503 6.68413 4.40003 6.10994 4.40003 5.43921C4.40003 4.76848 4.63503 4.19429 5.10503 3.71665C5.57503 3.239 6.14003 3.00018 6.80003 3.00018C7.46003 3.00018 8.02503 3.239 8.49503 3.71665C8.96503 4.19429 9.20003 4.76848 9.20003 5.43921C9.20003 6.10994 8.96503 6.68413 8.49503 7.16177C8.02503 7.63941 7.46003 7.87823 6.80003 7.87823ZM6.80003 6.65872C7.13003 6.65872 7.41253 6.53931 7.64753 6.30049C7.88253 6.06167 8.00003 5.77457 8.00003 5.43921C8.00003 5.10384 7.88253 4.81675 7.64753 4.57793C7.41253 4.33911 7.13003 4.2197 6.80003 4.2197C6.47003 4.2197 6.18753 4.33911 5.95253 4.57793C5.71753 4.81675 5.60003 5.10384 5.60003 5.43921C5.60003 5.77457 5.71753 6.06167 5.95253 6.30049C6.18753 6.53931 6.47003 6.65872 6.80003 6.65872Z"
        fill="#383838"
      />
    </g>
  </svg>
);

const getLabel = ({ showLabel, isConnected }) => {
  if (!showLabel) return '';
  return isConnected ? 'Connected' : 'Add Connection';
};

const ConnectionButton = ({
  showLabel = true,
  isConnected,
  onClick,
  className,
  disabled = false,
}) => {
  const label = getLabel({ showLabel, isConnected });

  return (
    <PrimaryButton
      data-cy="follow-button"
      data-cy-status={isConnected ? 'connected' : 'not-connected'}
      onClick={onClick}
      label={label}
      icon={isConnected ? <ConnectedIcon /> : <NotConnectedIcon />}
      isActive={isConnected}
      className={className}
      disabled={disabled}
    />
  );
};

export default ConnectionButton;
