import React from 'react';

const Search = ({ className = '' }) => (
  <svg className={className} viewBox="0 0 34 34" fill="none">
    <path
      d="M24.7438 13.0991C24.7458 15.4373 24.0512 17.7236 22.7479 19.6687C21.4446 21.6138 19.5912 23.1304 17.4221 24.0265C15.2529 24.9227 12.8656 25.1582 10.562 24.7032C8.25843 24.2483 6.14212 23.1233 4.4808 21.4707C2.81948 19.818 1.68774 17.7119 1.22885 15.4188C0.769956 13.1256 1.0045 10.7485 1.90276 8.58813C2.80102 6.42772 4.32266 4.5811 6.2752 3.28185C8.22774 1.98261 10.5234 1.28913 12.8719 1.28913C14.4316 1.28385 15.9769 1.58565 17.419 2.17717C18.8611 2.76868 20.1715 3.63824 21.2749 4.73582C22.3782 5.8334 23.2527 7.13734 23.8481 8.57264C24.4434 10.0079 24.7478 11.5463 24.7438 13.0991V13.0991Z"
      stroke="black"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M21.7002 22.0292L32.9996 33.2792"
      stroke="black"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
  </svg>
);

export default Search;
