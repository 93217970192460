import React from 'react';
import css from './Tabs.module.css';

const Tab = ({ tab, isActive, onClick }) => {
  return (
    <div
      data-cy="dossier-tab"
      className={css.tab}
      data-status={isActive ? 'active' : 'inactive'}
      onClick={onClick}
    >
      <p className={css.tabName}>{tab.toLowerCase()}</p>
    </div>
  );
};

const Tabs = ({ tabs, active, setActive }) => {
  return (
    <div className={css.tabs} data-cy="dossier-tabs">
      {tabs.map((tab) => (
        <Tab
          key={tab}
          tab={tab}
          isActive={active === tab}
          onClick={() => setActive(tab)}
        />
      ))}
    </div>
  );
};

export default Tabs;
