import React from 'react';

import css from './NotificationCount.module.css';
import { useNotificationContext } from '../../../../contexts/NotificationContext';

function NotificationCount() {
  const { unreadNotificationsCount } = useNotificationContext();

  if (unreadNotificationsCount === 0) return null;

  return <div className={css.badge}>{unreadNotificationsCount}</div>;
}

export default NotificationCount;
