import React, { useState } from 'react';

import css from './RegulationCard.module.css';
import { BaseCard } from '../BaseCard/BaseCard';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import useNavigation from '../../../hooks/useNavigation';
import NotesOutline from '../../../../icons/NotesOutline';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import { useModal } from '../../../../contexts/ModalContext';
import CompliantButton from './ComplyButton';

function RegulationCard({ regulation }) {
  const { toggleRegulationDossier } = useNavigation();
  const [localCompliant, setLocalCompliant] = useState(regulation.isCompliant);
  const { setModalData, resetModalData } = useModal();
  const router = useRouteInformation();

  const openRegulationDossier = () => {
    toggleRegulationDossier(regulation.id);
  };

  const onAddNoteClick = () => {
    const data = {
      ...regulation,
      summarizedName: regulation.title || regulation.summarizedName,
      dossierEntityId: router.query.get('entityId'),
    };
    router.openDossier({ regulation: data, dossierType: 'regulation' });
  };

  const handleComply = () => {
    setModalData({
      modalType: 'COMPLIANCE',
      openModal: true,
      regulationId: regulation.id,
      isCompliant: localCompliant,
      handleModalClose: ({ flag }) => {
        resetModalData();
        setLocalCompliant(flag);
      },
    });
  };

  return (
    <BaseCard.Root>
      <BaseCard.Row>
        <BaseCard.LeftColumn>
          <BaseCard.Title className={css.title} onClick={openRegulationDossier}>
            {regulation.title || regulation.summarizedName}
          </BaseCard.Title>

          <BaseCard.Summary
            summary={regulation.summary}
            bullets={regulation.bullets}
          />
        </BaseCard.LeftColumn>

        <BaseCard.RightColumn>
          <BaseCard.InfoColumn title="Type">
            {regulation.regulationType}
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Legal Name">
            {regulation.legalName}
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Issued By">
            <EntityPill
              id={regulation.issuer.id}
              image={regulation.issuer.image}
              name={regulation.issuer.name}
              shortName={regulation.issuer.shortestName}
              tooltip={regulation.issuer.tooltip}
              type={regulation.issuer.type}
              isConnected={regulation.issuer.isConnected}
              inSubscription={regulation.issuer.inSubscription}
              autoOpenDossier
              size="small"
            />
          </BaseCard.InfoColumn>

          {regulation.authorizedIssuer?.id && (
            <BaseCard.InfoColumn title="Relevant Individual">
              <EntityPill
                id={regulation.authorizedIssuer.id}
                image={regulation.authorizedIssuer.image}
                name={regulation.authorizedIssuer.name}
                shortName={regulation.authorizedIssuer.shortestName}
                tooltip={regulation.authorizedIssuer.tooltip}
                type={regulation.authorizedIssuer.type}
                isConnected={regulation.authorizedIssuer.isConnected}
                inSubscription={regulation.authorizedIssuer.inSubscription}
                autoOpenDossier
                size="small"
              />
            </BaseCard.InfoColumn>
          )}

          {regulation.industries?.length > 0 && (
            <BaseCard.InfoColumn title="Industries">
              <BaseCard.BriefPillList
                isGeography={false}
                briefs={regulation.industries}
              />
            </BaseCard.InfoColumn>
          )}

          <BaseCard.InfoColumn title="Jurisdiction">
            <BaseCard.BriefPillList
              isGeography
              briefs={[{ name: regulation.jurisdiction }]}
            />
          </BaseCard.InfoColumn>
        </BaseCard.RightColumn>
      </BaseCard.Row>
      <BaseCard.BottomRow className={css.footer}>
        <div className={css.buttons}>
          <button type="button" className={css.button} onClick={onAddNoteClick}>
            <NotesOutline />
            Add Note
          </button>
          <div className={css.divider} />
          <CompliantButton
            isCompliant={localCompliant}
            onClick={handleComply}
          />
        </div>
        <BaseCard.CardSources
          sources={[regulation.source, regulation.attachment]}
        />
      </BaseCard.BottomRow>
    </BaseCard.Root>
  );
}

export default RegulationCard;
