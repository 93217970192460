import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import css from './RemoveConnection.module.css';
import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../Buttom/Buttom';
import EntityPill from '../../../../../components/EntityPill/EntityPill';
import GET_ENTITY_BY_ID from '../../../../../queries/getEntityById';

function RemoveConnection({ onClose, entity, entityId, note, onRemove }) {
  const [entityData, setEntity] = useState(entity);
  const { loading } = useQuery(GET_ENTITY_BY_ID, {
    skip: entity,
    variables: {
      id: entityId,
    },
    onCompleted: (data) => {
      setEntity(data.getDossierEntity);
    },
  });

  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Remove personal connection</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      {loading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            width: 400,
          }}
        >
          <div style={{ height: 20 }} className="skeleton-v2" />
          <div style={{ height: 20, width: '60%' }} className="skeleton-v2" />
        </div>
      )}

      {!loading && entityData && (
        <div className={css.message}>
          <div>
            Are you sure you want to remove
            <EntityPill
              name={entityData.name}
              type={entityData.type}
              isConnected={entityData.isConnected}
              image={entityData.image}
              shortName={entityData.shortestName}
              size="small"
            />
            from your
          </div>
          personal connections?
        </div>
      )}

      {note && (
        <textarea
          className={css.textarea}
          type="text-area"
          disabled
          value={note}
        />
      )}

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="danger" onClick={onRemove}>
          Remove
        </Button>
      </div>
    </div>
  );
}

export default RemoveConnection;
