import { gql } from '@apollo/client';

const query = gql`
  query getDossierRegulations($id: ID!, $page: Int, $limit: Int) {
    getDossierRegulations(id: $id, page: $page, limit: $limit) {
      totalRegulations
      regulations {
        id
        legalName
        summarizedName
        issuer {
          id
          name
          shortestName
          image
          type
          topicsId
          inSubscription
          isConnected
          tooltip
        }
        authorizedIssuer {
          id
          name
          shortestName
          image
          type
          topicsId
          inSubscription
          isConnected
          tooltip
        }
        startDate
        endDate
        createdAt
        updatedAt
        summary
        bullets
        source
        attachment
        jurisdiction
        industries {
          id
          name
          tooltip
          type
        }
        notesCount
        isCompliant
      }
    }
  }
`;

export default query;
