import { gql } from '@apollo/client';

const query = gql`
  query listCountries($limit: Int, $skip: Int) {
    listCountries(limit: $limit, skip: $skip) {
      countries {
        id
        name
        flag
        isoCode
        mainEntityId
        subscribed
      }
    }
  }
`;

export default query;
