import React from 'react';
import DossierHeader from '../../../../../components/DossierHeader/DossierHeader';

import css from './LoadingState.module.css';

function LoadingState() {
  return (
    <>
      <div className={css.header}>
        <DossierHeader />
      </div>
      <div className={css.loadingState}>
        <div
          className={`skeleton-v2 ${css.avatar}`}
          style={{ borderRadius: 50 }}
        />
        <div style={{ height: 40 }} className="skeleton-v2" />
        <div className={css.info}>
          <div className="skeleton-v2" />
          <div className="skeleton-v2" />
        </div>
        <div style={{ height: 30 }} className="skeleton-v2" />
        <div style={{ height: 30 }} className="skeleton-v2" />
        <div style={{ height: 350, marginTop: 15 }} className="skeleton-v2" />
      </div>
    </>
  );
}

export default LoadingState;
