import { gql } from '@apollo/client';

const query = gql`
  query getTopicBriefSubscriptions {
    getTopicBriefSubscriptions {
      subscribedTopics {
        id
        name
        flag
        subscribedBriefs
      }
      briefs {
        id
        name
        type
      }
    }
  }
`;

export default query;
