import React from 'react';
import EntityPill from '../../../../../../components/EntityPill/EntityPill';
import { useRouteInformation } from '../../../../../../contexts/RouteInformationContext';
import { formatDate, isSameDate } from '../../../../../../lib/date';
import Source from '../../../../../../components/Source/Source';
import css from './RoleUpdate.module.css';
import { getDateValue } from '../../../../../../lib/facets/role';

const RoleUpdate = ({ update }) => {
  const router = useRouteInformation();

  const {
    createdAt,
    updatedAt,
    startDate,
    endDate,
    employer,
    entity,
    org,
    position,
    source,
  } = update;

  const firstUpdate = formatDate(createdAt);
  const lastUpdate = formatDate(updatedAt);

  const date = getDateValue({ start: startDate, end: endDate });

  const isNew = isSameDate(firstUpdate, lastUpdate);

  const handleEntityClick = () => {
    router.toggleDossier({ dossierEntityId: entity.id, dossierType: 'entity' });
  };

  const handleOrganizationClick = () => {
    router.toggleDossier({
      dossierEntityId: employer.id,
      dossierType: 'entity',
    });
  };

  return (
    <div className={css.update} data-cy="feed-update" data-cy-facet="role">
      <div className={css.content}>
        <p className={css.title}>{isNew ? 'New' : 'Updated'} Role</p>
        <div className={css.details}>
          <p className={css.date}>{lastUpdate}</p>
        </div>
        <div className={css.details}>
          <EntityPill
            name={entity.name}
            image={entity.image}
            type={entity.type}
            inSubscription={entity.inSubscription}
            size="medium"
            isConnected={entity.isConnected}
            onClick={handleEntityClick}
          />
        </div>
        <div className={css.role}>
          {employer && employer.id && (
            <div className={css.orgDetail}>
              Employer:{' '}
              <EntityPill
                className={css.organizationPill}
                name={employer.name}
                image={employer.image}
                type={employer.type}
                inSubscription={employer.inSubscription}
                isConnected={employer.isConnected}
                size="small"
                onClick={handleOrganizationClick}
              />
            </div>
          )}
          {org && (
            <p className={css.detail}>
              Organization: <span className={css.organizationName}>{org}</span>
            </p>
          )}
          {position && <p className={css.detail}>Role: {position}</p>}
          {date && <p className={css.detail}>Dates: {date}</p>}
        </div>
        {source && (
          <p data-cy="source-list" className={css.source}>
            <Source source={source} />
          </p>
        )}
      </div>
    </div>
  );
};

export default RoleUpdate;
