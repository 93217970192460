import React from 'react';
import { format } from 'date-fns';
import Connection from './icons/Connection';
import Bookmark from './icons/Bookmark';
import Pdb from './icons/Pdb';
import SavedSearch from './icons/SavedSearch';
import Notes from './icons/Notes';
import { NOTIFICATION_ACTIONS } from '..';

export const NotificationType = {
  BOOKMARK: 'BOOKMARK',
  USER_CONNECTION: 'USER_CONNECTION',
  PDB: 'PDB',
  SAVED_SEARCH: 'SAVED_SEARCH',
  NOTE: 'NOTE',
};

export const getIconByType = (type) => {
  switch (type) {
    case NotificationType.USER_CONNECTION:
      return <Connection />;
    case NotificationType.BOOKMARK:
      return <Bookmark />;
    case NotificationType.PDB:
      return <Pdb />;
    case NotificationType.SAVED_SEARCH:
      return <SavedSearch />;
    case NotificationType.NOTE:
      return <Notes />;
    default:
      return null;
  }
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  return format(date, 'HH:mm');
};

export const getNotificationActions = (notification) => {
  switch (notification.type) {
    case NotificationType.BOOKMARK:
      return [
        {
          type: NOTIFICATION_ACTIONS.MARK_AS_READ,
          label: `Mark as read`,
        },
        {
          type: NOTIFICATION_ACTIONS.REMOVE_BOOKMARK,
          label: `Remove bookmark "${notification.title}"`,
        },
      ];
    case NotificationType.PDB:
      return [
        {
          type: NOTIFICATION_ACTIONS.CUSTOMIZE_PDB,
          label: `Customize Daily Brief`,
        },
      ];
    case NotificationType.NOTE:
      return [
        {
          type: NOTIFICATION_ACTIONS.MARK_AS_READ,
          label: `Mark as read`,
        },
        {
          type: NOTIFICATION_ACTIONS.REMOVE_NOTE,
          label: `Remove note "${notification.title}"`,
        },
      ];
    case NotificationType.USER_CONNECTION:
      return [
        {
          type: NOTIFICATION_ACTIONS.MARK_AS_READ,
          label: `Mark as read`,
        },
        {
          type: NOTIFICATION_ACTIONS.REMOVE_CONNECTION,
          label: `Remove connection "${notification.title}"`,
        },
      ];
    case NotificationType.SAVED_SEARCH:
      return [
        {
          type: NOTIFICATION_ACTIONS.MARK_AS_READ,
          label: `Mark as read`,
        },
        {
          type: NOTIFICATION_ACTIONS.REMOVE_SEARCH,
          label: `Remove Search "${notification.title}"`,
        },
      ];
    default:
      return [];
  }
};

export const getNotificatioReason = (notification) => {
  switch (notification.type) {
    case NotificationType.BOOKMARK:
      return `You received this notification because
       you bookmarked ${notification.title}`;
    case NotificationType.PDB:
      return `You received this notification because you customized Daily Brief`;
    case NotificationType.NOTE:
      return `You received this notification because you have ${notification.title} in your notes `;
    case NotificationType.USER_CONNECTION:
      return `You received this notification because  ${notification.title} in your network `;
    case NotificationType.SAVED_SEARCH:
      return `You received this notification because you saved ${notification.title} in your searches `;
    default:
      return '';
  }
};
