import React from 'react';

import SideNav from './components/SideNav/SideNav';
import LeftPanel from './components/LeftPanel/LeftPanel';

import css from './App.module.css';
import CommandKey from './components/CommandKey/CommandKey';

function AppV2({ children }) {
  const router = children; // render internal routes

  return (
    <div className={css.app}>
      <SideNav />
      <LeftPanel />
      <div id="outlet" className={css.routerOutlet}>
        {router}
      </div>
      <CommandKey />
    </div>
  );
}

export default AppV2;
