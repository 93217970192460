import React from 'react';

const getTransform = (direction) => {
  if (direction === 'right') return `scale(1, 1) rotate(0)`;
  if (direction === 'left') return `scale(-1, 1) rotate(0)`;
  if (direction === 'up') return `scale(1, 1) rotate(-90)`;
  if (direction === 'down') return `scale(1, 1) rotate(90)`;
  return '';
};

const Pagination = ({ className, direction = 'right' }) => {
  const transform = getTransform(direction);
  return (
    <svg
      data-cy="pagination-icon"
      data-cy-direction={direction}
      className={className}
      viewBox="0 0 6 8"
      fill="none"
    >
      <g transform={transform} transform-origin="center">
        <path
          d="M1.26668 8L0.333344 7.06667L3.40001 4L0.333344 0.933333L1.26668 0L5.26668 4L1.26668 8Z"
          fill="#717171"
        />
      </g>
    </svg>
  );
};

export default Pagination;
