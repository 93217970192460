/* eslint-disable import/no-extraneous-dependencies */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const URL = `${process.env.REACT_APP_API_V2_URL}/graphql`;

const request = new HttpLink({ uri: URL });

const auth = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token || '',
    },
  }));

  return forward(operation);
});

const error = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const detail = `Message: ${message}, Location: ${locations}, Path: ${path}`;
      console.error(`[GraphQL Error]: ${detail}`);
    });
  }
  if (networkError) {
    console.error(`[Network Error]: ${networkError}`);
    if (networkError.statusCode === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.reload();
    }
  }
});

const client = new ApolloClient({
  link: from([error, auth, request]),
  cache: new InMemoryCache(),
  assumeImmutableResults: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

const WebSocketLink = new GraphQLWsLink(
  createClient({
    url: URL.replace('http', 'ws'),
    connectionParams: {
      authToken: localStorage.getItem('token'),
    },
  }),
);

export const WebSocketClient = new ApolloClient({
  link: WebSocketLink,
  cache: new InMemoryCache(),
});

export default client;
