import React from 'react';
import css from './PrimaryButton.module.css';

const PrimaryButton = ({
  className,
  label,
  icon: leadingIcon,
  trailingIcon,
  onClick,
  iconPosition = 'leading',
  isActive,
  disabled,
  ...rest
}) => {
  const isIconButton = !label && leadingIcon;

  const hasLeadingIcon = leadingIcon && iconPosition === 'leading';
  const hasTrailingIcon = trailingIcon;

  const handleClick = (e) => {
    if (disabled) return;
    onClick && onClick(e);
  };

  return (
    <button
      className={`${css.button} ${className}`}
      onClick={handleClick}
      disabled={disabled}
      type="button"
      data-type={isIconButton ? 'icon-button' : 'text-button'}
      data-status={isActive ? 'active' : 'inactive'}
      {...rest}
    >
      {hasLeadingIcon && (
        <span className={css.icon} data-position="leading">
          {leadingIcon}
        </span>
      )}
      {label && <span className={css.label}>{label}</span>}
      {hasTrailingIcon && (
        <span className={css.icon} data-position="trailing">
          {trailingIcon}
        </span>
      )}
    </button>
  );
};

export default PrimaryButton;
