import React from 'react';

import css from './ModalHeader.module.css';
import CloseIcon from '../../../../icons/CloseIcon/CloseIcon';

function ModalHeader({ title, onClose }) {
  return (
    <div className={css.header}>
      <h2>{title}</h2>
      <button type="button" className={css.closeBtn} onClick={onClose}>
        <CloseIcon />
      </button>
    </div>
  );
}

export default ModalHeader;
