import React, { useEffect, useState } from 'react';
import { Loading } from '@axis/xyz.app.loading';
import BriefPill from '../../components/BriefPill/BriefPill';
import DossierCard from '../../components/DossierCard/DossierCard';
import css from './SharedIndustries.module.css';

const PAGE_SIZE = 25;
const SAFETY_PAGE_BUFFER = 24;

const getIndustriesPage = (industries, page) => {
  const start = (page - 1) * 25;
  const end = start + 25;
  return industries.slice(start, end);
};

const SharedIndustries = ({
  industries = [],
  total,
  isLoading,
  onLoadMore,
}) => {
  const [page, setPage] = useState(1);

  const availablePages = Math.ceil(industries.length / PAGE_SIZE);
  const totalPages = Math.ceil(total / PAGE_SIZE);
  const visibleIndustries = getIndustriesPage(industries, page);
  const isAllIndustriesLoaded = industries.length === total;
  const isEmpty = visibleIndustries.length === 0;

  const isPageLoading =
    (!isAllIndustriesLoaded && page * PAGE_SIZE > industries.length) ||
    (page === 1 && isLoading);

  useEffect(() => {
    if (isAllIndustriesLoaded) return;
    if (availablePages - page < SAFETY_PAGE_BUFFER && !isLoading) {
      onLoadMore();
    }
  }, [page]);

  const showCard = (industriesData) =>
    industriesData.length > 0 ? (
      <DossierCard
        loading={isPageLoading}
        title="Industries"
        count={total}
        currentPage={page}
        totalPages={totalPages}
        showPages
        onPageChange={setPage}
      >
        <div className={css.container}>
          {isEmpty && !isLoading && (
            <div className={css.empty}>
              These entities do not have any shared industries
            </div>
          )}
          <div className={css.briefs}>
            {visibleIndustries.map(({ id, name }) => (
              <BriefPill key={id} name={name} />
            ))}
          </div>
        </div>
      </DossierCard>
    ) : null;

  return (
    <div>
      {isLoading ? (
        <div className={css.loadingArea}>
          <Loading className={css.loading} />
        </div>
      ) : (
        showCard(industries)
      )}
    </div>
  );
};

export default SharedIndustries;
