import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App/App';
import ApolloClient from './hooks/useApolloClient';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './font.css';
import './index.css';

window.addEventListener('beforeunload', () => {
  window.scrollTo(0, 0);
});

ReactDOM.render(
  <ApolloClient>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloClient>,
  document.getElementById('root'),
);
