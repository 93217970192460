import { toast } from 'react-toastify';

const defaultProps = {
  position: 'bottom-right',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const notify = {
  error: (msg, props = defaultProps) => toast.error(msg, props),
  warning: (msg, props = defaultProps) => toast.warning(msg, props),
  info: (msg, props = defaultProps) => toast.info(msg, props),
  success: (msg, props = defaultProps) => toast.success(msg, props),
};

export default notify;
