import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.167 17.5V4.167c0-.459.163-.851.49-1.177.326-.327.718-.49 1.176-.49h8.334c.458 0 .85.163 1.177.49.326.326.49.718.49 1.177V17.5L10 15l-5.833 2.5zm1.666-2.542L10 13.167l4.167 1.791V4.167H5.833v10.791z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
