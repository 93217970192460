import React, { useMemo, useState } from 'react';

import Modal from 'react-modal';
import { uniqueId } from 'lodash';
import { useMutation } from '@apollo/client';
import css from './Notifications.module.css';
import PanelHeader from '../PanelHeader/PanelHeader';
import NotificationCard from './NotificationCard/NotificationCard';
import { useNotificationContext } from '../../../../contexts/NotificationContext';
import NotificationBell from '../../../../icons/NotificationBell';
import { NOTIFICATION_ACTIONS, getTimeline } from '.';
import useNavigation from '../../../hooks/useNavigation';
import { NotificationType } from './NotificationCard/utils';
import { ROUTES } from '../../../shared/routes';
import { modalStyles } from '../../../shared/modal';
import RemoveConnection from '../Connections/RemoveConnection/RemoveConnection';
import REMOVE_CONNECTION from '../../../../queries/toggleUserConnection';
import REMOVE_BOOKMARK from '../../../../queries/toggleBookmark';
import DELETE_NOTE from '../../../../queries/deleteNote';
import DELETE_SEARCH from '../../../../queries/deleteSavedSearch';
import notify from '../../../../lib/notify';
import RemoveBookmark from '../Bookmarks/RemoveBookmark/RemoveBookmark';
import DeleteNote from '../Notes/DeleteNoteModal/DeleteNoteModal';
import RemoveModal from '../SavedSearches/RemoveModal/RemoveModal';

const typeModal = {
  REMOVE_CONNECTION: 'REMOVE_CONNECTION',
  REMOVE_BOOKMARK: 'REMOVE_BOOKMARK',
  REMOVE_NOTE: 'REMOVE_NOTE',
  REMOVE_SEARCH: 'REMOVE_SEARCH',
};

function Notifications() {
  const {
    notifications,
    isLoadingNotifications,
    markNotificationAsRead,
    unreadNotificationsCount,
    markAllNotificationsAsRead,
  } = useNotificationContext();

  const [selectedNotificationId, setSelectedNotificationId] = useState();
  const [openedModal, setOpenedModal] = useState(null);
  const { navigate, params } = useNavigation();

  const [removeConnection] = useMutation(REMOVE_CONNECTION);
  const [removeBookmark] = useMutation(REMOVE_BOOKMARK);
  const [removeNote] = useMutation(DELETE_NOTE);
  const [removeSavedSearch] = useMutation(DELETE_SEARCH);

  const timeline = useMemo(() => getTimeline(notifications), [notifications]);

  const isEmpty = notifications.length === 0;
  const showMarkAllReadButton = !isLoadingNotifications && !isEmpty;

  const navigateToSearchWithFilters = async (notification) => {
    const uuid = uniqueId('search-');
    params.set('queryId', uuid);

    const { searchTerm } = notification.query;

    try {
      const { startDate, endDate, entities } = notification.query;

      sessionStorage.setItem(
        uuid,
        JSON.stringify({
          entities,
          dates: [startDate, endDate],
          searchTypes: 'event',
        }),
      );

      if (searchTerm?.trim()) {
        params.set('search', searchTerm);
      }

      navigate(`/search`, params);
    } catch (error) {
      notify.error(
        'An unexpected error occurred while trying to load notification results.',
      );
    }
  };

  const navigateByType = (notification) => {
    const { type } = notification;

    if (type === NotificationType.PDB) {
      navigate(ROUTES.PDB, params);
      return;
    }
    navigateToSearchWithFilters(notification);
  };

  const handleNotificationClick = (notification) => {
    setSelectedNotificationId(notification.id);
    navigateByType(notification);
    markNotificationAsRead(notification.id);
  };

  const handleNotificationAction = (notification, action) => {
    switch (action.type) {
      case NOTIFICATION_ACTIONS.CUSTOMIZE_PDB:
        navigate(ROUTES.PDB, params, { settings: true });
        break;
      case NOTIFICATION_ACTIONS.REMOVE_CONNECTION:
        setOpenedModal({
          modal: typeModal.REMOVE_CONNECTION,
          context: notification,
        });
        break;
      case NOTIFICATION_ACTIONS.MARK_AS_READ:
        markNotificationAsRead(notification.id);
        break;
      case NOTIFICATION_ACTIONS.REMOVE_BOOKMARK:
        setOpenedModal({
          modal: typeModal.REMOVE_BOOKMARK,
          context: notification,
        });
        break;
      case NOTIFICATION_ACTIONS.REMOVE_NOTE:
        setOpenedModal({
          modal: typeModal.REMOVE_NOTE,
          context: notification,
        });
        break;
      case NOTIFICATION_ACTIONS.REMOVE_SEARCH:
        setOpenedModal({
          modal: typeModal.REMOVE_SEARCH,
          context: notification,
        });
        break;
      default:
        break;
    }
  };

  const onRemoveConnectionConfirmed = async (notification) => {
    try {
      setOpenedModal(null);
      const entityId = openedModal.context.query.entities[0].id;
      await removeConnection({
        variables: { entityId, flag: false },
      });
      markNotificationAsRead(notification.id);
      notify.success('Connection removed!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  const onRemoveBookmarkConfirmed = async (notification) => {
    try {
      setOpenedModal(null);
      const { id } = openedModal.context.query.entities[0];
      await removeBookmark({ variables: { id, type: 'ENTITY' } });
      markNotificationAsRead(notification.id);
      notify.success('Connection removed!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  const onConfirmDeleteNote = async (notification) => {
    try {
      setOpenedModal(null);
      const noteId = openedModal.context.query.entities[0].id;
      await removeNote({
        variables: { noteId, noteType: 'entityNotes' },
      });
      markNotificationAsRead(notification.id);
      notify.success('Note removed!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  const onConfirmDeleteSearch = async (notification) => {
    try {
      setOpenedModal(null);
      const savedSearchId = openedModal.context.query.searchId;
      await removeSavedSearch({ variables: { savedSearchId } });
      markNotificationAsRead(notification.id);
      notify.success('Search removed!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  return (
    <div className={css.main}>
      <PanelHeader
        count={unreadNotificationsCount}
        title="Notifications"
        showInputSearch={false}
        bottomContent={
          showMarkAllReadButton ? (
            <div
              className={css.markAllRead}
              onClick={() =>
                markAllNotificationsAsRead(notifications.map(({ id }) => id))
              }
            >
              Mark all as read
            </div>
          ) : null
        }
      />
      <UiManager isLoading={isLoadingNotifications} isEmpty={isEmpty}>
        <div className={css.list}>
          {timeline.map((item) => (
            <div key={item.date}>
              <div className={css.timeline}>{item.date}</div>
              {item.notifications.map((notification) => (
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                  onClick={handleNotificationClick}
                  handleAction={handleNotificationAction}
                  selected={selectedNotificationId === notification.id}
                />
              ))}
            </div>
          ))}
        </div>
      </UiManager>

      <Modal
        isOpen={Boolean(openedModal)}
        style={modalStyles}
        contentLabel="Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpenedModal(null)}
      >
        {openedModal?.modal === typeModal.REMOVE_CONNECTION && (
          <RemoveConnection
            entity={openedModal.context.query.entities[0]}
            onClose={() => setOpenedModal(null)}
            onRemove={() => onRemoveConnectionConfirmed(openedModal.context)}
          />
        )}

        {openedModal?.modal === typeModal.REMOVE_NOTE && (
          <DeleteNote
            note={openedModal.context.query.note}
            onClose={() => setOpenedModal(null)}
            onDelete={() => onConfirmDeleteNote(openedModal.context)}
          />
        )}

        {openedModal?.modal === typeModal.REMOVE_BOOKMARK && (
          <RemoveBookmark
            entity={openedModal.context.query.entities[0]}
            onClose={() => setOpenedModal(null)}
            onRemove={() => onRemoveBookmarkConfirmed(openedModal.context)}
          />
        )}

        {openedModal?.modal === typeModal.REMOVE_SEARCH && (
          <RemoveModal
            onClose={() => setOpenedModal(null)}
            onConfirm={() => onConfirmDeleteSearch(openedModal.context)}
            name={openedModal.context.title}
          />
        )}
      </Modal>
    </div>
  );
}

export default Notifications;

const UiManager = ({ isLoading, isEmpty, children }) => {
  if (isLoading) {
    return (
      <div className={css.loadingState}>
        <div style={{ height: 70 }} className="skeleton-v2" />
        <div style={{ height: 70 }} className="skeleton-v2" />
        <div style={{ height: 70 }} className="skeleton-v2" />
        <div style={{ height: 70 }} className="skeleton-v2" />
        <div style={{ height: 70 }} className="skeleton-v2" />
        <div style={{ height: 70 }} className="skeleton-v2" />
      </div>
    );
  }
  if (isEmpty) {
    return (
      <div className={css.empty}>
        <div className={css.emptyIcon}>
          <NotificationBell />
        </div>
        <div className={css.emptyTitle}>No Notifications</div>
        <div className={css.emptyDescription}>
          Save search, bookmarks, leave note or connect <br /> with someone to
          receive a notification
        </div>
      </div>
    );
  }

  return children;
};
