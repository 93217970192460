import { useIdentity } from '../contexts/IdentityContext';
import { useRouteInformation } from '../contexts/RouteInformationContext';
import isValidUUID from '../lib/uuid';

const RedirectToFeed = () => {
  const router = useRouteInformation();
  const { user } = useIdentity();

  const { topicId } = router.params;
  const { primaryTopic } = user;

  if (isValidUUID(topicId)) router.navigateToFeed({ topicId });
  else router.navigateToFeed({ topicId: primaryTopic });

  return null;
};

export default RedirectToFeed;
