import React from 'react';

import css from './EmptyState.module.css';

function TreeMapEmptyState() {
  return (
    <div className={css.main}>
      <h2>No results found</h2>
      <h3>Change or remove some filters to see more data</h3>
    </div>
  );
}

export default TreeMapEmptyState;
