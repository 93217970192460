import React from 'react';
import css from './EventCardSkeleton.module.css';

export const EventCardSkeleton = () => {
  return (
    <div className={css.main}>
      <div>
        <div style={{ height: 25 }} className="skeleton-v2" />
        <div style={{ height: 200, marginTop: 20 }} className="skeleton-v2" />
      </div>
      <div>
        <div style={{ height: 25, width: '30%' }} className="skeleton-v2" />
        <div
          style={{ height: 50, marginTop: 10, width: '90%' }}
          className="skeleton-v2"
        />

        <div
          style={{ height: 25, width: '30%', marginTop: 10 }}
          className="skeleton-v2"
        />
        <div
          style={{ height: 50, marginTop: 10, width: '60%' }}
          className="skeleton-v2"
        />

        <div
          style={{ height: 25, width: '30%', marginTop: 10 }}
          className="skeleton-v2"
        />
        <div
          style={{ height: 20, marginTop: 10, width: '20%' }}
          className="skeleton-v2"
        />
      </div>
    </div>
  );
};
