import { gql } from '@apollo/client';

const query = gql`
  query classifyQuery($query: String) {
    classifyQuery(query: $query) {
      classificationType
    }
  }
`;

export default query;
