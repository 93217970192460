import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { mergeFacetSearchResults } from '../../../lib/search';
import QUERY_SEARCH_FACETS from '../../../queries/searchEventsAndRegulations';

// Global variable to store previous variables
let previousData = null;
let eventNextToken = null;
let hasMoreEventsToLoad = true;
let hasMoreRegulationsToLoad = true;

function useSearchFacets() {
  const [facetResults, setFacetResults] = useState([]);
  const [variables, setVariables] = useState();

  const { loading } = useQuery(QUERY_SEARCH_FACETS, {
    variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        const results = data.searchEventsAndRegulations;

        if (variables.filterCategories.events) {
          eventNextToken = results.events.nextToken;
        }

        if (variables.filterCategories.regulations) {
          hasMoreRegulationsToLoad = results.regulations.results.length > 0;
        }

        setFacetResults((currentResults) =>
          mergeFacetSearchResults({
            currentResults,
            newResults: results,
            facetType: variables.type,
          }),
        );
      }
    },
  });

  const fetchFacets = ({ pageInfo, ...data }) => {
    const {
      search = '',
      type,
      countries,
      industries,
      entities,
      dates,
      regulationTypes,
      eventTypes,
    } = data;

    if (type === 'actors') return;

    const { limit, skip } = pageInfo;

    const vars = {
      name: search,
      filterIndustries: industries,
      filterEntities: entities,
      topics: countries,
      entities,
      type,
      filterCategories: {},
      id: window.crypto.randomUUID(),
    };

    if (['events', 'all'].includes(type)) {
      // Check if the current filters are equal to the previous ones
      const isEqual = JSON.stringify(data) === JSON.stringify(previousData);

      let nextToken = null;

      // If the current request is identical to the previous request,
      // only fetch if there are more pages to load (nextToken is not null)
      if (isEqual) {
        if (eventNextToken === null) {
          hasMoreEventsToLoad = false;
          console.log('No more pages to load.');
          return;
        }
        nextToken = eventNextToken;
      } else {
        // reset the next token
        eventNextToken = null;
      }

      hasMoreEventsToLoad = true;

      vars.filterCategories.events = {
        limit,
        eventFilters: eventTypes,
        nextToken,
      };
    }

    if (['regulations', 'all'].includes(type)) {
      vars.filterCategories.regulations = {
        limit,
        skip,
        regulationTypes,
      };
    }

    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      vars.startDate = startDate;
      vars.endDate = endDate;
    }

    // Update the previous values
    previousData = data;

    setVariables(vars);
  };

  return {
    facetResults,
    fetchFacets,
    setFacetResults,
    isFacetResultsLoading: loading,
    eventNextToken,
    hasMoreEventsToLoad,
    hasMoreRegulationsToLoad,
  };
}

export default useSearchFacets;
