import React from 'react';
import Pagination from '../../icons/Pagination';
import css from './PaginationButton.module.css';

const PaginationButton = ({ disabled, direction, onClick }) => {
  return (
    <button
      disabled={disabled}
      className={css.button}
      type="button"
      data-direction={direction}
      data-cy="pagination-button"
      onClick={onClick}
    >
      <Pagination className={css.icon} direction={direction} />
    </button>
  );
};

export default PaginationButton;
