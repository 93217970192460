import { useIdentity } from '../contexts/IdentityContext';
import { useRouteInformation } from '../contexts/RouteInformationContext';

const useLogout = () => {
  const { removeToken, removeUser } = useIdentity();
  const router = useRouteInformation();
  return () => {
    // Remove the auth token
    removeToken();
    removeUser();
    // Stop FullStory
    if (window.FS && window.FS.shutdown) {
      window.FS.shutdown();
    }
    // Redirect to the login page
    router.navigateToLogin({});
  };
};

export default useLogout;
