import React, { useEffect, useRef, useState } from 'react';

import css from './Header.module.css';
import Button from '../../../Buttom/Buttom';

import { Countries, Industries } from './Tags/Tags';
import HeaderLoadingState from './HeaderLoadingState';
import ConnectButton from './ConnectButton/ConnectButton';
import BookmarkButton from './BookmarkButton/BookMarkButton';
import NotesOutline from '../../../../../icons/NotesOutline';
import DossierAvatar from '../../DossierAvatar/DossierAvatar';
import Online from '../../../../../dossier/facets/Online/Online';
import FallbackImage from '../../../../../components/FallbackImage/FallbackImage';
import Badge from '../../../Badge/Badge';
import Tabs from '../Body/Tabs/Tabs';
import UpArrow from '../../../../../icons/UpArrow';

const getTabDOMNode = (tab) =>
  document.querySelector(
    `div[data-type="dossier-section-v2"][data-group="${tab}"]`,
  );

const tabs = ['overview', 'timeline', 'network'];

function Header({
  size,
  dossierRef,
  entity,
  isLoading,
  toggleBookmark,
  toggleUserConnection,
  showNotesDossier,
}) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isTop, setTop] = useState(true);
  const headerRef = useRef();

  const onScroll = (event) => {
    const scroll = event.target.scrollTop;
    setTop(scroll === 0);

    const tabOffsets = tabs.map((tab, _index, existingTabs) => {
      const tabDOMNode = getTabDOMNode(tab);

      const firstTabDOMNode = getTabDOMNode(existingTabs[0]);
      if (!tabDOMNode) return 0;

      return {
        tab,
        offset: tabDOMNode.offsetTop - firstTabDOMNode.offsetTop,
      };
    });

    const scrollingTab = tabOffsets.find((tab, i, existingTabs) => {
      if (i === existingTabs.length - 1) return true;
      return scroll >= tab.offset && scroll < existingTabs[i + 1].offset;
    });

    setActiveTab(scrollingTab.tab);
  };

  const handleChangeTab = (tab) => {
    const dossierSection = getTabDOMNode(tab);
    if (!dossierSection) return;

    const closeButtonHeight = 48;
    const dossierOffset = dossierSection.offsetTop;
    const headerHeight = headerRef.current.scrollHeight + closeButtonHeight;
    const contentOffset = dossierRef.current.offsetTop;
    const scrollPosition = dossierOffset - headerHeight - contentOffset;
    dossierRef.current.scrollTo(0, scrollPosition);
  };

  const scrollUp = () => {
    dossierRef.current.scrollTo(0, 0);
  };

  useEffect(() => {
    if (dossierRef.current) {
      dossierRef.current.addEventListener('scroll', onScroll);
    }
    return () => {
      if (dossierRef.current) {
        dossierRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, [dossierRef]);

  if (isLoading) return <HeaderLoadingState />;

  const isSmallDossier = !isTop && size !== 'expanded';

  return (
    <div className={css.main} ref={headerRef}>
      {!isSmallDossier && (
        <div className={css.normalHeader}>
          <div className={css.firstLine}>
            <DossierAvatar>
              <FallbackImage src={entity.image} className={css.entityImage} />
            </DossierAvatar>

            <div className={css.actions}>
              <BookmarkButton entity={entity} toggleBookmark={toggleBookmark} />

              <ConnectButton
                entity={entity}
                toggleConnection={toggleUserConnection}
              />
            </div>
          </div>

          <div>
            <h1 className={css.name} data-cy="dossier-entity-name">
              {entity.name}
            </h1>
            <h3 className={css.roleTitle} data-cy="dossier-entity-role">
              {entity.primaryRoleTitle}
            </h3>
          </div>

          <Online socials={entity.onlinePresence} />

          <div className={css.tags}>
            <Industries industries={entity.industries} size={size} />
            <Countries
              primaryTopicId={entity.primaryTopicId}
              countries={entity.countries}
              size={size}
            />
          </div>

          <div className={css.links}>
            {/* Temporarily removed until we re-work network page */}
            {/* <Button
              type="secondary"
              className={css.linkButton}
              data-cy="relationships-button"
              onClick={handleNetworkClick}
            >
              <SolidExplore className={css.icon} />
              Relationships
              <Badge count={entity.relationshipCount} />
            </Button> */}
            <Button
              type="secondary"
              className={css.linkButton}
              data-cy="notes-button"
              onClick={showNotesDossier}
            >
              <NotesOutline />
              Notes
              <Badge count={entity.notesCount} />
            </Button>
          </div>
        </div>
      )}

      {isSmallDossier && (
        <div className={css.smallHeader}>
          <DossierAvatar small>
            <FallbackImage src={entity.image} className={css.entityImage} />
          </DossierAvatar>

          <h1 className={css.smallName}>{entity.name}</h1>

          <Button
            type="secondary"
            data-cy="dossier-scroll-up"
            className={css.scrollUpBtn}
            onClick={scrollUp}
          >
            <UpArrow />
            Scroll Up
          </Button>
        </div>
      )}

      <Tabs tabs={tabs} setActive={handleChangeTab} active={activeTab} />
    </div>
  );
}

export default Header;
