import React from 'react';

import css from './Home.module.css';
import TrendingEntities from '../../components/TrendingEntities/TrendingEntities';
import TreeMapWrapper from '../../components/TreeMap/TreeMapWrapper';
import DailyBriefWrapper from '../../components/DailyBrief/DailyBriefWrapper';
import UnifiedSearch from '../../components/UnifiedSearch/UnifiedSearch';

function Home() {
  return (
    <div className={css.main}>
      <div className={css.space}>
        <UnifiedSearch />
      </div>
      <div className={css.pdb}>
        <DailyBriefWrapper />
        <TrendingEntities />
      </div>

      <div className={css.treeMap}>
        <TreeMapWrapper />
      </div>
    </div>
  );
}

export default Home;
