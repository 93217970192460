import { gql } from '@apollo/client';

const query = gql`
  query getDossierRelationship($id: ID!) {
    getDossierRelationship(id: $id) {
      actors {
        id
        name
        image
        type
        topicsId
        inSubscription
        isConnected
      }
      landscapes {
        id
        name
        image
        type
        topicsId
        inSubscription
      }
    }
  }
`;

export default query;
