import React, { useMemo } from 'react';

import css from './OptionRow.module.css';
import Total from '../../../../../components/Total/Total';

const childSpacing = 25;

function OptionRow({ option, handleOnInputChange, counts }) {
  const count = useMemo(() => {
    if (option.value === 'organizations' && option.level === 0) {
      return counts.organizations + counts.companies + counts.governments;
    }

    return counts[option.value];
  }, [counts]);

  return [
    <React.Fragment key={option.id}>
      <div
        className={css.optionRow}
        style={{ paddingLeft: option.level * childSpacing }}
        data-cy={`tree-select-option-${option.id}`}
        data-testid={`tree-select-option-${option.id}`}
        onClick={() => handleOnInputChange(option)}
      >
        <input
          type="checkbox"
          className={css.input}
          checked={option.selected}
          onChange={(e) => {
            // change is triggered by parent div click
            e.stopPropagation();
          }}
        />
        <div data-cy="option-name" className={css.name}>
          {option.name}
        </div>

        <Total total={count} />
      </div>

      {option.children.map((child) => (
        <OptionRow
          key={child.id}
          option={child}
          counts={counts}
          handleOnInputChange={handleOnInputChange}
        />
      ))}
    </React.Fragment>,
  ];
}

export default OptionRow;
