import { gql } from '@apollo/client';

const COMBINED_SEARCH_COUNTS_QUERY = gql`
  query CombinedSearchCounts(
    $name: String
    $filterIndustries: [ID]
    $topics: [ID]
    $filterEntities: [ID]
    $filterActorTypes: [CountActorTypes]
    $bookmarked: Boolean
    $connected: Boolean
    $compliance: Boolean
    $notes: Boolean
    $startDate: String
    $endDate: String
  ) {
    searchEntitiesCount(
      name: $name
      filterIndustries: $filterIndustries
      filterActorTypes: $filterActorTypes
      topics: $topics
      filterEntities: $filterEntities
      bookmarked: $bookmarked
      connected: $connected
      notes: $notes
      compliance: $compliance
    ) {
      categories {
        total
        type
      }
    }
    searchEventsAndRegulationsCount(
      name: $name
      filterIndustries: $filterIndustries
      topics: $topics
      filterEntities: $filterEntities
      bookmarked: $bookmarked
      connected: $connected
      notes: $notes
      compliance: $compliance
      startDate: $startDate
      endDate: $endDate
    ) {
      events {
        total
      }
      regulations {
        total
      }
      regulationTypesCount {
        type
        total
      }
      eventTypesCount {
        type
        total
      }
    }
  }
`;

export default COMBINED_SEARCH_COUNTS_QUERY;
