import React, { useState } from 'react';
import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../Buttom/Buttom';
import css from './EditSavedSearch.module.css';
import EntityPill from '../../../../../components/EntityPill/EntityPill';
import BriefPill from '../../../../../components/BriefPill/BriefPill';

function EditSavedSearch({ onClose, onConfirm, search }) {
  const [data, setData] = useState(JSON.parse(search.search_data));
  const [value, setValue] = useState(search.name);

  const onRemoveEntity = (removeId) => {
    setData({
      ...data,
      filterEntities: data.filterEntities.filter(({ id }) => id !== removeId),
    });
  };

  const onRemoveIndustry = (removeId) => {
    setData({
      ...data,
      filterIndustries: data.filterIndustries.filter(
        ({ id }) => id !== removeId,
      ),
    });
  };

  const onRemoveCountry = (removeId) => {
    setData({
      ...data,
      topics: data.topics.filter(({ id }) => id !== removeId),
    });
  };

  const onRemoveCategory = (removeCategory) => {
    setData({
      ...data,
      filterCategories: data.filterCategories.filter(
        (category) => category !== removeCategory,
      ),
    });
  };

  const onConfirmChanges = () => {
    const updatedSearch = {
      ...search,
      name: value,
      search_data: JSON.stringify(data),
    };

    onConfirm(updatedSearch);
  };

  const hasEntities = data.filterEntities.length > 0;
  const hasIndustries = data.filterIndustries.length > 0;
  const hasCountries = data.topics.length > 0;
  const hasCategories = data.filterCategories.length > 0;

  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Edit saved search</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      <div className={css.copy}>
        {hasEntities && (
          <div className={css.group}>
            <h3>Entities</h3>
            <div>
              {data.filterEntities.map((entity) => (
                <EntityPill
                  key={entity.id}
                  image={entity.image}
                  name={entity.name}
                  type={entity.type}
                  size="small"
                  onClose={() => onRemoveEntity(entity.id)}
                />
              ))}
            </div>
          </div>
        )}

        {hasIndustries && (
          <div className={css.group}>
            <h3>Industries</h3>
            <div>
              {data.filterIndustries.map((industry) => (
                <BriefPill
                  key={industry.id}
                  name={industry.name}
                  size="small"
                  onClose={() => onRemoveIndustry(industry.id)}
                />
              ))}
            </div>
          </div>
        )}

        {hasCountries && (
          <div className={css.group}>
            <h3>Countries</h3>
            <div>
              {data.topics.map((topic) => (
                <BriefPill
                  key={topic.id}
                  name={topic.name}
                  isGeography
                  size="small"
                  onClose={() => onRemoveCountry(topic.id)}
                />
              ))}
            </div>
          </div>
        )}

        {hasCategories && (
          <div className={css.group}>
            <h3>Categories</h3>
            <div>
              {data.filterCategories.map((category) => (
                <BriefPill
                  key={category}
                  name={category}
                  onClose={() => onRemoveCategory(category)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <input
        type="text"
        value={value}
        className={css.input}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Name your search"
      />

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="primary" onClick={onConfirmChanges}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default EditSavedSearch;
