import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';

import css from './SavedSearchCard.module.css';
import BriefPill from '../../../../../components/BriefPill/BriefPill';
import EntityPill from '../../../../../components/EntityPill/EntityPill';

import More from '../../../../../icons/More';
import EditIcon from '../../../../../icons/Edit';
import HomeIcon from '../../../../../icons/Home';
import TrashIcon from '../../../../../icons/Trash';
import Search from '../../../../../icons/Search';
import useNavigation from '../../../../hooks/useNavigation';
import { useSharedState } from '../../../../../contexts/SharedStateContext';
import { ROUTES } from '../../../../shared/routes';

const SavedSearchCard = ({
  search,
  onRemove,
  setAsHomeFeed,
  onRemoveHomeFeed,
  onEdit,
}) => {
  const { selectedSavedSearch, setSelectedSavedSearch } = useSharedState();
  const { location, params, navigate } = useNavigation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {
    filterEntities,
    filterIndustries,
    topics,
    filterCategories,
    name,
    isHomeFeed,
  } = JSON.parse(search.search_data);

  const onMenuClicked = (e) => {
    e.stopPropagation();
    setIsMenuOpen(true);
  };

  const handleEdit = (item) => {
    setIsMenuOpen(false);
    onEdit(item);
  };

  const handleRemove = (item) => {
    setIsMenuOpen(false);
    onRemove(item);
  };

  const handleSetAsFeed = (item) => {
    setIsMenuOpen(false);
    setAsHomeFeed(item);
  };

  const handleRemoveHomeFeed = (item) => {
    setIsMenuOpen(false);
    onRemoveHomeFeed(item);
  };

  const onClick = (item) => {
    setSelectedSavedSearch(item);
    const validRoutes = [ROUTES.FEED, ROUTES.ADV_SEARCH, ROUTES.NETWORK];

    if (!validRoutes.includes(location.pathname)) {
      navigate(ROUTES.ADV_SEARCH, params);
    }
  };

  return (
    <div
      key={search.id}
      className={css.searchCard}
      data-active={search.id === selectedSavedSearch?.id}
      onClick={() => onClick(search)}
    >
      {isHomeFeed && <div className={css.homeFeed}>Home Feed</div>}

      <div className={css.searchCardHeader}>
        <Search className={css.searchIcon} />
        <div className={css.searchName} data-cy="saved-search-name">
          {search.name}
        </div>
        <Popover
          isOpen={isMenuOpen}
          positions={['bottom', 'right']}
          containerClassName={css.menu}
          onClickOutside={(e) => {
            e.stopPropagation();
            setIsMenuOpen(!isMenuOpen);
          }}
          content={
            <div>
              <div
                className={css.menuItem}
                data-cy="saved-searches-card-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(search);
                }}
              >
                <EditIcon className={css.editIcon} /> Edit saved search
              </div>
              {isHomeFeed ? (
                <div
                  className={css.menuItem}
                  data-removal
                  data-cy="saved-searches-card-edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveHomeFeed(search);
                  }}
                >
                  <HomeIcon className={css.homeIcon} color="#cc0909" /> Remove
                  as home feed
                </div>
              ) : (
                <div
                  className={css.menuItem}
                  data-cy="saved-searches-card-edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetAsFeed(search);
                  }}
                >
                  <HomeIcon className={css.homeIcon} /> Set as home feed
                </div>
              )}
              {!isHomeFeed && (
                <div
                  className={css.menuItem}
                  data-removal
                  data-cy="saved-searches-card-delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove(search);
                  }}
                >
                  <TrashIcon className={css.trashIcon} color="#cc0909" /> Delete
                  saved search
                </div>
              )}
            </div>
          }
        >
          <button
            type="button"
            className={css.moreBtn}
            onClick={onMenuClicked}
            data-cy="more-option-saved-search"
          >
            <More />
          </button>
        </Popover>
      </div>

      <div className={css.tagList}>
        {filterEntities.map((entity) => (
          <EntityPill
            key={entity.id}
            name={entity.shortestName || entity.name}
            type={entity.type}
            size="small"
            image={entity.image}
          />
        ))}

        {topics.map((topic) => (
          <BriefPill key={topic.id} name={topic.name} isGeography />
        ))}

        {filterIndustries.map((industry) => (
          <BriefPill key={industry.id} name={industry.name} />
        ))}

        {filterCategories.map((category) => (
          <BriefPill key={category} name={category} />
        ))}

        <div className={css.searchText}>{name ? `"${name}"` : null}</div>
      </div>
    </div>
  );
};

export default SavedSearchCard;
