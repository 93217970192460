import React from 'react';

import css from './SmallAppointment.module.css';
import { BaseCard } from '../BaseCard/BaseCard';
import useNavigation from '../../../hooks/useNavigation';

function SmallAppointmentCard({ appointment }) {
  const { toggleAppointmentDossier } = useNavigation();

  return (
    <div className={css.main}>
      <BaseCard.DateRow date={appointment.startDate} />
      <BaseCard.Title
        onClick={() => toggleAppointmentDossier(appointment.id)}
        className={css.title}
      >
        {appointment.headline}
      </BaseCard.Title>
    </div>
  );
}

export default SmallAppointmentCard;
