import * as React from 'react';

function ChevronRight(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M6.267 12l-.934-.933L8.4 8 5.333 4.933 6.267 4l4 4-4 4z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default ChevronRight;
