import * as React from 'react';

function AddConnection(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M12 9.333v-2h-2V6h2V4h1.333v2h2v1.333h-2v2H12zM6 8a2.568 2.568 0 01-1.883-.783 2.568 2.568 0 01-.784-1.884c0-.733.261-1.36.784-1.883A2.568 2.568 0 016 2.667c.733 0 1.36.26 1.883.783.522.522.783 1.15.783 1.883 0 .734-.26 1.362-.783 1.884A2.568 2.568 0 016 8zM.667 13.333v-1.866c0-.378.097-.725.291-1.042.195-.317.453-.558.775-.725a9.898 9.898 0 012.1-.775 9.172 9.172 0 014.333 0c.712.172 1.412.43 2.1.775.323.167.581.408.775.725.195.317.292.664.292 1.042v1.866H.667zM2 12h8v-.533a.648.648 0 00-.333-.567 8.69 8.69 0 00-1.817-.675 7.734 7.734 0 00-3.7 0 8.69 8.69 0 00-1.817.675.648.648 0 00-.333.567V12zm4-5.333c.367 0 .68-.13.941-.392.262-.261.392-.575.392-.942 0-.366-.13-.68-.392-.941A1.284 1.284 0 006 4c-.367 0-.68.13-.942.392a1.284 1.284 0 00-.391.941c0 .367.13.681.391.942.261.261.575.392.942.392z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default AddConnection;
