import React from 'react';
import { useRouteInformation } from '../../../../../../contexts/RouteInformationContext';
import { getDateValue } from '../../../../../../lib/facets/education';
import { formatDate, isSameDate } from '../../../../../../lib/date';
import Source from '../../../../../../components/Source/Source';
import EntityPill from '../../../../../../components/EntityPill/EntityPill';
import css from './EducationUpdate.module.css';

const EducationUpdate = ({ update }) => {
  const router = useRouteInformation();

  const {
    createdAt,
    updatedAt,
    degree,
    startDate,
    endDate,
    entity,
    institution,
    institutionText,
    source,
    specialty,
  } = update;

  const firstUpdate = formatDate(createdAt);
  const lastUpdate = formatDate(updatedAt);
  const date = getDateValue({ start: startDate, end: endDate });
  const isNew = isSameDate(firstUpdate, lastUpdate);

  const handleEntityClick = () => {
    router.toggleDossier({ dossierEntityId: entity.id, dossierType: 'entity' });
  };

  const handleInstitutionClick = () => {
    router.toggleDossier({
      dossierEntityId: institution.id,
      dossierType: 'entity',
    });
  };

  return (
    <div className={css.update} data-cy="feed-update" data-cy-facet="education">
      <div className={css.content}>
        <p className={css.title}>{isNew ? 'New' : 'Updated'} Education</p>
        <div className={css.details}>
          <p className={css.date}>{lastUpdate}</p>
        </div>
        <div className={css.details}>
          <EntityPill
            name={entity.name}
            image={entity.image}
            type={entity.type}
            inSubscription={entity.inSubscription}
            size="medium"
            isConnected={entity.isConnected}
            onClick={handleEntityClick}
          />
        </div>
        <div className={css.education}>
          {institution && institution.id && (
            <div className={css.orgDetail}>
              Institution:{' '}
              <EntityPill
                className={css.organizationPill}
                name={institution.name}
                image={institution.image}
                type={institution.type}
                inSubscription={institution.inSubscription}
                isConnected={institution.isConnected}
                size="small"
                onClick={handleInstitutionClick}
              />
            </div>
          )}
          {institutionText && (
            <p className={css.detail}>
              Institution:{' '}
              <span className={css.organizationName}>{institutionText}</span>
            </p>
          )}
          {degree && <p className={css.detail}>Degree: {degree}</p>}
          {specialty && <p className={css.detail}>Specialty: {specialty}</p>}
          {date && <p className={css.detail}>Dates: {date}</p>}
        </div>
        {source && (
          <p data-cy="source-list" className={css.source}>
            <Source source={source} />
          </p>
        )}
      </div>
    </div>
  );
};

export default EducationUpdate;
