import React from 'react';

import css from './TreeMapTooltip.module.css';

function TreeMapTooltip({ node }) {
  const { data } = node;

  return (
    <div className={css.main}>
      <div>{data.industry.name}</div>
      <div>{data.country.name}</div>
      <div>{data.occurrences} Events</div>
    </div>
  );
}

export default TreeMapTooltip;
