import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom';

function useDatesUrlState() {
  const location = useLocation();
  const history = useHistory();

  const [dates, setDates] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const start = searchParams.get('startDate');
    const end = searchParams.get('endDate');

    return [Number(start) || null, Number(end) || null];
  });

  const updateState = ({ startDate, endDate }) => {
    const searchParams = new URLSearchParams(location.search);

    if (!startDate) {
      searchParams.delete('startDate');
    } else {
      searchParams.set('startDate', startDate);
    }

    if (!endDate) {
      searchParams.delete('endDate');
    } else {
      searchParams.set('endDate', endDate);
    }

    history.push({
      search: searchParams.toString(),
    });
    setDates([startDate, endDate]);
  };

  const setLatestStateFromUrl = () => {
    setDates(() => {
      const searchParams = new URLSearchParams(location.search);
      const start = searchParams.get('startDate');
      const end = searchParams.get('endDate');

      return [Number(start) || null, Number(end) || null];
    });
  };

  useEffect(() => {
    setLatestStateFromUrl();
  }, [location]);

  return [dates, updateState];
}

export default useDatesUrlState;
