import * as React from 'react';

function NewExplore(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 3.333c0-.46.373-.833.833-.833h13.334c.46 0 .833.373.833.833v2.5c0 .46-.373.834-.833.834H3.333a.833.833 0 01-.833-.834v-2.5z"
        fill="#656A70"
      />
      <path
        opacity={0.2}
        d="M2.5 14.166c0-.46.373-.833.833-.833h13.334c.46 0 .833.373.833.833v2.5c0 .46-.373.834-.833.834H3.333a.833.833 0 01-.833-.834v-2.5z"
        fill="#656A70"
      />
      <path
        opacity={0.4}
        d="M17.5 8.75a.833.833 0 00-.833-.833H3.333a.833.833 0 00-.833.833v2.5c0 .46.373.834.833.834h13.334c.46 0 .833-.373.833-.834v-2.5z"
        fill="#656A70"
      />
    </svg>
  );
}

export default NewExplore;
