import { gql } from '@apollo/client';

const query = gql`
  query getRelationshipDossierSharedRoles(
    $sourceId: ID!
    $targetId: ID!
    $page: Int
    $limit: Int
  ) {
    getRelationshipDossierSharedRoles(
      sourceId: $sourceId
      targetId: $targetId
      page: $page
      limit: $limit
    ) {
      totalSharedRoles
      sharedRoles {
        sourceEntityName
        sourceEntityPosition
        sourceEntityStartDate
        sourceEntityEndDate
        targetEntityName
        targetEntityPosition
        targetEntityStartDate
        targetEntityEndDate
        roleOverlapStart
        roleOverlapEnd
        employerEntity {
          id
          name
          image
          inSubscription
          type
          shortestName
          isConnected
        }
      }
    }
  }
`;

export default query;
