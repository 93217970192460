import React from 'react';

import css from './Card.module.css';

function Card({ children, selected, className, ...props }) {
  return (
    <div
      data-cy="notes-card"
      className={`${css.card} ${className}`}
      data-selected={selected}
      {...props}
    >
      {children}
    </div>
  );
}

export default Card;
