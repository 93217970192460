const api = process.env.REACT_APP_API_URL;

const POST = ({ url = '/', body = {}, headers = {} }) => {
  return fetch(`${api}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  });
};

export const resetPassword = (password, resetToken) => {
  return POST({
    url: '/reset-pwd',
    body: { password, resetToken },
  }).then((data) => {
    const { status } = data;
    if ([200, 201].includes(status)) return true;
    throw new Error();
  });
};

const saveUserIdentityOnAtlas = ({ email, name, userId, userHash }) => {
  if (!window.Atlas || !window.Atlas.identify) return;
  window.Atlas.identify({ email, name, userId, userHash, isVisitor: false });
};

export const requestNewPassword = (email) => {
  return POST({
    url: '/send-pwd-reset',
    body: { email, scope: 'user' },
  }).then((data) => {
    const { status } = data;
    if ([200, 201].includes(status)) return true;
    throw new Error();
  });
};

export const login = (email, password) => {
  return POST({
    url: '/authentication',
    body: { email, password, strategy: 'local' },
  })
    .then((data) => data.json())
    .then((response) => {
      const { accessToken, authentication, user } = response;
      if (!accessToken) throw response;
      const { payload } = authentication;
      saveUserIdentityOnAtlas(payload);
      return {
        token: accessToken,
        trialRemainingTime: payload.trialRemainingTime,
        user,
      };
    });
};
