import { useSubscription } from '@apollo/client';
import { useState } from 'react';
import { SEMANTIC_SEARCH } from '../graphql/semanticSearch';

import { WebSocketClient } from '../../apollo/apollo';

function useSearch(question) {
  const [data, setData] = useState({
    answer: '',
    sources: null,
    answerCompleted: false,
  });
  const { loading } = useSubscription(SEMANTIC_SEARCH, {
    variables: { question },
    client: WebSocketClient,
    onData: (stream) => {
      const source = stream.data.data.semanticSearch.results;
      const textChunk = stream.data.data.semanticSearch.text;
      const isStreamEnd = stream.data.data.semanticSearch.endOfStream;

      setData(({ answer, answerCompleted }) => ({
        answer: answer + (textChunk ?? ''),
        answerCompleted: answerCompleted === true ? true : isStreamEnd,
        source,
      }));
    },
  });

  return { isLoading: loading, data };
}

export default useSearch;
