import { gql } from '@apollo/client';

const query = gql`
  query getTreeMapDetails(
    $countryId: ID!
    $industryId: ID!
    $startDate: String!
    $endDate: String!
  ) {
    getTreeMapDetails(
      countryId: $countryId
      industryId: $industryId
      startDate: $startDate
      endDate: $endDate
    ) {
      largestEventsCount
      data {
        id
        score
        eventsCount
        eventIds
        timestamp
      }
    }
  }
`;

export default query;
