import { gql } from '@apollo/client';

const getNotesForRegulationQuery = gql`
  query getNotesForRegulation($regulationId: ID!) {
    getNotesForRegulation(regulationId: $regulationId) {
      id
      note
      date
      summarized_name
      source
      userName
    }
  }
`;

export default getNotesForRegulationQuery;
