import { gql } from '@apollo/client';

const query = gql`
  query getEntityGraph($entityId: ID, $filters: GraphSearchFilters) {
    getEntityGraph(entityId: $entityId, filters: $filters) {
      nodes {
        id
        name
        image
        labels
        inSubscription
        isConnected
      }
      links {
        source
        target
        type
        strength
      }
    }
  }
`;

export default query;
