/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import { requestNewPassword } from '../../../../lib/client';
import Logo from '../../../../icons/Logo';

import notify from '../../../../lib/notify';
import { validateEmail } from '../utils';
import css from './ResetPassword.module.css';
import EmailInput from '../EmailInput/EmailInput';
import Help from '../Help/Help';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import InternalLink from '../../../../components/InternalLink/InternalLink';

const ResetPassword = () => {
  const router = useRouteInformation();

  const [hasFormErrors, setFormErrors] = useState(false);

  const [email, setEmail] = useState('');
  const [isValid, setValid] = useState(true);

  useEffect(() => {
    const validEmail = validateEmail(email);
    const validForm = validEmail;
    setValid(validForm);
    setFormErrors(false);
  }, [email]);

  const onClick = async () => {
    try {
      await requestNewPassword(email);
      notify.success('A reset link has been sent to your email');
    } catch (err) {
      notify.success('There was an error sending the link');
    }
  };

  const handleGoBack = () => router.navigateToLogin({});

  return (
    <div className={css.formContainer}>
      <div className={css.header}>
        <div className={css.logoContainer}>
          <Logo />
        </div>
        <Help className={css.headerHelp} />
      </div>
      <div className={css.form}>
        <p className={css.title}>Reset Password</p>
        <p className={css.subtitle}>
          Enter your email address and we&quot;ll send you a link to reset your
          password
        </p>
        <EmailInput
          className={css.inputContent}
          value={email}
          hasErrors={hasFormErrors}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className={css.footer}>
          <button
            type="button"
            className={css.button}
            onClick={onClick}
            disabled={!isValid}
          >
            Request new password
          </button>
          <Help className={css.footerHelp} />
          <InternalLink onClick={handleGoBack} className={css.back}>
            Back to Login
          </InternalLink>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
