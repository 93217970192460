import React from 'react';
import { BaseCard } from '../BaseCard/BaseCard';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import { formatDateMed } from '../../../../lib/date';
import css from './AppointmentCard.module.css';
import useNavigation from '../../../hooks/useNavigation';
import NotesOutline from '../../../../icons/NotesOutline';

function AppointmentCard({ appointment }) {
  const { toggleAppointmentDossier } = useNavigation();

  return (
    <BaseCard.Root>
      <BaseCard.Row>
        <BaseCard.LeftColumn>
          <BaseCard.DateRow date={appointment.startDate} />
          <BaseCard.Title
            onClick={() => toggleAppointmentDossier(appointment.id)}
          >
            {appointment.headline}
          </BaseCard.Title>
        </BaseCard.LeftColumn>

        <BaseCard.RightColumn>
          <BaseCard.InfoColumn title="Person">
            <EntityPill
              id={appointment.entity.id}
              image={appointment.entity.image}
              name={appointment.entity.name}
              shortName={appointment.entity.shortestName}
              tooltip={appointment.entity.tooltip}
              type={appointment.entity.type}
              isConnected={appointment.entity.isConnected}
              inSubscription={appointment.entity.inSubscription}
              autoOpenDossier
              size="small"
            />
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Title">
            {appointment.title}
          </BaseCard.InfoColumn>

          {appointment.organization && !appointment.organizationEntity && (
            <BaseCard.InfoColumn title="Organization">
              {appointment.organization}
            </BaseCard.InfoColumn>
          )}

          {appointment.organizationEntity && (
            <BaseCard.InfoColumn title="Organization">
              <EntityPill
                id={appointment.organizationEntity.id}
                image={appointment.organizationEntity.image}
                name={appointment.organizationEntity.name}
                shortName={appointment.organizationEntity.shortestName}
                tooltip={appointment.organizationEntity.tooltip}
                type={appointment.organizationEntity.type}
                isConnected={appointment.organizationEntity.isConnected}
                inSubscription={appointment.organizationEntity.inSubscription}
                autoOpenDossier
                size="small"
              />
            </BaseCard.InfoColumn>
          )}

          <BaseCard.InfoColumn title="Start Date">
            {formatDateMed(appointment.startDate)}
          </BaseCard.InfoColumn>
        </BaseCard.RightColumn>
      </BaseCard.Row>

      <BaseCard.BottomRow className={css.footer}>
        <div className={css.buttons}>
          <button
            type="button"
            className={css.button}
            onClick={() => toggleAppointmentDossier(appointment.id)}
          >
            <NotesOutline />
            Add Note
          </button>
        </div>
        <BaseCard.CardSources sources={[appointment.source]} />
      </BaseCard.BottomRow>
    </BaseCard.Root>
  );
}

export default AppointmentCard;
