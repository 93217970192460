import React, { useMemo } from 'react';

import css from './EventCard.module.css';
import { formatDateMed } from '../../../../../../../lib/date';
import EntityPill from '../../../../../../../components/EntityPill/EntityPill';
import BriefPill from '../../../../../../../components/BriefPill/BriefPill';
import Source from '../../../../../Source/Source';
import Score from '../../../../../Score/Score';
import { calcImportanceScore } from '../../../../../../shared/events';
import Summary from '../../../../../Summary/Summary';
import useNavigation from '../../../../../../hooks/useNavigation';

function EventCard({ event }) {
  const { toggleEventDossier } = useNavigation();

  const score = useMemo(
    () => calcImportanceScore(event.magnitude, event.sentiment),
    [event],
  );

  const briefs = useMemo(() => {
    if (!event.briefs) return [];

    return event.briefs.filter(({ id }) => id);
  }, [event]);

  const onTitleClick = (eventID) => {
    toggleEventDossier(eventID);
  };

  return (
    <div className={css.main} data-cy="event-card">
      <div
        className={css.title}
        data-cy="event-title"
        onClick={() => onTitleClick(event.id)}
      >
        {event.title}
      </div>
      <div className={css.date} data-cy="event-date">
        {formatDateMed(event.date)}

        <Score score={score} size="small" data-cy="event-score" />
      </div>
      <div className={css.entities} data-cy="event-entities">
        {event.entities.map((entity) => (
          <EntityPill
            key={entity.id}
            id={entity.id}
            autoOpenDossier
            name={entity.name}
            image={entity.image}
            isConnected={entity.isConnected}
            inSubscription={entity.inSubscription}
            type={entity.type}
            tooltip={entity.tooltip}
            size="small"
          />
        ))}
      </div>
      <div className={css.briefs} data-cy="event-briefs">
        {briefs.map((brief) => (
          <BriefPill key={brief.id} name={brief.name} tooltip={brief.tooltip} />
        ))}
        {event.countries.map((country) => (
          <BriefPill key={country.id} name={country.name} isGeography />
        ))}
      </div>

      <Summary summary={event.summary} bullets={event.bullets} />

      <div className={css.sources} data-cy="event-sources">
        {event.sources.map((source) => (
          <Source src={source} key={source} />
        ))}
      </div>
    </div>
  );
}

export default EventCard;
