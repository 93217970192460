import React from 'react';
import CloseIcon from '../../../../../icons/CloseIcon/CloseIcon';
import Button from '../../../Buttom/Buttom';
import css from './RemoveHomeFeed.module.css';

function RemoveHomeFeed({ onClose, onConfirm }) {
  return (
    <div className={css.main}>
      <div className={css.header}>
        <h2>Remove as your home feed</h2>
        <button type="button" className={css.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>

      <div className={css.copy}>
        This will reset your home feed to your default subscriptions
      </div>

      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="danger" onClick={onConfirm}>
          Remove
        </Button>
      </div>
    </div>
  );
}

export default RemoveHomeFeed;
