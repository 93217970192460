import { uniqBy } from 'lodash';

export const entityFilterInitialState = {
  query: '',
  searchResults: [],
  isPopupOpen: false,
  selectedOptions: [],
};

export const ACTIONS = {
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
  UPDATE_QUERY: 'UPDATE_QUERY',
  UPDATE_SEARCH_RESULTS: 'UPDATE_SEARCH_RESULTS',
  SELECT_OPTION: 'SELECT_OPTION',
  UNSELECT_OPTION: 'UNSELECT_OPTION',
  SYNC_SELECTED_OPTIONS: 'SYNC_SELECTED_OPTIONS',
  DESELECT_ALL: 'DESELECT_ALL',
};

const buildOptions = (entities = [], selected = false) => {
  return entities.map((entity) => ({ ...entity, selected }));
};

/**
 * @param state
 * @param {{type: ACTION, payload?: any }} action
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_POPUP: {
      return { ...state, isPopupOpen: true };
    }
    case ACTIONS.CLOSE_POPUP: {
      return { ...state, isPopupOpen: false, searchResults: [], query: '' };
    }
    case ACTIONS.UPDATE_QUERY:
      return { ...state, query: action.payload };
    case ACTIONS.UPDATE_SEARCH_RESULTS:
      return { ...state, searchResults: buildOptions(action.payload) };
    case ACTIONS.DESELECT_ALL:
      return {
        ...state,
        searchResults: [],
        selectedOptions: state.selectedOptions.map((item) => ({
          ...item,
          selected: false,
        })),
      };
    case ACTIONS.SELECT_OPTION: {
      const results = state.searchResults.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, selected: true };
        }
        return item;
      });

      const updatedSelectedOptions = uniqBy(
        [...state.selectedOptions, { ...action.payload, selected: true }],
        'id',
      );
      return {
        ...state,
        searchResults: results,
        selectedOptions: updatedSelectedOptions,
      };
    }
    case ACTIONS.UNSELECT_OPTION: {
      const results = state.searchResults.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, selected: false };
        }
        return item;
      });

      const updatedSelectedOptions = state.selectedOptions.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, selected: false };
        }
        return item;
      });

      return {
        ...state,
        searchResults: results,
        selectedOptions: updatedSelectedOptions,
      };
    }
    case ACTIONS.SYNC_SELECTED_OPTIONS: {
      return { ...state, selectedOptions: buildOptions(action.payload, true) };
    }
    default:
      return state;
  }
};
