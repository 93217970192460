import React, { useState } from 'react';

import { Popover } from 'react-tiny-popover';
import css from './NoteCard.module.css';
import Edit from '../../../../icons/Edit';
import DeleteIcon from '../../../../icons/Trash';
import IconButton from '../../IconButton/IconButton';
import More from '../../../../icons/More';
import { formatDateMed } from '../../../../lib/date';

function NoteCard({ note, onEdit, onDelete }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={css.card}>
      <div className={css.header}>
        <p>{note.note}</p>
        <Popover
          isOpen={isMenuOpen}
          positions={['left', 'bottom']}
          containerClassName={css.menu}
          onClickOutside={(e) => {
            e.stopPropagation();
            setIsMenuOpen(!isMenuOpen);
          }}
          content={
            <div>
              <div
                className={css.menuItem}
                data-cy="compliant-card-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMenuOpen(false);
                  onEdit(note);
                }}
              >
                <Edit className={css.editIcon} /> Edit note
              </div>
              <div
                className={css.menuItem}
                data-cy="compliant-card-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMenuOpen(false);
                  onDelete(note);
                }}
              >
                <DeleteIcon className={css.deleteIcon} /> Delete note
              </div>
            </div>
          }
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setIsMenuOpen(true);
            }}
          >
            <More />
          </IconButton>
        </Popover>
      </div>
      <div className={css.divider} />
      <div className={css.date}>{formatDateMed(note.date)}</div>
    </div>
  );
}

export default NoteCard;
