/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import InternalLink from '../../../../components/InternalLink/InternalLink';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import Password from '../../../../icons/LoginLock';
import Eye from '../../../../icons/LoginEye';
import css from './PasswordInput.module.css';

const PasswordInput = ({
  className = '',
  label = 'Password',
  value = '',
  hasErrors = false,
  showVisibleIcon = true,
  showForgotPassword = true,
  onChange = () => {},
  onSubmit = () => {},
}) => {
  const router = useRouteInformation();
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);

  const handleForgotPassword = () => router.navigateToPasswordReset();

  return (
    <div className={className}>
      <div className={css.labelContainer}>
        <p className={css.label}>{label}</p>
        {showForgotPassword && (
          <InternalLink
            className={css.forgotPassword}
            onClick={handleForgotPassword}
          >
            Forgot password?
          </InternalLink>
        )}
      </div>
      <div className={css.inputContent}>
        <div className={css.icon} data-position="left">
          <Password color={passwordIsFocused === true ? '#8f59ff' : null} />
        </div>
        <input
          name="password"
          onBlur={() => setPasswordIsFocused(false)}
          onFocus={() => setPasswordIsFocused(true)}
          onChange={onChange}
          onKeyUp={(e) => e.key === 'Enter' && onSubmit()}
          value={value}
          className={css.input}
          data-status={hasErrors ? 'error' : 'normal'}
          type={isShowPassword ? 'text' : 'password'}
        />
        {showVisibleIcon && (
          <div
            className={css.icon}
            data-position="right"
            onClick={() => setShowPassword((c) => !c)}
          >
            <Eye color={isShowPassword === true ? '#8f59ff' : null} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordInput;
