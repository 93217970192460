import React, { useEffect, useState } from 'react';
import css from './SavedSearchFilter.module.css';
import listSavedSearchs from '../../../../../queries/listSavedSearches';
import deleteSavedSearchMutation from '../../../../../queries/deleteSavedSearch';
import { useApolloClient } from '../../../../../hooks/useApolloClient';
import arrowBack from '../../../../../icons/arrow_back.svg';
import SearchIcon from '../../../../../icons/Search';
import notify from '../../../../../lib/notify';
import SavedSearchCard from '../../../../../components/SavedSearchCard/SavedSearchCard';

const findSavedSearch = (client, searchText) => {
  return client
    .query({
      query: listSavedSearchs,
      variables: { name: searchText, graphEnabled: true },
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => data.listSavedSearchs.savedSearches);
};

const deleteSavedSearch = (client, savedSearchId) => {
  return client
    .mutate({
      mutation: deleteSavedSearchMutation,
      variables: { savedSearchId },
    })
    .then(({ data }) => data.deleteSavedSearch);
};

const SavedSearch = ({ setGraphFilterIndustries, setCenterId }) => {
  const [showSavedSearches, setShowSavedSearches] = useState(false);
  const [refreshSavedSearches, setRefreshSavedSearches] = useState(false);
  const [searchSavedName, setSearchSavedName] = useState('');
  const [savedSearches, setSavedSearches] = useState([]);
  const client = useApolloClient();

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSavedSearches(await findSavedSearch(client, searchSavedName));
      e.target.blur();
    }
  };

  const handleClearSearchBar = async () => {
    if (searchSavedName) {
      setSearchSavedName('');
      setSavedSearches(await findSavedSearch(client, ''));
    }
  };

  useEffect(() => {
    client
      .query({
        query: listSavedSearchs,
        variables: { graphEnabled: true },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) =>
        setSavedSearches(data.listSavedSearchs.savedSearches),
      );
  }, [refreshSavedSearches]);

  const onCardSearchIconClick = (savedSearch, searchName) => {
    // setGraphFilterCategory(savedSearch.filterCategories);
    setGraphFilterIndustries(savedSearch.filterIndustries);
    if (savedSearch.filterEntities.length > 0) {
      setCenterId(savedSearch.filterEntities[0].id);
    }
    setShowSavedSearches(false);
    notify.success(
      <div>
        <p>
          <b>Viewing saved search results</b>
        </p>
        {`You are currently viewing a search titled ${searchName}!`}
      </div>,
      { position: 'bottom-right' },
    );
  };

  const onCardDeleteIconClick = async (savedSearchId) => {
    deleteSavedSearch(client, savedSearchId);
    const updatedSavedSearches = savedSearches.filter(
      (savedSearch) => savedSearch.id !== savedSearchId,
    );
    setSavedSearches(updatedSavedSearches);
  };

  return (
    <>
      <div
        className={css.extraFilterRow}
        onClick={() => setShowSavedSearches(true)}
      >
        <span className={css.extraFilterRowType}>Saved Searches</span>
        <span className={css.extraFilterRowTotal}>{savedSearches.length}</span>
      </div>
      <div
        className={
          showSavedSearches ? css.listSavedSearches : css.hideSavedSearches
        }
      >
        <div className={css.listSavedSearchesHeader}>
          <span onClick={() => setShowSavedSearches(false)}>
            <img data-cy="saved-searches-back" src={arrowBack} alt="back" />
          </span>
        </div>
        <div className={css.listSavedSearchesBody}>
          <div className={css.listSavedSearchesBodyHeader}>Saved Searches</div>
          <div className={css.searchArea}>
            <SearchIcon className={css.searchIcon} />
            <input
              data-cy="search-input"
              className={css.input}
              type="text"
              value={searchSavedName}
              onChange={(e) => setSearchSavedName(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Search by Name"
            />
            {searchSavedName && (
              <span
                data-cy="search-clear"
                className={css.clear}
                onClick={handleClearSearchBar}
              />
            )}
          </div>
          {savedSearches.map((savedSearch) => (
            <SavedSearchCard
              key={savedSearch.id}
              savedSearch={savedSearch}
              onClick={onCardSearchIconClick}
              onDelete={onCardDeleteIconClick}
              refreshSavedSearches={() =>
                setRefreshSavedSearches(!refreshSavedSearches)
              }
              source="explore"
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SavedSearch;
