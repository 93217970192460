import { gql } from '@apollo/client';

const query = gql`
  mutation toggleUserConnection(
    $entityId: ID!
    $note: String
    $flag: Boolean!
  ) {
    toggleUserConnection(entityId: $entityId, note: $note, flag: $flag)
  }
`;

export default query;
