import React from 'react';
import CloseIcon from '../../../../icons/CloseIcon/CloseIcon';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import css from './CloseDossierButton.module.css';

const CloseDossierButton = () => {
  const router = useRouteInformation();
  return (
    <div
      className={css.button}
      onClick={router.closeDossier}
      data-cy="dossier-close"
    >
      <CloseIcon className={css.icon} />
    </div>
  );
};

export default CloseDossierButton;
