import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M10.667 7.333V6h4v1.333h-4zM6 8a2.568 2.568 0 01-1.883-.783 2.568 2.568 0 01-.783-1.884c0-.733.26-1.36.783-1.883A2.568 2.568 0 016 2.667c.734 0 1.361.26 1.884.783.522.522.783 1.15.783 1.883 0 .734-.261 1.361-.783 1.884A2.568 2.568 0 016 8zM.667 13.333v-1.866c0-.378.097-.725.292-1.042.194-.317.452-.558.775-.725a9.898 9.898 0 012.1-.775 9.172 9.172 0 014.333 0c.711.172 1.411.43 2.1.775.322.167.58.408.775.725.194.317.292.664.292 1.042v1.866H.667zM2 12h8v-.533a.648.648 0 00-.333-.567 8.69 8.69 0 00-1.817-.675 7.734 7.734 0 00-3.7 0 8.69 8.69 0 00-1.816.675.648.648 0 00-.334.567V12zm4-5.333c.367 0 .68-.13.942-.392.261-.261.392-.575.392-.942 0-.366-.13-.68-.392-.941A1.284 1.284 0 006 4c-.366 0-.68.13-.941.392a1.284 1.284 0 00-.392.941c0 .367.13.68.392.942.26.261.575.392.941.392z"
          fill="#929AA3"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
