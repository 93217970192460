import { MAX_LINK_TEXT_FONT_SIZE } from './graph.constants';
import { clamp } from './graph.utils';

export const isSameLink = (link1, link2) => {
  const sameSource = link1.source.id === link2.source.id;
  const sameTarget = link1.target.id === link2.target.id;
  return sameSource && sameTarget;
};

export const isLinkRelevant = ({ link, highlightLinks }) => {
  const isHighlighted = highlightLinks.some((hLink) => isSameLink(hLink, link));
  return isHighlighted;
};

export const getLinkName = (link) => {
  const { type = '' } = link;
  return ` ${type.replaceAll('_', ' ')} `;
};

export const getLinkOpacity = (strength, linkToCenter) => {
  if (strength >= 5) return linkToCenter ? 1 : 0.5;
  if (strength >= 4) return linkToCenter ? 0.8 : 0.3;
  if (strength >= 3) return linkToCenter ? 0.3 : 0.2;
  if (strength >= 2) return linkToCenter ? 0.15 : 0.1;
  return linkToCenter ? 0.1 : 0.05;
};

export const getLinkColor = ({
  centerNodeId,
  link,
  isHighlighted,
  hasHighlightedElements,
}) => {
  if (isHighlighted) return `rgba(120, 120, 120, 1)`;
  const { strength = 0, source, target } = link;
  const linkToCenter = centerNodeId
    ? [source.id, target.id].includes(centerNodeId)
    : true;
  const opacity = hasHighlightedElements
    ? 0.05
    : getLinkOpacity(strength, linkToCenter);

  return `rgba(120, 120, 120, ${opacity})`;
};

export const getLinkLength = (link) => {
  const { source, target } = link;
  const linkVector = {
    x: target.x - source.x,
    y: target.y - source.y,
  };
  return Math.sqrt(linkVector.x ** 2 + linkVector.y ** 2);
};

export const getLinkMidPoint = (link) => {
  const { source, target } = link;
  return {
    x: source.x + (target.x - source.x) / 2,
    y: source.y + (target.y - source.y) / 2,
  };
};

export const getLinkTextAngle = (link) => {
  const { source, target } = link;
  const linkVector = {
    x: target.x - source.x,
    y: target.y - source.y,
  };

  const textAngle = Math.atan2(linkVector.y, linkVector.x);
  // Maintain label vertical orientation for legibility
  if (textAngle > Math.PI / 2) return textAngle - Math.PI;
  if (textAngle < -Math.PI / 2) return Math.PI + textAngle;
  return textAngle;
};

export const getTextWidth = ({ context, text, fontSize }) => {
  context.font = `${fontSize}px Sans-Serif`;
  return context.measureText(text).width;
};

export const getLinkTextFontSize = ({ context, text, maxTextLength }) => {
  const textWidth = getTextWidth({ context, text, fontSize: 1 });
  const computedFontSize = maxTextLength / textWidth;
  const fontSize = Math.min(MAX_LINK_TEXT_FONT_SIZE, computedFontSize);
  return fontSize;
};

export const paintLinkText = ({
  context,
  position,
  dimensions,
  color,
  fontSize = 1,
  label,
}) => {
  const { x, y } = position;
  const { width, height } = dimensions;
  context.font = `${fontSize}px Sans-Serif`;
  context.save();
  context.translate(x, y);
  context.fillStyle = 'white';
  context.fillRect(-width / 2, -height / 2, width, height);
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillStyle = color;
  context.fillText(label, 0, 0);
  context.restore();
  return context;
};

const getTargetPillDimensions = ({ context, target, scale }) => {
  context.save();
  const hasImage = target.image && target.labels.includes('Actor');

  const fontSize = clamp(12 / scale, 2, 5);
  context.font = `${fontSize}px Sans-Serif`;
  const textWidth = context.measureText(target.label).width;

  const pillDetails = {
    x: textWidth + fontSize * 1.5,
    y: fontSize * 2.5,
  };

  const pillDimensions = {
    x: target.x - pillDetails.x / 2 - (hasImage ? fontSize * 2 : 0),
    y: target.y - pillDetails.y / 2,
    width: pillDetails.x + (hasImage ? fontSize * 2 : 0),
    height: pillDetails.y,
  };

  /*
   * If you want to debug the pill dimensions, uncomment the following lines
   * context.rect(pillDimensions.x, pillDimensions.y, pillDimensions.width, pillDimensions.height);
   * context.stroke();
   */

  context.restore();
  return pillDimensions;
};

const getIntersectionCoordinates = ({ source, target, pillDimensions }) => {
  // Safety padding separates the arrows a bit from the pill
  const SAFETY_PADDING = 0;

  const linkVector = {
    h: source.x - target.x,
    v: source.y - target.y,
  };

  const linkAngle = Math.atan2(linkVector.v, linkVector.h);

  const { x: originX, y: originY, height, width } = pillDimensions;

  const linkTangent = Math.abs(linkVector.v / linkVector.h);
  const nodeTangent = Math.abs(height / width);

  /*
   * If this condition is met this means the link is
   * intersecting with the node from the bottom edge.
   * So we know the Y coordinate of the intersection
   * And all we do is obtain the X coordinate with simple math.
   */
  if (linkTangent > nodeTangent && linkAngle > 0) {
    const fixedCoordinate = originY + height + SAFETY_PADDING;
    const ratio = (fixedCoordinate - source.y) / (target.y - source.y);
    return {
      y: fixedCoordinate,
      x: ratio * (target.x - source.x) + source.x,
    };
  }

  /*
   * Same principle as above, except the intersection is in the top edge.
   * We have a fixed Y coordinate and obtain the X coordinate for the intersection
   */
  if (linkTangent > nodeTangent) {
    const fixedCoordinate = originY - SAFETY_PADDING;
    const ratio = (fixedCoordinate - source.y) / (target.y - source.y);
    return {
      y: fixedCoordinate,
      x: ratio * (target.x - source.x) + source.x,
    };
  }

  /*
   * Same principle as above, but the intersection comes from the left edge
   * So we have a fixed X coordinate and we just need to find the Y coordinate
   */
  if (Math.abs(linkAngle) > Math.PI / 2) {
    const fixedCoordinate = originX - SAFETY_PADDING;
    const ratio = (fixedCoordinate - source.x) / (target.x - source.x);
    return {
      x: fixedCoordinate,
      y: ratio * (target.y - source.y) + source.y,
    };
  }

  /*
   * The remaining condition is that the intersection comes from the right edge
   * So we have a fixed X coordinate and we just need to find the Y coordinate
   */
  const fixedCoordinate = originX + width + SAFETY_PADDING;
  const ratio = (fixedCoordinate - source.x) / (target.x - source.x);
  return {
    x: fixedCoordinate,
    y: ratio * (target.y - source.y) + source.y,
  };
};

const getArrowEdges = ({ source, target, intersection }) => {
  // Arrow dimensions
  const ARROW_HEAD_ANGLE = Math.PI / 12;
  const ARROW_HEAD_SIZE = 10;

  const linkVector = {
    h: source.x - target.x,
    v: source.y - target.y,
  };

  const linkAngle = Math.atan2(linkVector.v, linkVector.h);

  return [
    {
      x:
        intersection.x +
        Math.cos(linkAngle + ARROW_HEAD_ANGLE) * ARROW_HEAD_SIZE,
      y:
        intersection.y +
        Math.sin(linkAngle + ARROW_HEAD_ANGLE) * ARROW_HEAD_SIZE,
    },
    {
      x:
        intersection.x +
        Math.cos(linkAngle - ARROW_HEAD_ANGLE) * ARROW_HEAD_SIZE,
      y:
        intersection.y +
        Math.sin(linkAngle - ARROW_HEAD_ANGLE) * ARROW_HEAD_SIZE,
    },
  ];
};

export const paintLinkArrow = ({ context, link, scale, color }) => {
  const { source, target } = link;

  /*
   * We need to draw a directional arrow for the link.
   * So we need to determine the target's pill dimensions.
   */
  const pillDimensions = getTargetPillDimensions({ context, target, scale });

  /*
   * Then we need to determine the intersection point of the link and the pill.
   * This depends on the angles and there is a lot of math involved.
   * We've also left some padding just in case!
   */
  const intersection = getIntersectionCoordinates({
    source,
    target,
    pillDimensions,
  });

  const arrowEdges = getArrowEdges({ source, target, intersection });

  context.save();
  context.fillStyle = color;
  context.beginPath();
  context.moveTo(intersection.x, intersection.y);
  context.lineTo(arrowEdges[0].x, arrowEdges[0].y);
  context.lineTo(arrowEdges[1].x, arrowEdges[1].y);
  context.fill();
  context.restore();
};
