import { gql } from '@apollo/client';

const query = gql`
  query getTreeMap(
    $countryIds: [String]
    $industryIds: [String]
    $startDate: String
    $endDate: String
  ) {
    getTreeMap(
      countryIds: $countryIds
      industryIds: $industryIds
      startDate: $startDate
      endDate: $endDate
    ) {
      fromCache
      ttl
      totalEvents
      nodes {
        industry {
          id
          name
        }
        country {
          id
          name
        }
        occurrences
        score
        areaPercentage
      }
    }
  }
`;

export default query;
