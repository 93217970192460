import { gql } from '@apollo/client';

const getNotesForEventQuery = gql`
  query getNotesForEvent($eventId: ID!) {
    getNotesForEvent(eventId: $eventId) {
      id
      note
      title
      source
      userName
      date
    }
  }
`;

export default getNotesForEventQuery;
