import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Loading } from '@axis/xyz.app.loading';
import EntityPill from '../../components/EntityPill/EntityPill';
import DossierCard from '../../components/DossierCard/DossierCard';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import css from './SharedRoles.module.css';

const PAGE_SIZE = 1;
const SAFETY_PAGE_BUFFER = 2;

const getRolePage = (roles, page) => {
  const start = (page - 1) * 1;
  const end = start + 1;
  return roles.slice(start, end);
};

const SharedRoles = ({ roles = [], total, isLoading, onLoadMore }) => {
  const router = useRouteInformation();

  const [page, setPage] = useState(1);

  const availablePages = Math.ceil(roles.length / PAGE_SIZE);
  const totalPages = Math.ceil(total / PAGE_SIZE);
  const visibleRoles = getRolePage(roles, page);
  const isAllRolesLoaded = roles.length === total;
  const isEmpty = visibleRoles.length === 0;

  const isPageLoading =
    (!isAllRolesLoaded && page * PAGE_SIZE > roles.length) ||
    (page === 1 && isLoading);

  useEffect(() => {
    if (isAllRolesLoaded) return;
    if (availablePages - page < SAFETY_PAGE_BUFFER && !isLoading) {
      onLoadMore();
    }
  }, [page]);

  const handleEntityClick = (entityId) => {
    router.openDossier({ dossierEntityId: entityId, dossierType: 'entity' });
  };

  const getRoleDescription = (name, position, end) => `
    ${name} ${end ? 'was' : 'is'} ${position}
  `;

  const getOverlap = (overlapStart, overlapEnd, entityName) => {
    if (!overlapStart)
      return `These two entities did not work at ${entityName} at the same time`;

    if (
      overlapEnd &&
      new Date(overlapStart).getTime() > new Date(overlapEnd).getTime()
    )
      return `These two entities did not work at ${entityName} at the same time`;

    if (overlapStart && !overlapEnd)
      return `Overlapped between ${DateTime.fromISO(overlapStart).toFormat(
        'LLL yyyy',
      )} - Present`;

    return `Overlapped between ${DateTime.fromISO(overlapStart).toFormat(
      'LLL yyyy',
    )} - ${DateTime.fromISO(overlapEnd).toFormat('LLL yyyy')}`;
  };

  const showCard = (rolesData) =>
    rolesData.length > 0 ? (
      <DossierCard
        loading={isPageLoading}
        title="Roles"
        count={total}
        currentPage={page}
        totalPages={totalPages}
        showPages
        onPageChange={setPage}
      >
        <div className={css.container}>
          {isEmpty && !isLoading && (
            <div className={css.empty}>
              These entities do not have any shared role
            </div>
          )}
          {visibleRoles.map(({ employerEntity: entity, ...data }, i) => (
            <div key={i}>
              <div className={css.entity}>
                <EntityPill
                  key={entity.id}
                  size="medium"
                  name={entity.name}
                  shortName={entity.shortestName}
                  image={entity.image}
                  type={entity.type}
                  inSubscription={entity.inSubscription}
                  onClick={() => handleEntityClick(entity.id)}
                />
              </div>
              <ul style={{ marginLeft: '20px' }}>
                <li className={css.role}>
                  {getRoleDescription(
                    data.sourceEntityName,
                    data.sourceEntityPosition,
                    data.sourceEntityEndDate,
                  )}
                </li>
                <li className={css.role}>
                  {getRoleDescription(
                    data.targetEntityName,
                    data.targetEntityPosition,
                    data.targetEntityEndDate,
                  )}
                </li>
                <li className={css.role}>
                  {getOverlap(
                    data.roleOverlapStart,
                    data.roleOverlapEnd,
                    entity.name,
                  )}
                </li>
              </ul>
            </div>
          ))}
        </div>
      </DossierCard>
    ) : null;

  return (
    <div>
      {isLoading ? (
        <div className={css.loadingArea}>
          <Loading className={css.loading} />
        </div>
      ) : (
        showCard(roles)
      )}
    </div>
  );
};

export default SharedRoles;
