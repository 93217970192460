export const ACTIONS = {
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
  EXPAND_OPTION: 'EXPAND_OPTION',
  COLLAPSE_OPTION: 'COLLAPSE_OPTION',
  SELECT_OPTION: 'SELECT_OPTION',
  UNSELECT_OPTION: 'UNSELECT_OPTION',
  UPDATE_QUERY: 'UPDATE_QUERY',
  UPDATE_OPTIONS: 'UPDATE_OPTIONS',
  SYNC_SELECTED_OPTIONS: 'SYNC_SELECTED_OPTIONS',
};

const getNodeAndChildIds = (parent) => {
  if (!parent.children.length) return [parent.id];

  const allChildIds = [];

  function traverse(node) {
    allChildIds.push(node.id);

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        traverse(child);
      }
    }
  }

  traverse(parent);
  return allChildIds;
};

/**
 * @param state
 * @param {{type: ACTION, payload?: any }} action
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_OPTIONS:
      return { ...state, options: action.payload };
    case ACTIONS.OPEN_POPUP:
      return {
        ...state,
        isPopupOpen: true,
        selectedOptions: action.payload.map(({ id }) => id),
      };
    case ACTIONS.CLOSE_POPUP:
      return {
        ...state,
        isPopupOpen: false,
        expandedOptions: [],
        selectedOptions: [],
        query: '',
      };
    case ACTIONS.EXPAND_OPTION:
      return {
        ...state,
        expandedOptions: [...state.expandedOptions, action.payload],
      };
    case ACTIONS.COLLAPSE_OPTION:
      return {
        ...state,
        expandedOptions: state.expandedOptions.filter(
          (item) => item !== action.payload,
        ),
      };
    case ACTIONS.SELECT_OPTION:
      return {
        ...state,
        selectedOptions: [
          ...state.selectedOptions,
          ...getNodeAndChildIds(action.payload),
        ],
      };
    case ACTIONS.UNSELECT_OPTION: {
      const removeSelectionIds = getNodeAndChildIds(action.payload);
      return {
        ...state,
        selectedOptions: state.selectedOptions.filter(
          (id) => !removeSelectionIds.includes(id),
        ),
      };
    }
    case ACTIONS.UPDATE_QUERY:
      return { ...state, query: action.payload };
    case ACTIONS.SYNC_SELECTED_OPTIONS:
      return { ...state, selectedOptions: action.payload.map(({ id }) => id) };
    default:
      return state;
  }
};
