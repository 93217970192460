import React, { createContext, useContext, useEffect } from 'react';
import { useIdentity } from './IdentityContext';
import useRageClickTracker from '../hooks/useRageClickTracker';

const collectMetrics = process.env.REACT_APP_COLLECT_METRICS === 'enabled';

/**
 * WARNING: The events and parameters here are picked up automatically by
 * Google Tag Manager and then sent to Google Analytics for tracking.
 *
 * Adding, modifying or removing any data here will probably mean updating
 * the configuration in GTM as well! Make sure to keep them in sync!
 */

export const sendEvent = (type, data = {}) => {
  if (!collectMetrics) return;
  // Google Analytics
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: type, ...data });
};

const Context = createContext();

export const AnalyticsTrackingContext = ({ children }) => {
  const { user } = useIdentity();

  useEffect(() => {
    if (!collectMetrics) return;
    window.dataLayer = window.dataLayer || [];
    if (user && user.id) {
      window.dataLayer.push({
        user_id: user.id || '',
        axis_id: user.id || '',
        axis_email: user.email || '',
        axis_name: user.name || '',
      });
    }
  }, [user]);

  useRageClickTracker();

  return <Context.Provider value={{ sendEvent }}>{children}</Context.Provider>;
};

export const useAnalytics = () => useContext(Context);
