import { useState } from 'react';

export default function useCardPagination(list) {
  const [state, setState] = useState(() => {
    if (!list) return null;

    return {
      totalPages: list?.length,
      currentIndex: 0,
      data: list?.[0],
    };
  });

  const onNextPage = () => {
    setState((current) => {
      const nextIndex = current.currentIndex + 1;
      return {
        ...current,
        currentIndex: nextIndex,
        data: list[nextIndex],
      };
    });
  };

  const onPreviousPage = () => {
    setState((current) => {
      const nextIndex = current.currentIndex - 1;
      return {
        ...current,
        currentIndex: nextIndex,
        data: list[nextIndex],
      };
    });
  };

  const resetState = (array) => {
    setState(() => {
      if (!array) return null;

      return {
        totalPages: array?.length,
        currentIndex: 0,
        data: array?.[0],
      };
    });
  };

  return {
    state,
    pagination: { ...(state || {}), onNextPage, onPreviousPage },
    resetState,
  };
}
