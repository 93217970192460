import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteInformation } from '../../contexts/RouteInformationContext';
import { useIdentity } from '../../contexts/IdentityContext';
import LoginForm from './components/LoginForm/LoginForm';
import ResetPassword from './components/ResetPassword/ResetPassword';
import RestorePassword from './components/RestorePassword/RestorePassword';

import css from './Login.module.css';

const Login = () => {
  const { token, user } = useIdentity();
  const router = useRouteInformation();

  if (token && user.id) {
    /*
     * The "state" property can be set by the router
     * But it can also be null if the router has not interacted with the history yet
     */
    const { state = {} } = router.location;
    if (state && state.from) {
      router.navigateToLocation(state.from);
    } else {
      router.navigateToHome();
    }
    return null;
  }

  return (
    <div className={css.container}>
      <Switch>
        <Route path="/login/reset" component={ResetPassword} />
        <Route path="/login/restore" component={RestorePassword} />
        <Route path="/login" component={LoginForm} />
      </Switch>
    </div>
  );
};

export default Login;
