import { gql } from '@apollo/client';

const query = gql`
  query getSearchGraph(
    $filters: SearchGraphFilters
    $name: String
    $topics: [ID]
    $filterIndustries: [ID]
    $filterEntities: [ID]
    $semanticSearchIds: [ID]
  ) {
    getSearchGraph(
      filters: $filters
      name: $name
      topics: $topics
      filterIndustries: $filterIndustries
      filterEntities: $filterEntities
      semanticSearchIds: $semanticSearchIds
    ) {
      nodes {
        id
        name
        image
        labels
        inSubscription
        isConnected
      }
      links {
        source
        target
        type
        strength
      }
    }
  }
`;

export default query;
