export const DossierType = {
  DossierType: 'dossierType',
  Entity: 'entity',
  Event: 'eventDetails',
  Regulation: 'regulationDetails',
  Appointment: 'appointmentDetails',
  Quote: 'quoteDetails',
};

export const UrlParam = {
  eventId: 'dossierEventId',
  regulationId: 'regulationId',
  entityId: 'dossierEntityId',
  appointmentId: 'dossierAppointmentId',
  quoteId: 'dossierQuoteId',
  noteId: 'noteId',
  viewQuote: 'viewQuote',
};
