import React from 'react';
import DossierCard from '../components/DossierCard/DossierCard';
import { useRouteInformation } from '../contexts/RouteInformationContext';
import EntityPill from '../components/EntityPill/EntityPill';
import RelationshipEvents from './RelationshipEvents/RelationshipEvents';
import SharedRoles from './SharedRoles/SharedRoles';
import css from './Dossier.module.css';
import SharedLocations from './SharedLocations/SharedLocations';
import SharedIndustries from './SharedIndustries/SharedIndustries';
import SharedLinks from './SharedLinks/SharedLinks';
import Summary from './Summary/Summary';
import AxisUserGuard from '../v2/components/AxisUserGuard/AxisUserGuard';

const Dossier = ({
  dossierRelationships,
  // Shared events
  dossierEvents,
  totalDossierEvents,
  isEventsLoading,
  onLoadMoreEvents,
  // Shared roles
  sharedRoles,
  totalSharedRoles,
  isRoleLoading,
  onLoadMoreRoles,
  // Shared locations
  sharedLocations,
  totalSharedLocations,
  isLocationsLoading,
  onLoadMoreLocations,
  // Shared industries
  sharedIndustries,
  totalSharedIndustries,
  isIndustriesLoading,
  onLoadMoreIndustries,
  // Shared links
  sharedLinks,
  totalSharedLinks,
  isLinksLoading,
  onLoadMoreLinks,
}) => {
  const { sourceEntity, targetEntity, summary } = dossierRelationships;
  const router = useRouteInformation();

  const handleEntityClick = (entityId) => {
    router.openDossier({ dossierEntityId: entityId, dossierType: 'entity' });
  };

  const noTimelineAvailable = () =>
    !sharedRoles.length &&
    !isRoleLoading &&
    !sharedLocations.length &&
    !isLocationsLoading &&
    !sharedIndustries.length &&
    !isIndustriesLoading &&
    !sharedLinks.length &&
    !isLinksLoading &&
    !dossierEvents.length &&
    !isEventsLoading;

  return (
    <div data-cy="dossier-relationship" className={css.dossier}>
      <div className={css.header}>
        <div className={css.title}>
          <h1 className={css.titleText}>Relationship Details</h1>
        </div>
        <div className={css.entities}>
          {[sourceEntity, targetEntity].map((entity) => (
            <EntityPill
              key={entity.id}
              size="small"
              name={entity.name}
              shortName={entity.shortestName}
              image={entity.image}
              type={entity.type}
              inSubscription={entity.inSubscription}
              onClick={() => handleEntityClick(entity.id)}
            />
          ))}
        </div>
        {/* {earliestDate ? (
          <div className={css['range-container']}>
            <div className={css.date}>
              <CalendarIcon className={css.calendar} />
              <p className={css.text}>
                {DateTime.fromISO(
                  new Date(earliestDate).toISOString(),
                ).toFormat('LLLL yyyy')}{' '}
                - Present
              </p>
            </div>
          </div>
        ) : null} */}
      </div>
      <hr className={css.line} />
      <div className={css.content}>
        <AxisUserGuard>
          <Summary summary={summary} />
        </AxisUserGuard>

        <SharedRoles
          roles={sharedRoles}
          total={totalSharedRoles}
          isLoading={isRoleLoading}
          onLoadMore={onLoadMoreRoles}
        />
        <SharedLocations
          locations={sharedLocations}
          total={totalSharedLocations}
          isLoading={isLocationsLoading}
          onLoadMore={onLoadMoreLocations}
        />
        <SharedIndustries
          industries={sharedIndustries}
          total={totalSharedIndustries}
          isLoading={isIndustriesLoading}
          onLoadMore={onLoadMoreIndustries}
        />
        <SharedLinks
          links={sharedLinks}
          total={totalSharedLinks}
          isLoading={isLinksLoading}
          onLoadMore={onLoadMoreLinks}
        />
        <RelationshipEvents
          events={dossierEvents}
          totalEvents={totalDossierEvents}
          isEventsLoading={isEventsLoading}
          onLoadMore={onLoadMoreEvents}
        />
        {noTimelineAvailable() && (
          <DossierCard
            loading={false}
            title="Shared Timeline"
            count={0}
            currentPage={1}
            totalPages={0}
            showPages
          >
            <div className={css.container}>
              <div className={css.empty}>
                These entities do not have any timeline
              </div>
            </div>
          </DossierCard>
        )}
      </div>
    </div>
  );
};

export default Dossier;
