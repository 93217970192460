import React, { memo } from 'react';

import css from './NodeMenu.module.css';

function NodeMenu({
  node,
  handleSetNodeFocus,
  hideEntity,
  hideRelationships,
  expandNode,
}) {
  return (
    <div className={css.menu}>
      <ul>
        {!node.expanded && (
          <li>
            <span
              onClick={() => {
                expandNode(node);
              }}
            >
              Show connected entities
            </span>
          </li>
        )}

        {node.expanded && (
          <li>
            <span
              onClick={() => {
                hideRelationships(node);
              }}
            >
              Hide relationships
            </span>
          </li>
        )}

        <li>
          <span
            onClick={() => {
              hideEntity(node);
            }}
          >
            Hide entity
          </span>
        </li>

        <li>
          <span
            onClick={() => {
              handleSetNodeFocus(node);
            }}
          >
            Set as focused entity
          </span>
        </li>
      </ul>
    </div>
  );
}

export default memo(NodeMenu);
