import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '../apollo/apollo';

const ApolloClient = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export const useApolloClient = () => client;

export default ApolloClient;
