import React from 'react';
import { ResultEntity, ResultFacet } from '../ResultCards/ResultCards';
import styles from './CategoryResults.module.css';
import AnimatedLogo from '../../../../v2/components/AxisAnimatedLogo/AnimatedLogo';

export const EntityCategoryResults = ({
  className = '',
  name = '',
  total = 0,
  entities = [],
  onClick,
}) => {
  if (total === 0) return null;
  return (
    <div
      data-cy="search-results-category"
      className={`${styles.category} ${className}`}
    >
      <p className={styles.title}>{name}</p>
      <div className={styles.results}>
        {entities.map((entity) => (
          <ResultEntity key={entity.id} entity={entity} />
        ))}
      </div>
      {total > entities.length && (
        <button
          data-cy="search-results-pagination-button"
          type="button"
          className={styles.more}
          onClick={onClick}
        >
          View all {total}
        </button>
      )}
    </div>
  );
};

export const FacetCategoryResults = ({
  className = '',
  name = '',
  type = '',
  total = 0,
  facets = [],
  onClick,
}) => {
  if (total === 0) return null;
  return (
    <div
      data-cy="search-results-category"
      className={`${styles.category} ${className}`}
    >
      <p className={styles.title}>{name}</p>
      <div>
        {facets.map((facet) => (
          <ResultFacet key={facet.id} facet={facet} type={type} />
        ))}
      </div>
      {total > facets.length && (
        <button
          data-cy="search-results-pagination-button"
          type="button"
          className={styles.more}
          onClick={onClick}
        >
          View all {total}
        </button>
      )}
    </div>
  );
};

export const ListResults = ({
  className = '',
  isLoading = false,
  results = [],
  resultType = 'entity',
  type = '',
}) => (
  <div
    data-cy="search-results-list-content"
    className={`${styles.category} ${className}`}
  >
    <div data-cy="search-results-list-results">
      {resultType === 'entity' &&
        results.map((entity) => (
          <ResultEntity key={entity.id} entity={entity} />
        ))}
      {resultType === 'facet' &&
        results.map((facet) => (
          <ResultFacet key={facet.id} facet={facet} type={type} />
        ))}
    </div>
    {isLoading && (
      <div className={styles.loading}>
        <AnimatedLogo customClass={styles.loadingSpinner} />
      </div>
    )}
  </div>
);
