import * as React from 'react';

function NewFeed(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.917 10a3.734 3.734 0 013.75 3.75c0 .733-.209 1.425-.575 2l2.566 2.583-1.158 1.159-2.6-2.559a3.747 3.747 0 01-1.983.567 3.734 3.734 0 01-3.75-3.75 3.734 3.734 0 013.75-3.75zm0 1.667a2.083 2.083 0 100 4.166 2.083 2.083 0 000-4.166zM4.167 2.5h11.666A1.66 1.66 0 0117.5 4.167v6.691a5.657 5.657 0 00-1.667-1.691v-5H4.167v11.666h3.75A5.448 5.448 0 009.008 17.5H4.167A1.66 1.66 0 012.5 15.833V4.167A1.66 1.66 0 014.167 2.5zm1.666 3.333h8.334V7.5H5.833V5.833zm0 3.334h4.192a5.656 5.656 0 00-1.692 1.666h-2.5V9.167zm0 3.333h1.809a5.808 5.808 0 00-.142 1.25v.417H5.833V12.5z"
        fill="#656A70"
      />
    </svg>
  );
}

export default NewFeed;
