import { gql } from '@apollo/client';

const query = gql`
  query getUserConnections($searchTerm: String, $limit: Int, $skip: Int) {
    getUserConnections(searchTerm: $searchTerm, limit: $limit, skip: $skip) {
      total
      connections {
        note
        createdAt
        updatedAt
        entity {
          id
          name
          type
          shortestName
          image
          inSubscription
          primaryRoleTitle
        }
      }
    }
  }
`;

export default query;
