import React from 'react';

function Location(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1368 9.28828C9.67012 9.98163 8.95817 10.7371 8.0008 11.5547C7.04349 10.7371 6.33151 9.98163 5.86489 9.28828C5.39781 8.59533 5.16426 7.95263 5.16426 7.36026C5.16426 6.47158 5.45099 5.76373 6.02445 5.2367C6.59743 4.70919 7.25623 4.44544 8.0008 4.44544C8.74542 4.44544 9.40449 4.70919 9.97793 5.2367C10.5509 5.76373 10.8374 6.47158 10.8374 7.36026C10.8374 7.95263 10.6038 8.59533 10.1368 9.28828ZM7.46046 7.77828C7.60796 7.92616 7.78812 8.00006 8.0008 8.00006C8.21355 8.00006 8.39396 7.92616 8.5419 7.77828C8.68937 7.62994 8.76314 7.44912 8.76314 7.23582C8.76314 7.03439 8.68937 6.85665 8.5419 6.70262C8.39396 6.54859 8.21355 6.47158 8.0008 6.47158C7.78812 6.47158 7.60796 6.54859 7.46046 6.70262C7.31249 6.85665 7.2385 7.03439 7.2385 7.23582C7.2385 7.44912 7.31249 7.62994 7.46046 7.77828Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00078 0.800781C4.02433 0.800781 0.800781 4.02433 0.800781 8.00078C0.800781 11.9772 4.02433 15.2008 8.00078 15.2008C11.9772 15.2008 15.2008 11.9772 15.2008 8.00078C15.2008 4.02433 11.9772 0.800781 8.00078 0.800781ZM2.40078 8.00078C2.40078 4.90799 4.90799 2.40078 8.00078 2.40078C11.0936 2.40078 13.6008 4.90799 13.6008 8.00078C13.6008 11.0936 11.0936 13.6008 8.00078 13.6008C4.90799 13.6008 2.40078 11.0936 2.40078 8.00078Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Location;
