import * as React from 'react';

function PersonOutline(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M9.997 10a3.21 3.21 0 01-2.355-.98 3.21 3.21 0 01-.979-2.353c0-.917.327-1.702.98-2.354a3.21 3.21 0 012.354-.98 3.21 3.21 0 012.354.98 3.21 3.21 0 01.979 2.354 3.21 3.21 0 01-.98 2.354A3.21 3.21 0 019.998 10zM3.33 16.667v-2.334c0-.472.121-.906.365-1.302a2.43 2.43 0 01.968-.906c.861-.43 1.736-.753 2.625-.969a11.464 11.464 0 012.709-.323c.916 0 1.82.108 2.708.323a12.38 12.38 0 012.625.969c.403.208.726.51.969.906s.364.83.364 1.302v2.334H3.33zM4.997 15h10v-.667a.811.811 0 00-.417-.708 10.863 10.863 0 00-2.27-.844 9.667 9.667 0 00-4.626 0c-.764.188-1.52.469-2.27.844a.811.811 0 00-.417.708V15zm5-6.667c.458 0 .85-.163 1.177-.49.326-.326.49-.718.49-1.176 0-.459-.164-.851-.49-1.177A1.605 1.605 0 009.997 5c-.459 0-.851.163-1.177.49-.327.326-.49.718-.49 1.177 0 .458.163.85.49 1.177.326.326.718.49 1.177.49z"
          fill="#0E0F10"
        />
      </g>
    </svg>
  );
}

export default PersonOutline;
