import React from 'react';

import css from './Bio.module.css';
import DossierCard from '../../DossierCard/DossierCard';
import AiBadge from '../../../../../AiBadge/AiBadge';
import BetaBadge from '../../../../../BetaBadge/BetaBadge';

function Bio({ bio, className }) {
  return (
    <DossierCard
      title={
        <>
          Biography <AiBadge /> <BetaBadge />
        </>
      }
      className={className}
      data-cy="bio-facet"
    >
      <p className={css.bio}>{bio}</p>
    </DossierCard>
  );
}

export default Bio;
