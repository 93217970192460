import React, { useMemo } from 'react';
import CountryFilter from '../../../components/Filters/CountryFilter/CountryFilter';
import { useAxisData } from '../../../../contexts/AxisDataContext';

function SingleCountryFilter({ countryId, setCountryId }) {
  const { topics } = useAxisData();

  const onCountryChange = (data) => {
    setCountryId(data[0].id);
  };

  const activeCountries = useMemo(() => {
    return topics.filter(({ id }) => id === countryId);
  }, [countryId]);

  return (
    <CountryFilter
      singleSelection
      activeCountries={activeCountries}
      setActiveCountries={onCountryChange}
    />
  );
}

export default SingleCountryFilter;
