import React, { useMemo } from 'react';

import IndustryFilter from '../../../components/Filters/IndustryFilter/IndustryFilter';
import { useAxisData } from '../../../../contexts/AxisDataContext';

function SingleIndustryFilter({ industryId, setIndustryID }) {
  const { flatIndustries } = useAxisData();

  const onApply = (data) => {
    setIndustryID(data[0].id);
  };

  const activeIndustryFilters = useMemo(() => {
    return flatIndustries.filter(({ id }) => id === industryId);
  }, [industryId, flatIndustries]);

  return (
    <IndustryFilter
      singleSelection
      options={flatIndustries}
      activeIndustryFilters={activeIndustryFilters}
      applyFilters={onApply}
    />
  );
}

export default SingleIndustryFilter;
