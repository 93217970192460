import { gql } from '@apollo/client';

const getNotesForEntityQuery = gql`
  query getNotesForEntity($entityId: ID!) {
    getNotesForEntity(entityId: $entityId) {
      connectionNote {
        note
        date
        entity {
          name
          image
          type
          inSubscription
          isConnected
          shortestName
        }
        userName
      }
      entityNotes {
        id
        note
        date
        entity {
          id
          name
          image
          type
          shortestName
        }
        userName
      }
      eventNotes {
        id
        note
        title
        source
        userName
        date
      }
      regulationNotes {
        id
        note
        date
        summarized_name
        source
        userName
      }
      complianceNotes {
        note
        date
        summarized_name
        source
        userName
      }
    }
  }
`;

export default getNotesForEntityQuery;
