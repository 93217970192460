import React from 'react';
import RedirectToFeed from './RedirectToFeed';
import { useRouteInformation } from '../contexts/RouteInformationContext';
import isValidUUID from '../lib/uuid';

const isInvalidUUID = (value) => {
  if (value && !isValidUUID(value)) return true;
  return false;
};

const ProtectAccess = ({ children }) => {
  const router = useRouteInformation();

  const { topicId } = router.params;
  const entityId = router.query.get('entityId');
  const source = router.query.get('source');
  const target = router.query.get('target');

  if (isInvalidUUID(topicId)) return <RedirectToFeed />;
  if (isInvalidUUID(entityId)) return <RedirectToFeed />;
  if (isInvalidUUID(source)) return <RedirectToFeed />;
  if (isInvalidUUID(target)) return <RedirectToFeed />;

  return children;
};

export default ProtectAccess;
