import { gql } from '@apollo/client';

const query = gql`
  query getAllNotes($searchTerm: String) {
    getAllUserNotes(searchTerm: $searchTerm) {
      notes {
        __typename
        ... on entityNotes {
          id
          note
          date
          updated_at
          entity {
            id
            name
            shortestName
            image
            type
            isConnected
            inSubscription
          }
        }
        ... on eventNotes {
          id
          note
          date
          updated_at
          event {
            id
            title
          }
        }
        ... on regulationNotes {
          id
          note
          date
          updated_at
          regulation {
            id
            summarized_name
            isCompliant
          }
        }
        ... on complianceNotes {
          id
          note
          date
          updated_at
          regulation {
            id
            summarized_name
            isCompliant
          }
        }
        ... on connectionNotes {
          id
          note
          date
          updated_at
          entity {
            id
            name
            shortestName
            image
            type
            isConnected
            inSubscription
          }
        }
        ... on appointmentNotes {
          id
          note
          updated_at
          appointment {
            id
            headline
          }
        }
      }
    }
  }
`;

export default query;
