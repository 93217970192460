import { createStaticRanges } from 'react-date-range';
import { subDays, subWeeks, subMonths } from 'date-fns';
import { formatDateMed } from './date';

const SEARCH_ENTITIES = [
  'actors', // Level 1
  'people', // Level 2
  'peopleRegulator', // Level 3
  'government', // Level 2
  'governmentRegulator', // Level 3
  'organizations', // Level 2
  'companies', // Level 2
  'contexts', // Level 1
];

const SEARCH_FACETS = [
  'regulations', // Level 1
  'events', // Level 1
  'quotes', // Level 1
  'appointments',
];

export const SEARCH_TYPES = [
  {
    type: 'actors',
    children: [
      {
        type: 'people',
        children: [{ type: 'peopleRegulator' }],
      },
      {
        type: 'government',
        children: [{ type: 'governmentRegulator' }],
      },
      { type: 'organizations' },
      { type: 'companies' },
    ],
  },
  { type: 'regulations' },
  { type: 'events' },
  { type: 'quotes' },
];

export const escapeRegex = (input) =>
  input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const getResultType = (type) => {
  if (SEARCH_ENTITIES.includes(type)) return 'entity';
  if (SEARCH_FACETS.includes(type)) return 'facet';
  return 'overview';
};

export const findTypeByName = (searchType, elements = SEARCH_TYPES) => {
  const foundElement = elements.find((element) => element.type === searchType);
  if (foundElement) {
    return foundElement;
  }

  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i];
    if (element.children) {
      const foundChild = findTypeByName(searchType, element.children);
      if (foundChild) return foundChild;
    }
  }
  return null;
};

export const getTypeChildren = (type) => {
  const typeDetails = findTypeByName(type);
  const children = typeDetails ? typeDetails.children || [] : [];
  return children.map((child) => child.type);
};

export const getSearchResultAction = ({ entity, router, topicId = null }) => {
  router.toggleDossier({
    dossierEntityId: entity.id,
    dossierType: 'entity',
  });
  if (topicId) {
    router.navigateToExplore({
      topicId,
      entityId: entity.id,
      dossierEntityId: entity.id,
      notifyTopicChange: true,
    });
  }
};

export const getTotalForSearchType = ({ type, entities, facets }) => {
  const details = [...entities, ...facets].find((t) => t.type === type);
  return details ? details.total : 0;
};

export const getLabelFromType = (type) => {
  if (type === 'actors') return 'Actors';
  if (type === 'people') return 'People';
  if (type === 'peopleRegulator') return 'Regulators (People)';
  if (type === 'government') return 'Government';
  if (type === 'governmentRegulator') return 'Regulators (Government)';
  if (type === 'organizations') return 'Organizations';
  if (type === 'companies') return 'Companies';
  if (type === 'contexts') return 'Contexts';
  if (type === 'regulations') return 'Regulations';
  if (type === 'events') return 'Events';
  if (type === 'regulation') return 'Regulations';
  if (type === 'event') return 'Events';
  if (type === 'quotes') return 'Quotes';
  if (type === 'appointments') return 'Appointments';
  if (type === 'all') return 'All';
  return '';
};

/*
 * Facet results are returned differently from entity results
 * However, for rendering it's easier if they have the same format
 * So we just need to convert them.
 * Facet results come as an object with properties
 * Entity results come as an array of objects with a "type" property to indicate the type of entity
 */
export const transformFacetSearchResult = (facetResults) => {
  const { events, regulations, quotes, appointments } = facetResults;
  return [
    {
      type: 'regulations',
      ...regulations,
    },
    {
      type: 'events',
      ...events,
    },
    {
      type: 'quotes',
      ...quotes,
    },
    {
      type: 'appointments',
      ...appointments,
    },
  ];
};

/*
 * Entity search results look like this:
 * [
 *   {
 *    type: 'people',
 *    total: 6,
 *    entities: [{...}, {...}, {...}, {...}, {...}, {...}]
 *   },
 *   {
 *     type: 'companies',
 *     total: 2,
 *     entities: [{...}, {...}]
 *   },
 *   ...
 * ]
 *
 * So merging them means combining them according to the entity type.
 */
export const mergeEntitySearchResults = ({
  currentResults,
  newResults,
  entityType,
}) => {
  // If we have nothing then just set the new results
  if (!currentResults || currentResults.length === 0) {
    return newResults;
  }

  /*
   * We start from the existing result, and update the total and entities.
   * For the new entities we make sure we are not inserting duplicates.
   */
  const entityInfo = currentResults.find((cat) => cat.type === entityType);
  const newEntityInfo = newResults.find((cat) => cat.type === entityType);

  if (!entityInfo && !newEntityInfo) {
    return currentResults;
  }

  let updatedType = {};

  if (entityInfo && !newEntityInfo) {
    updatedType = entityInfo;
  }

  if (!entityInfo && newEntityInfo) {
    updatedType = newEntityInfo;
  }

  if (entityInfo && newEntityInfo) {
    // Get the entity list
    const { entities: currentEntities } = entityInfo;
    const { entities: newEntities } = newEntityInfo;

    updatedType = {
      ...entityInfo,
      total: newEntityInfo.total,
      entities: [
        ...currentEntities,
        ...newEntities.filter(
          (entity) => !currentEntities.some((e) => e.id === entity.id),
        ),
      ],
    };
  }

  // Combine results from other entity types + our own.
  return [
    ...currentResults.filter((cat) => cat.type !== entityType),
    updatedType,
  ];
};

/*
 * Facet search results look like this:
 * {
 *   events: {
 *     total: 6,
 *     facets: [{...}, {...}, {...}, {...}, {...}, {...}]
 *   },
 *   regulations: {
 *    total: 2,
 *    facets: [{...}, {...}]
 *  },
 * }
 * So merging them means combining them according to the entity type.
 * We are going to do the same thing we have done with entity search results.
 * Meaning we are going to put them in an array with a "type" property
 */
export const mergeFacetSearchResults = ({
  currentResults,
  newResults,
  facetType,
}) => {
  // If we have nothing then just set the new results
  if (!currentResults || currentResults.length === 0) {
    return transformFacetSearchResult(newResults);
  }

  /*
   * We start from the existing result, and update the total and entities.
   * For the new entities we make sure we are not inserting duplicates.
   */

  const facetInfo = currentResults.find((cat) => cat.type === facetType);
  const newFacetInfo = newResults[facetType];

  if (!facetInfo && !newFacetInfo) {
    return currentResults;
  }

  let updatedType = {};

  if (facetInfo && !newFacetInfo) {
    updatedType = facetInfo;
  }

  if (!facetInfo && newFacetInfo) {
    updatedType = newFacetInfo;
  }

  if (facetInfo && newFacetInfo) {
    // Get the facet list
    const { results: currentFacets = [] } = facetInfo;
    const { results: newFacets } = newFacetInfo;

    updatedType = {
      ...facetInfo,
      total: newFacetInfo.total,
      results: [...currentFacets, ...newFacets],
    };
  }

  // Combine results from other facet types + our own.
  return [
    ...currentResults.filter((cat) => cat.type !== facetType),
    updatedType,
  ];
};

export const describeActiveFilters = ({
  searchTypes,
  searchIndustries,
  searchEntities,
  searchCountries,
}) => {
  let filter = '';

  if (searchTypes.length > 0) {
    filter = `Entity/Facet types: ${searchTypes
      .map(getLabelFromType)
      .join(', ')}. `;
  }

  if (searchIndustries.length > 0) {
    filter += `Briefs: ${searchIndustries.map((i) => i.name).join(', ')}. `;
  }

  if (searchEntities.length > 0) {
    filter += `Entities: ${searchEntities.map((e) => e.name).join(', ')}. `;
  }

  if (searchCountries.length > 0) {
    filter += `Countries: ${searchCountries.map((c) => c.name).join(', ')}. `;
  }

  return filter;
};

export const formatDateRangeFilter = ({ startDate, endDate }) => {
  return `${formatDateMed(startDate)} - ${formatDateMed(endDate)}`;
};

export const getSearchQuickRanges = () => {
  const now = new Date();
  return createStaticRanges([
    {
      label: 'Last 24 hours',
      range: () => ({ startDate: subDays(now, 1), endDate: now }),
    },
    {
      label: 'Last 3 days',
      range: () => ({ startDate: subDays(now, 3), endDate: now }),
    },
    {
      label: 'Last week',
      range: () => ({ startDate: subWeeks(now, 1), endDate: now }),
    },
    {
      label: 'Last 30 days',
      range: () => ({ startDate: subDays(now, 30), endDate: now }),
    },
    {
      label: 'Last 3 months',
      range: () => ({ startDate: subMonths(now, 3), endDate: now }),
    },
    {
      label: 'Last 6 months',
      range: () => ({ startDate: subMonths(now, 6), endDate: now }),
    },
    {
      label: 'Last 12 months',
      range: () => ({ startDate: subMonths(now, 12), endDate: now }),
    },
  ]);
};

const getLength = (list) => {
  return list ? list.length : 0;
};

// Get the total results available for the current search
export const getTotalSearchResults = ({ entities, facets, resultType }) => {
  if (resultType === 'entity') {
    return entities.length;
  }

  if (resultType === 'facet') {
    return facets.reduce((acc, curr) => acc + getLength(curr.results), 0);
  }

  const entityCount = entities.reduce(
    (acc, curr) => acc + getLength(curr.entities),
    0,
  );
  const facetsCount = facets.reduce(
    (acc, curr) => acc + getLength(curr.results),
    0,
  );

  return entityCount + facetsCount;
};

// Get the results available for the current search (the results itself)
export const getSearchResultElements = ({
  entities,
  facets,
  resultType,
  category,
  counts,
}) => {
  if (!category) return { type: '', total: 0, results: [] };
  if (resultType === 'facet') {
    const relevantFacet = facets.find((f) => f.type === category);
    if (relevantFacet) {
      return {
        type: category,
        total: counts ? counts[relevantFacet.type] : 0,
        results: relevantFacet.results,
      };
    }
  }
  if (resultType === 'entity') {
    return {
      type: category,
      total: counts?.actors || 0,
      results: entities,
    };
  }
  return { type: category, total: 0, results: [] };
};

export const isLoadingResults = ({
  isLoadingFacets,
  isLoadingEntities,
  resultType,
}) => {
  if (resultType === 'entity') {
    return isLoadingEntities;
  }
  if (resultType === 'facet') {
    return isLoadingFacets;
  }
  return isLoadingEntities || isLoadingFacets;
};
