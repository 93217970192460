import React from 'react';

import css from './LoadingScreen.module.css';
import AnimatedLogo from '../../v2/components/AxisAnimatedLogo/AnimatedLogo';

const LoadingScreen = () => {
  return (
    <div data-cy="loading-screen" className={css.loadingScreen}>
      <AnimatedLogo customClass={css.logo} />
    </div>
  );
};

export default LoadingScreen;
