import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M1.333 14v-1.333h6v-7.45A1.946 1.946 0 016.117 4H4l2 4.667c0 .555-.228 1.027-.683 1.416-.456.39-1.006.584-1.65.584-.645 0-1.195-.195-1.65-.584-.456-.389-.684-.86-.684-1.416l2-4.667H2V2.667h4.117c.133-.39.372-.709.716-.959.345-.25.734-.375 1.167-.375.433 0 .822.125 1.167.375.344.25.583.57.716.959H14V4h-1.333l2 4.667c0 .555-.228 1.027-.684 1.416-.455.39-1.005.584-1.65.584-.644 0-1.194-.195-1.65-.584-.455-.389-.683-.86-.683-1.416L12 4H9.883a1.946 1.946 0 01-1.216 1.217v7.45h6V14H1.333zm9.75-5.333h2.5l-1.25-2.9-1.25 2.9zm-8.666 0h2.5l-1.25-2.9-1.25 2.9zM8 4a.647.647 0 00.475-.191.647.647 0 00.192-.476.647.647 0 00-.192-.475A.647.647 0 008 2.667a.645.645 0 00-.475.191.646.646 0 00-.192.475c0 .19.064.348.192.476A.645.645 0 008 4z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
