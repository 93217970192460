import React from 'react';

import css from './ExploreSearch.module.css';
import MiniSearch from '../../../../header/MiniSearch/MiniSearch';

function ExploreSearch() {
  return (
    <div className={css.main}>
      <MiniSearch />
    </div>
  );
}

export default ExploreSearch;
