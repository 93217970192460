import { gql } from '@apollo/client';

const query = gql`
  query getTrendingEntities($limit: Int) {
    getTrendingEntities(limit: $limit) {
      entity {
        id
        name
        shortestName
        type
        inSubscription
        isConnected
        image
      }
    }
  }
`;

export default query;
