import * as React from 'react';

function ChevronLeft(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M9.333 12l-4-4 4-4 .934.933L7.2 8l3.067 3.067-.934.933z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default ChevronLeft;
