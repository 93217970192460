import React from 'react';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import css from './Controls.module.css';

const Controls = ({ svgRef }) => (
  <div data-cy="network-controls" className={css.controls} data-area="explore">
    <div className={css.bottomControls}>
      <div className={css.zoomSection}>
        <p className={css.sectionTitle}>Zoom</p>
        <Tooltip text="Zoom to fit">
          <input
            type="button"
            className={css.zoomToFit}
            onClick={() => svgRef.current.zoomToFit(1000, 10, () => true)}
          />
        </Tooltip>
        <Tooltip text="Zoom in">
          <input
            type="button"
            className={css.zoomInc}
            onClick={() =>
              svgRef.current.zoom(svgRef.current.zoom() + 0.1, 300)
            }
          />
        </Tooltip>
        <Tooltip text="Zoom out">
          <input
            type="button"
            className={css.zoomDec}
            onClick={() =>
              svgRef.current.zoom(svgRef.current.zoom() - 0.1, 300)
            }
          />
        </Tooltip>
      </div>
    </div>
  </div>
);

export default Controls;
