import React from 'react';

const BriefPillCloseIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 8 8" fill="none">
    <path
      d="M1.2 7.5L0.5 6.8L3.3 4L0.5 1.2L1.2 0.5L4 3.3L6.8 0.5L7.5 1.2L4.7 4L7.5 6.8L6.8 7.5L4 4.7L1.2 7.5Z"
      fill="#606060"
    />
  </svg>
);

export default BriefPillCloseIcon;
