import { gql } from '@apollo/client';

const toggleUserCompliance = gql`
  mutation toggleUserCompliance(
    $regulationId: ID!
    $note: String
    $flag: Boolean!
  ) {
    toggleUserCompliance(regulationId: $regulationId, note: $note, flag: $flag)
  }
`;

export default toggleUserCompliance;
