import React from 'react';
import { Loading } from '@axis/xyz.app.loading';
import css from './GraphLoader.module.css';

const GraphLoader = () => {
  return (
    <div className={css.loadingWrapper}>
      <Loading className={css.loading} />
    </div>
  );
};

export default GraphLoader;
