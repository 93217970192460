import React from 'react';
import css from './Total.module.css';

const NumberFormater = Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumFractionDigits: 1,
});

const formatTotal = (total) => {
  if (!total) return '';
  return NumberFormater.format(total);
};

function Total({ isLoading, total, ...rest }) {
  return (
    <span data-loading={isLoading} className={css.total} {...rest}>
      {!isLoading && formatTotal(total)}
    </span>
  );
}

export default Total;
