import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={17}
        height={16}
      >
        <path fill="#D9D9D9" d="M0.5 0H16.5V16H0.5z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M14.5 4.667h-4V3.333h4v1.334zM3.833 14V3.333c0-.366.13-.68.392-.941C4.485 2.13 4.8 2 5.166 2h4.667a4.55 4.55 0 00-.5.958A3.036 3.036 0 009.166 4c0 .8.253 1.506.759 2.117a3.25 3.25 0 001.908 1.15c.256.033.478.05.667.05.189 0 .41-.017.666-.05V14L8.5 12l-4.667 2z"
          fill="#929AA3"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
