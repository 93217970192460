import * as React from 'react';

function UpArrow(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.333 8l-.933.95-3.733-3.733v8.116H7.333V5.217L3.6 8.95 2.667 8 8 2.667 13.333 8z"
        fill="currentColor"
      />
    </svg>
  );
}

export default UpArrow;
