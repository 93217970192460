import * as React from 'react';

function Connection(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_7090_2059)" fill="#929AA3">
        <path d="M7.333 8a2.666 2.666 0 100-5.333 2.666 2.666 0 100 5.333zm0-4c.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334C6.6 6.667 6 6.067 6 5.333 6 4.6 6.6 4 7.333 4zm-4 8c.134-.42 1.714-1.12 3.307-1.293L8 9.373a6.229 6.229 0 00-.667-.04C5.553 9.333 2 10.227 2 12v1.333h6L6.667 12H3.333zm10.4-3.667l-3.42 3.447-1.38-1.387-.933.94 2.313 2.334 4.354-4.394-.934-.94z" />
        <path d="M6.64 10.707c-1.593.173-3.173.873-3.307 1.293H6.64v-1.293zM7.333 4c.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334C6.6 6.667 6 6.067 6 5.333 6 4.6 6.6 4 7.333 4z" />
      </g>
      <defs>
        <clipPath id="clip0_7090_2059">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Connection;
