import React from 'react';
import css from './ConnectionCard.module.css';
import EntityPill from '../EntityPill/EntityPill';
import ConnectionButton from '../Connection/ConnectionButton';
import BriefPill from '../BriefPill/BriefPill';
import { formatDateMed } from '../../lib/date';

const ConnectionCard = ({ connectionData, userName, onEntityPillClick }) => {
  const { entity, note, createdAt } = connectionData;
  return (
    <div>
      <div className={css.card}>
        <div className={css.topSection}>
          <div className={css.entitySection}>
            <EntityPill
              name={entity.name}
              shortName={entity.shortestName}
              image={entity.image}
              type={entity.type}
              size="medium"
              onClick={() => onEntityPillClick(entity.id)}
            />
          </div>
          <div className={css.noteSection}>{note}</div>
          <div className={css.connectedSection}>
            <ConnectionButton data-status="active" isConnected disabled />
          </div>
        </div>
        <div className={css.bottomSection}>
          <BriefPill name={userName} className={css.userPill} />
          {formatDateMed(createdAt)}
        </div>
      </div>
    </div>
  );
};

export default ConnectionCard;
