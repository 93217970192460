import React, { memo } from 'react';

import css from './BGMenu.module.css';

function BGMenu({ resetMenu }) {
  return (
    <div
      className={css.menu}
      onClick={() => {
        resetMenu();
      }}
    >
      <div className={css.header}>
        <div className={css.title}>
          Right click on a node or edge to see options
        </div>
      </div>
    </div>
  );
}

export default memo(BGMenu);
