import { gql } from '@apollo/client';

const query = gql`
  query getEventById($eventId: ID) {
    getEventById(eventId: $eventId) {
      event {
        id
        title
        date
        sources
        summary
        bullets
        sentiment
        magnitude
        entities {
          id
          name
          shortestName
          image
          type
          isConnected
          inSubscription
          tooltip
        }
        countries {
          id
          name
        }
        briefs {
          id
          name
          tooltip
        }
        quotes {
          id
          quote
          context
          speaker {
            id
            name
            shortestName
            image
            type
            topicsId
            inSubscription
            isConnected
            tooltip
          }
          countries {
            id
            name
          }
          industries {
            id
            name
          }
        }
      }
    }
  }
`;

export default query;
