import React, { createContext, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAxisData } from './AxisDataContext';
import notify from '../lib/notify';

const Context = createContext();

export const getTopicURLFromId = ({ topicId, topics }) => {
  const topic = topics.find((t) => t.id === topicId);
  // We find the topic - so we return the pretty name
  if (topic) {
    return (topic.name || '').toLowerCase().replace(/\s/g, '-');
  }
  // We don't find the topic - so we return the first topic we are subscribed to from the list
  const firstTopic = topics.find((t) => t.subscribed);
  if (firstTopic) {
    return (firstTopic.name || '').toLowerCase().replace(/\s/g, '-');
  }
  // We still have nothing - so we return nothing - we should never be here!
  return '';
};

const getParameters = ({ path, topics }) => {
  const FIXED_URLS = ['account', 'login'];
  const fragment = path.split('/')[1];
  if (FIXED_URLS.includes(fragment)) return {};
  const topic = topics.find((t) => {
    const name = (t.name || '').toLowerCase().replace(/\s/g, '-');
    return name === fragment;
  });
  if (!topic) return {};
  return {
    topicId: topic.id,
    topicName: fragment,
  };
};

const shouldNotifyTopicChange = ({ currentURL, nextURL, topics }) => {
  const isTopic = (urlFragment) => {
    return topics
      .map((t) => (t.name || '').toLowerCase().replace(/\s/g, '-'))
      .includes(urlFragment);
  };
  const currentTopicName = currentURL.split('/')[1];
  const nextTopicName = nextURL.split('/')[1];
  if (!isTopic(currentTopicName) || !isTopic(nextTopicName)) return false;
  return currentTopicName !== nextTopicName;
};

export const RouteInformationContext = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [activeEntityId, setActiveEntityId] = React.useState(null);

  const { topics } = useAxisData();

  const params = getParameters({ path: location.pathname, topics });

  const push = ({ pathname, search = '', state = {}, notifyTopicChange }) => {
    if (notifyTopicChange) {
      const shouldNotify = shouldNotifyTopicChange({
        currentURL: location.pathname,
        nextURL: pathname,
        topics,
      });
      if (shouldNotify) notify.info('Your country has been changed');
    }
    history.push({ pathname, search }, state);
  };

  const query = new URLSearchParams(location.search);

  const isSearchPage = () => {
    return location.pathname.includes('/search');
  };

  const isExplorePage = () => {
    return location.pathname.includes('/network');
  };

  const isDashboardPage = () => {
    return location.pathname.includes('/dashboard');
  };

  const isAccountPage = () => {
    return location.pathname.includes('/account');
  };

  const navigate = ({
    pathname: newPathname,
    query: newQuery,
    state,
    notifyTopicChange,
  }) => {
    const newSearch = new URLSearchParams(newQuery).toString();
    const { pathname, search } = location;
    if (pathname === newPathname && search.substring(1) === newSearch) return;
    push({
      pathname: newPathname,
      search: newSearch,
      state,
      notifyTopicChange,
    });
  };

  const navigateToLocation = ({
    pathname: newPathname,
    search: newSearch,
    state: newState,
    notifyTopicChange = false,
  }) => {
    push({
      pathname: newPathname,
      search: newSearch,
      state: newState,
      notifyTopicChange,
    });
  };

  const navigateToPasswordReset = () => navigate({ pathname: '/login/reset' });

  const navigateToLogin = ({ pathname = '/', search = '' }) => {
    navigate({
      pathname: '/login',
      state: { from: { pathname, search } },
    });
  };

  const navigateToHome = () => navigate({ pathname: '/' });

  const navigateToAccount = () => navigate({ pathname: '/profile' });

  const navigateToSearch = ({ search = '', notifyTopicChange = true }) => {
    if (!search) {
      navigate({ pathname: `/search` });
    } else {
      navigate({
        pathname: `/search`,
        query: { search },
        notifyTopicChange,
      });
    }
  };

  const navigateToFeed = ({ notifyTopicChange = true }) => {
    navigate({
      pathname: `/feed`,
      notifyTopicChange,
    });
  };

  const navigateToExplore = ({ entityId, notifyTopicChange = true }) => {
    navigate({
      pathname: `/network`,
      query: {
        dossierType: 'entity',
        entityId,
        dossierEntityId: entityId,
      },
      notifyTopicChange,
    });
  };

  const navigateToSearchView = ({ data }) => {
    navigate({
      pathname: `/network`,
      query: {
        data,
      },
    });
  };

  const openDossier = ({
    entityId,
    source,
    target,
    dossierType,
    dossierEntityId,
    event,
    regulation,
  }) => {
    const dossierQuery = new URLSearchParams(location.search);
    // Entity ID
    if (entityId) dossierQuery.set('entityId', entityId);
    else dossierQuery.delete('entityId');

    // Dossier Entity ID
    if (dossierEntityId) dossierQuery.set('dossierEntityId', dossierEntityId);
    else dossierQuery.delete('dossierEntityId');

    // Event ID
    if (event && event.id) dossierQuery.set('eventId', event.id);
    else dossierQuery.delete('eventId');

    // Regulation ID
    if (regulation && regulation.id)
      dossierQuery.set('regulationId', regulation.id);
    else dossierQuery.delete('regulationId');

    // Source
    if (source) dossierQuery.set('source', source);
    else dossierQuery.delete('source');
    // Target
    if (target) dossierQuery.set('target', target);
    else dossierQuery.delete('target');
    // Dossier Type
    if (dossierType) dossierQuery.set('dossierType', dossierType);
    else dossierQuery.delete('dossierType');

    setActiveEntityId(dossierEntityId);
    // Update the URL
    push({
      pathname: location.pathname,
      search: dossierQuery.toString(),
      state: { dossierType, event, regulation },
    });
  };

  const closeDossier = () => {
    const dossierQuery = new URLSearchParams(location.search);
    // We can't delete the entityId if we are in the explore page!
    if (!isExplorePage()) {
      dossierQuery.delete('entityId');
      dossierQuery.delete('regulationId');
    }
    dossierQuery.delete('source');
    dossierQuery.delete('target');
    dossierQuery.delete('dossierType');
    dossierQuery.delete('dossierEntityId');
    dossierQuery.delete('dossierRegulationId');
    dossierQuery.delete('dossierEventId');
    dossierQuery.delete('dossierAppointmentId');
    dossierQuery.delete('dossierQuoteId');
    dossierQuery.delete('viewQuote');
    setActiveEntityId(null);
    push({
      pathname: location.pathname,
      search: dossierQuery.toString(),
    });
  };

  const toggleDossier = ({
    entityId = null,
    dossierEntityId = null,
    source = null,
    target = null,
    dossierType = null,
  }) => {
    const dossierQuery = new URLSearchParams(location.search);
    const isSameDossier =
      dossierEntityId === dossierQuery.get('dossierEntityId') &&
      source === dossierQuery.get('source') &&
      target === dossierQuery.get('target') &&
      dossierType === dossierQuery.get('dossierType');
    if (isSameDossier) {
      closeDossier();
    } else {
      openDossier({ entityId, dossierEntityId, source, target, dossierType });
      setActiveEntityId(dossierEntityId);
    }
  };

  const value = {
    params,
    query,
    location,
    activeEntityId,

    navigateToLocation,

    navigateToLogin,
    navigateToPasswordReset,
    navigateToHome,
    navigateToAccount,
    navigateToSearch,
    navigateToFeed,
    navigateToExplore,
    navigateToSearchView,

    openDossier,
    closeDossier,
    toggleDossier,

    isSearchPage,
    isExplorePage,
    isDashboardPage,
    isAccountPage,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useRouteInformation = () => useContext(Context);
