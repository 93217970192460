import { useEffect } from 'react';

function useOnKeyDown(targetRef, key, callback) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === key) {
        callback(event);
      }
    };

    const targetElement = targetRef.current;

    if (targetElement) {
      targetElement.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [targetRef, key, callback]);
}

export default useOnKeyDown;
