import querySearchFacets from '../../queries/searchEventsAndRegulations';
import querySearchEntities from '../../queries/searchEntities';
import queryAiSuggestions from '../../queries/getAiSuggestions';
import searchAutocomplete from '../../queries/searchAutocomplete';

export const fetchSearchFacetResults = ({
  client,
  name,
  topics,
  filterIndustries,
  filterEntities,
  filterCategories,
  startDate,
  endDate,
  bookmarked = false,
  connected = false,
  notes = false,
  compliance = false,
}) => {
  return client.query({
    query: querySearchFacets,
    variables: {
      name,
      topics,
      filterIndustries,
      filterEntities,
      filterCategories,
      startDate,
      endDate,
      bookmarked,
      connected,
      notes,
      compliance,
    },
  });
};

export const fetchSearchEntityResults = ({
  client,
  name,
  topics,
  filterIndustries,
  filterEntities,
  filterActorTypes,
  limit,
  skip,
  bookmarked = false,
  connected = false,
  notes = false,
  compliance = false,
}) => {
  return client
    .query({
      query: querySearchEntities,
      variables: {
        name,
        topics,
        filterIndustries,
        filterEntities,
        filterActorTypes,
        bookmarked,
        connected,
        notes,
        compliance,
        limit,
        skip,
      },
    })
    .then(({ data }) => data.searchEntities.entities);
};

export const fetchAiSuggestions = ({ client, term }) => {
  return client
    .query({
      query: queryAiSuggestions,
      variables: {
        term,
      },
    })
    .then(({ data }) => data.getSuggestions?.entities);
};

export const fetchAutocomplete = ({
  client,
  term,
  symbol,
  topics,
  entities,
  limit = 20,
}) => {
  return client
    .query({
      query: searchAutocomplete,
      variables: {
        term,
        symbol,
        topics,
        entities,
        limit,
      },
    })
    .then(({ data }) => data.autoCompleteQuery);
};
