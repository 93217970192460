/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from 'react';
import css from './EntityDossierNotes.module.css';
import Tabs from '../../dossier/components/Tabs/Tabs';
import client from '../../apollo/apollo';
import getNotesForEntityQuery from '../../queries/getNotesForEntity';
import notify from '../../lib/notify';
import NoteCard from '../NoteCard/NoteCard';

const EntityDossierNotes = ({
  entity,
  notesRefetchToggls,
  size,
  handleToggleNoteUpdate,
}) => {
  const [activeTab, setActiveTab] = useState(entity.type);
  const [notes, setNotes] = useState({
    entityNotes: [],
    eventNotes: [],
    regulationNotes: [],
    connectionNote: {},
    complianceNotes: [],
  });
  useEffect(() => {
    client
      .query({
        query: getNotesForEntityQuery,
        variables: { entityId: entity.id },
      })
      .then(({ errors, data }) => {
        if (errors || !data) {
          return notify.error(errors[0].message, { position: 'top-right' });
        }
        return setNotes(data.getNotesForEntity);
      })
      .catch((e) => {
        return notify.error(e.message, { position: 'top-right' });
      });
  }, [entity.id, notesRefetchToggls]);

  const notesTab = useMemo(() => {
    const tabs = [];
    if (notes.connectionNote || notes.entityNotes.length > 0) {
      tabs.push(
        `${entity.type} | ${notes.entityNotes.length + !!notes.connectionNote}`,
      );
    }
    if (notes.eventNotes.length > 0) {
      tabs.push(`Events | ${notes.eventNotes.length}`);
    }
    if (notes.complianceNotes.length || notes.regulationNotes.length > 0) {
      tabs.push(
        `Regulations | ${
          notes.regulationNotes.length + notes.complianceNotes.length
        }`,
      );
    }
    return tabs;
  }, [notes]);

  useEffect(() => {
    setActiveTab(notesTab[0]);
  }, []);

  return (
    <div className={css.notesTabs} data-cy="notes-tab">
      <Tabs
        tabs={notesTab}
        setActive={(tab) => {
          setActiveTab(tab);
        }}
        active={activeTab}
      />
      <div className={css.cardSection} data-size={size} data-cy="notes-card">
        {activeTab.includes(entity.type) && notes.connectionNote && (
          <NoteCard
            note={notes.connectionNote.note}
            date={notes.connectionNote.date}
            userName={notes.connectionNote.userName}
            entity={notes.connectionNote.entity}
            noteType={notes.connectionNote.__typename}
            isConnected
            handleToggleNoteUpdate={handleToggleNoteUpdate}
          />
        )}
        {activeTab.includes(entity.type) &&
          notes.entityNotes.length > 0 &&
          notes.entityNotes.map((note) => (
            <NoteCard
              key={note.id}
              id={note.id}
              note={note.note}
              date={note.date}
              userName={note.userName}
              entity={note.entity}
              noteType={note.__typename}
              handleToggleNoteUpdate={handleToggleNoteUpdate}
            />
          ))}
        {activeTab.includes('Events') &&
          notes.eventNotes.length > 0 &&
          notes.eventNotes.map((note) => (
            <NoteCard
              key={note.id}
              id={note.id}
              note={note.note}
              date={note.date}
              userName={note.userName}
              title={note.title}
              source={note.source}
              noteType={note.__typename}
              handleToggleNoteUpdate={handleToggleNoteUpdate}
            />
          ))}

        {activeTab.includes('Regulations') &&
          notes.complianceNotes.length > 0 &&
          notes.complianceNotes.map((note) => (
            <NoteCard
              key={note.summarized_name}
              note={note.note}
              date={note.date}
              userName={note.userName}
              title={note.summarized_name}
              source={note.source}
              noteType={note.__typename}
              handleToggleNoteUpdate={handleToggleNoteUpdate}
            />
          ))}
        {activeTab.includes('Regulations') &&
          notes.regulationNotes.length > 0 &&
          notes.regulationNotes.map((note) => (
            <NoteCard
              key={note.id}
              id={note.id}
              note={note.note}
              date={note.date}
              userName={note.userName}
              title={note.summarized_name}
              source={note.source}
              noteType={note.__typename}
              handleToggleNoteUpdate={handleToggleNoteUpdate}
            />
          ))}
      </div>
    </div>
  );
};

export default EntityDossierNotes;
