import * as React from 'react';

function Smart(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.667.667l-.84 1.833L10 3.333l1.827.84.84 1.827.833-1.827 1.834-.84L13.5 2.5M6 2.667L4.334 6.333.667 8l3.667 1.667L6 13.333l1.667-3.666L11.334 8 7.667 6.333m5 3.667l-.84 1.827-1.827.84 1.827.833.84 1.833.833-1.833 1.834-.833-1.834-.84"
        fill="currentColor"
      />
    </svg>
  );
}

export default Smart;
