import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { useFeed } from '../../Feed';
import EducationUpdate from './facets/EducationUpdate/EducationUpdate';
import RegulationUpdate from './facets/RegulationUpdate/RegulationUpdate';
import RoleUpdate from './facets/RoleUpdate/RoleUpdate';
import css from './FeedList.module.css';
import { useAxisData } from '../../../../contexts/AxisDataContext';
import QuoteUpdate from './facets/QuoteUpdate/QuoteUpdate';
import AppointmentCard from '../../../../v2/components/Cards/AppointmentCard/AppointmentCard';
import EventCard from '../../../../v2/components/Cards/EventCard/EventCard';
import { EventCardSkeleton } from '../../../../v2/components/Skeletons/EventCardSkeleton';
import AnimatedLogo from '../../../../v2/components/AxisAnimatedLogo/AnimatedLogo';

const Update = ({ update }) => {
  const { __typename: facet } = update;
  if (facet === 'FeedEducation') return <EducationUpdate update={update} />;
  if (facet === 'FeedEvent') return <EventCard event={update} />;
  if (facet === 'FeedRegulation') return <RegulationUpdate update={update} />;
  if (facet === 'FeedRole') return <RoleUpdate update={update} />;
  if (facet === 'FeedQuote') return <QuoteUpdate update={update} />;
  if (facet === 'FeedAppointment')
    return <AppointmentCard appointment={update} />;
  return null;
};

const FeedList = ({ updatedDays }) => {
  const {
    results,
    totals,
    setPagination,
    isResultsLoading,
    extraFilter,
    feedResultType,
  } = useFeed();

  const { totalConnections } = useAxisData();

  const totalResults = useMemo(() => {
    if (feedResultType === 'regulation') {
      return totals.find((item) => item.type === 'regulation').count;
    }
    return totals.reduce((acc, total) => acc + total.count, 0);
  }, [totals]);

  const list = useRef();
  const loader = useRef(null);
  const remaining = totalResults - results.length;

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];

      if (target.isIntersecting && remaining > 0) {
        setPagination({
          offset: results.length,
          limit: Math.min(10, remaining),
        });
      }
    },
    [remaining],
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    const element = loader && loader.current;
    if (!element) return () => {};

    observer.observe(element);
    return () => observer.unobserve(element);
  }, [handleObserver]);

  const getEmptyResultText = () => {
    if (extraFilter === 'connections' && totalConnections === 0) {
      return 'No Connections found, connect yourself with an entity by using the "Add Connection" button in the dossier';
    }
    return 'There are no results to show at this time.';
  };

  const updateMessage = useMemo(() => {
    if (updatedDays === 1) {
      return `${totalResults} update in the past day`;
    }

    return `${totalResults} updates in the past ${updatedDays} days`;
  }, [totalResults, updatedDays]);

  return (
    <div className={css.main} data-cy="feed-list">
      <div ref={list} data-cy="feed-update-view">
        {results.length === 0 && !isResultsLoading && (
          <div data-cy="feed-list-empty" className={css.empty}>
            <p className={css.text}>{getEmptyResultText()}</p>
          </div>
        )}
        {results.map((update) => (
          <Update key={`${update.id}`} update={update} />
        ))}
        <div ref={loader} />
        {isResultsLoading &&
          (results.length === 0 ? (
            <div className={css.loadingContainer}>
              <EventCardSkeleton />
              <EventCardSkeleton />
              <EventCardSkeleton />
              <EventCardSkeleton />
            </div>
          ) : (
            <div className={css.loading}>
              <AnimatedLogo customClass={css.loadingSpinner} />
            </div>
          ))}
        {results.length > 0 && (
          <div className={css.total} data-cy="feed-list-total">
            <p className={css.description}>{updateMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedList;
