import React from 'react';

import css from './CountryOption.module.css';

function CountryOption({ option, onChange }) {
  return (
    <div className={css.main} onClick={() => onChange(option)}>
      <input
        type="checkbox"
        checked={option.selected}
        onChange={(e) => {
          e.stopPropagation();
        }}
      />
      <div className={css.optionName}>{option.name}</div>
    </div>
  );
}

export default CountryOption;
