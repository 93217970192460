import React, { useMemo, useState } from 'react';

import { Popover } from 'react-tiny-popover';
import css from './NotificationCard.module.css';
import {
  formatTime,
  getIconByType,
  getNotificatioReason,
  getNotificationActions,
} from './utils';
import IconButton from '../../../IconButton/IconButton';
import More from '../../../../../icons/More';

function NotificationCard({ notification, selected, onClick, handleAction }) {
  const [isMenuOpen, setIsMenuOpen] = useState();

  const icon = useMemo(() => getIconByType(notification.type), [notification]);
  const [actions, reason] = useMemo(
    () => [
      getNotificationActions(notification),
      getNotificatioReason(notification),
    ],
    [notification],
  );

  return (
    <div
      className={css.main}
      data-unread={!notification.read}
      onClick={() => onClick(notification)}
    >
      <div className={css.selected} data-selected={selected} />
      <div className={css.icon}>{icon}</div>
      <div className={css.info}>
        <div className={css.infoTime}>{formatTime(notification.timestamp)}</div>
        <div className={css.infoMain}>{notification.title}</div>
        <div className={css.infoSecondary}>{notification.description}</div>
      </div>
      <Popover
        isOpen={isMenuOpen}
        positions={['bottom', 'right']}
        containerClassName={css.menu}
        onClickOutside={(e) => {
          setIsMenuOpen(!isMenuOpen);
          e.stopPropagation();
        }}
        content={
          <div>
            {actions.map((action, index) => (
              <div
                key={index}
                className={css.menuItem}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAction(notification, action);
                  setIsMenuOpen(false);
                }}
              >
                {action.label}
              </div>
            ))}
            <div className={css.reason}>{reason}</div>
          </div>
        }
      >
        <IconButton
          onClick={(e) => {
            setIsMenuOpen(true);
            e.stopPropagation();
          }}
        >
          <More />
        </IconButton>
      </Popover>
    </div>
  );
}

export default NotificationCard;
