import React from 'react';

import css from './PopupContent.module.css';
import PersonOutline from '../../../../../icons/PersonOutline';
import EntityPill from '../../../../../components/EntityPill/EntityPill';
import ListDivider from '../../components/ListDivider/ListDivider';
import AdvancedSearch from '../../../../../icons/AdvancedSearch';

function EmptyResults({ query }) {
  return (
    <div className={css.emptyState}>
      <div className={css.emptyIcon}>
        <AdvancedSearch />
      </div>
      <div>No results for &quot;{query}&quot;</div>
      <div>
        Please, check your spelling or <br /> try searching for something else
      </div>
    </div>
  );
}

function EmptyState() {
  return (
    <div className={css.emptyState}>
      <div className={css.emptyIcon}>
        <PersonOutline />
      </div>
      <div>No selected actors </div>
      <div>Search for actors to filter</div>
    </div>
  );
}

function LoadingState() {
  return (
    <div className={css.loadingState}>
      <div style={{ height: 40 }} className="skeleton-v2" />
      <div style={{ height: 40 }} className="skeleton-v2" />
      <div style={{ height: 40 }} className="skeleton-v2" />
      <div style={{ height: 40 }} className="skeleton-v2" />
      <div style={{ height: 40 }} className="skeleton-v2" />
      <div style={{ height: 40 }} className="skeleton-v2" />
    </div>
  );
}

const Option = ({ option, onChange }) => {
  return (
    <div className={css.option} onClick={() => onChange(option)}>
      <input
        type="checkbox"
        checked={option.selected}
        onChange={(e) => {
          e.stopPropagation();
        }}
      />
      <EntityPill
        id={option.id}
        image={option.image}
        name={option.name}
        shortName={option.shortestName}
        tooltip={option.tooltip}
        type="Actor"
        isConnected={option.isConnected}
        inSubscription={option.inSubscription}
        onClick={() => {}}
        size="small"
      />
    </div>
  );
};

function PopupContent({
  query,
  selectedOptions,
  searchResults,
  isLoading,
  onChange,
  deselectAll,
}) {
  if (isLoading) return <LoadingState />;

  if (
    searchResults.length === 0 &&
    selectedOptions.length === 0 &&
    query.length > 0
  )
    return <EmptyResults query={query} />;

  if (searchResults.length === 0 && selectedOptions.length === 0)
    return <EmptyState />;

  if (searchResults.length > 0)
    return (
      <div>
        <ListDivider title="SEARCH RESULTS" />
        {searchResults.map((option) => (
          <Option key={option.id} option={option} onChange={onChange} />
        ))}
      </div>
    );

  return (
    <div>
      <ListDivider
        title="SELECTED ACTORS"
        buttonLabel="Deselect All"
        onClick={deselectAll}
      />
      {selectedOptions.map((option) => (
        <Option key={option.id} option={option} onChange={onChange} />
      ))}
    </div>
  );
}

export default PopupContent;
