import React, { useRef } from 'react';

import css from './Search.module.css';
import useUrlState from '../../../hooks/useUrlState';
import Question from './components/Question/Question';

const Divider = ({ questionLength, currentIndex }) => {
  if (questionLength <= currentIndex + 1) return null;

  return <div className={css.divider} />;
};

function Search() {
  const containerRef = useRef(null);
  const [question] = useUrlState('question');
  const questions = [question];

  return (
    <div ref={containerRef} className={css.main}>
      {questions.map((q, index) => (
        <React.Fragment key={index}>
          <Question key={q} question={q} />
          <Divider
            key={`divider-${index}`}
            currentIndex={index}
            questionLength={questions.length}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

export default Search;
