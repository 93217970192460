import React from 'react';
import { useSearch } from '../../Search';

import css from './AiSuggestions.module.css';
import { ResultEntity } from '../ResultCards/ResultCards';
import AiBadge from '../../../../v2/components/AiBadge/AiBadge';

function AiSuggestions() {
  const { aiEntitySuggestions } = useSearch();

  if (!aiEntitySuggestions || aiEntitySuggestions.length === 0) return null;

  return (
    <div data-cy="search-results-ai" className={css.category}>
      <div className={css.header}>
        <p className={css.title}>Suggestions</p>
        <AiBadge />
      </div>
      <div className={css.results}>
        {aiEntitySuggestions.map((entity) => (
          <ResultEntity key={entity.id} entity={entity} />
        ))}
      </div>
    </div>
  );
}

export default AiSuggestions;
