import React from 'react';
import Check from '../../../icons/Check';

import css from './CompliantBadge.module.css';

function CompliantBadge() {
  return (
    <button type="button" className={css.compliant}>
      <Check />
      Compliant
    </button>
  );
}

export default CompliantBadge;
